import { HelperService } from '@app/services/helper.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./helper.service";
var ReportsService = /** @class */ (function () {
    function ReportsService(_http, helperService) {
        this._http = _http;
        this.helperService = helperService;
    }
    ReportsService.prototype.generateEventLogReports = function (requestParams) {
        var queryParams = this.helperService.buildQueryParams(requestParams);
        return this._http.get('/admin/reports/logs/generate', {
            params: queryParams,
            responseType: 'blob',
            observe: 'response'
        });
    };
    ReportsService.prototype.generateSearchEntriesReports = function (requestParams) {
        var queryParams = this.helperService.buildQueryParams(requestParams);
        return this._http.get('/admin/reports/search-entries/generate', {
            params: queryParams,
            responseType: 'blob',
            observe: 'response'
        });
    };
    ReportsService.prototype.generateUserReport = function (reportUsers) {
        var queryParam = this.helperService.buildQueryParams(reportUsers);
        return this._http.get('/admin/reports/users/generate', {
            responseType: 'blob',
            observe: 'response',
            params: queryParam
        });
    };
    ReportsService.prototype.getTableEventLogReport = function (paginatorConfig) {
        return this._http.get('/admin/reports/logs', paginatorConfig);
    };
    ReportsService.prototype.getTableSearchEntriesReport = function (paginatorConfig) {
        return this._http.get('/admin/reports/search-entries', paginatorConfig);
    };
    ReportsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportsService_Factory() { return new ReportsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HelperService)); }, token: ReportsService, providedIn: "root" });
    return ReportsService;
}());
export { ReportsService };
