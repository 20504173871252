<div class="container">
  <div class="row">
    <div class="col-12 text-justify">
      <h4 class="text-center mb-4">
        Regulamin korzystania z usługi świadczonej drogą elektroniczną przy użyciu Aplikacji „Małopolska nauka” bazy
        danych kompetencji pracowników naukowych, powstałej w związku z realizacją projektu pn. SPIN - Małopolskie
        Centra Transferu Wiedzy wsparciem dla przedsiębiorców w ramach Regionalnego Programu Operacyjnego Województwa
        Małopolskiego na lata 2014 – 2020.
      </h4>
      <h5 class="text-center">Rozdział I</h5>
      <h6 class="text-center mb-4">Definicje</h6>
      <ol>
        <li>
          <strong>Aplikacja</strong> &ndash; oprogramowanie o nazwie „Małopolska nauka” - baza danych kompetencji
          pracowników naukowych, przeznaczone do uruchamiania za pomocą przeglądarki internetowej na posiadanym przez
          Użytkownika Urządzeniu, za pomocą którego Usługodawca świadczy usługi drogą elektroniczną. Aplikacja umożliwia
          odnalezienie pracownika naukowego mogącego przygotować nowatorskie rozwiązanie, bądź przedsiębiorcę z terenu
          Województwa Małopolskiego, chętnego do nawiązania współpracy oraz posiadającego kompetencje, które pozwolą
          wdrożyć, przetestować nowatorskie rozwiązania. Baza zawiera dwa rodzaje zestawów informacji: o grupie
          naukowców oraz o grupie przedsiębiorców. Dane w Bazie mogą być przeszukiwane zarówno po dokonaniu rejestracji
          jak i bez niej, jednak dopiero po dokonaniu bezpłatnej rejestracji użytkownik otrzymuje dostęp, nie tylko do
          danych zgromadzonych w bazie, ale także do możliwości nawiązania współpracy bezpośrednio za pomocą
          wewnętrznego komunikatora.
        </li>
        <li><strong>Regulamin</strong> &ndash; niniejszy regulamin.</li>
        <li>
          <strong>Serwis</strong> &ndash; serwis internetowy prowadzony przez Usługodawcę pod adresem
          <strong>https://wiedza.malopolska.pl</strong>, w którym świadczy on usługi drogą elektroniczną.
        </li>
        <li>
          <strong>System</strong> &ndash; platforma komunikacyjna „Małopolska nauka” - baza danych kompetencji
          pracowników naukowych umożliwiająca korzystanie z niej za pośrednictwem Serwisu, Aplikacji.
        </li>
        <li>
          <strong>Usługodawca</strong> &ndash; Województwo Małopolskie z siedzibą przy ulicy Basztowej 22, 31-156
          Kraków, NIP 676-21-78-337
        </li>
        <li>
          <strong>Administrator</strong> &ndash; Województwo Małopolskie z siedzibą przy ulicy Basztowej 22, 31-156
          Kraków, NIP 676-21-78-337
        </li>
        <li>
          <strong>Urządzenie</strong> &ndash; telekomunikacyjne urządzenie końcowe w rozumieniu art. 2 pkt 43 ustawy z
          dnia 16 lipca 2004 r. Prawo telekomunikacyjne (np. telefon komórkowy, tablet, komputer PC).
        </li>
        <li>
          <strong>Umowa</strong> &ndash; umowa o świadczenie usług drogą elektroniczną zawarta między Usługodawcą a
          Użytkownikiem w związku z korzystaniem z Aplikacji, Serwisu, zgodnie z postanowieniami niniejszego Regulaminu.
        </li>
        <li>
          <strong>Usługa</strong> &ndash; usługa w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą
          elektroniczną, świadczona na rzecz Użytkownika na podstawie Regulaminu, polegająca na udostępnieniu bazy
          danych naukowców małopolskich uczelni i przedsiębiorców zainteresowanych współpracą wraz z możliwością
          komunikacji pomiędzy przedsiębiorcami a naukowcami oraz monitorowaniem efektów nawiązanej współpracy.
        </li>
        <li>
          <strong>Użytkownik</strong> &ndash; osoba fizyczna oraz przedsiębiorca (tj. osoba fizyczna, osoba prawna oraz
          jednostka organizacyjna nieposiadającą osobowości prawnej, której ustawa przyznaję zdolność prawną i która we
          własnym imieniu prowadzi działalność gospodarczą) korzystający z Aplikacji.
        </li>
        <li><strong>Zarejestrowany Użytkownik</strong> - użytkownik, który założył konto w Systemie.</li>
      </ol>
      <h5 class="text-center mt-4">Rozdział II</h5>
      <h6 class="text-center mb-4">Postanowienia ogólne</h6>
      <ol>
        <li>
          Niniejszy Regulamin określa warunki i zasady korzystania z Aplikacji, Serwisu udostępnianych przez
          Usługodawcę, a także prawa i obowiązki Użytkowników oraz Usługodawcy. Regulamin normuje zakres
          odpowiedzialności Usługodawcy jako podmiotu zarządzającego Systemem.
        </li>
        <li>
          Warunkiem korzystania z Aplikacji, Serwisu jest zapoznanie się z Regulaminem i jego akceptacja.
        </li>
      </ol>
      <h5 class="text-center mt-4">Rozdział III</h5>
      <h6 class="text-center mb-4">Wymagania techniczne</h6>
      <p class="mb-0">
        W celu korzystania z Aplikacji Użytkownik powinien zapewnić następujące wymagania techniczne:
      </p>
      <div class="ml-4">
        <div>a)&nbsp;aktywne połączenie internetowe,</div>
        <div>
          b)&nbsp;zainstalowana przeglądarka internetowa w jednej z poniżej podanych wersji: Microsoft Edge, Internet
          Explorer (od wersji 10), Chrome (od wersji 30), Firefox (od wersji 26), Safari (od wersji 5),Opera (od wersji
          18).
        </div>
      </div>
      <h5 class="text-center mt-4">Rozdział IV</h5>
      <h6 class="text-center mb-4">Logowanie i korzystanie z Aplikacji</h6>
      <ol>
        <li>
          Z Aplikacji można korzystać bez logowania lub logując się za pomocą formularza logowania.
        </li>
        <li>
          Zalogowanie odbywa się z wykorzystaniem danych profilu Użytkownika i wymagane jest, jeśli Użytkownik chce
          poznać szczegółowe dane modułu Wyszukiwarki lub skorzystać z modułu Administratora lub Komunikatora.
        </li>
        <li>Usługodawca ma dostęp do danych zalogowanych Użytkowników.</li>
        <li>
          Usługodawca świadczy następujące usługi:
          <div class="ml-4">
            <div>a)&nbsp;Katalogowanie danych Użytkowników</div>
            <div>b)&nbsp;Wyszukiwanie Użytkowników</div>
            <div>c)&nbsp;Wysyłanie korespondencji pomiędzy Użytkownikami Aplikacji.</div>
          </div>
        </li>
        <li>
          Korzystanie z Aplikacji jak i jej poszczególnych elementów jest dobrowolne i nieodpłatne.
        </li>
        <li>
          Z chwilą rozpoczęcia korzystania z Aplikacji między Usługodawcą a Użytkownikiem zawierana jest Umowa na czas
          nieokreślony.
        </li>
        <li>
          W celu korzystania z pełnej funkcjonalności Aplikacji Urządzenie musi mieć zapewnione aktywne połączenie
          internetowe.
        </li>
      </ol>
      <h5 class="text-center mt-4">Rozdział V</h5>
      <h6 class="text-center mb-4">Odpowiedzialność Użytkownika</h6>
      <ol>
        <li>
          Użytkownik przyjmuje do wiadomości, że Aplikacja wykorzystuje połączenie internetowe do wielu funkcji a za
          dostęp do sieci Internet lub transmisji danych może być pobierana opłata zgodnie z cennikiem dostawcy.
        </li>
        <li>
          Użytkownicy odpowiedzialni są za wszelkie naruszenia obowiązującego prawa oraz postanowień niniejszego
          Regulaminu podczas korzystania z Aplikacji, Serwisu.
        </li>
        <li>
          Zakazane jest wprowadzanie przez Użytkowników jakiejkolwiek treści o charakterze bezprawnym, w szczególności:
          <div class="ml-4">
            <div>a)&nbsp;naruszających w jakikolwiek sposób obowiązujące prawo i dobre obyczaje,</div>
            <div>b)&nbsp;wzywających do nienawiści rasowej, etnicznej, wyznaniowej,</div>
            <div>c)&nbsp;zawierających treści pornograficzne,</div>
            <div>d)&nbsp;pochwalających faszyzm, nazizm, komunizm,</div>
            <div>e)&nbsp;propagujących przemoc, obrażających uczucia religijne,</div>
            <div>f)&nbsp;uznawanych za spam,</div>
            <div>g)&nbsp;nieprawdziwych.</div>
          </div>
        </li>
        <li>
          Użytkownik ponosi pełną odpowiedzialność za złamanie obowiązującego prawa bądź szkodę wywołaną jego
          działaniami w Aplikacji, w szczególności za podanie niezgodnych z prawem informacji lub nieprawdziwych danych
          osobowych, naruszenie dóbr osobistych lub praw autorskich oraz praw pokrewnych, a także ujawnienie tajemnicy
          służbowej lub innej informacji poufnej.
        </li>
        <li>
          Administrator zastrzega sobie prawo do odmowy świadczenia usług wobec Użytkownika, w przypadku naruszenia lub
          uzasadnionego podejrzenia naruszenia przez Użytkownika obowiązującego prawa lub postanowień niniejszego
          Regulaminu.
        </li>
        <li>
          Każdy może zgłosić Usługodawcy fakt naruszenia powyższych warunków, w szczególności osoba, która nie wyrażała
          zgody na rozpowszechnienie jej wizerunku, może żądać niezwłocznego usunięcia zdjęcia, na którym został
          utrwalony jej wizerunek. Zgłoszenia można wysyłać na kontakt@wiedza.malopolska.pl
        </li>
        <li>
          Administrator nie odpowiada za ewentualne naruszenie prawa przez Użytkownika umieszczającego zdjęcia i inne
          treści w Aplikacji.
        </li>
        <li>
          Użytkownikowi nie wolno Aplikacji kopiować, reprodukować, dekompilować, dezasemblować, itp. ani w żaden inny
          sposób próbować poznać kod źródłowy Aplikacji.
        </li>
        <li>
          Użytkownik nie może w żaden sposób rozpowszechniać Aplikacji, wydzierżawiać, wynajmować, użyczać, itp.
        </li>
        <li>
          Usługodawca nie gwarantuje, że informacje pozyskane z systemów zewnętrznych będą zawsze dostępne.
        </li>
        <li>
          Usługodawca nie gwarantuje, że transmisja danych za pośrednictwem innych systemów, w szczególności w
          Internecie i w sieciach telekomunikacyjnych, nie jest śledzona, nagrywana lub zniekształcona przez osoby
          trzecie.
        </li>
        <li>
          Użytkownik wyraźnie potwierdza, że dane odczytywane bezpośrednio z urządzenia lub przy użyciu zewnętrznych
          usług mogą zawierać błędy, a Usługodawca nie ponosi odpowiedzialności za prawidłowość tych danych.
        </li>
      </ol>
      <h5 class="text-center mt-4">Rozdział VI</h5>
      <h6 class="text-center mb-4">Wyłączenie odpowiedzialności Usługodawcy</h6>
      <ol>
        <li>
          Usługodawca nie ponosi odpowiedzialności za zakłócenia w prawidłowym funkcjonowaniu Aplikacji a także utraty
          danych Użytkownika powstałych w wyniku działania siły wyższej, osób trzecich, a także w wyniku działania
          Usługodawcy zmierzającego do poprawy Aplikacji.
        </li>
        <li>
          Usługodawca nie odpowiada za działania osób trzecich polegające na niezgodnym z powszechnie obowiązującym
          prawem lub niniejszym Regulaminem wykorzystywaniu kont Użytkowników.
        </li>
        <li>
          Zawartość Aplikacji, jest dostarczana „w aktualnej postaci” („as is”), w najszerszym zakresie dozwolonym przez
          obowiązujące prawo, z wyłączeniem wszelkiej gwarancji, wyraźnej lub domniemanej, co do treści lub sposobu
          funkcjonowania Aplikacji i każdego jej elementu. Usługodawca ani żaden podmiot z nim powiązany nie ponosi
          odpowiedzialności za jakiekolwiek szkody dowolnego rodzaju wynikające z korzystania z Aplikacji, a także
          treści w nim zawartej i nie udziela żadnych gwarancji jakiegokolwiek rodzaju wyrażonych wprost, dorozumianych,
          ustawowych lub w inny sposób, w tym, bez ograniczeń, gwarancji przydatności do określonego celu lub braku
          ukrytych lub innych wad, lub braku błędów. Usługodawca nie odpowiada ponadto wobec Użytkowników i osób
          trzecich za:
          <div class="ml-4">
            <div>a)&nbsp;treści zamieszczane przez Użytkowników w Aplikacji,</div>
            <div>
              b)&nbsp;jakiekolwiek szkody Użytkownika spowodowane nieprawidłowym zapisem lub odczytem treści przez
              Użytkowników,
            </div>
            <div>
              c)&nbsp;szkody powstałe w wyniku wyłączenia Aplikacji lub awarii teleinformatycznej, awarii sieci
              energetycznej, itp.
            </div>
          </div>
        </li>
        <li>
          Usługodawca nie ponosi odpowiedzialności za poniesione szkody, w tym utracone korzyści Użytkownika, jak
          również osób trzecich wynikające z ograniczeń, błędów oprogramowania lub urządzeń, wirusów, straty danych lub
          nieprawidłowego używania samej Aplikacji.
        </li>
      </ol>
      <h5 class="text-center mt-4">Rozdział VII</h5>
      <h6 class="text-center mb-4">Produkty podmiotów trzecich</h6>
      <ol>
        <li>
          Usługodawca nie ponosi odpowiedzialności za produkty i komponenty podmiotów trzecich, które są częścią
          Aplikacji, Serwisu lub do których dostęp Użytkownik może uzyskać w związku z korzystaniem z Aplikacji,
          Serwisu. W szczególności, nie jest odpowiedzialny za poprawność działania, przydatność do określonego celu i
          bezpieczeństwo danych. Przed użyciem produktu podmiotu trzeciego, Użytkownik zobowiązany jest zapoznać się z
          jego warunkami używania, regulaminami i polityką prywatności.
        </li>
        <li>
          W przypadku korzystania z usług podmiotów trzecich (np. poprzez kliknięcie odnośnika do zewnętrznego serwisu
          bądź użycie komponentu podmiotu trzeciego, będącego częścią Aplikacji) może być wymagane podanie danych (np.
          adres e-mail, imię), które są przetwarzane przez dostawcę tej usługi. Usługodawca nie odpowiada za
          przetwarzanie tych danych przez podmioty trzecie. Zaleca się zapoznanie z polityką prywatności dostawcy
          usługi.
        </li>
        <li>
          Administrator może używać rozwiązania Google Analytics w celu monitorowania i przekazywania anonimowych
          informacji dotyczących sposobu korzystania z Aplikacji lub Serwisu. Polityka prywatności firmy Google jest
          dostępna na stronie internetowej:
          <a href="https://policies.google.com/privacy?hl=pl" target="_blank"
            >https://policies.google.com/privacy?hl=pl</a
          >.
        </li>
      </ol>
      <h5 class="text-center mt-4">Rozdział VIII</h5>
      <h6 class="text-center mb-4">Zmiana regulaminu</h6>
      <ol>
        <li>
          Usługodawca zastrzega sobie prawo do zmiany niniejszego Regulaminu w dowolnym momencie według własnego
          uznania.
        </li>
        <li>
          Poprzez zapoznanie się i zaakceptowanie nowej wersji Regulaminu umieszczonej w Aplikacji Użytkownik wyraża
          zgodę na przestrzeganie zmienionego Regulaminu.
        </li>
        <li>
          Jeżeli Użytkownik nie zgadza się na zmianę Regulaminu, powinien niezwłocznie zaprzestać korzystania z
          Aplikacji, Serwisu.
        </li>
      </ol>
      <h5 class="text-center mt-4">Rozdział IX</h5>
      <h6 class="text-center mb-4">Rozwiązanie Umowy</h6>
      <ol>
        <li>
          Użytkownik może rozwiązać Umowę w każdym czasie poprzez wysłanie wiadomości do Administratora na adres
          kontakt@wiedza.malopolska.pl
        </li>
        <li>
          Rozwiązanie Umowy przez Użytkownika jest równoznaczne z zaprzestaniem świadczenia Usług na jego rzecz.
        </li>
        <li>
          Usługodawca może w każdym czasie postanowić o zaprzestaniu rozwijania lub wspierania Aplikacji, Serwisu oraz
          wyłączyć dostęp do Systemu.
        </li>
        <li>
          Usługodawca może usunąć konto Użytkownika w razie naruszenia niniejszego Regulaminu, w szczególności
          wykorzystywania Aplikacji, Serwisu niezgodnie z ich przeznaczeniem oraz powszechnie obowiązującymi przepisami
          prawa.
        </li>
        <li>Usługodawca zastrzega sobie prawo do zaprzestania świadczenia Usług w każdym czasie.</li>
        <li>
          Po rozwiązaniu Umowy Użytkownik zobowiązany jest do niezwłocznego zaprzestania korzystania z Aplikacji,
          Serwisu.
        </li>
      </ol>
      <h5 class="text-center mt-4">Rozdział X</h5>
      <h6 class="text-center mb-4">Odstąpienie od Umowy</h6>
      <ol>
        <li>
          Użytkownik będący konsumentem ma prawo odstąpić od Umowy bez podania przyczyny w ciągu 14 (czternastu) dni od
          daty jej zawarcia.
        </li>
        <li>
          W celu odstąpienia od Umowy Użytkownik składa Usługodawcy oświadczenie o odstąpieniu od Umowy.
        </li>
        <li>
          Dla zachowania terminu do odstąpienia od Umowy, o którym mowa w punkcie 1, wystarczające jest wysłanie
          oświadczenia o odstąpieniu na adres kontakt@wiedza.malopolska.pl przed upływem terminu do odstąpienia od
          Umowy.
        </li>
        <li>
          Po złożeniu oświadczenia o odstąpieniu od Umowy Użytkownik zobowiązany jest do niezwłocznego zaprzestania
          korzystania z Aplikacji, Serwisu.
        </li>
      </ol>
      <h5 class="text-center mt-4">Rozdział XI</h5>
      <h6 class="text-center mb-4">Postępowanie reklamacyjne</h6>
      <ol>
        <li>
          Reklamacje związane ze świadczeniem Usług mogą być wysyłane na adres: kontakt@wiedza.malopolska.pl
        </li>
        <li>
          Reklamacje będą rozpatrywane w ciągu 14 dni roboczych od ich dostarczenia do Usługodawcy.
        </li>
        <li>
          Po rozpatrzeniu reklamacji Usługodawca niezwłocznie poinformuje osobę wnoszącą reklamacje o jej wyniku.
        </li>
      </ol>
      <h5 class="text-center mt-4">Rozdział XII</h5>
      <h6 class="text-center mb-4">Prywatność</h6>
      <ol>
        <li>
          Polityka Prywatności określająca zasady dotyczące zbierania, przetwarzania i ochrony danych dotyczących
          Użytkowników Systemu, stanowi załącznik nr 1 do niniejszego Regulaminu.
        </li>
        <li>
          W celu świadczenia usługi Aplikacja korzysta z plików cookies tj. plików które służą identyfikacji
          przeglądarki użytkownika Aplikacji podczas korzystania z usługi.
        </li>
        <li>
          W przypadku pytań lub wątpliwości co do przetwarzania danych Użytkowników lub stosowania plików cookies
          Administrator prosi o kontakt na adres e-mail: kontakt@wiedza.malopolska.pl
        </li>
      </ol>
      <h5 class="text-center mt-4">Rozdział XIII</h5>
      <h6 class="text-center mb-4">
        Informacja o szczególnych zagrożeniach związanych z korzystaniem przez użytkowników z usług świadczonych drogą
        elektroniczną przez Województwo Małopolskie z siedzibą przy ulicy Basztowej 22, 31-156 Kraków, NIP 676-21-78-337
      </h6>
      <p>
        Usługodawca stosownie do art. 6 pkt 1) ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną,
        informuje o szczególnych zagrożeniach związanych z korzystaniem przez użytkowników z usług świadczonych drogą
        elektroniczną przez Usługodawcę:
      </p>
      <ol>
        <li>
          Możliwość otrzymania spamu, czyli niezamówionej informacji reklamowej (handlowej) przekazywanej drogą
          elektroniczną.
        </li>
        <li>
          Możliwość odnalezienia słabości systemu kryptograficznego i złamania zabezpieczeń systemu teleinformatycznego
          w celu pozyskania danych osobowych i informacji.
        </li>
        <li>
          Możliwość odnalezienia słabości systemu kryptograficznego, a tym samym umożliwienia jego złamania lub
          obejścia, a w konsekwencji możliwość pozyskania osobistych i poufnych informacji w celu kradzieży tożsamości.
        </li>
        <li>
          Działanie oprogramowania typu malware, czyli oprogramowania, które jest w stanie, po uruchomieniu, zarazić
          pliki w sposób samopowielający, zazwyczaj nie będąc zauważonym przez Użytkownika.
        </li>
        <li>
          Obecność i działanie robaków internetowych (worm), czyli szkodliwego oprogramowania zdolnego do
          samopowielania.
        </li>
        <li>
          Możliwość zadziałania oprogramowania typu spyware, to jest oprogramowania szpiegującego działania Użytkownika
          w Internecie, instalującego się bez jego wiedzy, zgody i kontroli.
        </li>
        <li>
          Możliwość bycia narażonym na cracking lub phishing (łowienie haseł) poprzez wysyłanie fałszywych wiadomości
          elektronicznych przypominających do złudzenia autentyczne i w konsekwencji pozyskanie osobistych i poufnych
          informacji dotyczących użytkownika.
        </li>
        <li>
          Możliwość bycia narażonym na sniffing - niedozwolony podsłuch, polegający na wykorzystaniu sniffera - programu
          komputerowego, którego zadaniem jest przechwytywanie i ewentualne analizowanie danych przepływających w sieci.
        </li>
        <li>
          Możliwość bycia narażonym na działania innego niechcianego lub "złośliwego" oprogramowania, wykonującego
          czynności niezamierzone przez Użytkownika, niewchodzące w granice definicji wymienionych powyżej, a
          występujące pod nazwami: wabbit, trojan, backdoor, exploit, rootkit, keylogger, dialer, hoax, itp.
        </li>
        <li>
          Naruszanie autorskich praw majątkowych poprzez ich nieuprawnione kopiowanie i wykorzystanie bez zgody i wiedzy
          podmiotu uprawnionego.
        </li>
      </ol>
      <p>
        Informacja dotyczy zagrożeń, które Usługodawca identyfikuje jako zagrożenia potencjalne, które powinny być brane
        pod uwagę mimo stosowania przez Usługodawcę systemów zabezpieczających infrastrukturę informatyczną przed
        nieuprawnionym oddziaływaniem osób trzecich.
      </p>
      <h5 class="text-center mt-4">Rozdział XIV</h5>
      <h6 class="text-center mb-4">Postanowienia końcowe</h6>
      <p>
        Wszelkie pytania, uwagi i sugestie oraz reklamacje powinny być wysyłane na e-mail: kontakt@wiedza.malopolska.pl.
      </p>
      <p><strong>Aktualizacja z dnia 27.08.2020r.</strong></p>
    </div>
  </div>
</div>
