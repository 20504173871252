import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DictionaryService = /** @class */ (function () {
    function DictionaryService(_http) {
        this._http = _http;
        this.resourceUrl = '/dictionaries/roles';
    }
    DictionaryService.prototype.getRoles = function () {
        return this._http.get(this.resourceUrl);
    };
    DictionaryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DictionaryService_Factory() { return new DictionaryService(i0.ɵɵinject(i1.HttpClient)); }, token: DictionaryService, providedIn: "root" });
    return DictionaryService;
}());
export { DictionaryService };
