import { CommunicatorModule } from './entities/communicator/communicator.module';
import { UserAppModule } from './entities/user-app/user-app.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TokenInterceptor } from '@app/core/authentication/token.interceptor';
import { AdminAppModule } from './entities/admin-app/admin-app.module';
import { EntitiesModule } from '@app/entities/entities.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CallendarDefaultModule } from './shared/callendar/callendar.module';
import { WcagModule } from './shared/wcag/wcag.module';
import { NotFoundModule } from './shared/not-found/not-found.module';
import { SearchModule } from './entities/search/search.module';
import { StaticFooterModule } from './entities/static-footer/static-footer.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './translations/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgbModule,
    CoreModule,
    SharedModule,
    ShellModule,
    SearchModule,
    AdminAppModule,
    CommunicatorModule,
    UserAppModule,
    StaticFooterModule,
    EntitiesModule,
    CallendarDefaultModule,
    WcagModule,
    NotFoundModule,
    AppRoutingModule // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
