import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { isObject } from 'lodash';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  providers: [MessageService]
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private _notificationErrorSubscription$: Subject<boolean> = new Subject();

  constructor(
    private messageService: MessageService,
    public _notificationService: NotificationService,
    public translate: TranslateService
  ) {
    // Get translation from error file
    translate.use('error');
  }

  ngOnInit(): void {
    this.subscribeToMessageError();
  }
  ngOnDestroy(): void {
    this._notificationErrorSubscription$.next();
    this._notificationErrorSubscription$.complete();
  }

  addSingleMessage(message: any) {
    this.translateString(message.message).then(translatedSummary => {
      this.messageService.add({
        severity: message.type,
        summary: message.title,
        detail: translatedSummary,
        life: 5000
      });
    });
  }

  addMessageFormError(message: any) {
    const listField = Object.keys(message);

    listField.forEach(fieldName => {
      this.translateString(`fields.${fieldName}`).then(translatedField => {
        this.translateString(`errors.${message[fieldName]}`).then(translatedValue => {
          const notTranslatedString = translatedValue.search('errors.') !== -1;
          this.translateFieldError(translatedField).then(translatedSummary => {
            this.messageService.add({
              severity: 'error',
              summary: translatedSummary,
              detail: notTranslatedString ? message[fieldName] : translatedValue,
              life: 5000
            });
          });
        });
      });
    });
  }

  translateString(translateValue: string): Promise<string> {
    return new Promise(resolve => {
      this.translate.get(translateValue).subscribe(translated => {
        resolve(this.translate.instant(translated));
      });
    });
  }

  translateFieldError(translatedField: string): Promise<string> {
    return new Promise(resolve => {
      this.translate
        .get('rootFieldError', {
          fieldName: translatedField
        })
        .subscribe(translatedError => {
          resolve(translatedError);
        });
    });
  }

  private subscribeToMessageError() {
    this._notificationService
      .getMessage()
      .pipe(takeUntil(this._notificationErrorSubscription$))
      .subscribe((message: any) => {
        if (message.message) {
          this.addSingleMessage(message);
        } else if (isObject(message.error)) {
          this.addMessageFormError(message.error);
        }
      });
  }
}
