import { ConfigurationAppComponent } from './configuration-app.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { AuthenticationGuard } from '@app/core/authentication/authentication.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'configuration-app',
      component: ConfigurationAppComponent,
      data: { title: extract('Konfiguracja aplikacji'), expectedRole: ['ADMIN', 'CONTACT_ADMIN'] },
      canActivate: [AuthenticationGuard]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ConfigurationAppRoutingModule {}
