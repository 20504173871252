import { Component, OnInit, OnDestroy } from '@angular/core';
import { IProblemBase, IProblemBaseFilters } from '@app/interfaces/problem-base.interface';
import { MessageService, ConfirmationService, Message } from 'primeng/api';
import { ITableInterface, IPaginatorConfig, ITableData } from '@app/interfaces/table.interface';
import { ProblemBaseService } from '@app/services/problem-base.service';
import { TableService } from '@app/services/table.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';

@Component({
  selector: 'app-problem-base',
  templateUrl: './problem-base.component.html',
  styleUrls: ['./problem-base.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class ProblemBaseComponent implements OnInit, OnDestroy {
  problemData: IProblemBase[] = null;
  problemCols: ITableInterface[];
  paginator: IPaginatorConfig = null;
  isAdmin: boolean;
  msgs: Message[] = [];
  filters: IProblemBaseFilters = {
    description: null,
    companyName: null
  };

  constructor(
    private problemBaseService: ProblemBaseService,
    private tableService: TableService,
    private authenticationService: AuthenticationService,
    private _credentialsService: CredentialsService,
    private confirmationService: ConfirmationService,
    private _notificationService: NotificationService
  ) {}

  ngOnDestroy(): void {
    this.tableService.restorePaginatorConfig();
  }

  ngOnInit(): void {
    this.getProblems();

    this.problemCols = [
      { field: 'companyName', header: 'Nazwa' },
      { field: 'description', header: 'Opis problemu' }
    ];

    this.isAdmin = this.checkIsAnyAdmin();
  }

  checkIsAnyAdmin(): boolean {
    const isLogged = this._credentialsService.isAuthenticated();

    if (isLogged) {
      const userData = this.authenticationService.getUserData();
      return userData.roles.some((role: string) => {
        return role === 'ADMIN';
      });
    } else {
      return false;
    }
  }

  showEmptyDataAlert(): void {
    this.msgs = [];
    this.msgs.push({ severity: 'info', summary: 'Informacja', detail: 'Brak danych' });
  }

  customSort(event: any): void {
    if (event.sortField) {
      const currentPaginator = this.tableService.getPaginatorConfig();
      const paginator = {
        direction: event.sortOrder === -1 ? 'DESC' : 'ASC',
        page: currentPaginator.page,
        size: currentPaginator.size,
        properties: [event.sortField],
        sort: true
      };
      this.tableService.setPaginatorConfig(paginator);
      this.getProblems();
    }
  }

  getProblems(isFilter: boolean = false): void {
    if (isFilter) {
      this.tableService.restorePaginatorConfig();
    }

    this.problemBaseService
      .getProblems(this.tableService.prepareParamsToServer(this.filters))
      .subscribe((problemData: ITableData) => {
        this.problemData = problemData.content;
        this.tableService.setPaginatorConfigFormServer(problemData);
        this.paginator = this.tableService.getPaginatorConfig();

        if (!problemData.content.length) {
          this.showEmptyDataAlert();
        }
      });
  }

  paginate(paginatorEvent: any): void {
    const currentPaginator = this.tableService.getPaginatorConfig();
    const paginator = {
      direction: currentPaginator.direction,
      page: paginatorEvent.page,
      size: paginatorEvent.rows,
      properties: currentPaginator.properties,
      sort: currentPaginator.sort
    };
    this.tableService.setPaginatorConfig(paginator);
    this.getProblems();
  }

  clearFilter() {
    this.filters = {
      companyName: null,
      description: null
    };
  }

  removeProblem(problem: IProblemBase) {
    if (this.isAdmin) {
      this.confirmationService.confirm({
        message: `Czy na pewno chcesz usunąć problem z bazy?`,
        accept: () => {
          this.problemBaseService.removeProblem(problem.id).subscribe(resp => {
            this._notificationService.sendMessage({
              type: 'success',
              title: 'Sukces!',
              message: 'Pomyślnie usunięto wpis z bazy problemów'
            });
            this.getProblems();
          });
        }
      });
    }
  }
}
