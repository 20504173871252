<div class="modal-body">
  <div class="row">
    <div class="col-6 text-center">
      <img src="assets/images/scientist-icon.png" class="img-fluid" alt="Naukowiec" /><br />
      <button
        class="btn btn-blue"
        type="button"
        (click)="selectAccountType('scientist')"
        aria-label="Przycisk wyboru typu konta naukowiec"
      >
        Naukowiec
      </button>
    </div>
    <div class="col-6 text-center">
      <img src="assets/images/employer-icon.png" class="img-fluid" alt="Przedsiębiorca" /><br />
      <button
        class="btn btn-green"
        type="button"
        (click)="selectAccountType('employer')"
        aria-label="Przycisk wyboru typu konta przedsiębiorca"
      >
        Przedsiębiorca
      </button>
    </div>
  </div>
</div>
