/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-type-selection.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./account-type-selection.component";
import * as i3 from "../../../shared/dialog/dialog-base.service";
var styles_AccountTypeSelectionComponent = [i0.styles];
var RenderType_AccountTypeSelectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountTypeSelectionComponent, data: {} });
export { RenderType_AccountTypeSelectionComponent as RenderType_AccountTypeSelectionComponent };
export function View_AccountTypeSelectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 19, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "col-6 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "Naukowiec"], ["class", "img-fluid"], ["src", "assets/images/scientist-icon.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["aria-label", "Przycisk wyboru typu konta naukowiec"], ["class", "btn btn-blue"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectAccountType("scientist") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        Naukowiec\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(13, 0, null, null, 7, "div", [["class", "col-6 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(15, 0, null, null, 0, "img", [["alt", "Przedsi\u0119biorca"], ["class", "img-fluid"], ["src", "assets/images/employer-icon.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["aria-label", "Przycisk wyboru typu konta przedsi\u0119biorca"], ["class", "btn btn-green"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectAccountType("employer") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        Przedsi\u0119biorca\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_AccountTypeSelectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-type-selection", [], null, null, null, View_AccountTypeSelectionComponent_0, RenderType_AccountTypeSelectionComponent)), i1.ɵdid(1, 114688, null, 0, i2.AccountTypeSelectionComponent, [i3.DialogBaseService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountTypeSelectionComponentNgFactory = i1.ɵccf("app-account-type-selection", i2.AccountTypeSelectionComponent, View_AccountTypeSelectionComponent_Host_0, {}, {}, []);
export { AccountTypeSelectionComponentNgFactory as AccountTypeSelectionComponentNgFactory };
