import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { EventLogComponent } from './event-log.component';
import { AuthenticationGuard } from '@app/core/authentication/authentication.guard';
var ɵ0 = { title: extract('Dziennik zdarzeń'), expectedRole: ['ADMIN', 'CONTACT_ADMIN'] };
var routes = [
    Shell.childRoutes([
        {
            path: 'event-log',
            component: EventLogComponent,
            data: ɵ0,
            canActivate: [AuthenticationGuard]
        }
    ])
];
var EventLogRoutingModule = /** @class */ (function () {
    function EventLogRoutingModule() {
    }
    return EventLogRoutingModule;
}());
export { EventLogRoutingModule };
export { ɵ0 };
