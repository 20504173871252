import { PaginatorModule } from 'primeng/paginator';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationAppComponent } from './configuration-app.component';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { ConfigurationAppRoutingModule } from './configuration-app-routing.module';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FormsModule } from '@angular/forms';
import { PasswordRuleDialogComponent } from './password-rule-dialog/password-rule-dialog.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { WcagModule } from '@app/shared/wcag/wcag.module';
import { DictionaryEditComponent } from './dictionary-edit/dictionary-edit.component';

@NgModule({
  declarations: [ConfigurationAppComponent, PasswordRuleDialogComponent, DictionaryEditComponent],
  imports: [
    FormsModule,
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    WcagModule,
    CheckboxModule,
    TableModule,
    TooltipModule,
    MessagesModule,
    DropdownModule,
    ConfirmDialogModule,
    ConfigurationAppRoutingModule,
    MultiSelectModule,
    PaginatorModule,
    OverlayPanelModule
  ]
})
export class ConfigurationAppModule {}
