import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DictionariesService {
  private _dictionariesUrl = '/dictionaries/';
  constructor(private _http: HttpClient) {}

  getAllAcademicTitles(): Observable<object> {
    return this._http.get<object>(this._dictionariesUrl + 'academic-titles');
  }

  getAllIntelligentSpecialisations(): Observable<object> {
    return this._http.get<object>(this._dictionariesUrl + 'intelligent-specialisations');
  }

  getAllScienceDomains(): Observable<object> {
    return this._http.get<object>(this._dictionariesUrl + 'science-domains');
  }

  getAllSciences(): Observable<object> {
    return this._http.get<object>(this._dictionariesUrl + 'sciences');
  }

  getAllSpecialisations(): Observable<object> {
    return this._http.get<object>(this._dictionariesUrl + 'specialisations');
  }

  getAllCommunes(): Observable<object> {
    return this._http.get<object>(this._dictionariesUrl + 'communes');
  }

  getAllRoles(): Observable<object> {
    return this._http.get<object>(this._dictionariesUrl + 'roles');
  }

  getAllUniversities(): Observable<object> {
    return this._http.get<object>(this._dictionariesUrl + 'universities');
  }

  getAllDepartments(): Observable<object> {
    return this._http.get<object>(this._dictionariesUrl + 'departments');
  }

  getAllPasswordRules(): Observable<object> {
    return this._http.get<object>('/admin/password-rule');
  }

  getAllUsersFromAdmin(): Observable<object> {
    return this._http.get<object>('/admin/users');
  }

  getAllStatuses(): Observable<object> {
    return of([
      {
        name: 'Dostępny',
        id: 'AVAILABLE'
      },
      {
        name: 'Zajęty',
        id: 'BUSY'
      }
    ]);
  }

  getAllLoginMethod(): Observable<object> {
    return of([
      {
        name: 'Poprzez login',
        id: 'LOGIN'
      },
      {
        name: 'Poprzez e-mail',
        id: 'EMAIL'
      },
      {
        name: 'Poprzez login lub e-mail',
        id: 'LOGIN_EMAIL'
      }
    ]);
  }

  getAllKindOfReport(): Observable<object> {
    return of([
      {
        name: 'Komunikator',
        id: 'COMMUNICATOR'
      },
      {
        name: 'Wyszukiwarka',
        id: 'SEARCH_ENGINE'
      },
      {
        name: 'Profil administratora',
        id: 'ADMIN_PROFILE'
      },
      {
        name: 'Profil naukowca',
        id: 'SCIENTIST_PROFILE'
      },
      {
        name: 'Profil przedsiębiorcy',
        id: 'EMPLOYER_PROFILE'
      },
      {
        name: 'Dziennik zdarzeń',
        id: 'EVENT_LOG'
      }
    ]);
  }

  getAllKindUserGenerateReport(): Observable<object> {
    return of([
      {
        name: 'Główny raport',
        id: 'GENERAL'
      },
      {
        name: 'Raport aktywnych profili',
        id: 'ACTIVE_PROFILES'
      },
      {
        name: 'Raport nieaktywnych profili',
        id: 'INACTIVE_PROFILES'
      }
    ]);
  }

  getAllKindOfSearchEntries(): Observable<object> {
    return of([
      {
        name: 'Liczba pytań',
        id: 'SEARCH_ENTRY_COUNT'
      },
      {
        name: 'Data złożenia pytania',
        id: 'SEARCH_ENTRY_DATE'
      },
      {
        name: 'Liczba nawiązanych współpracy',
        id: 'ESTABLISHED_COOPERATION_COUNT'
      },
      {
        name: 'Liczba aktywnych współpracy po 30 dniach',
        id: 'ACTIVE_COOPERATION_AFTER_30_DAYS_COUNT'
      },
      {
        name: 'Liczba aktywnych współpracy po 6 miesiącach',
        id: 'ACTIVE_COOPERATION_AFTER_6_MONTH_COUNT'
      },
      {
        name: 'Liczba krótkich współpracy trwających mniej niż 30 dni',
        id: 'SHORT_ACTIVE_COOPERATION_COUNT'
      },
      {
        name: 'Liczba współpracy trwających pomiędzy 30 dni a 6 miesięcy',
        id: 'LONG_ACTIVE_COOPERATION_COUNT '
      }
    ]);
  }

  getAllUsersBasicInfo(): Observable<object> {
    return this._http.get<object>('/admin/users/basic-info');
  }

  getAllEditableDictionaries(): Observable<object> {
    return of([
      {
        name: 'Tytuły akademickie',
        id: 'ACADEMIC_TITLE',
        command: this.getAllAcademicTitles(),
        nestedDict: []
      },
      {
        name: 'Gminy',
        id: 'COMMUNES',
        command: this.getAllCommunes(),
        nestedDict: []
      },
      {
        name: 'Wydziały',
        id: 'DEPARTMENTS',
        command: this.getAllDepartments(),
        nestedDict: [
          {
            name: 'UNIVERSITIES',
            field: 'universityId',
            command: this.getAllUniversities()
          }
        ]
      },
      {
        name: 'Inteligentna specjalizacja',
        id: 'INTELLIGENT_SPECIALISATION',
        command: this.getAllIntelligentSpecialisations(),
        nestedDict: []
      },
      {
        name: 'Dziedzina',
        id: 'SCIENCE_DOMAIN',
        command: this.getAllScienceDomains(),
        nestedDict: [
          {
            name: 'SCIENCES',
            field: 'scienceId',
            command: this.getAllSciences()
          }
        ]
      },
      {
        name: 'Klasyfikacja dziedzin i nauk',
        id: 'SCIENCE',
        command: this.getAllSciences(),
        nestedDict: []
      },
      {
        name: 'Specjalizacje',
        id: 'SPECIALISATION',
        command: this.getAllSpecialisations(),
        nestedDict: [
          {
            name: 'SCIENCE-DOMAINS',
            field: 'scienceDomainId',
            command: this.getAllScienceDomains()
          },
          {
            name: 'INTELLIGENT-SPECIALISATIONS',
            field: 'intelligentSpecialisationId',
            command: this.getAllIntelligentSpecialisations()
          }
        ]
      },
      {
        name: 'Uczelnie',
        id: 'UNIVERSITY',
        command: this.getAllUniversities(),
        nestedDict: []
      }
    ]);
  }

  getSimpleUserStatus(): Observable<object> {
    return of([
      { label: 'Aktywny', value: true },
      { label: 'Zajęty', value: false }
    ]);
  }

  getAllUserStatus(): Observable<object> {
    return of([
      {
        value: 'ACTIVE',
        label: 'Aktywny'
      },
      {
        value: 'INACTIVE',
        label: 'Nieaktywny'
      },
      {
        value: 'ACTIVE_COOPERATION',
        label: 'Współpracujący'
      },
      {
        value: 'BLOCKED',
        label: 'Zablokowany'
      }
    ]);
  }

  getAllLimitedRoles(): Observable<object> {
    return of([
      {
        id: 1,
        name: 'ADMIN'
      },
      {
        id: 5,
        name: 'LIMITED_EMPLOYER'
      },
      {
        id: 4,
        name: 'LIMITED_SCIENTIST'
      },
      {
        id: 2,
        name: 'SCIENTIST'
      },
      {
        id: 3,
        name: 'EMPLOYER'
      }
    ]);
  }
}
