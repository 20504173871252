import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutBaseComponent } from './about-base.component';
import { AboutBaseRoutingModule } from './about-base-routing.module';
import { AccordionModule } from 'primeng/accordion';

@NgModule({
  declarations: [AboutBaseComponent],
  imports: [CommonModule, AccordionModule, AboutBaseRoutingModule]
})
export class AboutBaseModule {}
