import { UserManagementService } from './../../../services/user-management.service';
import { IDefaultDict } from './../../../interfaces/dictionary.interface';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DictionariesService } from '@app/services/dictionaries.service';
import { zip, BehaviorSubject } from 'rxjs';
import { ScientistService } from '@app/services/scientist.service';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { DynamicDialogConfig, SelectItem } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { each, filter, find } from 'lodash';
import { IModifyAccount, IRegeneratePassword } from '@app/interfaces/user-management.interface';
import { IUserScientist } from '@app/interfaces/user-profile.interface';

@Component({
  selector: 'app-scientist-registration',
  templateUrl: './scientist-registration.component.html',
  styleUrls: ['./scientist-registration.component.scss']
})
export class ScientistRegistrationComponent implements OnInit {
  isProfileLoaded = new BehaviorSubject<boolean>(false);
  maxTextareaLength = 2000;
  configData: any;
  isPreviewMode: boolean;
  scientistsId: number;
  intelligentSpecialisationImageId = 9;

  userScientist: IUserScientist = {
    username: null,
    email: null,
    firstName: null,
    surname: null,
    department: null,
    universityId: null,
    projectsDescription: null,
    scientificWorksDescription: null,
    descriptionOfCooperationWithEmployers: null,
    phoneNumber: null,
    academicTitleId: null,
    keyWords: null,
    numberOfProjects: null,
    leadingSpecialisationId: null,
    intelligentSpecialisationId: null,
    specialisations: []
  };

  // Dictionaries model
  academicTitlesDict: SelectItem[];
  universitiesDict: SelectItem[];
  specialisationsDict: SelectItem[];
  intelligentSpecialisationDict: SelectItem[];
  statusesDict: SelectItem[];
  departmentDict: SelectItem[];
  sciencesDict: SelectItem[];
  scienceDomainSelectDict: SelectItem[];
  allSpecialisationDict: SelectItem[];
  aditionalSpecialisationDict: SelectItem[];

  // All dictionaries
  scienceDomainsDict: any;
  specialisations: any;
  departments: any;

  constructor(
    private _dictionariesService: DictionariesService,
    private _scientistService: ScientistService,
    private _dialogService: DialogBaseService,
    private _notificationService: NotificationService,
    private _userManagementService: UserManagementService,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this._getAllDictionaries();

    this.configData = this.config.data;
    if (this.configData && this.configData.id) {
      this.isPreviewMode = this.configData.preview;
      this.getScientistUserProfile(this.configData.id);
    }
  }

  getScientistUserProfile(id: number) {
    this._scientistService.getScientist(id).subscribe(response => {
      this.userScientist = response;
      this.scientistsId = response.id;
      this.intelligentSpecialisationImageId = response.intelligentSpecialisationId;

      if (response.specialisations.length === 0) {
        this.addSpecialisation();
      }
      this.isProfileLoaded.next(true);
    });
  }

  keyWordsOnKeyDown(event: KeyboardEvent) {
    if (event.key === ',') {
      event.preventDefault();
      const element = event.target as HTMLElement;
      element.blur();
      element.focus();
    }
  }

  addSpecialisation() {
    const emptySpecialisation: any = {
      id: null,
      name: null
    };

    this.userScientist.specialisations.push(emptySpecialisation);
  }

  removeEmptySpecialisation() {
    const isNotEmptySpecialisation = this.userScientist.specialisations.some(specialisation => {
      return specialisation.id !== null;
    });

    if (!isNotEmptySpecialisation) {
      this.userScientist.specialisations = [];
    }
  }

  save() {
    this.removeEmptySpecialisation();

    this._scientistService.updateScientist(this.userScientist, this.scientistsId).subscribe(response => {
      this._dialogService.closeModal();
      this._notificationService.sendMessage({
        type: 'success',
        title: 'Aktualizacja użytkownika',
        message: 'Pomyślnie zaktualizowano naukowca'
      });
    });
  }

  getSciencesAndClasification() {
    const filteredSpecialisation = filter(this.specialisations, specialisation => {
      return (
        specialisation.intelligentSpecialisationId === this.userScientist.intelligentSpecialisationId &&
        specialisation.id === this.userScientist.leadingSpecialisationId
      );
    });

    this.userScientist.scienceDomainId = filteredSpecialisation && filteredSpecialisation[0].scienceDomainId;
    this.userScientist.scienceId = find(this.scienceDomainsDict, {
      id: this.userScientist.scienceDomainId
    }).scienceId;
  }

  setInteligentSpecialisation(specialisationId: number) {
    if (specialisationId) {
      const specialisation = find(this.specialisations, { id: specialisationId });
      if (specialisation) {
        const intelligentSpecialisation: any = find(this.intelligentSpecialisationDict, {
          value: specialisation.intelligentSpecialisationId
        });
        this.userScientist.intelligentSpecialisationId = intelligentSpecialisation.value;
        this.intelligentSpecialisationImageId = intelligentSpecialisation.value;
      }
      this.aditionalSpecialisationDict = this.aditionalSpecialisationDict.filter((itemDict: SelectItem) => {
        return itemDict.value !== specialisationId;
      });
    }
  }

  filteredDepartmentByUniversity(universityId: number) {
    if (this.departments) {
      this.departmentDict = (this.departments as any[])
        .filter((department: any) => {
          return department.universityId === universityId;
        })
        .map((department: any) => {
          return {
            label: department.name,
            value: department.name
          };
        });
    }
  }

  changeModelScienceDomainDict(scienceId: number) {
    this.userScientist.scienceDomainId = null;
    this.userScientist.leadingSpecialisationId = null;

    this.scienceDomainSelectDict = this.mapScienceDomains(scienceId);
  }

  changeModelSpecialisationDict(scienceDomainId: number) {
    this.userScientist.leadingSpecialisationId = null;

    this.specialisationsDict = this.mapSpecialisations(scienceDomainId);
    this.aditionalSpecialisationDict = this.mapSpecialisations(scienceDomainId);
  }

  mapScienceDomains(scienceId: number): SelectItem[] {
    if (this.scienceDomainsDict && scienceId) {
      return this.scienceDomainsDict
        .filter((scienceDomain: any) => {
          return scienceDomain.scienceId === scienceId;
        })
        .map((scienceDomain: any) => {
          return {
            label: scienceDomain.name,
            value: scienceDomain.id
          };
        });
    } else {
      return null;
    }
  }

  mapSpecialisations(scienceDomainId: number): SelectItem[] {
    if (this.specialisations && scienceDomainId) {
      return this.specialisations
        .filter((specialisation: any) => {
          return specialisation.scienceDomainId === scienceDomainId;
        })
        .map((specialisation: any) => {
          return {
            label: specialisation.name,
            value: specialisation.id
          };
        });
    } else {
      return null;
    }
  }

  _getAllDictionaries() {
    zip(
      this._dictionariesService.getAllAcademicTitles(),
      this._dictionariesService.getAllUniversities(),
      this._dictionariesService.getAllScienceDomains(),
      this._dictionariesService.getAllSciences(),
      this._dictionariesService.getAllSpecialisations(),
      this._dictionariesService.getAllIntelligentSpecialisations(),
      this._dictionariesService.getAllStatuses(),
      this._dictionariesService.getAllDepartments()
    ).subscribe(
      ([
        AcademicTitles,
        Universities,
        ScienceDomains,
        Sciences,
        Specialisations,
        IntelligentSpecialisations,
        Statuses,
        Departments
      ]) => {
        this.academicTitlesDict = (AcademicTitles as IDefaultDict[]).map(academicTitle => {
          return {
            label: academicTitle.name,
            value: academicTitle.id
          };
        });
        this.universitiesDict = (Universities as IDefaultDict[]).map(university => {
          return {
            label: university.name,
            value: university.id
          };
        });
        this.sciencesDict = (Sciences as IDefaultDict[]).map(sciences => {
          return {
            label: sciences.name,
            value: sciences.id
          };
        });
        this.scienceDomainSelectDict = (ScienceDomains as IDefaultDict[]).map(scienceDomain => {
          return {
            label: scienceDomain.name,
            value: scienceDomain.id
          };
        });
        this.specialisationsDict = (Specialisations as IDefaultDict[]).map(specialisation => {
          return {
            label: specialisation.name,
            value: specialisation.id
          };
        });

        this.allSpecialisationDict = (Specialisations as IDefaultDict[]).map(spec => {
          return {
            label: spec.name,
            value: spec.id
          };
        });

        this.intelligentSpecialisationDict = (IntelligentSpecialisations as IDefaultDict[]).map(
          intelligentSpecialisation => {
            return {
              label: intelligentSpecialisation.name,
              value: intelligentSpecialisation.id
            };
          }
        );

        this.statusesDict = (Statuses as IDefaultDict[]).map(status => {
          return {
            label: status.name,
            value: status.id
          };
        });

        // All dictionaries
        this.specialisations = Specialisations;
        this.scienceDomainsDict = ScienceDomains;
        this.departments = Departments;

        this.isProfileLoaded.subscribe((isLoaded: boolean) => {
          if (isLoaded) {
            this.getSciencesAndClasification();

            this.scienceDomainSelectDict = this.mapScienceDomains(this.userScientist.scienceId);
            this.specialisationsDict = this.mapSpecialisations(this.userScientist.scienceDomainId);
            this.aditionalSpecialisationDict = this.mapSpecialisations(this.userScientist.scienceDomainId);
            this.setInteligentSpecialisation(this.userScientist.leadingSpecialisationId);

            if (this.userScientist.universityId) {
              this.filteredDepartmentByUniversity(this.userScientist.universityId);
            }
          }
        });
      }
    );
  }
}
