import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
var AccountService = /** @class */ (function () {
    function AccountService(_http) {
        this._http = _http;
        this._accountUrl = '/accounts/info';
    }
    AccountService.prototype.getAccount = function () {
        return this._http.get(this._accountUrl);
    };
    AccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.ɵɵinject(i1.HttpClient)); }, token: AccountService, providedIn: "root" });
    return AccountService;
}());
export { AccountService };
