import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WcagComponent } from './wcag.component';
import { WcagImgDirective } from './wcag-img.directive';
import { WcagAriaDirective } from './wcag-aria.directive';

@NgModule({
  declarations: [WcagComponent, WcagImgDirective, WcagAriaDirective],
  imports: [CommonModule],
  exports: [WcagComponent, WcagImgDirective, WcagAriaDirective]
})
export class WcagModule {}
