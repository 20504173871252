<div class="container-fluid my-4">
  <div class="row">
    <div class="col-md-6 col-3">
      <h4>Konfiguracja witryny</h4>
    </div>
    <div class="col-md-6 col-9">
      <div class="d-flex justify-content-end space-between-btn">
        <button
          class="btn btn-primary mb-3"
          type="button"
          (click)="modalDictionaryEdit()"
          aria-label="Przycisk edycji słowników"
        >
          Edytuj słowniki
        </button>
      </div>
    </div>
  </div>
</div>

<div class="main-form-group">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 col-6">
        <div class="form-group">
          <label for="configuration_app_site_name">Nazwa strony</label>
          <input
            id="configuration_app_site_name"
            class="form-control"
            type="text"
            name="configuration_app_site_name"
            [(ngModel)]="websiteConfig.name"
          />
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="form-group">
          <label for="configuration_app_keywords">Słowa kluczowe</label>
          <input
            id="configuration_app_keywords"
            class="form-control"
            type="text"
            name="configuration_app_keywords"
            [(ngModel)]="websiteConfig.keyWords"
          />
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="form-group">
          <label for="configuration_app_description">Opis strony</label>
          <input
            id="configuration_app_description"
            class="form-control"
            type="text"
            name="configuration_app_description"
            [(ngModel)]="websiteConfig.description"
          />
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="mn-form-group">
          <label for="configuration_app_login_type">Sposób logowania</label>
          <p-dropdown
            [options]="loginMethodDict"
            name="configuration_app_login_type"
            id="configuration_app_login_type"
            [(ngModel)]="websiteConfig.loginMethod"
            placeholder="Wybierz z listy"
            styleClass="form-control"
            filter="true"
            ariaLabelledBy="configuration_app_login_type"
            inputId="configuration_app_login_type"
            emptyFilterMessage="Brak wyników wyszukiwania"
            ariaFilterLabel="Filtrowanie wyników"
          ></p-dropdown>
        </div>
      </div>
      <div class="col-md-3 col-6">
        <p-checkbox
          binary="true"
          label="Tryb konserwacji"
          [(ngModel)]="websiteConfig.maintenance"
          class="ui-checkbox-container"
        ></p-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary" type="button" (click)="saveWebsiteConfig()" aria-label="Przycisk zapisz">
            Zapisz
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt-2">
  <div class="row">
    <div class="col-12">
      <h2 class="heading">Domyślna konfiguracja siły hasła</h2>
    </div>
  </div>
</div>

<div class="main-form-group">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 col-12">
        <div class="form-group">
          <label for="configuration_app_min_pass_len">Minimalna długość:</label>
          <input
            id="configuration_app_min_pass_len"
            class="form-control"
            type="number"
            name="configuration_app_min_pass_len"
            [(ngModel)]="defaultPasswordRule.minPasswordLength"
          />
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group">
          <label for="configuration_app_min_spec_char">Minimalna ilość znaków specjalnych:</label>
          <input
            id="configuration_app_min_spec_char"
            class="form-control"
            type="number"
            name="configuration_app_min_spec_char"
            [(ngModel)]="defaultPasswordRule.minSpecialChar"
          />
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group">
          <label for="configuration_app_min_num">Minimalna ilość cyfr:</label>
          <input
            id="configuration_app_min_num"
            class="form-control"
            type="number"
            name="configuration_app_min_num"
            [(ngModel)]="defaultPasswordRule.minDigit"
          />
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group">
          <label for="configuration_app_min_upper_letter">Minimalna ilość dużych liter:</label>
          <input
            id="configuration_app_min_upper_letter"
            class="form-control"
            type="text"
            name="configuration_app_min_upper_letter"
            [(ngModel)]="defaultPasswordRule.minCapitalLetters"
          />
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group">
          <label for="configuration_app_min_pass_lifecycle">Ważność hasła (dni):</label>
          <input
            id="configuration_app_min_pass_lifecycle"
            class="form-control"
            type="text"
            name="configuration_app_min_pass_lifecycle"
            [(ngModel)]="defaultPasswordRule.passwordExpirationTimeInDays"
          />
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group">
          <label for="configuration_app_disable_rule_for">Włączenie reguły dla:</label>
          <p-multiSelect
            id="configuration_app_disable_rule_for"
            [options]="users"
            [(ngModel)]="defaultPasswordRule.usersId"
            styleClass="form-control"
            selectedItemsLabel="{0} wybranych opcji"
            defaultLabel="Wybierz z listy"
          ></p-multiSelect>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group">
          <label for="configuration_app_count_remember_pass">Liczba zapamiętanych haseł:</label>
          <input
            id="configuration_app_count_remember_pass"
            class="form-control"
            type="text"
            name="configuration_app_count_remember_pass"
            [(ngModel)]="defaultPasswordRule.numberOfPreviousPasswordsToCheck"
          />
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group h-100 d-flex align-items-center mt-2">
          <p-checkbox
            binary="true"
            label="Przypomnienie o zmianie hasła"
            [(ngModel)]="defaultPasswordRule.passwordChangeReminder"
            class="ui-checkbox-container"
          ></p-checkbox>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group h-100 d-flex align-items-center mt-2">
          <p-checkbox
            binary="true"
            label="Wymagana zmiana hasła przy pierwszym logowaniu"
            [(ngModel)]="defaultPasswordRule.requiredPasswordChangeAtFirstLogin"
            class="ui-checkbox-container"
          ></p-checkbox>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary"
            type="button"
            (click)="saveDefaultPasswordRule()"
            aria-label="Przycisk zapisz"
          >
            Zapisz
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row mb-3">
    <div class="col-12">
      <div class="d-flex justify-content-end space-between-btn">
        <button
          class="btn btn-primary"
          type="button"
          (click)="modalPasswordRule()"
          aria-label="Przycisk dodania reguły hasła"
        >
          Dodaj regułę hasła
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="passwordRules?.length">
        <p-table
          [columns]="passwordRulesCols"
          [value]="passwordRules"
          [responsive]="true"
          [autoLayout]="true"
          styleClass="mn-table"
          [lazy]="true"
          sortMode="single"
          (onLazyLoad)="customSort($event)"
          [customSort]="true"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                *ngFor="let col of columns"
                [pSortableColumn]="col.field"
                [pSortableColumnDisabled]="col.disabledSort"
              >
                <div class="d-flex">
                  {{ col.header }}
                  <p-sortIcon
                    *ngIf="!col.disabledSort"
                    [field]="col.field"
                    ariaLabel="Kliknij, aby posortować"
                    ariaLabelDesc="Kliknij, aby sortować w kolejności malejącej"
                    ariaLabelAsc="Kliknij, aby sortować w kolejności rosnącej"
                  ></p-sortIcon>
                </div>
              </th>
              <th class="mn-table-column-action"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
            <tr>
              <td *ngFor="let col of columns">
                <ng-container [ngSwitch]="col.custom">
                  <span *ngSwitchCase="'isHoverable'">
                    <i
                      class="fas fa-info-circle"
                      *ngIf="rowData[col.field] && rowData[col.field].length"
                      (mouseenter)="showOverlayPanel($event, rowData[col.field], tableHoverUserList)"
                      (mouseleave)="hideOverlayPanel(tableHoverUserList)"
                    ></i>
                  </span>
                  <span *ngSwitchCase="'translate'">{{ rowData[col.field] | translate }}</span>
                  <span *ngSwitchCase="'boolean'">{{ 'yesOrNoValue.' + rowData[col.field] | translate }}</span>
                  <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                </ng-container>
              </td>
              <td>
                <div class="mn-table-btn-actions">
                  <button
                    type="button"
                    class="btn btn-default"
                    pTooltip="Podgląd"
                    tooltipPosition="top"
                    aria-label="Przycisk podglądu reguły hasła"
                    (click)="modalPasswordRule(rowData, true)"
                  >
                    <i class="fas fa-eye"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-default"
                    pTooltip="Edytuj"
                    tooltipPosition="top"
                    aria-label="Przycisk edycji reguły hasła"
                    (click)="modalPasswordRule(rowData)"
                    [disabled]="rowData.type === 'DEFAULT'"
                  >
                    <i class="fas fa-pen"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-default"
                    pTooltip="Ustaw domyślne"
                    tooltipPosition="top"
                    aria-label="Przycisk ustawienia domyślnie reguły hasła"
                    (click)="changeDefaultPasswordRule(rowData)"
                    [disabled]="rowData.type === 'DEFAULT'"
                  >
                    <i class="fas fa-exchange-alt"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-default"
                    pTooltip="Usuń"
                    tooltipPosition="top"
                    aria-label="Przycisk usuwania reguły hasła"
                    (click)="removePasswordRule(rowData)"
                    [disabled]="rowData.type === 'DEFAULT'"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-paginator
          [rows]="paginator.size"
          [totalRecords]="paginator.totalElements"
          (onPageChange)="paginate($event)"
          [rowsPerPageOptions]="[10, 20, 50, 100]"
        ></p-paginator>
        <p-overlayPanel #tableHoverUserList appendTo="body">
          <div class="d-block" *ngFor="let users of userOverlay">
            <span>{{ users.username }}</span>
          </div>
        </p-overlayPanel>
      </ng-container>
      <ng-container *ngIf="!passwordRules?.length">
        <p-messages [(value)]="msgs" [closable]="false"></p-messages>
      </ng-container>
    </div>
  </div>
</div>
<p-confirmDialog header="Potwierdź operację" icon="pi pi-exclamation-triangle" acceptLabel="Tak" rejectLabel="Nie">
</p-confirmDialog>
