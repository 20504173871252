/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mail-notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./mail-notification.component";
import * as i3 from "../../../shared/notifications/notification.service";
import * as i4 from "@angular/router";
import * as i5 from "../../../services/mail-notification.service";
var styles_MailNotificationComponent = [i0.styles];
var RenderType_MailNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MailNotificationComponent, data: {} });
export { RenderType_MailNotificationComponent as RenderType_MailNotificationComponent };
export function View_MailNotificationComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_MailNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mail-notification", [], null, null, null, View_MailNotificationComponent_0, RenderType_MailNotificationComponent)), i1.ɵdid(1, 114688, null, 0, i2.MailNotificationComponent, [i3.NotificationService, i4.ActivatedRoute, i4.Router, i5.MailNotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MailNotificationComponentNgFactory = i1.ɵccf("app-mail-notification", i2.MailNotificationComponent, View_MailNotificationComponent_Host_0, {}, {}, []);
export { MailNotificationComponentNgFactory as MailNotificationComponentNgFactory };
