<div class="px-0" [ngClass]="{ container: !isAdmin, 'container-fluid': isAdmin }">
  <div class="main-form-group" [ngClass]="{ 'px-3': !isAdmin, 'px-4': isAdmin }">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="companyDisplayName">Nazwa:</label>
          <input
            id="companyDisplayName"
            class="form-control"
            type="text"
            name="companyDisplayName"
            [(ngModel)]="filters.companyName"
          />
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="problemQuery">Opis problemu:</label>
          <input
            id="problemQuery"
            class="form-control"
            type="text"
            name="problemQuery"
            [(ngModel)]="filters.description"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary mr-2"
            type="button"
            (click)="clearFilter()"
            aria-label="Przycisk czyszczenia filtrów"
          >
            Wyczyść filtry
          </button>
          <button class="btn btn-primary" type="button" (click)="getProblems(true)" aria-label="Przycisk wyszukiwania">
            Wyszukaj
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div [ngClass]="{ container: !isAdmin, 'container-fluid': isAdmin }">
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="problemData?.length">
        <p-table
          [columns]="problemCols"
          [value]="problemData"
          styleClass="mn-table"
          [responsive]="true"
          [autoLayout]="true"
          [lazy]="true"
          sortMode="single"
          (onLazyLoad)="customSort($event)"
          [customSort]="true"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                *ngFor="let col of columns"
                [pSortableColumn]="col.sortProperty || col.field"
                [pSortableColumnDisabled]="col.disabledSort"
              >
                <div class="d-flex">
                  {{ col.header }}
                  <p-sortIcon
                    *ngIf="!col.disabledSort"
                    [field]="col.field"
                    ariaLabel="Kliknij, aby posortować"
                    ariaLabelDesc="Kliknij, aby sortować w kolejności malejącej"
                    ariaLabelAsc="Kliknij, aby sortować w kolejności rosnącej"
                  ></p-sortIcon>
                </div>
              </th>
              <th class="mn-table-column-simple-action" *ngIf="isAdmin"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td *ngFor="let col of columns">{{ rowData[col.field] }}</td>
              <td *ngIf="isAdmin">
                <fieldset class="d-block">
                  <div class="mn-table-btn-actions">
                    <button
                      type="button"
                      class="btn btn-default"
                      pTooltip="Usuń wpis"
                      tooltipPosition="top"
                      aria-label="Usuń wpis"
                      (click)="removeProblem(rowData)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </fieldset>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-paginator
          [rows]="paginator.size"
          [totalRecords]="paginator.totalElements"
          (onPageChange)="paginate($event)"
          [rowsPerPageOptions]="[10, 20, 50, 100]"
        ></p-paginator>
      </ng-container>
      <ng-container *ngIf="!problemData?.length">
        <p-messages [(value)]="msgs" [closable]="false"></p-messages>
      </ng-container>
    </div>
  </div>
</div>
<p-confirmDialog header="Potwierdź operację" icon="pi pi-exclamation-triangle" acceptLabel="Tak" rejectLabel="Nie">
</p-confirmDialog>
