import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { MailNotificationComponent } from './mail-notification.component';
var ɵ0 = { title: extract('Potwierdzenie współpracy') }, ɵ1 = { title: extract('Potwierdzenie współpracy') };
var routes = [
    Shell.childRoutes([
        {
            path: 'notifications/cooperation',
            component: MailNotificationComponent,
            data: ɵ0
        },
        {
            path: 'notifications/cooperation/:token',
            component: MailNotificationComponent,
            data: ɵ1
        }
    ])
];
var MailNotificationRoutingModule = /** @class */ (function () {
    function MailNotificationRoutingModule() {
    }
    return MailNotificationRoutingModule;
}());
export { MailNotificationRoutingModule };
export { ɵ0, ɵ1 };
