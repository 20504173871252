import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var LoaderService = /** @class */ (function () {
    function LoaderService() {
        this._loaderStatus$ = new Subject();
    }
    LoaderService.prototype.setLoderStatus = function (status) {
        this._loaderStatus$.next(status);
    };
    LoaderService.prototype.getLoaderStatus = function () {
        return this._loaderStatus$.asObservable();
    };
    LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
    return LoaderService;
}());
export { LoaderService };
