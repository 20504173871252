import { HelperService } from '@app/services/helper.service';
import { IEmployerLimitedSearchProfile } from './../../../interfaces/search.interface';
import { AuthenticationService } from './../../../core/authentication/authentication.service';
import { IDefaultDict } from '@app/interfaces/dictionary.interface';
import { DictionariesService } from '@app/services/dictionaries.service';
import { SearchService } from './../../../services/search.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
import { DynamicDialogConfig, SelectItem } from 'primeng/api';

import {
  IScientistLimitedSearchProfile,
  IScientistSearchProfile,
  IRequestEstablishCooperation
} from '@app/interfaces/search.interface';
import { zip } from 'rxjs';
import { find, filter } from 'lodash';

@Component({
  selector: 'app-show-user-profile',
  templateUrl: './show-user-profile.component.html',
  styleUrls: ['./show-user-profile.component.scss']
})
export class ShowUserProfileComponent implements OnInit {
  @ViewChild('printProfileResult', { static: false }) printProfileResult: ElementRef;

  scientistLimitedProfile: IScientistLimitedSearchProfile = {
    firstName: null,
    surname: null,
    department: null,
    universityId: null,
    projectsDescription: null,
    scientificWorksDescription: null,
    descriptionOfCooperationWithEmployers: null,
    academicTitleId: null,
    keyWords: [],
    scienceId: null,
    scienceDomainId: null,
    numberOfProjects: null,
    leadingSpecialisationId: null,
    intelligentSpecialisationId: null,
    specialisations: [],
    availableForCooperation: null
  };

  employerLimitedProfile: IEmployerLimitedSearchProfile = {
    companyName: null,
    scienceId: null,
    communeId: null,
    city: null,
    scienceDomainId: null,
    intelligentSpecialisationId: null,
    leadingSpecialisationId: null,
    specialisations: null
  };

  scientistProfile: IScientistSearchProfile = {
    email: null,
    phoneNumber: null
  };

  establishedCooperation: boolean;
  canEstablishCooperation: boolean;
  isUserEmployer: boolean;
  isUserScientist: boolean;
  userId: number;

  requestForEstablishCooperation: IRequestEstablishCooperation = {
    scientistId: null,
    searchEntryId: null,
    title: null
  };

  // Dictionaries
  departmentsDict: SelectItem[];
  scienceDomainDict: SelectItem[];
  sciencesDict: SelectItem[];
  specialisationsDict: SelectItem[];
  academicTitlesDict: SelectItem[];
  universitiesDict: SelectItem[];
  tradesDict: SelectItem[];
  communesDict: SelectItem[];
  intelligentSpecialisationsDict: SelectItem[];
  statusesDict: SelectItem[];
  scienceDomainSelectDict: SelectItem[];

  // All dictionaries
  scienceDomainsDict: any;
  specialisations: any;

  constructor(
    private _notificationService: NotificationService,
    private _dialogService: DialogBaseService,
    public config: DynamicDialogConfig,
    private searchService: SearchService,
    private dictionatiesService: DictionariesService,
    private authenticationService: AuthenticationService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    if (this.config.data) {
      this.userId = this.config.data.userId;
      if (this.config.data.userType === 'SCIENTIST') {
        this.getLimitedScientistProfile(this.userId);
        this.requestForEstablishCooperation.scientistId = this.config.data.userId;
        this.requestForEstablishCooperation.searchEntryId = this.config.data.searchEntryId;
      } else {
        this.getEmployerLimitedProfile(this.userId);
      }

      this.isUserScientist = this.config.data.activeTabId === 0;
      this.isUserEmployer = this.config.data.activeTabId === 1;

      this.isUserCanEstablishCooperation();
    }

    this._getDictionaries();
  }

  print(): void {
    this.helperService.printFromImage(this.printProfileResult.nativeElement);
  }

  async isUserCanEstablishCooperation() {
    this.authenticationService.userData$.subscribe(async userData => {
      const userRoles = (await this.authenticationService.getUserRoles()).some((role: string) => {
        return role === 'EMPLOYER' && !this.isUserEmployer;
      });

      this.canEstablishCooperation = userRoles;
    });
  }

  getLimitedScientistProfile(userId: number) {
    this.searchService.getScientistLimitedProfile(userId).subscribe(response => {
      this.scientistLimitedProfile = response;
    });
  }

  getEmployerLimitedProfile(userId: number) {
    this.searchService.getEmployerLimitedProfile(userId).subscribe(response => {
      this.employerLimitedProfile = response;
    });
  }

  getScientistProfile() {
    this.searchService.requestEstablishCooperation(this.requestForEstablishCooperation).subscribe(response => {
      this.scientistProfile.email = response.email;
      this.scientistProfile.phoneNumber = response.phoneNumber;
      this.establishedCooperation = true;

      this._notificationService.sendMessage({
        type: 'success',
        title: 'Dane kontaktowe',
        message: 'Współpraca została nawiązana pomyślnie'
      });
    });
  }

  changeModelScienceDomainDict(scienceId: number) {
    this.scientistLimitedProfile.scienceDomainId = null;
    this.scientistLimitedProfile.leadingSpecialisationId = null;
    this.employerLimitedProfile.scienceDomainId = null;
    this.employerLimitedProfile.leadingSpecialisationId = null;

    this.scienceDomainSelectDict = this.mapScienceDomains(scienceId);
  }

  mapScienceDomains(scienceId: number): SelectItem[] {
    if (this.scienceDomainsDict && scienceId) {
      return this.scienceDomainsDict
        .filter((scienceDomain: any) => {
          return scienceDomain.scienceId === scienceId;
        })
        .map((scienceDomain: any) => {
          return {
            label: scienceDomain.name,
            value: scienceDomain.id
          };
        });
    } else {
      return null;
    }
  }

  changeModelSpecialisationDict(scienceDomainId: number) {
    this.scientistLimitedProfile.leadingSpecialisationId = null;
    this.employerLimitedProfile.leadingSpecialisationId = null;

    this.specialisationsDict = this.mapSpecialisations(scienceDomainId);
  }

  mapSpecialisations(scienceDomainId: number): SelectItem[] {
    if (this.specialisations && scienceDomainId) {
      return this.specialisations
        .filter((specialisation: any) => {
          return specialisation.scienceDomainId === scienceDomainId;
        })
        .map((specialisation: any) => {
          return {
            label: specialisation.name,
            value: specialisation.id
          };
        });
    } else {
      return null;
    }
  }

  setInteligentSpecialisation(specialisationId: number) {
    if (specialisationId) {
      const specialisation = find(this.specialisations, { id: specialisationId });
      if (specialisation) {
        const intelligentSpecialisation: any = find(this.intelligentSpecialisationsDict, {
          value: specialisation.intelligentSpecialisationId
        });
        if (this.isUserEmployer) {
          this.employerLimitedProfile.intelligentSpecialisationId = intelligentSpecialisation.value;
        } else if (this.isUserScientist) {
          this.scientistLimitedProfile.intelligentSpecialisationId = intelligentSpecialisation.value;
        }
      }
    }
  }

  getSciencesAndClasification() {
    if (this.isUserEmployer) {
      const filteredSpecialisation = filter(this.specialisations, specialisation => {
        return (
          specialisation.intelligentSpecialisationId === this.employerLimitedProfile.intelligentSpecialisationId &&
          specialisation.id === this.employerLimitedProfile.leadingSpecialisationId
        );
      });

      this.employerLimitedProfile.scienceDomainId = filteredSpecialisation && filteredSpecialisation[0].scienceDomainId;
      this.employerLimitedProfile.scienceId = find(this.scienceDomainsDict, {
        id: this.employerLimitedProfile.scienceDomainId
      }).scienceId;
    } else if (this.isUserScientist) {
      const filteredSpecialisation = filter(this.specialisations, specialisation => {
        return (
          specialisation.intelligentSpecialisationId === this.scientistLimitedProfile.intelligentSpecialisationId &&
          specialisation.id === this.scientistLimitedProfile.leadingSpecialisationId
        );
      });

      this.scientistLimitedProfile.scienceDomainId =
        filteredSpecialisation && filteredSpecialisation[0].scienceDomainId;
      this.scientistLimitedProfile.scienceId = find(this.scienceDomainsDict, {
        id: this.scientistLimitedProfile.scienceDomainId
      }).scienceId;
    }
  }

  // Dictionaries
  _getDictionaries() {
    zip(
      this.dictionatiesService.getAllDepartments(),
      this.dictionatiesService.getAllScienceDomains(),
      this.dictionatiesService.getAllSciences(),
      this.dictionatiesService.getAllSpecialisations(),
      this.dictionatiesService.getAllAcademicTitles(),
      this.dictionatiesService.getAllUniversities(),
      this.dictionatiesService.getAllCommunes(),
      this.dictionatiesService.getSimpleUserStatus(),
      this.dictionatiesService.getAllIntelligentSpecialisations()
    ).subscribe(
      ([
        Departments,
        ScienceDomain,
        Sciences,
        Specialisations,
        AcademicTitles,
        Universities,
        Communes,
        Statuses,
        IntelligentSpecialisations
      ]) => {
        this.departmentsDict = (Departments as IDefaultDict[]).map(dict => {
          return {
            label: dict.name,
            value: dict.id
          };
        });
        this.scienceDomainDict = (ScienceDomain as IDefaultDict[]).map(dict => {
          return {
            label: dict.name,
            value: dict.id
          };
        });
        this.scienceDomainSelectDict = (ScienceDomain as IDefaultDict[]).map(scienceDomain => {
          return {
            label: scienceDomain.name,
            value: scienceDomain.id
          };
        });
        this.sciencesDict = (Sciences as IDefaultDict[]).map(dict => {
          return {
            label: dict.name,
            value: dict.id
          };
        });
        this.specialisationsDict = (Specialisations as IDefaultDict[]).map(dict => {
          return {
            label: dict.name,
            value: dict.id
          };
        });
        this.academicTitlesDict = (AcademicTitles as IDefaultDict[]).map(dict => {
          return {
            label: dict.name,
            value: dict.id
          };
        });
        this.universitiesDict = (Universities as IDefaultDict[]).map(dict => {
          return {
            label: dict.name,
            value: dict.id
          };
        });

        this.communesDict = (Communes as IDefaultDict[]).map(dict => {
          return {
            label: dict.name,
            value: dict.id
          };
        });

        this.statusesDict = Statuses as SelectItem[];

        this.intelligentSpecialisationsDict = (IntelligentSpecialisations as IDefaultDict[]).map(dict => {
          return {
            label: dict.name,
            value: dict.id
          };
        });

        // Get All dict
        this.scienceDomainsDict = ScienceDomain;
        this.specialisations = Specialisations;

        this.getSciencesAndClasification();
      }
    );
  }
}
