import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { EmployerRegisterRoutingModule } from './employer-register-routing.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployerRegisterComponent } from './employer-register.component';
import { WcagModule } from '@app/shared/wcag/wcag.module';

@NgModule({
  declarations: [EmployerRegisterComponent],
  imports: [CommonModule, FormsModule, DropdownModule, TooltipModule, WcagModule, EmployerRegisterRoutingModule]
})
export class EmployerRegisterModule {}
