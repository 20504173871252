import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EventLogService = /** @class */ (function () {
    function EventLogService(_http) {
        this._http = _http;
        this._eventLogUrl = '/admin/event-logs';
    }
    EventLogService.prototype.getEventLogs = function (paginatorConfig) {
        return this._http.get(this._eventLogUrl, paginatorConfig);
    };
    EventLogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventLogService_Factory() { return new EventLogService(i0.ɵɵinject(i1.HttpClient)); }, token: EventLogService, providedIn: "root" });
    return EventLogService;
}());
export { EventLogService };
