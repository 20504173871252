import { MultitagSelectModule } from './multitag-select/multitag-select.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallendarDefaultModule } from './callendar/callendar.module';

@NgModule({
  imports: [CommonModule, CallendarDefaultModule],
  declarations: [],
  exports: [MultitagSelectModule, CallendarDefaultModule]
})
export class SharedModule {}
