import * as tslib_1 from "tslib";
import { TableService } from './../../../services/table.service';
import { HelperService } from '@app/services/helper.service';
import { ReportsService } from './../../../services/reports.service';
import { zip } from 'rxjs';
import { DictionariesService } from './../../../services/dictionaries.service';
import { OnInit, OnDestroy } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
var ReportsComponent = /** @class */ (function () {
    function ReportsComponent(_dictionariesService, reportsService, fileSaverService, helperService, tableService) {
        this._dictionariesService = _dictionariesService;
        this.reportsService = reportsService;
        this.fileSaverService = fileSaverService;
        this.helperService = helperService;
        this.tableService = tableService;
        this.msgs = [];
        this.paginatorEventLog = null;
        this.eventLogTableData = null;
        this.paginatorSearchEntries = null;
        this.searchEntriesTableData = null;
        this.reportEventLog = {
            startDate: null,
            endDate: null,
            userEmail: null,
            kindOfReport: null
        };
        this.reportSearchEntries = {
            startDate: null,
            endDate: null,
            userEmail: null,
            kind: null
        };
        this.reportUsers = {
            kind: null
        };
    }
    ReportsComponent.prototype.ngOnDestroy = function () {
        this.tableService.restorePaginatorConfig();
    };
    ReportsComponent.prototype.ngOnInit = function () {
        this.reportEventLogTableCols = [
            { field: 'id', header: 'ID' },
            { field: 'createDateTime', header: 'Data', custom: 'isDate' },
            { field: 'userEmail', header: 'Adres e-mail' },
            { field: 'modificationKind', header: 'Rodzaj zmiany' },
            { field: 'description', header: 'Opis' }
        ];
        this.searchEntriesTableCols = [
            { field: 'id', header: 'ID' },
            { field: 'entryDate', header: 'Data', custom: 'isDate', sortProperty: 'createTime' },
            { field: 'userEmail', header: 'Adres e-mail' },
            { field: 'entryContent', header: 'Treść zapytania', sortProperty: 'content' },
            { field: 'participants', header: 'Wynik wyszukiwania', custom: 'isHoverable', disabledSort: true },
            {
                field: 'isCooperationEstablished',
                header: 'Nawiazanie współpracy',
                custom: 'boolean',
                disabledSort: true
            },
            {
                field: 'isCooperationActiveAfter30Days',
                header: 'Aktywna współpraca po 30 dniach',
                custom: 'boolean',
                disabledSort: true
            },
            {
                field: 'isCooperationActiveAfter6Months',
                header: 'Aktywna współpraca po 6 miesiącach',
                custom: 'boolean',
                disabledSort: true
            }
        ];
        this.getEventLogsData();
        this.getSearchEntriesData();
        this._getDictionaries();
    };
    ReportsComponent.prototype.paginateEventLogReport = function (paginatorEvent) {
        var currentPaginator = this.tableService.getPaginatorConfig();
        var paginator = {
            direction: currentPaginator.direction,
            page: paginatorEvent.page,
            size: paginatorEvent.rows,
            properties: currentPaginator.properties,
            sort: currentPaginator.sort
        };
        this.tableService.setPaginatorConfig(paginator);
        this.getEventLogsData();
    };
    ReportsComponent.prototype.customSortEventLogReport = function (event) {
        if (event.sortField) {
            var currentPaginator = this.tableService.getPaginatorConfig();
            var paginator = {
                direction: event.sortOrder === -1 ? 'DESC' : 'ASC',
                page: currentPaginator.page,
                size: currentPaginator.size,
                properties: [event.sortField],
                sort: true
            };
            this.tableService.setPaginatorConfig(paginator);
            this.getEventLogsData();
        }
    };
    ReportsComponent.prototype.paginateSearchEntriesReport = function (paginatorEvent) {
        var currentPaginator = this.tableService.getPaginatorConfig();
        var paginator = {
            direction: currentPaginator.direction,
            page: paginatorEvent.page,
            size: paginatorEvent.rows,
            properties: currentPaginator.properties,
            sort: currentPaginator.sort
        };
        this.tableService.setPaginatorConfig(paginator);
        this.getSearchEntriesData();
    };
    ReportsComponent.prototype.customSortSearchEntriesReport = function (event) {
        if (event.sortField) {
            var currentPaginator = this.tableService.getPaginatorConfig();
            var paginator = {
                direction: event.sortOrder === -1 ? 'DESC' : 'ASC',
                page: currentPaginator.page,
                size: currentPaginator.size,
                properties: [event.sortField],
                sort: true
            };
            this.tableService.setPaginatorConfig(paginator);
            this.getSearchEntriesData();
        }
    };
    ReportsComponent.prototype.getEventLogsData = function () {
        var _this = this;
        var paramsToServer = {
            startDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportEventLog.startDate),
            endDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportEventLog.endDate),
            userEmail: this.reportEventLog.userEmail,
            kindOfReport: this.reportEventLog.kindOfReport
        };
        this.helperService.removeNullPropertyFromObject(paramsToServer);
        this.reportsService
            .getTableEventLogReport(this.tableService.prepareParamsToServer(paramsToServer))
            .subscribe(function (eventLogReport) {
            _this.eventLogTableData = eventLogReport.content;
            _this.tableService.setPaginatorConfigFormServer(eventLogReport);
            _this.paginatorEventLog = _this.tableService.getPaginatorConfig();
            if (!eventLogReport.content.length) {
                _this.showEmptyDataAlert();
            }
        });
    };
    ReportsComponent.prototype.getSearchEntriesData = function () {
        var _this = this;
        var paramsToServer = {
            startDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportSearchEntries.startDate),
            endDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportSearchEntries.endDate),
            userEmail: this.reportSearchEntries.userEmail,
            kind: this.reportSearchEntries.kind
        };
        this.helperService.removeNullPropertyFromObject(paramsToServer);
        this.reportsService
            .getTableSearchEntriesReport(this.tableService.prepareParamsToServer(paramsToServer))
            .subscribe(function (searchEntriesReport) {
            _this.searchEntriesTableData = searchEntriesReport.content.map(function (searchEntries) {
                searchEntries.isCooperationEstablished = searchEntries.cooperationStatus.isCooperationEstablished;
                searchEntries.isCooperationActiveAfter30Days = searchEntries.cooperationStatus.isCooperationActiveAfter30Days;
                searchEntries.isCooperationActiveAfter6Months =
                    searchEntries.cooperationStatus.isCooperationActiveAfter6Months;
                return searchEntries;
            });
            _this.tableService.setPaginatorConfigFormServer(searchEntriesReport);
            _this.paginatorSearchEntries = _this.tableService.getPaginatorConfig();
            if (!searchEntriesReport.content.length) {
                _this.showEmptyDataAlert();
            }
        });
    };
    ReportsComponent.prototype.showOverlayPanel = function (event, userData, overlaypanel) {
        this.userOverlay = userData;
        if (userData && userData.length) {
            overlaypanel.show(event);
        }
    };
    ReportsComponent.prototype.hideOverlayPanel = function (overlaypanel) {
        overlaypanel.hide();
    };
    ReportsComponent.prototype.clearFilter = function (filtersGroupName) {
        switch (filtersGroupName) {
            case 'eventLog':
                this.reportEventLog = {
                    startDate: null,
                    endDate: null,
                    userEmail: null,
                    kindOfReport: null
                };
                break;
            case 'searchEntries':
                this.reportSearchEntries = {
                    startDate: null,
                    endDate: null,
                    userEmail: null,
                    kind: null
                };
                break;
        }
    };
    ReportsComponent.prototype.generateEventLogReport = function () {
        var _this = this;
        this.reportsService
            .generateEventLogReports({
            startDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportEventLog.startDate),
            endDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportEventLog.endDate),
            userEmail: this.reportEventLog.userEmail,
            kindOfReport: this.reportEventLog.kindOfReport
        })
            .subscribe(function (response) {
            var headers = response.headers;
            var contentDisposition = headers.get('content-disposition').split('=');
            _this.fileSaverService.save(response.body, contentDisposition[1]);
        });
    };
    ReportsComponent.prototype.generateSearchEntriesReport = function () {
        var _this = this;
        this.reportsService
            .generateSearchEntriesReports({
            startDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportSearchEntries.startDate),
            endDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportSearchEntries.endDate),
            userEmail: this.reportSearchEntries.userEmail,
            kind: this.reportSearchEntries.kind
        })
            .subscribe(function (response) {
            var headers = response.headers;
            var contentDisposition = headers.get('content-disposition').split('=');
            _this.fileSaverService.save(response.body, contentDisposition[1]);
        });
    };
    ReportsComponent.prototype.generateUserReport = function () {
        var _this = this;
        this.reportsService.generateUserReport(this.reportUsers).subscribe(function (response) {
            var headers = response.headers;
            var contentDisposition = headers.get('content-disposition').split('=');
            _this.fileSaverService.save(response.body, contentDisposition[1]);
        });
    };
    ReportsComponent.prototype.isFilterFill = function (objectToCheck) {
        return this.helperService.checkAnyObjectFieldIsFill(objectToCheck);
    };
    ReportsComponent.prototype.showEmptyDataAlert = function () {
        this.msgs = [];
        this.msgs.push({ severity: 'info', summary: 'Informacja', detail: 'Brak danych' });
    };
    ReportsComponent.prototype._getDictionaries = function () {
        var _this = this;
        zip(this._dictionariesService.getAllKindOfReport(), this._dictionariesService.getAllKindOfSearchEntries(), this._dictionariesService.getAllKindUserGenerateReport()).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 3), KindOfReport = _b[0], KindOfSearchEntries = _b[1], UserGenerateReport = _b[2];
            _this.kindOfUsersReport = UserGenerateReport.map(function (usersReport) {
                return {
                    label: usersReport.name,
                    value: usersReport.id
                };
            });
            _this.kindOfReportDict = KindOfReport.map(function (kindOfReport) {
                return {
                    label: kindOfReport.name,
                    value: kindOfReport.id
                };
            });
            _this.kindOfSearchEntries = KindOfSearchEntries.map(function (kindOfSearchEntry) {
                return {
                    label: kindOfSearchEntry.name,
                    value: kindOfSearchEntry.id
                };
            });
        });
    };
    return ReportsComponent;
}());
export { ReportsComponent };
