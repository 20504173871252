import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { EventLogComponent } from './event-log.component';
import { AuthenticationGuard } from '@app/core/authentication/authentication.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'event-log',
      component: EventLogComponent,
      data: { title: extract('Dziennik zdarzeń'), expectedRole: ['ADMIN', 'CONTACT_ADMIN'] },
      canActivate: [AuthenticationGuard]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class EventLogRoutingModule {}
