import { Component, OnInit } from '@angular/core';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';

@Component({
  selector: 'app-account-type-selection',
  templateUrl: './account-type-selection.component.html',
  styleUrls: ['./account-type-selection.component.scss']
})
export class AccountTypeSelectionComponent implements OnInit {
  constructor(private _dialogBaseService: DialogBaseService) {}

  ngOnInit() {}

  selectAccountType(type: string) {
    switch (type) {
      case 'scientist':
        this._dialogBaseService.setSwitchTemplateStatus('ScientistRegistrationComponent', {
          closable: true,
          header: 'Dodawanie naukowca',
          width: '60%'
        });
        break;
      case 'employer':
        this._dialogBaseService.setSwitchTemplateStatus('EmployerRegistrationComponent', {
          closable: true,
          header: 'Dodawanie przedsiębiorcy',
          width: '60%'
        });
        break;
    }
  }
}
