import { HelperService } from './helper.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  IUserScientist,
  IUserEmployer,
  IUserChangePass,
  IUserChangeEmail
} from '@app/interfaces/user-profile.interface';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  private resourceUrl = '/';

  constructor(private _http: HttpClient, private helperService: HelperService) {}

  getScientistProfile(scientistId: number): Observable<IUserScientist> {
    return this._http.get<IUserScientist>(`${this.resourceUrl}scientists/${scientistId}`);
  }

  getEmployerProfile(employerId: number): Observable<IUserEmployer> {
    return this._http.get<IUserEmployer>(`${this.resourceUrl}employers/${employerId}`);
  }

  updateScientistProfile(userId: number, payload: IUserScientist): Observable<IUserScientist> {
    return this._http.put<IUserScientist>(`${this.resourceUrl}scientists/${userId}`, payload);
  }

  updateEmployerProfile(userId: number, payload: IUserEmployer): Observable<IUserEmployer> {
    return this._http.put<IUserEmployer>(`${this.resourceUrl}employers/${userId}`, payload);
  }

  updateScientistLimitedProfile(userId: number, payload: IUserScientist): Observable<IUserScientist> {
    return this._http.put<IUserScientist>(`${this.resourceUrl}scientists/first-login/${userId}`, payload);
  }

  updateEmployerLimitedProfile(userId: number, payload: IUserEmployer): Observable<IUserEmployer> {
    return this._http.put<IUserEmployer>(`${this.resourceUrl}employers/first-login/${userId}`, payload);
  }

  changePassword(userId: number, payload: IUserChangePass): Observable<IUserChangePass> {
    return this._http.put<IUserChangePass>(`/accounts/change-password/${userId}`, payload);
  }

  changeEmail(userId: number, payload: IUserChangeEmail): Observable<IUserChangeEmail> {
    return this._http.put<IUserChangeEmail>(`/accounts/change-email/${userId}`, payload);
  }
}
