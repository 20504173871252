import { WcagService } from './../../shared/wcag/wcag.service';
import { Subject } from 'rxjs';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService, CredentialsService, I18nService, Credentials } from '@app/core';
import { AccountService } from '@app/services/account.service';
import { HelperService } from '@app/services/helper.service';
import { NotificationService } from '@app/shared/notifications/notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  isLogged: boolean;
  isAdmin: boolean;
  isMobileLayout: boolean;
  menuHidden = true;
  startHideMenu = true;
  user: any;
  mobileLogo: string;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private _credentialsService: CredentialsService,
    private _notificationService: NotificationService,
    private _accountService: AccountService,
    private i18nService: I18nService,
    private helperService: HelperService,
    private wcagService: WcagService
  ) {}

  ngOnInit() {
    this.isLogged = this._credentialsService.isAuthenticated();
    this.getAccountInformation();

    this.wcagService.isContrastVesrion.subscribe(isConstrast => {
      if (isConstrast) {
        this.mobileLogo = '/assets/logo_contrast.png';
      } else {
        this.mobileLogo = '/assets/logo.png';
      }
    });

    this.isMobileLayout = document.body.offsetWidth < 1080;
    window.onresize = () => (this.isMobileLayout = window.innerWidth <= 1080);
  }

  getAccountInformation(): void {
    if (this.isLogged) {
      this._accountService.getAccount().subscribe(
        (data: any) => {
          this.user = data;
          this.checkUserIsAdmin(data);
          this.authenticationService.setUserData(data);

          // Check is user Limited, if yes => redirect to fill profile
          this.helperService.limitedUserRedirect();

          if (data.showPasswordExpiredNotification) {
            this._notificationService.sendMessage({
              type: 'info',
              title: 'Informacja',
              message: 'Zbliża się termin ważności hasła. Przejdź do profilu, aby je zmienić.'
            });
          }
        },
        () => {
          this.isLogged = this._credentialsService.isAuthenticated();
        }
      );
    } else {
      this.authenticationService.logout();
    }
  }

  checkUserIsAdmin(userData: any): void {
    this.isAdmin = userData.roles.some((role: string) => {
      return role === 'ADMIN';
    });
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
    this.startHideMenu = false;
  }

  login(username: string, password: string) {
    this.authenticationService.login(username, password).subscribe((credentials: Credentials) => {
      this._credentialsService.setCredentials(credentials);
      this.isLogged = true;
      this._notificationService.sendMessage({
        type: 'success',
        title: 'Logowanie',
        message: 'Logowanie przebiegło pomyślnie'
      });
      this.getAccountInformation();
      this.router.navigate(['search'], { replaceUrl: true });
    });
  }

  logout() {
    this.authenticationService.logout().subscribe(() => {
      this.isLogged = false;
      this.isAdmin = false;
      this.user = null;
      this.router.navigate(['search'], { replaceUrl: true });
    });
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }
}
