import { WcagService } from './../../shared/wcag/wcag.service';
import { Component, OnInit } from '@angular/core';
import { IFooter } from '@app/interfaces/footer.interface';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  listFooterImage: IFooter[] = [
    {
      source: 'assets/footer/fundusze.png',
      alt: 'Fundusze europejskie',
      href: 'https://www.funduszeeuropejskie.gov.pl/'
    },
    { source: 'assets/footer/rp.png', alt: 'Rzeczpospolita Polska', href: 'https://www.gov.pl/' },
    {
      source: 'assets/footer/malopolska.png',
      alt: 'Urząd Marszałkowski Małopolski',
      href: 'https://www.malopolska.pl/'
    },
    {
      source: 'assets/footer/ue.png',
      alt: 'Unia europejska europejski fundusz rozwoju regionalnego',
      href: 'https://ec.europa.eu/regional_policy/pl/funding/erdf/'
    }
  ];

  constructor(private wcagService: WcagService) {}

  ngOnInit() {
    this.wcagService.isContrastVesrion.subscribe(isConstrast => {
      if (isConstrast) {
        this.listFooterImage = [
          {
            source: 'assets/footer/fundusze_contrast.png',
            alt: 'Fundusze europejskie',
            href: 'https://www.funduszeeuropejskie.gov.pl/'
          },
          { source: 'assets/footer/rp_contrast.png', alt: 'Rzeczpospolita Polska', href: 'https://www.gov.pl/' },
          {
            source: 'assets/footer/malopolska_contrast.png',
            alt: 'Urząd Marszałkowski Małopolski',
            href: 'https://www.malopolska.pl/'
          },
          {
            source: 'assets/footer/ue_contrast.png',
            alt: 'Unia europejska europejski fundusz rozwoju regionalnego',
            href: 'https://ec.europa.eu/regional_policy/pl/funding/erdf/'
          }
        ];
      } else {
        this.listFooterImage = [
          {
            source: 'assets/footer/fundusze.png',
            alt: 'Fundusze europejskie',
            href: 'https://www.funduszeeuropejskie.gov.pl/'
          },
          { source: 'assets/footer/rp.png', alt: 'Rzeczpospolita Polska', href: 'https://www.gov.pl/' },
          {
            source: 'assets/footer/malopolska.png',
            alt: 'Urząd Marszałkowski Małopolski',
            href: 'https://www.malopolska.pl/'
          },
          {
            source: 'assets/footer/ue.png',
            alt: 'Unia europejska europejski fundusz rozwoju regionalnego',
            href: 'https://ec.europa.eu/regional_policy/pl/funding/erdf/'
          }
        ];
      }
    });
  }

  backToTop(): void {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}
