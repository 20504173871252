import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventLogComponent } from './event-log.component';
import { EventLogRoutingModule } from './event-log-routing.module';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';
import { WcagModule } from '@app/shared/wcag/wcag.module';

@NgModule({
  declarations: [EventLogComponent],
  imports: [
    CommonModule,
    EventLogRoutingModule,
    WcagModule,
    TableModule,
    PaginatorModule,
    TooltipModule,
    MessagesModule,
    DropdownModule
  ]
})
export class EventLogModule {}
