import { WcagModule } from './../shared/wcag/wcag.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from '../shared/loader/loader.component';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { NotificationsComponent } from './../shared/notifications/notifications.component';
import { DialogBaseModule } from '@app/shared/dialog/dialog-base.module';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ButtonModule,
    ToastModule,
    DialogBaseModule,
    WcagModule
  ],
  declarations: [HeaderComponent, FooterComponent, ShellComponent, LoaderComponent, NotificationsComponent]
})
export class ShellModule {}
