import { AccountService } from './../../services/account.service';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';

import { Credentials, CredentialsService } from './credentials.service';
import { HttpClient } from '@angular/common/http';

export interface LoginContext {
  accessToken: string;
  tokenType: string;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public isLogged = new BehaviorSubject<boolean>(false);
  public userData$ = new BehaviorSubject<any>(null);
  private authUrl = '/auth/sign-in';
  private userData: any = null;

  constructor(
    private credentialsService: CredentialsService,
    private _http: HttpClient,
    private accountService: AccountService
  ) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */

  login(username: string, password: string): Observable<Credentials> {
    const payload = {
      login: username,
      password
    };
    return this._http.post<Credentials>(this.authUrl, payload);
  }
  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    sessionStorage.removeItem('userData');

    this.userData = null;
    this.credentialsService.setCredentials(null, null, true);
    this.isLogged.next(false);
    this.userData$.next(null);
    return of(true);
  }

  getUserRoles(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.userData$.subscribe((userData: any) => {
        const userRoles = userData ? userData.roles : [];
        resolve(userRoles);
      });
    });
  }

  getUserData() {
    if (!this.userData) {
      this.userData = JSON.parse(sessionStorage.getItem('userData'));
    }

    this.userData$.next(this.userData);

    if (this.credentialsService.isAuthenticated() && !this.userData) {
      this.logout();
      return null;
    }
    return this.userData;
  }

  setUserData(userData: any) {
    this.userData = userData;
    sessionStorage.removeItem('userData');
    sessionStorage.setItem('userData', JSON.stringify(userData));
    if (this.credentialsService.isAuthenticated()) {
      this.isLogged.next(true);
    }
  }

  reloadUserInfo(): Observable<any> {
    const reloadUserData = new Observable(observer => {
      this.accountService.getAccount().subscribe(response => {
        this.setUserData(response);
        observer.next(this.getUserData());
      });
    });

    return reloadUserData;
  }
}
