import { MessagesModule } from 'primeng/messages';
import { PaginatorModule } from 'primeng/paginator';
import { ReportsComponent } from './reports.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { ReportsRoutingModule } from './reports-routing.module';
import { FileSaverModule } from 'ngx-filesaver';

import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { WcagModule } from '@app/shared/wcag/wcag.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  declarations: [ReportsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    WcagModule,
    TableModule,
    CalendarModule,
    DropdownModule,
    FileSaverModule,
    FormsModule,
    PaginatorModule,
    OverlayPanelModule,
    MessagesModule,
    ReportsRoutingModule
  ]
})
export class ReportsModule {}
