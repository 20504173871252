import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Logger } from '../logger.service';
import { NotificationService } from '@app/shared/notifications/notification.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/notifications/notification.service";
var log = new Logger('ErrorHandlerInterceptor');
/**
 * Adds a default error handler to all requests.
 */
var ErrorHandlerInterceptor = /** @class */ (function () {
    function ErrorHandlerInterceptor(_httpNotificationHandler) {
        this._httpNotificationHandler = _httpNotificationHandler;
    }
    ErrorHandlerInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        return next.handle(request).pipe(catchError(function (error) { return _this.errorHandler(error); }));
    };
    // Customize the default error handler here if needed
    ErrorHandlerInterceptor.prototype.errorHandler = function (response) {
        switch (response.status) {
            case 500:
                this._httpNotificationHandler.sendMessage({
                    type: 'error',
                    title: 'Wewnętrzny błąd serwera',
                    message: 'Skontaktuj się z serwisem',
                    error: response.error || null
                });
                break;
            case 401:
                this._httpNotificationHandler.sendMessage({
                    type: 'error',
                    title: 'Autoryzacja',
                    message: response.error && response.error.message ? response.error.message : 'Niepoprawne dane logowania',
                    error: response.error || null
                });
                break;
            case 404:
                this._httpNotificationHandler.sendMessage({
                    type: 'error',
                    title: 'Błąd!',
                    message: 'Nie znaleziono strony',
                    error: response.error || null
                });
                break;
            default:
                this._httpNotificationHandler.sendMessage({
                    type: 'error',
                    title: response.error.error,
                    message: response.error.message,
                    error: response.status === 400 ? response.error : null
                });
                break;
        }
        throw response;
    };
    ErrorHandlerInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorHandlerInterceptor_Factory() { return new ErrorHandlerInterceptor(i0.ɵɵinject(i1.NotificationService)); }, token: ErrorHandlerInterceptor, providedIn: "root" });
    return ErrorHandlerInterceptor;
}());
export { ErrorHandlerInterceptor };
