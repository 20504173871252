import { CommunicatorComponent } from './communicator.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { AuthenticationGuard } from '@app/core/authentication/authentication.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'communicator',
      component: CommunicatorComponent,
      data: {
        title: extract('Komunikator'),
        expectedRole: ['ADMIN', 'CONTACT_ADMIN', 'SCIENTIST', 'EMPLOYER']
      },
      canActivate: [AuthenticationGuard]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class CommunicatorRoutingModule {}
