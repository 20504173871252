import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserManagementService = /** @class */ (function () {
    function UserManagementService(_http) {
        this._http = _http;
        this.resourceUrl = '/admin/users/extended';
    }
    UserManagementService.prototype.getUsers = function (paginatorConfig) {
        return this._http.get(this.resourceUrl, paginatorConfig);
    };
    UserManagementService.prototype.modifyUserAccount = function (id, data) {
        return this._http.put("/admin/accounts/modify/" + id, data);
    };
    UserManagementService.prototype.regeneratePassword = function (id, passwordChange) {
        return this._http.put("/admin/accounts/regenerate-password/" + id + "?passwordChangeRequired=" + passwordChange, null);
    };
    UserManagementService.prototype.getUserAccount = function (id) {
        return this._http.get("/admin/accounts/" + id);
    };
    UserManagementService.prototype.createAccount = function (user) {
        return this._http.post('/admin/users', user);
    };
    UserManagementService.prototype.getLoggedUser = function () {
        return this._http.get('/admin/users/loggedUsers');
    };
    UserManagementService.prototype.hideUser = function (id) {
        return this._http.put('/admin/accounts/hide/' + id, { id: id });
    };
    UserManagementService.prototype.markAsContactAdmin = function (newContactAdminId) {
        return this._http.put("/admin/users/contact-admin/" + newContactAdminId, {
            newContactAdminId: newContactAdminId
        });
    };
    UserManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserManagementService_Factory() { return new UserManagementService(i0.ɵɵinject(i1.HttpClient)); }, token: UserManagementService, providedIn: "root" });
    return UserManagementService;
}());
export { UserManagementService };
