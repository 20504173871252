import { UserProfileComponent } from './user-profile.component';
import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { AuthenticationGuard } from '@app/core/authentication/authentication.guard';
var ɵ0 = {
    title: extract('Profil użytkownika'),
    expectedRole: ['EMPLOYER', 'SCIENTIST', 'LIMITED_EMPLOYER', 'LIMITED_SCIENTIST', 'ADMIN', 'CONTACT_ADMIN']
};
var routes = [
    Shell.childRoutes([
        {
            path: 'profile',
            component: UserProfileComponent,
            data: ɵ0,
            canActivate: [AuthenticationGuard]
        }
    ])
];
var UserProfileRoutingModule = /** @class */ (function () {
    function UserProfileRoutingModule() {
    }
    return UserProfileRoutingModule;
}());
export { UserProfileRoutingModule };
export { ɵ0 };
