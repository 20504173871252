import { ICommunicatorMessageToAdmin, ICommunicatorSendMsgToUsers } from './../interfaces/communicator.interface';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ICommunicatorMessage,
  ICommunicatorMessageScientist,
  ICommunicatorMessageReplay
} from '@app/interfaces/communicator.interface';
import { ITableData, IPaginatorServerConfig } from '@app/interfaces/table.interface';

@Injectable({
  providedIn: 'root'
})
export class CommunicatorService {
  private resourceUrl = '/messages';

  constructor(private _http: HttpClient) {}

  getListMessage(paginatorConfig: IPaginatorServerConfig): Observable<ITableData> {
    const userId = paginatorConfig.params.userId;
    return this._http.get<ITableData>(`${this.resourceUrl}/received/${userId}`, paginatorConfig);
  }

  getListSendMessage(paginatorConfig: IPaginatorServerConfig): Observable<ITableData> {
    const userId = paginatorConfig.params.userId;
    return this._http.get<ITableData>(`${this.resourceUrl}/sent/${userId}`, paginatorConfig);
  }

  getListArchiveMessage(paginatorConfig: IPaginatorServerConfig): Observable<ITableData> {
    const userId = paginatorConfig.params.userId;
    return this._http.get<ITableData>(`${this.resourceUrl}/archived/${userId}`, paginatorConfig);
  }

  getMessage(messageId: number): Observable<ICommunicatorMessage[]> {
    return this._http.get<ICommunicatorMessage[]>(`${this.resourceUrl}/thread/${messageId}`);
  }

  sendMessageToAdmin(payload: ICommunicatorMessageToAdmin): Observable<ICommunicatorMessageToAdmin> {
    return this._http.post<ICommunicatorMessageToAdmin>(`${this.resourceUrl}/admin`, payload);
  }

  replayMessage(payload: ICommunicatorMessageReplay): Observable<ICommunicatorMessageReplay> {
    return this._http.post<ICommunicatorMessageReplay>(`${this.resourceUrl}/reply`, payload);
  }

  sendMessageToScientist(payload: ICommunicatorMessageScientist): Observable<ICommunicatorMessageScientist> {
    return this._http.post<ICommunicatorMessageScientist>(`${this.resourceUrl}/scientists`, payload);
  }

  sendMessageToUser(payload: ICommunicatorSendMsgToUsers): Observable<ICommunicatorSendMsgToUsers> {
    return this._http.post<ICommunicatorSendMsgToUsers>(`${this.resourceUrl}`, payload);
  }

  sendMessageToUsers(payload: ICommunicatorSendMsgToUsers): Observable<ICommunicatorSendMsgToUsers> {
    return this._http.post<ICommunicatorSendMsgToUsers>('/admin/messages', payload);
  }

  markMessageAsArchive(userId: number, messageId: number): Observable<any> {
    return this._http.put<any>(`${this.resourceUrl}/archive/${userId}/${messageId}`, {
      userId,
      messageId
    });
  }

  markMessageAsDeleted(userId: number, messageId: number): Observable<any> {
    return this._http.put<any>(`${this.resourceUrl}/delete/${userId}/${messageId}`, {
      userId,
      messageId
    });
  }
}
