<div class="container">
  <div class="row">
    <div class="w-100">
      <div class="card">
        <div class="card-header">
          <h3>Resetowanie hasła</h3>
        </div>
        <div class="card-body reset-padding-y" *ngIf="showResetPassEmailForm">
          <form id="password_reset_email_form">
            <div class="row px-3">
              <div class="col-12">
                <p class="text-center">
                  Aby zresetować hasło użytkownika, podaj poniżej adres e-mail na jaki jest zarejestrowane konto.
                </p>
              </div>
              <div class="col-lg-3 col-12 offset-lg-3">
                <div class="form-group">
                  <label for="email_reset">Adres e-mail:</label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="userEmailForm.email"
                    name="email_reset"
                    id="email_reset"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-12">
                <div class="d-flex justify-content-end align-items-center mt-2 h-100">
                  <button
                    class="btn btn-primary"
                    type="button"
                    aria-label="Przycisk zresetowania hasła"
                    (click)="requestResetPass()"
                    [disabled]="!userEmailForm.email"
                  >
                    Zresetuj hasło
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-body reset-padding-y" *ngIf="showResetPassForm">
          <form #resetPasswordForm="ngForm" id="reset_password_form">
            <div class="row px-3">
              <div class="col-12">
                <p class="text-center">Aby zresetować hasło uzupełnij pola poniżej</p>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label for="password_reset" class="required">Nowe hasło:</label>
                  <input
                    type="password"
                    class="form-control"
                    [(ngModel)]="userPasswordResetForm.password"
                    name="password_reset"
                    id="password_reset"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label for="repeatedPassword" class="required">Powtórz nowe hasło:</label>
                  <input
                    type="password"
                    class="form-control"
                    [(ngModel)]="userPasswordResetForm.repeatedPassword"
                    id="repeatedPassword"
                    name="repeatedPassword"
                    required
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="d-flex justify-content-end">
                  <button
                    class="btn btn-primary"
                    type="button"
                    aria-label="Przycisk zresetowania hasła"
                    (click)="resetPassword()"
                    [disabled]="!resetPasswordForm.form.valid"
                  >
                    Zresetuj hasło
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
