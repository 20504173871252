import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { ProblemBaseComponent } from './problem-base.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'problem-base', component: ProblemBaseComponent, data: { title: extract('Baza problemów') } }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ProblemBaseRoutingModule {}
