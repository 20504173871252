import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IEmployer } from '@app/interfaces/employer.interface';
import { IUserScientist } from '@app/interfaces/user-profile.interface';

type EntityResponseType = HttpResponse<IEmployer>;

@Injectable({
  providedIn: 'root'
})
export class ScientistService {
  private resourceUrl = '/scientists/';

  constructor(private _http: HttpClient) {}

  createScientist(scientist: any): Observable<any> {
    return this._http.post<any>(this.resourceUrl + 'register', scientist, { observe: 'response' });
  }

  updateScientist(scientist: any, id: number): Observable<IUserScientist> {
    return this._http.put<IUserScientist>('/admin/scientists/' + id, scientist);
  }

  getScientist(id: number): Observable<IUserScientist> {
    return this._http.get<IUserScientist>(this.resourceUrl + id);
  }
}
