import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageAccountComponent } from './manage-account.component';
import { ManageAccountRoutingModule } from './manage-account-routing.module';
import { WcagModule } from '@app/shared/wcag/wcag.module';

@NgModule({
  declarations: [ManageAccountComponent],
  imports: [CommonModule, FormsModule, WcagModule, ManageAccountRoutingModule]
})
export class ManageAccountModule {}
