import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { SearchComponent } from './search.component';
import { Shell } from '@app/shell/shell.service';
var ɵ0 = { title: extract('Wyszukiwarka') };
var routes = [
    Shell.childRoutes([
        { path: '', redirectTo: '/search', pathMatch: 'full' },
        { path: 'search', component: SearchComponent, data: ɵ0 }
    ])
];
var SearchRoutingModule = /** @class */ (function () {
    function SearchRoutingModule() {
    }
    return SearchRoutingModule;
}());
export { SearchRoutingModule };
export { ɵ0 };
