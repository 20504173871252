import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { NotFoundComponent } from './not-found.component';
var ɵ0 = { title: extract('Nie znaleziono strony') };
var routes = [
    Shell.childRoutes([
        {
            path: '404',
            component: NotFoundComponent,
            data: ɵ0
        }
    ])
];
var NotFoundRoutingModule = /** @class */ (function () {
    function NotFoundRoutingModule() {
    }
    return NotFoundRoutingModule;
}());
export { NotFoundRoutingModule };
export { ɵ0 };
