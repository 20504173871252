import { HelperService } from '@app/services/helper.service';
import { AuthenticationService } from '@app/core';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Logger } from '../logger.service';
import { CredentialsService } from './credentials.service';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService,
    private helperService: HelperService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const expectedRole: string[] = route.data.expectedRole;

    if (this.credentialsService.isAuthenticated()) {
      this.helperService.isLimitedUser().subscribe((isUserLimitedProfile: boolean) => {
        if (isUserLimitedProfile) {
          if (route.routeConfig.path !== 'profile') {
            this.router.navigate(['/profile'], { replaceUrl: true });
            return false;
          }
        }
      });

      const userRoles = await this.authenticationService.getUserRoles();

      const hasUserPermission =
        expectedRole !== null || expectedRole.length
          ? expectedRole.some(role => {
              return userRoles.indexOf(role) !== -1;
            })
          : null;

      if (hasUserPermission || expectedRole === null || !expectedRole.length) {
        return true;
      } else {
        this.router.navigate(['/search'], { replaceUrl: true });
        return false;
      }
    }

    log.debug('Not authenticated, redirecting and adding redirect url...');
    this.router.navigate(['/search'], { replaceUrl: true });
    return false;
  }
}
