import { WcagService } from './wcag.service';
import { Component, OnInit, ViewEncapsulation, Renderer2, Input } from '@angular/core';

@Component({
  selector: 'app-wcag',
  templateUrl: './wcag.component.html',
  styleUrls: ['./wcag.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WcagComponent implements OnInit {
  shouldShowDefaultVersion = false;
  shouldShowContrastVersion = true;
  @Input() isAdmin: boolean;
  @Input() isMobile: boolean;

  constructor(private renderer: Renderer2, private wcagService: WcagService) {}

  ngOnInit() {
    this.wcagService.getWcagConfig('wcag-view-mode').subscribe(getConfig => {
      switch (getConfig) {
        case 'contrast':
          this.setContrastVersion();
          break;
        case 'text':
          this.setTextVersion();
          break;
        default:
          this.setDefaultVersion();
          break;
      }
    });

    this.wcagService.getWcagConfig('wcag-font-size').subscribe((getFontSize: number) => {
      if (getFontSize) {
        const convertToNumber: number = Math.floor(getFontSize);
        if (convertToNumber >= 1 && convertToNumber <= 3) {
          this.changeFontSize(getFontSize);
        }
      }
    });
  }

  setDefaultVersion() {
    this.renderer.removeAttribute(document.body, 'class');
    this.shouldShowDefaultVersion = false;
    this.shouldShowContrastVersion = true;
    this.wcagService.setWcagConfig('wcag-view-mode', 'default');
    this.wcagService.setRemoveImages(false);
    this.wcagService.setContrastVersion(false);

    this.wcagService.getWcagConfig('wcag-is-reloaded').subscribe(isReloaded => {
      if (isReloaded === 'true') {
        this.wcagService.setWcagConfig('wcag-is-reloaded', 'false');
        location.reload();
      }
    });
  }

  setContrastVersion() {
    this.renderer.removeAttribute(document.body, 'class');
    this.renderer.addClass(document.body, 'contrast-version');
    this.shouldShowDefaultVersion = true;
    this.shouldShowContrastVersion = false;
    this.wcagService.setWcagConfig('wcag-view-mode', 'contrast');
    this.wcagService.setContrastVersion(true);
  }

  setTextVersion() {
    this.renderer.removeAttribute(document.body, 'class');
    this.renderer.addClass(document.body, 'text-version');
    this.wcagService.setWcagConfig('wcag-view-mode', 'text');
    this.wcagService.setWcagConfig('wcag-is-reloaded', 'true');
    this.wcagService.setRemoveImages(true);
    this.shouldShowDefaultVersion = true;
    this.shouldShowContrastVersion = true;
  }

  changeFontSize(size: number) {
    const html = document.querySelector('html');
    this.renderer.removeClass(document.body, 'increased-text');

    if (size !== 2) {
      this.renderer.setStyle(html, 'font-size', size === 1 ? '14px' : '18px');
      if (size === 1) {
        this.renderer.setStyle(document.body, 'font-size', '14px');
      } else {
        this.renderer.setStyle(document.body, 'font-size', '18px');
        this.renderer.addClass(document.body, 'increased-text');
      }
    } else {
      this.renderer.removeStyle(html, 'font-size');
      this.renderer.removeStyle(document.body, 'font-size');
    }

    this.wcagService.setWcagConfig('wcag-font-size', size);
  }
}
