import * as tslib_1 from "tslib";
import { HelperService } from './../../../../services/helper.service';
import { NotificationService } from './../../../../shared/notifications/notification.service';
import { RegisterService } from './../../../../services/register.service';
import { zip } from 'rxjs';
import { DictionariesService } from './../../../../services/dictionaries.service';
import { OnInit } from '@angular/core';
import { find } from 'lodash';
import { Router } from '@angular/router';
var EmployerRegisterComponent = /** @class */ (function () {
    function EmployerRegisterComponent(_dictionariesService, registerService, _notificationService, helperService, router) {
        this._dictionariesService = _dictionariesService;
        this.registerService = registerService;
        this._notificationService = _notificationService;
        this.helperService = helperService;
        this.router = router;
        this.userEmployer = {
            username: null,
            email: null,
            companyName: null,
            communeId: null,
            city: null,
            phoneNumber: null,
            leadingSpecialisationId: null,
            intelligentSpecialisationId: null,
            scienceId: null,
            scienceDomainId: null,
            specialisations: [],
            rodoAccepted: false,
            privacyPolicyAccepted: false,
            sendingInformationAccepted: false,
            dataProcessingAccepted: false
        };
        this.isSelectedAllRequirements = false;
        this.showMoreRequirements = {
            rodoAccepted: false,
            privacyPolicyAccepted: false,
            sendingInformationAccepted: false,
            dataProcessingAccepted: false
        };
    }
    EmployerRegisterComponent.prototype.ngOnInit = function () {
        this._getDictionaries();
        this.addSpecialisation();
    };
    EmployerRegisterComponent.prototype.changeShowMoreLess = function (type) {
        this.showMoreRequirements[type] = !this.showMoreRequirements[type];
    };
    EmployerRegisterComponent.prototype.isSelectedAll = function () {
        return (this.userEmployer.privacyPolicyAccepted &&
            this.userEmployer.rodoAccepted &&
            this.userEmployer.sendingInformationAccepted &&
            this.userEmployer.dataProcessingAccepted);
    };
    EmployerRegisterComponent.prototype.selectAllRequirements = function () {
        this.userEmployer.privacyPolicyAccepted = this.isSelectedAllRequirements;
        this.userEmployer.rodoAccepted = this.isSelectedAllRequirements;
        this.userEmployer.sendingInformationAccepted = this.isSelectedAllRequirements;
        this.userEmployer.dataProcessingAccepted = this.isSelectedAllRequirements;
    };
    EmployerRegisterComponent.prototype.addSpecialisation = function () {
        var emptySpecialisation = {
            id: null,
            name: null
        };
        this.userEmployer.specialisations.push(emptySpecialisation);
    };
    EmployerRegisterComponent.prototype.changeIntelligentSpecialisation = function () {
        var findInteligentSpecialisation = find(this.specialisations, ['id', this.userEmployer.leadingSpecialisationId]);
        this.userEmployer.intelligentSpecialisationId = findInteligentSpecialisation.intelligentSpecialisationId;
    };
    EmployerRegisterComponent.prototype.removeEmptySpecialisation = function () {
        var isNotEmptySpecialisation = this.userEmployer.specialisations.some(function (specialisation) {
            return specialisation.id !== null;
        });
        if (!isNotEmptySpecialisation) {
            this.userEmployer.specialisations = [];
        }
    };
    // Register user
    EmployerRegisterComponent.prototype.register = function () {
        var _this = this;
        this.removeEmptySpecialisation();
        if (this.helperService.comparePassword(this.userEmployer.password, this.userEmployer.passwordRepeat)) {
            this.registerService.registerEmployer(this.userEmployer).subscribe(function (resp) {
                _this._notificationService.sendMessage({
                    type: 'success',
                    title: 'Rejestracja',
                    message: 'Utworzenie konta przebiegło pomyślnie'
                });
                _this.router.navigate(['/register', 'employer']);
            });
        }
        else {
            this._notificationService.sendMessage({
                type: 'error',
                title: 'Rejestracja',
                message: 'Hasła nie są zgodne'
            });
        }
    };
    EmployerRegisterComponent.prototype.isOtherCommune = function (communeId) {
        return this.helperService.checkIsOtherCommune(communeId);
    };
    EmployerRegisterComponent.prototype.setInteligentSpecialisation = function (specialisationId) {
        if (specialisationId) {
            var specialisation = find(this.allSpecialisationsDict, { id: specialisationId });
            if (specialisation) {
                var intelligentSpecialisation = find(this.intelligentSpecialisationDict, {
                    value: specialisation.intelligentSpecialisationId
                });
                this.userEmployer.intelligentSpecialisationId = intelligentSpecialisation.value;
            }
            this.aditionalSpecialisationDict = this.aditionalSpecialisationDict.filter(function (itemDict) {
                return itemDict.value !== specialisationId;
            });
        }
    };
    // Prepare options
    EmployerRegisterComponent.prototype.changeModelScienceDomainDict = function (scienceId) {
        this.userEmployer.scienceDomainId = null;
        this.userEmployer.leadingSpecialisationId = null;
        this.scienceDomainSelectDict = this.mapScienceDomains(scienceId);
    };
    EmployerRegisterComponent.prototype.changeModelSpecialisationDict = function (scienceDomainId) {
        this.userEmployer.leadingSpecialisationId = null;
        this.specialisationSelectDict = this.mapSpecialisations(scienceDomainId);
        this.aditionalSpecialisationDict = this.mapSpecialisations(scienceDomainId);
    };
    EmployerRegisterComponent.prototype.mapScienceDomains = function (scienceId) {
        if (this.scienceDomainsDict && scienceId) {
            return this.scienceDomainsDict
                .filter(function (scienceDomain) {
                return scienceDomain.scienceId === scienceId;
            })
                .map(function (scienceDomain) {
                return {
                    label: scienceDomain.name,
                    value: scienceDomain.id
                };
            });
        }
        else {
            return null;
        }
    };
    EmployerRegisterComponent.prototype.mapSpecialisations = function (scienceDomainId) {
        if (this.allSpecialisationsDict && scienceDomainId) {
            return this.allSpecialisationsDict
                .filter(function (specialisation) {
                return specialisation.scienceDomainId === scienceDomainId;
            })
                .map(function (specialisation) {
                return {
                    label: specialisation.name,
                    value: specialisation.id
                };
            });
        }
        else {
            return null;
        }
    };
    // Get dictionaries
    EmployerRegisterComponent.prototype._getDictionaries = function () {
        var _this = this;
        zip(this._dictionariesService.getAllCommunes(), this._dictionariesService.getAllSpecialisations(), this._dictionariesService.getAllIntelligentSpecialisations(), this._dictionariesService.getAllScienceDomains(), this._dictionariesService.getAllSciences()).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 5), Commune = _b[0], Specialisations = _b[1], InteligentSpecialisation = _b[2], ScienceDomains = _b[3], Sciences = _b[4];
            _this.communesDict = Commune.map(function (commune) {
                return {
                    label: commune.name,
                    value: commune.id
                };
            });
            _this.specialisationsDict = Specialisations.map(function (specialisation) {
                return {
                    label: specialisation.name,
                    value: specialisation.id
                };
            });
            _this.intelligentSpecialisationDict = InteligentSpecialisation.map(function (intelSpec) {
                return {
                    label: intelSpec.name,
                    value: intelSpec.id
                };
            });
            _this.sciencesDict = Sciences.map(function (dict) {
                return {
                    label: dict.name,
                    value: dict.id
                };
            });
            // all ditionaries
            _this.specialisations = Specialisations;
            _this.scienceDomainsDict = ScienceDomains;
            _this.allSpecialisationsDict = Specialisations;
        });
    };
    return EmployerRegisterComponent;
}());
export { EmployerRegisterComponent };
