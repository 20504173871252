import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { merge } from 'lodash';
import { DialogService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class DialogBaseService {
  public dialogInstance: any = null;
  private _dialogBaseStatus$: Subject<any> = new Subject();
  private _dialogTemplateStatus$: Subject<any> = new Subject();

  constructor(private dialogService: DialogService) {}

  public setDialogBaseStatus(status: boolean): void {
    this._dialogBaseStatus$.next(status);
  }

  public getDialogBaseStatus(): Observable<boolean> {
    return this._dialogBaseStatus$.asObservable();
  }

  public setSwitchTemplateStatus(component: string, config?: object): void {
    if (this.dialogInstance) {
      if (config) {
        this.mergeConfig(config);
      }
      this._dialogTemplateStatus$.next(component);
    } else {
      this._dialogBaseStatus$.next(true);
      setTimeout(() => {
        if (config) {
          this.mergeConfig(config);
        }
        this._dialogTemplateStatus$.next(component);
      }, 1);
    }
  }

  public getSwitchTemplateStatus(): Observable<any> {
    return this._dialogTemplateStatus$.asObservable();
  }

  public createInstance(instance: any) {
    this.dialogInstance = instance;
  }

  public getInstance(): void {
    return this.dialogInstance;
  }

  public closeModal() {
    this.dialogInstance.close();
  }

  public mergeConfig(config: any) {
    const previousConfig = this.dialogService.dialogComponentRef.instance.config;
    this.dialogService.dialogComponentRef.instance.config = merge(previousConfig, config);
  }
}
