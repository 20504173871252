import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activeRoute.paramMap.subscribe((params: ParamMap) => {
      if (params.get('rodo')) {
        const getRodoEl = document.querySelector('#rodo');

        getRodoEl.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }
}
