<footer class="mt-4">
  <div class="container-fluid mn-footer-links px-2">
    <div class="row align-items-center justify-content-center h-100">
      <div class="col-12 footer-links">
        <div><a routerLink="/declaration-availability" (click)="backToTop()">Deklaracja dostępności</a></div>
        <div><a routerLink="/privacy-policy" (click)="backToTop()">Polityka prywatności</a></div>
        <div><a [routerLink]="['/privacy-policy', 'rodo']" (click)="backToTop()">RODO</a></div>
        <div><a routerLink="/regulations" (click)="backToTop()">Regulamin</a></div>
        <div>
          <a href="mailto:kontakt@wiedza.malopolska.pl" title="kontakt@wiedza.malopolska.pl"
            >Kontakt do administratora</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row my-3 align-items-center justify-content-center">
      <div class="mx-3" *ngFor="let image of listFooterImage">
        <a [href]="image.href" [title]="image.alt" target="_blank">
          <img [src]="image.source" class="img-fluid european-founds" [alt]="image.alt" />
        </a>
      </div>
    </div>
  </div>
</footer>
