<div class="container">
  <h3 class="mb-4">O Centrach Transferu Wiedzy</h3>
  <p class="text-justify">
    Centra Transferu Wiedzy to zespoły ekspertów o różnych umiejętnościach działające w wybranych jednostkach otoczenia
    biznesu w Małopolsce. Część zespołu stanowią naukowcy - specjaliści branżowi, część to osoby znające procesy
    transferu wiedzy i brokerzy zajmujący się nawiązywaniem kontaktów z przedsiębiorcami. Rolą tych zespołów jest
    udzielenie wsparcia przedsiębiorcom, którzy poszukują nowatorskich rozwiązań mogących poprawić sytuację firmy na
    rynku i budować ich przewagę konkurencyjną. Rozwiązania mogą być różnorodne, niemniej jednak ich cechą wspólną jest
    „szycie na miarę” potrzeb konkretnego przedsiębiorstwa. Zespół ekspertów przeanalizuje sytuację firmy, zaproponuje
    możliwe do zastosowania w konkretnym przypadku, a jeśli to potrzebne to postara się również wskazać możliwości
    sfinansowania wdrożeń proponowanych rozwiązań, ze środków zewnętrznych.
  </p>
  <p class="text-justify">
    Dziedziny, w których można nawiązać współpracę z Centrami Transferu Wiedzy w ramach projektu SPIN to:
  </p>
  <div class="row">
    <div class="icon-width d-flex justify-content-center">
      <img
        src="/assets/images/register-icons/scientist-icon_2.svg"
        class="img-fluid"
        alt="Nauki o życiu (life science)"
      />
    </div>
    <div class="content-width">
      <h4 class="mt-4">Nauki o życiu (life science)</h4>
      <p>
        Małopolskie Centrum Biotechnologii UJ
        <a href="http://www.mcb.uj.edu.pl/spin" target="_blank">www.mcb.uj.edu.pl/spin</a>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="icon-width d-flex justify-content-center">
      <img src="/assets/images/register-icons/scientist-icon_4.svg" class="img-fluid" alt="Energia zrównoważona" />
    </div>
    <div class="content-width">
      <h4 class="mt-4">Energia zrównoważona</h4>
      <p>
        Małopolskie Centrum Budownictwa Energooszczędnego
        <a
          href="http://mcbe.pk.edu.pl/index.php/spin-malopolskie-centra-transferu-wsparciem-dla-przedsiebiorcow-wiedzy/"
          target="_blank"
          >http://mcbe.pk.edu.pl/index.php/spin-malopolskie-centra-transferu-wsparciem-dla-przedsiebiorcow-wiedzy/</a
        >
      </p>
      <p>
        Centrum Zrównoważonego Rozwoju i Poszanowania Energii w Miękini
        <a href="http://www.spin.miekinia.agh.edu.pl/" target="_blank">http://www.spin.miekinia.agh.edu.pl/</a>
      </p>
      <p>
        Towarzystwo Geosynoptyków GEOS
        <a href="http://spinmctw.geos.agh.edu.pl/" target="_blank">http://spinmctw.geos.agh.edu.pl/</a>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="icon-width d-flex justify-content-center">
      <img
        src="/assets/images/register-icons/scientist-icon_1.svg"
        class="img-fluid"
        alt="Technologie informacyjne i komunikacyjne"
      />
    </div>
    <div class="content-width">
      <h4 class="mt-4">Technologie informacyjne i komunikacyjne</h4>
      <p>
        Fundacja Progress and Business
        <a href="http://www.pbf.pl/projekty/spin.html" target="_blank">http://www.pbf.pl/projekty/spin.html</a>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="icon-width d-flex justify-content-center">
      <img src="/assets/images/register-icons/scientist-icon_3.svg" class="img-fluid" alt="Chemia" />
    </div>
    <div class="content-width">
      <h4 class="mt-4">Chemia</h4>
      <p>
        Sieć Badawcza Łukasiewicza - Instytut Ceramiki i Materiałów Budowlanych w Krakowie
        <a href="https://www.icimb.pl/centrum-transferu-wiedzy" target="_blank"
          >https://www.icimb.pl/centrum-transferu-wiedzy</a
        >
      </p>
    </div>
  </div>
  <div class="row">
    <div class="icon-width d-flex justify-content-center">
      <img
        src="/assets/images/register-icons/scientist-icon_6.svg"
        class="img-fluid"
        alt="Produkcja metali i wyrobów metalowych oraz wyrobów z mineralnych surowców niemetalicznych"
      />
    </div>
    <div class="content-width">
      <h4 class="mt-4">Produkcja metali i wyrobów metalowych oraz wyrobów z mineralnych surowców niemetalicznych</h4>
      <p>
        Instytut Gospodarki Surowcami Mineralnymi i Energią Polskiej Akademii Nauk
        <a
          href="https://min-pan.krakow.pl/projekty/2020/03/20/spin-malopolskie-centra-transferu-wiedzy/"
          target="_blank"
          >https://min-pan.krakow.pl/projekty/2020/03/20/spin-malopolskie-centra-transferu-wiedzy/</a
        >
      </p>
    </div>
  </div>
  <div class="row">
    <div class="icon-width d-flex justify-content-center">
      <img
        src="/assets/images/register-icons/scientist-icon_8.svg"
        class="img-fluid"
        alt="Przemysły kreatywne oraz czasu wolnego"
      />
    </div>
    <div class="content-width">
      <h4 class="mt-4">Przemysły kreatywne oraz czasu wolnego</h4>
      <p>
        Fundacja Polish Heritage
        <a href="https://www.polishheritage.pl/ctw" target="_blank">https://www.polishheritage.pl/ctw</a>
      </p>
    </div>
  </div>
</div>
