import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProblemBaseComponent } from './problem-base.component';
import { ProblemBaseRoutingModule } from './problem-base-routing.module';
import { TableModule } from 'primeng/table';
import { WcagModule } from '@app/shared/wcag/wcag.module';
import { SharedModule } from '@app/shared';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  declarations: [ProblemBaseComponent],
  imports: [
    CommonModule,
    SharedModule,
    WcagModule,
    PaginatorModule,
    TooltipModule,
    ConfirmDialogModule,
    MessagesModule,
    TableModule,
    ProblemBaseRoutingModule
  ]
})
export class ProblemBaseModule {}
