import { NgForm } from '@angular/forms';
import { HelperService } from './../../../../services/helper.service';
import { NotificationService } from './../../../../shared/notifications/notification.service';
import { RegisterService } from './../../../../services/register.service';
import { IDefaultDict } from './../../../../interfaces/dictionary.interface';
import { zip } from 'rxjs';
import { DictionariesService } from './../../../../services/dictionaries.service';
import { SelectItem } from 'primeng/api';
import { IUserEmployer } from './../../../../interfaces/user-profile.interface';
import { Component, OnInit } from '@angular/core';
import { find } from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employer-register',
  templateUrl: './employer-register.component.html',
  styleUrls: ['./employer-register.component.scss']
})
export class EmployerRegisterComponent implements OnInit {
  employerRegister: NgForm;
  userEmployer: IUserEmployer = {
    username: null,
    email: null,
    companyName: null,
    communeId: null,
    city: null,
    phoneNumber: null,
    leadingSpecialisationId: null,
    intelligentSpecialisationId: null,
    scienceId: null,
    scienceDomainId: null,
    specialisations: [],
    rodoAccepted: false,
    privacyPolicyAccepted: false,
    sendingInformationAccepted: false,
    dataProcessingAccepted: false
  };

  isSelectedAllRequirements = false;

  showMoreRequirements = {
    rodoAccepted: false,
    privacyPolicyAccepted: false,
    sendingInformationAccepted: false,
    dataProcessingAccepted: false
  };

  // Dictionaries
  specialisations: any;
  tradesDict: SelectItem[];
  communesDict: SelectItem[];
  specialisationsDict: SelectItem[];
  intelligentSpecialisationDict: SelectItem[];
  scienceDomainsDict: any;
  sciencesDict: SelectItem[];
  aditionalSpecialisationDict: SelectItem[];
  allSpecialisationsDict: any;

  scienceDomainSelectDict: SelectItem[];
  specialisationSelectDict: SelectItem[];

  constructor(
    private _dictionariesService: DictionariesService,
    private registerService: RegisterService,
    private _notificationService: NotificationService,
    private helperService: HelperService,
    private router: Router
  ) {}

  ngOnInit() {
    this._getDictionaries();
    this.addSpecialisation();
  }

  changeShowMoreLess(type: string) {
    this.showMoreRequirements[type] = !this.showMoreRequirements[type];
  }

  isSelectedAll(): boolean {
    return (
      this.userEmployer.privacyPolicyAccepted &&
      this.userEmployer.rodoAccepted &&
      this.userEmployer.sendingInformationAccepted &&
      this.userEmployer.dataProcessingAccepted
    );
  }

  selectAllRequirements(): void {
    this.userEmployer.privacyPolicyAccepted = this.isSelectedAllRequirements;
    this.userEmployer.rodoAccepted = this.isSelectedAllRequirements;
    this.userEmployer.sendingInformationAccepted = this.isSelectedAllRequirements;
    this.userEmployer.dataProcessingAccepted = this.isSelectedAllRequirements;
  }

  addSpecialisation() {
    const emptySpecialisation: any = {
      id: null,
      name: null
    };

    this.userEmployer.specialisations.push(emptySpecialisation);
  }

  changeIntelligentSpecialisation() {
    const findInteligentSpecialisation = find(this.specialisations, ['id', this.userEmployer.leadingSpecialisationId]);
    this.userEmployer.intelligentSpecialisationId = findInteligentSpecialisation.intelligentSpecialisationId;
  }

  removeEmptySpecialisation() {
    const isNotEmptySpecialisation = this.userEmployer.specialisations.some(specialisation => {
      return specialisation.id !== null;
    });

    if (!isNotEmptySpecialisation) {
      this.userEmployer.specialisations = [];
    }
  }

  // Register user
  register() {
    this.removeEmptySpecialisation();

    if (this.helperService.comparePassword(this.userEmployer.password, this.userEmployer.passwordRepeat)) {
      this.registerService.registerEmployer(this.userEmployer).subscribe(resp => {
        this._notificationService.sendMessage({
          type: 'success',
          title: 'Rejestracja',
          message: 'Utworzenie konta przebiegło pomyślnie'
        });
        this.router.navigate(['/register', 'employer']);
      });
    } else {
      this._notificationService.sendMessage({
        type: 'error',
        title: 'Rejestracja',
        message: 'Hasła nie są zgodne'
      });
    }
  }

  isOtherCommune(communeId: number) {
    return this.helperService.checkIsOtherCommune(communeId);
  }

  setInteligentSpecialisation(specialisationId: number) {
    if (specialisationId) {
      const specialisation = find(this.allSpecialisationsDict, { id: specialisationId });
      if (specialisation) {
        const intelligentSpecialisation: any = find(this.intelligentSpecialisationDict, {
          value: specialisation.intelligentSpecialisationId
        });
        this.userEmployer.intelligentSpecialisationId = intelligentSpecialisation.value;
      }

      this.aditionalSpecialisationDict = this.aditionalSpecialisationDict.filter((itemDict: SelectItem) => {
        return itemDict.value !== specialisationId;
      });
    }
  }

  // Prepare options
  changeModelScienceDomainDict(scienceId: number) {
    this.userEmployer.scienceDomainId = null;
    this.userEmployer.leadingSpecialisationId = null;
    this.scienceDomainSelectDict = this.mapScienceDomains(scienceId);
  }

  changeModelSpecialisationDict(scienceDomainId: number) {
    this.userEmployer.leadingSpecialisationId = null;
    this.specialisationSelectDict = this.mapSpecialisations(scienceDomainId);
    this.aditionalSpecialisationDict = this.mapSpecialisations(scienceDomainId);
  }

  mapScienceDomains(scienceId: number): SelectItem[] {
    if (this.scienceDomainsDict && scienceId) {
      return this.scienceDomainsDict
        .filter((scienceDomain: any) => {
          return scienceDomain.scienceId === scienceId;
        })
        .map((scienceDomain: any) => {
          return {
            label: scienceDomain.name,
            value: scienceDomain.id
          };
        });
    } else {
      return null;
    }
  }

  mapSpecialisations(scienceDomainId: number): SelectItem[] {
    if (this.allSpecialisationsDict && scienceDomainId) {
      return this.allSpecialisationsDict
        .filter((specialisation: any) => {
          return specialisation.scienceDomainId === scienceDomainId;
        })
        .map((specialisation: any) => {
          return {
            label: specialisation.name,
            value: specialisation.id
          };
        });
    } else {
      return null;
    }
  }

  // Get dictionaries
  _getDictionaries() {
    zip(
      this._dictionariesService.getAllCommunes(),
      this._dictionariesService.getAllSpecialisations(),
      this._dictionariesService.getAllIntelligentSpecialisations(),
      this._dictionariesService.getAllScienceDomains(),
      this._dictionariesService.getAllSciences()
    ).subscribe(([Commune, Specialisations, InteligentSpecialisation, ScienceDomains, Sciences]) => {
      this.communesDict = (Commune as IDefaultDict[]).map(commune => {
        return {
          label: commune.name,
          value: commune.id
        };
      });

      this.specialisationsDict = (Specialisations as IDefaultDict[]).map(specialisation => {
        return {
          label: specialisation.name,
          value: specialisation.id
        };
      });

      this.intelligentSpecialisationDict = (InteligentSpecialisation as IDefaultDict[]).map(intelSpec => {
        return {
          label: intelSpec.name,
          value: intelSpec.id
        };
      });

      this.sciencesDict = (Sciences as IDefaultDict[]).map(dict => {
        return {
          label: dict.name,
          value: dict.id
        };
      });

      // all ditionaries
      this.specialisations = Specialisations;
      this.scienceDomainsDict = ScienceDomains;
      this.allSpecialisationsDict = Specialisations;
    });
  }
}
