import { Directive, Host, Optional, Self } from '@angular/core';
import { Calendar } from 'primeng/calendar';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'p-calendar'
})
export class CalendarDefaultsDirective {
  constructor(@Host() @Self() @Optional() public host: Calendar) {
    host.dateFormat = 'dd/mm/yy';
    host.locale = {
      firstDayOfWeek: 0,
      dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
      dayNamesShort: ['Ndz', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'So'],
      dayNamesMin: ['Nd', 'Po', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
      monthNames: [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień'
      ],
      monthNamesShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
      today: 'Dziś',
      clear: 'Wyczyść',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk'
    };
  }
}
