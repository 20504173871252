import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPaginatorServerConfig, ITableData } from '@app/interfaces/table.interface';

@Injectable({
  providedIn: 'root'
})
export class ProblemBaseService {
  private resourceUrl = '/public-search-entry';

  constructor(private _http: HttpClient) {}

  removeProblem(problemId: number): Observable<any> {
    return this._http.delete<ITableData>(`${this.resourceUrl}/${problemId}`);
  }

  getProblems(paginatorConfig: IPaginatorServerConfig): Observable<ITableData> {
    return this._http.get<ITableData>(this.resourceUrl, paginatorConfig);
  }
}
