<div class="container">
  <h3 class="mb-4">O Bazie</h3>
  <p class="text-justify">
    Odnalezienie ekspertów mogących poprawić sytuację firmy lub partnera biznesowego o specyficznych, unikatowych
    kompetencjach jest dużym wyzwaniem. Poszukiwanie informacji, wysyłanie wiadomości oraz przeszukiwanie zasobów
    Internetu pochłania wiele godzin, a i tak może okazać się mało skuteczne. Baza danych kompetencji pracowników
    naukowych jest propozycją nowatorskiego narzędzia, które umożliwia w krótkim czasie przy wykonaniu niedużego wysiłku
    odnalezienie pracownika naukowego mogącego przygotować nowatorskie rozwiązanie, bądź przedsiębiorcę z terenu
    Województwa Małopolskiego, chętnego do nawiązania współpracy oraz posiadającego kompetencje, które pozwolą wdrożyć,
    przetestować nowatorskie rozwiązania.
  </p>
  <p class="text-justify">
    Baza zawiera dwa rodzaje zestawów informacji: o grupie naukowców oraz o grupie przedsiębiorców. Informacje o
    naukowcach (profile) zawierają m. in. takie dane jak: osiągnięcia naukowe, obszary zainteresowań naukowych,
    zrealizowane projekty, wdrożone rozwiązania, patenty czy informację o publikacjach. Natomiast informacje o
    przedsiębiorcach to: nazwa firmy, branża i lokalizacja firmy.
  </p>
  <p class="text-justify">
    Dane w Bazie mogą być przeszukiwane zarówno po dokonaniu rejestracji jak i bez niej, jednak dopiero po dokonaniu
    bezpłatnej rejestracji użytkownik otrzymuje dostęp, nie tylko do danych zgromadzonych w bazie, ale także do
    możliwości nawiązania współpracy bezpośrednio za pomocą komunikatora. Dzięki takiemu rozwiązaniu, skróceniu ulega
    czas, jaki jest konieczny do wyszukania i nawiązania pierwszego kontaktu. Czy nawiązanie kontaktu przerodzi się w
    ścisłą współpracę zależy już bezpośrednio od chęci obu stron.
  </p>
  <p class="text-justify">
    Informacje o sposobie wyszukiwania, rejestracji i logowania do „Małopolskiej Nauki – bazy danych kompetencji
    pracowników naukowych” znajduje się poniżej.
  </p>
  <h3 class="mb-4">Instrukcja użytkownia</h3>
  <p-accordion [multiple]="true">
    <p-accordionTab header="Przejście na stronę główną">
      <p class="text-justify">
        Powrót na stronę główną (wyszukiwarka) możliwe jest po przez kliknięcie na logo "Małopolska" dostępne w lewej
        górnej części aplikacji.
      </p>
    </p-accordionTab>
    <p-accordionTab header="Rejestracja i logowanie">
      <p class="text-justify">
        Aby w pewni korzystać z bazy należy się zarejestrować po przez przycisk "Zarejestruj się, aby zobaczyć wyniki
        wyszukiwania", który jest dostępny na stronie głównej aplikacji. Następnie należy wybrać typ konta, a w dalszej
        części uzupełnić formularz rejestracyjny. Po poprawnym uzupełnieniu wszystkich pól i kliknięciu na przycisk
        "Zarejestruj" zostanie wysłana wiadomość na podany w formularzu adres e-mail z linkiem aktywacyjnym. W
        wiadomości aktywacyjnej należy kliknąć w podany link i konto zostanie aktywowane.
      </p>
      <p class="text-justify">
        Logowanie użytkownika odbywa się po przez formularz dostępny na stronie aplikacji w prawej górnej części. Po
        pomyślnym zalogowaniu, użytkownik zostanie przekierowany na stronę główną wyszukiwarki.
      </p>
    </p-accordionTab>
    <p-accordionTab header="Kontakt z naukowcami">
      <p class="text-justify">
        W celu skontaktowania się z naukowcami dostępnymi w bazie, należy w pierwszej kolejności wyszukać naukowców po
        przez wyszukiwarkę dostępną na stronie głównej. W dalczej kolejności należy zaznaczyć pole dostępne po lewej
        stronie wyników wyszukiwania oraz kliknąć na przycisk "Napisz wiadomość".
      </p>
    </p-accordionTab>
    <p-accordionTab header="Kontakt z przedsiębiorcami">
      <p class="text-justify">
        Aplikacja umożliwia również kontakt z przedsiębiorcami. Należy na stronie głównej aplikacji kliknąć na zakładkę
        "Wyszukaj przedsiębiorców", a następnie uzupełnić kryteria. Po kliknięciu na przycisk "Wyszukaj" aplikacja
        znajdzie dopasowane do kryteriów wyniki wyszukiwania. Aby skontaktować się z przedsiębiorcą, należy po lewej
        stronie wyników wyszukiwania zaznaczyć pole, a następnie kliknąć na przycisk "Wyślij wiadomość".
      </p>
    </p-accordionTab>
    <p-accordionTab header="Odczytywanie otrzymanych wiadomości">
      <p class="text-justify">
        Aby odczytać wiadomość należy kliknąć na nazwę użytkownika dostępną w prawej górnej części aplikacji, a
        następnie wybrać opcję "Komunikator"
      </p>
    </p-accordionTab>
    <p-accordionTab header="Edycja profilu">
      <p class="text-justify">
        W celu edycji profilu należy pomyślnie się zalogować, a następnie kliknąć na nazwę użytkownika dostępną w prawej
        górnej części aplikacji. Pojawi się menu, w którym należy wybrać opcję "Profil"
      </p>
    </p-accordionTab>
  </p-accordion>
</div>
