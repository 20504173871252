import { HelperService } from '@app/services/helper.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPaginatorServerConfig } from '@app/interfaces/table.interface';

@Injectable({
  providedIn: 'root'
})
export class MailNotificationService {
  private _eventLogUrl = '/admin/event-logs';
  constructor(private _http: HttpClient, private helperService: HelperService) {}

  getEventLogs(paginatorConfig: IPaginatorServerConfig): Observable<any> {
    return this._http.get<any>(this._eventLogUrl, paginatorConfig);
  }

  confirmCooperation(token: string, confirm: boolean): Observable<any> {
    const queryParam = this.helperService.buildQueryParams({ confirm });
    return this._http.get<any>(`/notifications/cooperation/token/${token}/confirm`, {
      params: queryParam
    });
  }
}
