import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EmployerService = /** @class */ (function () {
    function EmployerService(_http) {
        this._http = _http;
        this.resourceUrl = '/employers';
    }
    EmployerService.prototype.updateEmployer = function (userId, employer) {
        return this._http.put("/admin/employers/" + userId, employer);
    };
    EmployerService.prototype.getEmployer = function (id) {
        return this._http.get(this.resourceUrl + '/' + id);
    };
    EmployerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmployerService_Factory() { return new EmployerService(i0.ɵɵinject(i1.HttpClient)); }, token: EmployerService, providedIn: "root" });
    return EmployerService;
}());
export { EmployerService };
