import { IUserResetPassword } from '../../../../interfaces/user-profile.interface';
import { NotificationService } from '../../../../shared/notifications/notification.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { RegisterService } from '../../../../services/register.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss']
})
export class ManageAccountComponent implements OnInit {
  showResetPassForm: boolean;
  showResetPassEmailForm: boolean;
  passwordResetToken: string;

  userEmailForm: any = {
    email: null
  };

  userPasswordResetForm: IUserResetPassword = {
    password: null,
    repeatedPassword: null,
    token: null
  };

  constructor(
    private registerService: RegisterService,
    private route: ActivatedRoute,
    private router: Router,
    private _notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.route.url.subscribe(url => {
      if (url[0].path === 'reset') {
        this.route.paramMap.subscribe((params: ParamMap) => {
          this.passwordResetToken = params.get('passwordResetToken');
          if (this.passwordResetToken) {
            this.showResetPassForm = true;
            this.showResetPassEmailForm = false;
          } else {
            this.showResetPassEmailForm = true;
          }
        });
      } else {
        this.route.paramMap.subscribe((params: ParamMap) => {
          const token = params.get('token');

          if (token) {
            this.registerService.activateAccount(token).subscribe(
              resp => {
                if (resp.message) {
                  this._notificationService.sendMessage({
                    type: 'success',
                    title: 'Aktywacja',
                    message: 'Aktywacja konta przebiegła pomyślnie'
                  });
                  this.router.navigate(['/search']);
                }
              },
              () => {
                this.router.navigate(['/search']);
              }
            );
          } else {
            this.router.navigate(['/search']);
          }
        });
      }
    });
  }

  requestResetPass() {
    this.registerService.requestResetPass(this.userEmailForm.email).subscribe(
      resp => {
        this._notificationService.sendMessage({
          type: 'success',
          title: 'Reset hasła',
          message: 'Jeśli dane są poprawne, sprawdź swoją skrzynkę pocztową'
        });
        this.router.navigate(['/search']);
      },
      () => {
        this._notificationService.sendMessage({
          type: 'error',
          title: 'Błąd!',
          message: 'Nieudało się wysłać żądania'
        });
        this.router.navigate(['/search']);
      }
    );
  }

  resetPassword() {
    if (this.userPasswordResetForm.password !== this.userPasswordResetForm.repeatedPassword) {
      this._notificationService.sendMessage({
        type: 'warn',
        title: 'Reset hasła',
        message: 'Podane hasła różnią się'
      });
      return;
    }
    this.userPasswordResetForm.token = this.passwordResetToken;
    this.registerService.resetPassword(this.passwordResetToken, this.userPasswordResetForm).subscribe(
      resp => {
        this._notificationService.sendMessage({
          type: 'success',
          title: 'Reset hasła',
          message: 'Hasło zostało zmienione poprawnie'
        });
        this.router.navigate(['/search']);
      },
      () => {
        this._notificationService.sendMessage({
          type: 'error',
          title: 'Błąd!',
          message: 'Nieudało się zmienić hasła'
        });
      }
    );
  }
}
