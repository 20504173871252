import * as tslib_1 from "tslib";
import { HelperService } from '@app/services/helper.service';
import { AuthenticationService } from './../../../core/authentication/authentication.service';
import { DictionariesService } from '@app/services/dictionaries.service';
import { SearchService } from './../../../services/search.service';
import { OnInit, ElementRef } from '@angular/core';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
import { DynamicDialogConfig } from 'primeng/api';
import { zip } from 'rxjs';
import { find, filter } from 'lodash';
var ShowUserProfileComponent = /** @class */ (function () {
    function ShowUserProfileComponent(_notificationService, _dialogService, config, searchService, dictionatiesService, authenticationService, helperService) {
        this._notificationService = _notificationService;
        this._dialogService = _dialogService;
        this.config = config;
        this.searchService = searchService;
        this.dictionatiesService = dictionatiesService;
        this.authenticationService = authenticationService;
        this.helperService = helperService;
        this.scientistLimitedProfile = {
            firstName: null,
            surname: null,
            department: null,
            universityId: null,
            projectsDescription: null,
            scientificWorksDescription: null,
            descriptionOfCooperationWithEmployers: null,
            academicTitleId: null,
            keyWords: [],
            scienceId: null,
            scienceDomainId: null,
            numberOfProjects: null,
            leadingSpecialisationId: null,
            intelligentSpecialisationId: null,
            specialisations: [],
            availableForCooperation: null
        };
        this.employerLimitedProfile = {
            companyName: null,
            scienceId: null,
            communeId: null,
            city: null,
            scienceDomainId: null,
            intelligentSpecialisationId: null,
            leadingSpecialisationId: null,
            specialisations: null
        };
        this.scientistProfile = {
            email: null,
            phoneNumber: null
        };
        this.requestForEstablishCooperation = {
            scientistId: null,
            searchEntryId: null,
            title: null
        };
    }
    ShowUserProfileComponent.prototype.ngOnInit = function () {
        if (this.config.data) {
            this.userId = this.config.data.userId;
            if (this.config.data.userType === 'SCIENTIST') {
                this.getLimitedScientistProfile(this.userId);
                this.requestForEstablishCooperation.scientistId = this.config.data.userId;
                this.requestForEstablishCooperation.searchEntryId = this.config.data.searchEntryId;
            }
            else {
                this.getEmployerLimitedProfile(this.userId);
            }
            this.isUserScientist = this.config.data.activeTabId === 0;
            this.isUserEmployer = this.config.data.activeTabId === 1;
            this.isUserCanEstablishCooperation();
        }
        this._getDictionaries();
    };
    ShowUserProfileComponent.prototype.print = function () {
        this.helperService.printFromImage(this.printProfileResult.nativeElement);
    };
    ShowUserProfileComponent.prototype.isUserCanEstablishCooperation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.authenticationService.userData$.subscribe(function (userData) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var userRoles;
                    var _this = this;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.authenticationService.getUserRoles()];
                            case 1:
                                userRoles = (_a.sent()).some(function (role) {
                                    return role === 'EMPLOYER' && !_this.isUserEmployer;
                                });
                                this.canEstablishCooperation = userRoles;
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    ShowUserProfileComponent.prototype.getLimitedScientistProfile = function (userId) {
        var _this = this;
        this.searchService.getScientistLimitedProfile(userId).subscribe(function (response) {
            _this.scientistLimitedProfile = response;
        });
    };
    ShowUserProfileComponent.prototype.getEmployerLimitedProfile = function (userId) {
        var _this = this;
        this.searchService.getEmployerLimitedProfile(userId).subscribe(function (response) {
            _this.employerLimitedProfile = response;
        });
    };
    ShowUserProfileComponent.prototype.getScientistProfile = function () {
        var _this = this;
        this.searchService.requestEstablishCooperation(this.requestForEstablishCooperation).subscribe(function (response) {
            _this.scientistProfile.email = response.email;
            _this.scientistProfile.phoneNumber = response.phoneNumber;
            _this.establishedCooperation = true;
            _this._notificationService.sendMessage({
                type: 'success',
                title: 'Dane kontaktowe',
                message: 'Współpraca została nawiązana pomyślnie'
            });
        });
    };
    ShowUserProfileComponent.prototype.changeModelScienceDomainDict = function (scienceId) {
        this.scientistLimitedProfile.scienceDomainId = null;
        this.scientistLimitedProfile.leadingSpecialisationId = null;
        this.employerLimitedProfile.scienceDomainId = null;
        this.employerLimitedProfile.leadingSpecialisationId = null;
        this.scienceDomainSelectDict = this.mapScienceDomains(scienceId);
    };
    ShowUserProfileComponent.prototype.mapScienceDomains = function (scienceId) {
        if (this.scienceDomainsDict && scienceId) {
            return this.scienceDomainsDict
                .filter(function (scienceDomain) {
                return scienceDomain.scienceId === scienceId;
            })
                .map(function (scienceDomain) {
                return {
                    label: scienceDomain.name,
                    value: scienceDomain.id
                };
            });
        }
        else {
            return null;
        }
    };
    ShowUserProfileComponent.prototype.changeModelSpecialisationDict = function (scienceDomainId) {
        this.scientistLimitedProfile.leadingSpecialisationId = null;
        this.employerLimitedProfile.leadingSpecialisationId = null;
        this.specialisationsDict = this.mapSpecialisations(scienceDomainId);
    };
    ShowUserProfileComponent.prototype.mapSpecialisations = function (scienceDomainId) {
        if (this.specialisations && scienceDomainId) {
            return this.specialisations
                .filter(function (specialisation) {
                return specialisation.scienceDomainId === scienceDomainId;
            })
                .map(function (specialisation) {
                return {
                    label: specialisation.name,
                    value: specialisation.id
                };
            });
        }
        else {
            return null;
        }
    };
    ShowUserProfileComponent.prototype.setInteligentSpecialisation = function (specialisationId) {
        if (specialisationId) {
            var specialisation = find(this.specialisations, { id: specialisationId });
            if (specialisation) {
                var intelligentSpecialisation = find(this.intelligentSpecialisationsDict, {
                    value: specialisation.intelligentSpecialisationId
                });
                if (this.isUserEmployer) {
                    this.employerLimitedProfile.intelligentSpecialisationId = intelligentSpecialisation.value;
                }
                else if (this.isUserScientist) {
                    this.scientistLimitedProfile.intelligentSpecialisationId = intelligentSpecialisation.value;
                }
            }
        }
    };
    ShowUserProfileComponent.prototype.getSciencesAndClasification = function () {
        var _this = this;
        if (this.isUserEmployer) {
            var filteredSpecialisation = filter(this.specialisations, function (specialisation) {
                return (specialisation.intelligentSpecialisationId === _this.employerLimitedProfile.intelligentSpecialisationId &&
                    specialisation.id === _this.employerLimitedProfile.leadingSpecialisationId);
            });
            this.employerLimitedProfile.scienceDomainId = filteredSpecialisation && filteredSpecialisation[0].scienceDomainId;
            this.employerLimitedProfile.scienceId = find(this.scienceDomainsDict, {
                id: this.employerLimitedProfile.scienceDomainId
            }).scienceId;
        }
        else if (this.isUserScientist) {
            var filteredSpecialisation = filter(this.specialisations, function (specialisation) {
                return (specialisation.intelligentSpecialisationId === _this.scientistLimitedProfile.intelligentSpecialisationId &&
                    specialisation.id === _this.scientistLimitedProfile.leadingSpecialisationId);
            });
            this.scientistLimitedProfile.scienceDomainId =
                filteredSpecialisation && filteredSpecialisation[0].scienceDomainId;
            this.scientistLimitedProfile.scienceId = find(this.scienceDomainsDict, {
                id: this.scientistLimitedProfile.scienceDomainId
            }).scienceId;
        }
    };
    // Dictionaries
    ShowUserProfileComponent.prototype._getDictionaries = function () {
        var _this = this;
        zip(this.dictionatiesService.getAllDepartments(), this.dictionatiesService.getAllScienceDomains(), this.dictionatiesService.getAllSciences(), this.dictionatiesService.getAllSpecialisations(), this.dictionatiesService.getAllAcademicTitles(), this.dictionatiesService.getAllUniversities(), this.dictionatiesService.getAllCommunes(), this.dictionatiesService.getSimpleUserStatus(), this.dictionatiesService.getAllIntelligentSpecialisations()).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 9), Departments = _b[0], ScienceDomain = _b[1], Sciences = _b[2], Specialisations = _b[3], AcademicTitles = _b[4], Universities = _b[5], Communes = _b[6], Statuses = _b[7], IntelligentSpecialisations = _b[8];
            _this.departmentsDict = Departments.map(function (dict) {
                return {
                    label: dict.name,
                    value: dict.id
                };
            });
            _this.scienceDomainDict = ScienceDomain.map(function (dict) {
                return {
                    label: dict.name,
                    value: dict.id
                };
            });
            _this.scienceDomainSelectDict = ScienceDomain.map(function (scienceDomain) {
                return {
                    label: scienceDomain.name,
                    value: scienceDomain.id
                };
            });
            _this.sciencesDict = Sciences.map(function (dict) {
                return {
                    label: dict.name,
                    value: dict.id
                };
            });
            _this.specialisationsDict = Specialisations.map(function (dict) {
                return {
                    label: dict.name,
                    value: dict.id
                };
            });
            _this.academicTitlesDict = AcademicTitles.map(function (dict) {
                return {
                    label: dict.name,
                    value: dict.id
                };
            });
            _this.universitiesDict = Universities.map(function (dict) {
                return {
                    label: dict.name,
                    value: dict.id
                };
            });
            _this.communesDict = Communes.map(function (dict) {
                return {
                    label: dict.name,
                    value: dict.id
                };
            });
            _this.statusesDict = Statuses;
            _this.intelligentSpecialisationsDict = IntelligentSpecialisations.map(function (dict) {
                return {
                    label: dict.name,
                    value: dict.id
                };
            });
            // Get All dict
            _this.scienceDomainsDict = ScienceDomain;
            _this.specialisations = Specialisations;
            _this.getSciencesAndClasification();
        });
    };
    return ShowUserProfileComponent;
}());
export { ShowUserProfileComponent };
