<div class="wcag-container">
  <div [ngClass]="{ 'container-fluid': isAdmin, container: !isAdmin }">
    <div class="row">
      <div class="col-12 d-flex justify-content-end align-items-center wcag-buttons">
        <div class="wcag-group">
          <button
            class="btn btn-wcag"
            *ngIf="shouldShowContrastVersion"
            aria-label="Ustaw wersję kontrastową"
            (click)="setContrastVersion()"
            tabindex="{{ isMobile ? 4 : 2 }}"
          >
            Wersja kontrastowa
          </button>
        </div>
        <div class="wcag-group" *ngIf="shouldShowDefaultVersion">
          <button
            class="btn btn-wcag"
            aria-label="Przywróć wersję graficzną"
            (click)="setDefaultVersion()"
            tabindex="{{ isMobile ? 5 : 3 }}"
          >
            Wersja graficzna
          </button>
        </div>
        <div class="wcag-group">
          <button
            class="btn btn-wcag"
            aria-label="Ustaw wersję tekstową"
            (click)="setTextVersion()"
            tabindex="{{ isMobile ? 6 : 4 }}"
          >
            Wersja tekstowa
          </button>
        </div>
        <div class="wcag-group wcag-font-size d-flex align-items-stretch">
          <button
            class="btn btn-wcag btn-wcag-text"
            aria-label="Zmniejsz tekst"
            (click)="changeFontSize(1)"
            tabindex="{{ isMobile ? 7 : 5 }}"
          >
            <span class="d-flex h-100 align-items-end">A</span>
          </button>
          <button
            class="btn btn-wcag btn-wcag-text"
            aria-label="Domyślna wielkość tekst"
            (click)="changeFontSize(2)"
            tabindex="{{ isMobile ? 8 : 6 }}"
          >
            <span class="d-flex h-100 align-items-end">A</span>
          </button>
          <button
            class="btn btn-wcag btn-wcag-text"
            aria-label="Powiększ tekst"
            (click)="changeFontSize(3)"
            tabindex="{{ isMobile ? 9 : 7 }}"
          >
            <span class="d-flex h-100 align-items-end">A</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
