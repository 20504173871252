import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ConfigurationAppService = /** @class */ (function () {
    function ConfigurationAppService(_http) {
        this._http = _http;
        this.globalMaintenance = new BehaviorSubject(false);
        this.passwordRuleUrl = '/admin/password-rule';
    }
    ConfigurationAppService.prototype.setMaintenance = function (maintenance) {
        this.globalMaintenance.next(maintenance);
    };
    ConfigurationAppService.prototype.getPasswordRules = function (paginatorConfig) {
        return this._http.get(this.passwordRuleUrl + "/page", paginatorConfig);
    };
    ConfigurationAppService.prototype.getPasswordRule = function (id) {
        if (id === void 0) { id = 1; }
        return this._http.get(this.passwordRuleUrl + "/" + id);
    };
    ConfigurationAppService.prototype.getDefaultPasswordRule = function () {
        return this._http.get(this.passwordRuleUrl + "/default");
    };
    ConfigurationAppService.prototype.getGlobalWebsiteConfig = function () {
        return this._http.get('/websites');
    };
    ConfigurationAppService.prototype.getWebsiteConfig = function () {
        return this._http.get('/admin/websites');
    };
    ConfigurationAppService.prototype.updateWebsiteConfig = function (payload) {
        return this._http.put('/admin/websites', payload);
    };
    ConfigurationAppService.prototype.updatePasswordRule = function (passwordRule, passwordRuleId) {
        return this._http.put(this.passwordRuleUrl + "/" + passwordRuleId, passwordRule);
    };
    ConfigurationAppService.prototype.updateDefaultPasswordRule = function (passwordRule) {
        return this._http.put(this.passwordRuleUrl + "/default", passwordRule);
    };
    ConfigurationAppService.prototype.createPasswordRule = function (passwordRule) {
        return this._http.post(this.passwordRuleUrl, passwordRule);
    };
    ConfigurationAppService.prototype.removePasswordRule = function (id) {
        return this._http.delete(this.passwordRuleUrl + "/" + id);
    };
    ConfigurationAppService.prototype.changeDefaultPasswordRule = function (id) {
        return this._http.put(this.passwordRuleUrl + "/change-default/" + id, null);
    };
    ConfigurationAppService.prototype.updateDictionary = function (dictionaryName, body) {
        return this._http.put("/dictionaries/" + dictionaryName, body);
    };
    ConfigurationAppService.prototype.createItemDictionary = function (dictionaryName, body) {
        return this._http.post("/dictionaries/" + dictionaryName, body);
    };
    ConfigurationAppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationAppService_Factory() { return new ConfigurationAppService(i0.ɵɵinject(i1.HttpClient)); }, token: ConfigurationAppService, providedIn: "root" });
    return ConfigurationAppService;
}());
export { ConfigurationAppService };
