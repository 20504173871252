<div class="container">
  <div class="row">
    <div class="col-12">
      <p-tabView (onChange)="onChangeTab($event)">
        <p-tabPanel headerStyleClass="search-header-tab scientist">
          <ng-template pTemplate="header">Wyszukaj naukowców</ng-template>
          <ng-template pTemplate="content">
            <form #searchScientistForm="ngForm" id="search_scientist_form" class="search-form scientist-form-bg">
              <div class="row" *ngIf="!isLoading">
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <label
                      for="keyWords"
                      [ngClass]="{
                        required: !searchScientistModel.scienceId && !searchScientistModel.keyWords?.length
                      }"
                      >Słowa kluczowe:</label
                    >
                    <p-chips
                      [(ngModel)]="searchScientistModel.keyWords"
                      [addOnTab]="true"
                      [addOnBlur]="true"
                      (keydown)="keyWordsOnKeyDown($event)"
                      styleClass="form-control"
                      ariaLabelledBy="keyWords"
                      inputId="keyWords"
                      inputStyleClass="control"
                      id="keyWords"
                      name="keyWords"
                    >
                    </p-chips>
                    <span class="text-small">Wypisz słowa kluczowe po przecinku lub naciskając enter</span>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div
                    class="mn-form-group"
                    wcagAria
                    [wcagAriaFor]="'scienceId'"
                    [wcagAriaSelectName]="'scienceIdSelect'"
                  >
                    <label
                      for="scienceId"
                      [ngClass]="{
                        required: !searchScientistModel.scienceId && !searchScientistModel.keyWords?.length
                      }"
                      >Klasyfikacja dziedzin i nauki:</label
                    >
                    <p-dropdown
                      [options]="sciencesDict"
                      [(ngModel)]="searchScientistModel.scienceId"
                      (onChange)="getModelDictScience(searchScientistModel.scienceId)"
                      [showClear]="true"
                      filter="true"
                      placeholder="Wybierz z listy"
                      styleClass="form-control"
                      ariaLabelledBy="scienceId"
                      inputId="scienceId"
                      selectId="scienceId"
                      ariaFilterLabel="Filtrowanie wyników"
                      emptyFilterMessage="Brak wyników wyszukiwania"
                      id="scienceId"
                      name="scienceIdSelect"
                    >
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div
                    class="mn-form-group"
                    wcagAria
                    [wcagAriaFor]="'scienceDomainId'"
                    [wcagAriaSelectName]="'scienceDomainIdSelect'"
                  >
                    <label for="scienceDomainId">Dziedzina:</label>
                    <p-dropdown
                      [options]="scienceDropdownModel"
                      [(ngModel)]="searchScientistModel.scienceDomainId"
                      (onChange)="getModelDictSpecialisation(searchScientistModel.scienceDomainId)"
                      [showClear]="true"
                      filter="true"
                      placeholder="Wybierz z listy"
                      styleClass="form-control"
                      [disabled]="!searchScientistModel.scienceId"
                      id="scienceDomainId"
                      name="scienceDomainIdSelect"
                      ariaLabelledBy="scienceDomainId"
                      emptyFilterMessage="Brak wyników wyszukiwania"
                      inputId="scienceDomainId"
                      selectId="scienceDomainId"
                      ariaFilterLabel="Filtrowanie wyników"
                    >
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div
                    class="mn-form-group"
                    wcagAria
                    [wcagAriaFor]="'specialisationIdentifiers'"
                    [wcagAriaSelectName]="'specialisationIdentifiersSelect'"
                  >
                    <label for="specialisationIdentifiers">Specjalizacja:</label>
                    <p-dropdown
                      [options]="specialisationDropdownModel"
                      [(ngModel)]="searchScientistModel.specialisationIdentifiers"
                      [showClear]="true"
                      filter="true"
                      placeholder="Wybierz z listy"
                      styleClass="form-control"
                      [disabled]="!searchScientistModel.scienceId || !searchScientistModel.scienceDomainId"
                      id="specialisationIdentifiers"
                      name="specialisationIdentifiersSelect"
                      ariaLabelledBy="specialisationIdentifiers"
                      emptyFilterMessage="Brak wyników wyszukiwania"
                      inputId="specialisationIdentifiers"
                      selectId="specialisationIdentifiers"
                      ariaFilterLabel="Filtrowanie wyników"
                    ></p-dropdown>
                  </div>
                </div>
                <div class="col-12" *ngIf="isEmployer || isScientist">
                  <div class="form-group">
                    <label for="description" class="required">Opis problemu:</label>
                    <input
                      id="description"
                      class="form-control"
                      name="description"
                      [(ngModel)]="searchScientistModel.description"
                      [maxlength]="maxDescriptionLength"
                      required
                    />
                    <div class="d-flex justify-content-between">
                      <span class="text-small">Pole obowiązkowe</span>
                      <div class="mn-field-description text-right">
                        <span *ngIf="searchScientistModel.description" class="text-small"
                          >{{ searchScientistModel.description.length }} / {{ maxDescriptionLength }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12" *ngIf="isEmployer">
                  <label class="sr-only required" for="isSearchConfidential">Wyszukiwanie poufne</label>
                  <p-checkbox
                    binary="true"
                    label="Wyszukiwanie poufne"
                    [(ngModel)]="searchScientistModel.isSearchConfidential"
                    class="ui-checkbox-container"
                    ariaLabelledBy="isSearchConfidential"
                    inputId="isSearchConfidentialInput"
                    inputStyleClass="control"
                    id="isSearchConfidential"
                    name="isSearchConfidential"
                    required
                  ></p-checkbox>
                </div>
                <div class="col-12">
                  <div class="d-flex justify-content-end">
                    <div class="d-flex align-items-center mr-3">
                      <span class="text-small text-required">* - Pola obowiązkowe</span>
                    </div>
                    <button
                      class="btn btn-primary mr-2 btn-white"
                      type="reset"
                      (click)="clearFilter()"
                      aria-label="Przycisk czyszczenia filtrów"
                    >
                      Wyczyść filtry
                    </button>
                    <div
                      pTooltip="Uzupełnij przynajmniej jedno pole oprócz opisu problemu i wyszukiwania poufnego"
                      tooltipPosition="top"
                      tooltipStyleClass="text-center"
                      [tooltipDisabled]="!isEmployer || !isScientist || !isSearchQueryFill()"
                    >
                      <button
                        class="btn btn-primary btn-white"
                        type="submit"
                        aria-label="Przycisk wyszukiwania"
                        (click)="searchEngine()"
                        [disabled]="isSearchQueryFill() || !searchScientistForm.form.valid"
                      >
                        Wyszukaj
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel headerStyleClass="search-header-tab employer">
          <ng-template pTemplate="header">Wyszukaj przedsiębiorców</ng-template>
          <ng-template pTemplate="content">
            <form #searchEmployerForm="ngForm" id="search_employer_form" class="search-form employer-form-bg">
              <div class="row" *ngIf="!isLoading">
                <div class="col-md-6 col-12">
                  <div
                    class="mn-form-group"
                    wcagAria
                    [wcagAriaFor]="'scienceId'"
                    [wcagAriaSelectName]="'scienceIdSelect'"
                  >
                    <label for="scienceId" [ngClass]="{ required: !searchEmployerModel.scienceId }"
                      >Klasyfikacja branży:</label
                    >
                    <p-dropdown
                      [options]="sciencesDict"
                      [(ngModel)]="searchEmployerModel.scienceId"
                      (onChange)="getModelDictScience(searchEmployerModel.scienceId)"
                      [showClear]="true"
                      filter="true"
                      placeholder="Wybierz z listy"
                      styleClass="form-control"
                      id="scienceId"
                      name="scienceIdSelect"
                      ariaLabelledBy="scienceId"
                      emptyFilterMessage="Brak wyników wyszukiwania"
                      inputId="scienceId"
                      ariaFilterLabel="Filtrowanie wyników"
                    >
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div
                    class="mn-form-group"
                    wcagAria
                    [wcagAriaFor]="'scienceDomainId'"
                    [wcagAriaSelectName]="'scienceDomainIdSelect'"
                  >
                    <label for="scienceDomainId">Dziedzina:</label>
                    <p-dropdown
                      [options]="scienceDropdownModel"
                      [(ngModel)]="searchEmployerModel.scienceDomainId"
                      (onChange)="getModelDictSpecialisation(searchEmployerModel.scienceDomainId)"
                      [showClear]="true"
                      filter="true"
                      placeholder="Wybierz z listy"
                      styleClass="form-control"
                      [disabled]="!searchEmployerModel.scienceId"
                      id="scienceDomainId"
                      name="scienceDomainIdSelect"
                      ariaLabelledBy="scienceDomainId"
                      emptyFilterMessage="Brak wyników wyszukiwania"
                      inputId="scienceDomainId"
                      ariaFilterLabel="Filtrowanie wyników"
                    >
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div
                    class="mn-form-group"
                    wcagAria
                    [wcagAriaFor]="'specialisationIdentifiers'"
                    [wcagAriaSelectName]="'specialisationIdentifiersSelect'"
                  >
                    <label for="specialisationIdentifiers">Specjalizacja:</label>
                    <p-dropdown
                      [options]="specialisationDropdownModel"
                      [(ngModel)]="searchEmployerModel.specialisationIdentifiers"
                      placeholder="Wybierz z listy"
                      styleClass="form-control"
                      [disabled]="!searchEmployerModel.scienceId || !searchEmployerModel.scienceDomainId"
                      [showClear]="true"
                      filter="true"
                      id="specialisationIdentifiers"
                      name="specialisationIdentifiersSelect"
                      ariaLabelledBy="specialisationIdentifiers"
                      emptyFilterMessage="Brak wyników wyszukiwania"
                      inputId="specialisationIdentifiers"
                      ariaFilterLabel="Filtrowanie wyników"
                    >
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex justify-content-end">
                    <div class="d-flex align-items-center mr-3">
                      <span class="text-small text-required">* - Pola obowiązkowe</span>
                    </div>
                    <button
                      class="btn btn-primary mr-2 btn-white"
                      type="reset"
                      (click)="clearFilter()"
                      aria-label="Przycisk czyszczenia filtrów"
                    >
                      Wyczyść filtry
                    </button>
                    <button
                      class="btn btn-primary btn-white"
                      type="submit"
                      aria-label="Przycisk wyszukiwania"
                      (click)="searchEngine()"
                      [disabled]="isSearchQueryFill() || !searchEmployerForm.form.valid"
                    >
                      Wyszukaj
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ng-template>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
  <div class="row">
    <!-- SCIENTIST SEARCH -->
    <ng-container *ngIf="scientistsData?.length && activeTabId === 0 && isLogged">
      <div class="col-12 my-2 d-flex justify-content-between align-items-center">
        <span class="font-weight-bold">Liczba wyników wyszukiwania: {{ paginatorScientist.totalElements }}</span>
        <div class="d-flex">
          <button
            class="btn mr-2"
            aria-label="Drukuj wyniki wyszukiwania"
            pTooltip="Drukuj wyniki wyszukiwania"
            tooltipStyleClass="text-center"
            tooltipPosition="top"
            [useExistingCss]="true"
            printSectionId="printScientistResult"
            ngxPrint
          >
            <i class="fas fa-print"></i>
          </button>
          <div
            pTooltip="Aby wysłać wiadomość, zaznacz naukowców"
            tooltipPosition="top"
            [tooltipDisabled]="!!sendMessageListId.length"
            tooltipStyleClass="text-center"
          >
            <button
              class="btn btn-primary"
              [disabled]="!sendMessageListId.length"
              (click)="sendMessage(true)"
              aria-label="Przycisk wyślij wiadomość do naukowców, aby wysłać zaznacz naukowców"
            >
              Wyślij wiadomość
            </button>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row" id="printScientistResult" *ngIf="scientistsData?.length && userData">
          <div class="col-12" *ngFor="let scientistData of scientistsData">
            <div class="d-flex w-100 border-left border-right border-top align-items-center">
              <div class="col-1 d-flex justify-content-center">
                <p-checkbox
                  name="scientist"
                  value="{{ scientistData.userId }}"
                  [(ngModel)]="sendMessageListId"
                  [disabled]="userData.id === scientistData.userId"
                  class="ui-checkbox-container"
                ></p-checkbox>
              </div>
              <div
                class="col-11 cursor-pointer py-2"
                role="button"
                aria-pressed="false"
                (click)="showUserProfile(scientistData.id, 'SCIENTIST')"
                (keyup)="keyUpAccessibility()"
              >
                <div class="d-flex align-items-center justify-content-between border-bottom pb-2 search-result-item">
                  <div class="d-flex w-100">
                    <div class="d-flex justify-content-center align-items-center">
                      <img src="assets/images/scientist-icon.png" class="img-fluid profile-icon" alt="Naukowiec" />
                    </div>
                    <div class="d-flex p-3 align-items-center search-result-item-short-desc">
                      <span class="mr-2"
                        >{{ scientistData.academicTitle }} {{ scientistData.firstName }}
                        {{ scientistData.surname }}</span
                      >
                      <div class="border-left pl-2">
                        <span class="font-weight-bold">{{ scientistData.universityName }}, </span>
                        <span>{{ scientistData.department }}</span>
                      </div>
                    </div>
                  </div>
                  <span class="text-center" *ngIf="scientistData.matchCount"
                    >Dopasowanie: {{ scientistData.matchCount }}%</span
                  >
                </div>
                <div class="pt-2">
                  <span>{{ scientistData.projectsDescription }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="col-12">
      <p-paginator
        *ngIf="scientistsData?.length && activeTabId === 0"
        [rows]="paginatorScientist.size"
        [totalRecords]="paginatorScientist.totalElements"
        (onPageChange)="paginateScientist($event)"
        [rowsPerPageOptions]="[10, 20, 50, 100]"
      ></p-paginator>
      <ng-container *ngIf="!scientistsData?.length && activeTabId === 0 && !isAnnonymousSearched">
        <p-messages [(value)]="msgs" [closable]="false"></p-messages>
      </ng-container>
    </div>
    <!-- ANONYMOUS SCIENTIST SEARCH-->
    <ng-container *ngIf="activeTabId === 0 && !isLogged && isAnnonymousSearched">
      <div class="col-12 my-3" *ngIf="anonymousSearchModel?.length">
        <p-table
          [value]="anonymousSearchModel"
          [responsive]="true"
          [autoLayout]="true"
          [lazy]="true"
          styleClass="mn-table mn-table-bolder-header"
        >
          <ng-template pTemplate="caption">
            W bazie odnaleziono:
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th scope="col">Liczba odnalezionych naukowców</th>
              <th scope="col">Uczelnie</th>
              <th scope="col">Procentowe dopasowanie do zadanego kryterium wyszukiwania</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-anonymousSearch>
            <tr>
              <td>{{ anonymousSearch.searchResult }}</td>
              <td>{{ anonymousSearch.universities }}</td>
              <td>
                <span *ngIf="anonymousSearch.matchCount">{{ anonymousSearch.matchCount }} %</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="col-12 my-3" *ngIf="!anonymousSearchModel?.length && activeTabId === 0">
        <p-messages [(value)]="msgs" [closable]="false"></p-messages>
      </div>
    </ng-container>
    <!-- EMPLOYER SEARCH -->
    <ng-container *ngIf="employerData?.length && activeTabId === 1 && isLogged">
      <div class="col-12 my-2 d-flex justify-content-between align-items-center">
        <span class="font-weight-bold">Liczba wyników wyszukiwania: {{ paginatorEmployer.totalElements }}</span>
        <div class="d-flex">
          <button
            class="btn mr-2"
            aria-label="Drukuj wyniki wyszukiwania"
            pTooltip="Drukuj wyniki wyszukiwania"
            tooltipStyleClass="text-center"
            tooltipPosition="top"
            [useExistingCss]="true"
            printSectionId="printEmployerResult"
            ngxPrint
          >
            <i class="fas fa-print"></i>
          </button>
          <div
            pTooltip="Aby wysłać wiadomość, zaznacz przedsiębiorców"
            tooltipPosition="top"
            [tooltipDisabled]="!!sendMessageListId.length"
            tooltipStyleClass="text-center"
          >
            <button
              class="btn btn-primary"
              [disabled]="!sendMessageListId.length"
              (click)="sendMessage()"
              aria-label="Przycisk wyślij wiadomość do przedsiębiorców, aby wysłać zaznacz przedsiębiorców"
            >
              Wyślij wiadomość
            </button>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row" id="printEmployerResult" *ngIf="employerData?.length && userData">
          <div class="col-12" *ngFor="let employer of employerData">
            <div class="d-flex w-100 border-left border-right border-top align-items-center" *ngIf="employer.id">
              <div class="col-1 d-flex justify-content-center">
                <p-checkbox
                  name="scientist"
                  value="{{ employer.userId }}"
                  [(ngModel)]="sendMessageListId"
                  [disabled]="userData.id === employer.userId"
                  class="ui-checkbox-container"
                ></p-checkbox>
              </div>
              <div
                class="col-11 py-2 cursor-pointer"
                (click)="showUserProfile(employer.id, 'EMPLOYER')"
                role="button"
                aria-pressed="false"
                (keyup)="keyUpAccessibility()"
              >
                <div class="d-flex align-items-center justify-content-between border-bottom pb-2 search-result-item">
                  <div class="d-flex w-100">
                    <div class="d-block">
                      <img src="assets/images/employer-icon.png" class="img-fluid profile-icon" alt="Przedsiębiorca" />
                    </div>
                    <div class="d-flex p-3 align-items-center search-result-item-short-desc">
                      <span class="mr-2">{{ employer.companyName }}</span>
                    </div>
                  </div>
                </div>
                <div class="pt-2">
                  <p>
                    <ng-container *ngIf="employer.trade">
                      <span class="font-weight-bold">Klasyfikacja branży:</span> {{ employer.trade }}
                    </ng-container>
                    <ng-container *ngIf="employer.commune">
                      <span class="font-weight-bold ml-2">Gmina:</span> {{ employer.commune }}
                    </ng-container>
                    <ng-container *ngIf="employer.city">
                      <span class="font-weight-bold ml-2">Mejscowość:</span> {{ employer.city }}
                    </ng-container>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="col-12">
      <p-paginator
        *ngIf="employerData?.length && activeTabId === 1"
        [rows]="paginatorEmployer.size"
        [totalRecords]="paginatorEmployer.totalElements"
        (onPageChange)="paginateScientist($event)"
        [rowsPerPageOptions]="[10, 20, 50, 100]"
      ></p-paginator>
      <ng-container *ngIf="!employerData?.length && activeTabId === 1 && !isAnnonymousSearched">
        <p-messages [(value)]="msgs" [closable]="false"></p-messages>
      </ng-container>
    </div>
    <!-- ANONYMOUS EMPLOYER SEARCH-->
    <ng-container *ngIf="activeTabId === 1 && !isLogged && isAnnonymousSearched">
      <div class="col-12 my-3" *ngIf="anonymousSearchModel?.length">
        <p-table
          [value]="anonymousSearchModel"
          [responsive]="true"
          [autoLayout]="true"
          [lazy]="true"
          styleClass="mn-table mn-table-bolder-header"
        >
          <ng-template pTemplate="caption">
            W bazie odnaleziono:
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th scope="col">Liczba odnalezionych przedsiębiorców</th>
              <th scope="col">Branża</th>
              <th scope="col">Lokalizacja</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-anonymousSearch>
            <tr>
              <td>{{ anonymousSearch.searchResult }}</td>
              <td>{{ anonymousSearch.trades }}</td>
              <td>
                <span *ngIf="anonymousSearch.cities">{{ anonymousSearch.cities }}</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="col-12 my-3" *ngIf="!anonymousSearchModel?.length && activeTabId === 1">
        <p-messages [(value)]="msgs" [closable]="false"></p-messages>
      </div>
    </ng-container>
  </div>
  <!-- BOTTOM FOR UNLOGGED USER -->
  <div class="row mt-4" *ngIf="!isLogged">
    <div class="col-lg-6 col-12">
      <div class="steps stepper-horizontal">
        <div class="step">
          <div class="step-icon">
            <i class="fas fa-star"></i>
          </div>
          <p>Wybierz zakładkę</p>
        </div>
        <div class="step">
          <div class="step-icon">
            <i class="fas fa-pen"></i>
          </div>
          <p>Uzupełnij kryteria</p>
        </div>
        <div class="step">
          <div class="step-icon">
            <i class="fas fa-user"></i>
          </div>
          <p>Zaloguj się</p>
        </div>
        <div class="step">
          <div class="step-icon">
            <i class="fas fa-search"></i>
          </div>
          <p>Wyszukaj</p>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <div class="position-register-bar">
        <button
          class="btn btn-primary"
          type="button"
          routerLink="/register"
          aria-label="Przycisk rejestracji, aby poznać szczegóły"
        >
          Zarejestruj się, aby poznać szczegóły wyszukiwania
        </button>
      </div>
    </div>
  </div>
</div>
