import { AboutBaseComponent } from './about-base.component';
import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
var ɵ0 = { title: extract('O Bazie') };
var routes = [
    Shell.childRoutes([{ path: 'about-base', component: AboutBaseComponent, data: ɵ0 }])
];
var AboutBaseRoutingModule = /** @class */ (function () {
    function AboutBaseRoutingModule() {
    }
    return AboutBaseRoutingModule;
}());
export { AboutBaseRoutingModule };
export { ɵ0 };
