import { TableService } from '@app/services/table.service';
import { IDefaultDict } from './../../../interfaces/dictionary.interface';
import { DictionariesService } from '@app/services/dictionaries.service';
import { IPasswordRule, UserDataEntity, IConfigWebsite } from './../../../interfaces/configuration-app.interface';
import { ConfigurationAppService } from './../../../services/configuration-app.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ITableInterface, ITableData, IPaginatorConfig } from '@app/interfaces/table.interface';
import { Message, SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
import { IUsersSimple } from '@app/interfaces/user-management.interface';
import { OverlayPanel } from 'primeng/overlaypanel';
import { join } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-configuration-app',
  templateUrl: './configuration-app.component.html',
  styleUrls: ['./configuration-app.component.scss'],
  providers: [ConfirmationService]
})
export class ConfigurationAppComponent implements OnInit, OnDestroy {
  paginator: IPaginatorConfig = null;
  users: SelectItem[];
  userOverlay: UserDataEntity[];
  websiteConfig: IConfigWebsite = {
    name: null,
    keyWords: null,
    description: null,
    loginMethod: null,
    maintenance: null
  };
  defaultPasswordRule: IPasswordRule = {
    minPasswordLength: null,
    minCapitalLetters: null,
    minDigit: null,
    minSpecialChar: null,
    name: null,
    numberOfPreviousPasswordsToCheck: null,
    passwordChangeReminder: null,
    passwordExpirationTimeInDays: null,
    requiredPasswordChangeAtFirstLogin: null,
    usersId: null,
    userData: null
  };
  passwordRules: IPasswordRule[];
  ref: any;

  loginMethodDict: SelectItem[];

  // Table
  passwordRulesCols: ITableInterface[];
  msgs: Message[] = [];

  constructor(
    private configurationAppService: ConfigurationAppService,
    private _httpNotificationHandler: NotificationService,
    private _dictionaryService: DictionariesService,
    private _dialogBaseService: DialogBaseService,
    private confirmationService: ConfirmationService,
    private dictionaries: DictionariesService,
    private tableService: TableService,
    private translate: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.tableService.restorePaginatorConfig();
  }

  ngOnInit() {
    this.getDefaultPasswordRule();
    this.getUserDictionary();

    this.passwordRulesCols = [
      { field: 'id', header: 'ID' },
      { field: 'name', header: 'Nazwa reguły', custom: 'translate' },
      { field: 'minPasswordLength', header: 'Minimalna długość' },
      { field: 'minSpecialChar', header: 'Minimalna ilość znaków specjalnych' },
      { field: 'minDigit', header: 'Minimalna ilość cyfr' },
      { field: 'minCapitalLetters', header: 'Minimalna ilość dużych liter' },
      { field: 'passwordExpirationTimeInDays', header: 'Ważność hasła (dni)' },
      { field: 'userData', header: 'Włączenie reguły dla', custom: 'isHoverable', disabledSort: true },
      { field: 'numberOfPreviousPasswordsToCheck', header: 'Liczba zapamiętanych haseł' },
      { field: 'passwordChangeReminder', header: 'Przypomnienie o zmianie hasła', custom: 'boolean' }
    ];

    this.getAvailableLoginMethodDict();
    this.getWebsiteConfig();
    this.getPasswordRulesForTable();
  }

  paginate(paginatorEvent: any): void {
    const currentPaginator = this.tableService.getPaginatorConfig();
    const paginator = {
      direction: currentPaginator.direction,
      page: paginatorEvent.page,
      size: paginatorEvent.rows,
      properties: currentPaginator.properties,
      sort: currentPaginator.sort
    };
    this.tableService.setPaginatorConfig(paginator);
    this.getPasswordRulesForTable();
  }

  customSort(event: any) {
    if (event.sortField) {
      const currentPaginator = this.tableService.getPaginatorConfig();
      const paginator = {
        direction: event.sortOrder === -1 ? 'DESC' : 'ASC',
        page: currentPaginator.page,
        size: currentPaginator.size,
        properties: [event.sortField],
        sort: true
      };
      this.tableService.setPaginatorConfig(paginator);
      this.getPasswordRulesForTable();
    }
  }

  showOverlayPanel(event: any, userData: UserDataEntity[], overlaypanel: OverlayPanel) {
    this.userOverlay = userData;
    if (userData && userData.length) {
      overlaypanel.show(event);
    }
  }

  hideOverlayPanel(overlaypanel: OverlayPanel) {
    overlaypanel.hide();
  }

  showUserOverlay(userData: UserDataEntity[]): string {
    const users = userData.map(user => {
      return user.displayName || user.username;
    });
    return join(users, ', ');
  }

  getWebsiteConfig() {
    this.configurationAppService.getWebsiteConfig().subscribe(config => {
      this.websiteConfig = config;
    });
  }

  saveWebsiteConfig() {
    this.configurationAppService.updateWebsiteConfig(this.websiteConfig).subscribe(resp => {
      this._httpNotificationHandler.sendMessage({
        type: 'success',
        title: 'Sukces!',
        message: 'Konfiguracja witryny została zapisana'
      });
    });
  }

  getAvailableLoginMethodDict() {
    this._dictionaryService.getAllLoginMethod().subscribe(loginMethod => {
      this.loginMethodDict = (loginMethod as IDefaultDict[]).map(method => {
        return {
          label: method.name,
          value: method.id
        };
      });
    });
  }

  getDefaultPasswordRule(): void {
    this.configurationAppService.getDefaultPasswordRule().subscribe((passwordRule: IPasswordRule) => {
      this.defaultPasswordRule = passwordRule;
      this.defaultPasswordRule.usersId = passwordRule.userData.map(passRule => {
        return passRule.id;
      });
    });
  }

  getPasswordRulesForTable(): void {
    this.configurationAppService
      .getPasswordRules(this.tableService.prepareParamsToServer())
      .subscribe((passwordRules: ITableData) => {
        this.passwordRules = passwordRules.content;
        this.tableService.setPaginatorConfigFormServer(passwordRules);
        this.paginator = this.tableService.getPaginatorConfig();
      });
  }

  getUserDictionary(): void {
    this.dictionaries.getAllUsersFromAdmin().subscribe((users: IUsersSimple[]) => {
      this.users = users.map((user: IUsersSimple) => {
        return {
          label: user.displayName || user.username,
          value: user.id
        };
      });
    });
  }

  saveDefaultPasswordRule(): void {
    this.configurationAppService
      .updateDefaultPasswordRule(this.defaultPasswordRule)
      .subscribe((savedPasswordRule: IPasswordRule) => {
        this.defaultPasswordRule = savedPasswordRule;
        this.defaultPasswordRule.usersId = savedPasswordRule.userData.map(passRule => {
          return passRule.id;
        });
        this.getPasswordRulesForTable();

        this._httpNotificationHandler.sendMessage({
          type: 'success',
          title: 'Sukces!',
          message: 'Domyślna konfiguracja siły hasła została zapisana'
        });
      });
  }

  removePasswordRule(passwordRule: IPasswordRule): void {
    if (passwordRule.id !== 1) {
      this.confirmationService.confirm({
        message: `Czy na pewno chcesz usunąć regułę hasła o nazwie: ${passwordRule.name}?`,
        accept: () => {
          this.configurationAppService.removePasswordRule(passwordRule.id).subscribe(() => {
            this._httpNotificationHandler.sendMessage({
              type: 'success',
              title: 'Sukces!',
              message: 'Reguła hasła została usunięta poprawnie'
            });

            this.getPasswordRulesForTable();
          });
        }
      });
    }
  }

  changeDefaultPasswordRule(passwordRule: IPasswordRule): void {
    this.confirmationService.confirm({
      message: `Czy na pewno chcesz ustawić jako domyślną regułę hasła o nazwie: ${this.translate.instant(
        passwordRule.name
      )}?`,
      accept: () => {
        this.configurationAppService.changeDefaultPasswordRule(passwordRule.id).subscribe(resp => {
          this._httpNotificationHandler.sendMessage({
            type: 'success',
            title: 'Sukces!',
            message: 'Reguła hasła została ustawiona jako domyślna'
          });

          this.getPasswordRulesForTable();
          this.getDefaultPasswordRule();
        });
      }
    });
  }

  modalDictionaryEdit(): void {
    this._dialogBaseService.setSwitchTemplateStatus('DictionaryEditComponent', {
      closable: true,
      header: 'Edycja słowników',
      styleClass: 'medium-modal',
      contentStyle: { overflow: 'inherit' }
    });

    this.ref = this._dialogBaseService.getInstance();
  }

  modalPasswordRule(passwordData: IPasswordRule = null, isPreviewMode: boolean = false): void {
    this._dialogBaseService.setSwitchTemplateStatus('PasswordRuleDialogComponent', {
      data: {
        id: passwordData ? passwordData.id : null,
        preview: isPreviewMode,
        usersDict: this.users
      },
      closable: true,
      header: 'Konfiguracja siły hasła',
      styleClass: 'medium-modal',
      contentStyle: { overflow: 'inherit' }
    });

    this.ref = this._dialogBaseService.getInstance();
    this.ref.onClose.subscribe(() => {
      this.getPasswordRulesForTable();
    });
  }
}
