import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CommunicatorService = /** @class */ (function () {
    function CommunicatorService(_http) {
        this._http = _http;
        this.resourceUrl = '/messages';
    }
    CommunicatorService.prototype.getListMessage = function (paginatorConfig) {
        var userId = paginatorConfig.params.userId;
        return this._http.get(this.resourceUrl + "/received/" + userId, paginatorConfig);
    };
    CommunicatorService.prototype.getListSendMessage = function (paginatorConfig) {
        var userId = paginatorConfig.params.userId;
        return this._http.get(this.resourceUrl + "/sent/" + userId, paginatorConfig);
    };
    CommunicatorService.prototype.getListArchiveMessage = function (paginatorConfig) {
        var userId = paginatorConfig.params.userId;
        return this._http.get(this.resourceUrl + "/archived/" + userId, paginatorConfig);
    };
    CommunicatorService.prototype.getMessage = function (messageId) {
        return this._http.get(this.resourceUrl + "/thread/" + messageId);
    };
    CommunicatorService.prototype.sendMessageToAdmin = function (payload) {
        return this._http.post(this.resourceUrl + "/admin", payload);
    };
    CommunicatorService.prototype.replayMessage = function (payload) {
        return this._http.post(this.resourceUrl + "/reply", payload);
    };
    CommunicatorService.prototype.sendMessageToScientist = function (payload) {
        return this._http.post(this.resourceUrl + "/scientists", payload);
    };
    CommunicatorService.prototype.sendMessageToUser = function (payload) {
        return this._http.post("" + this.resourceUrl, payload);
    };
    CommunicatorService.prototype.sendMessageToUsers = function (payload) {
        return this._http.post('/admin/messages', payload);
    };
    CommunicatorService.prototype.markMessageAsArchive = function (userId, messageId) {
        return this._http.put(this.resourceUrl + "/archive/" + userId + "/" + messageId, {
            userId: userId,
            messageId: messageId
        });
    };
    CommunicatorService.prototype.markMessageAsDeleted = function (userId, messageId) {
        return this._http.put(this.resourceUrl + "/delete/" + userId + "/" + messageId, {
            userId: userId,
            messageId: messageId
        });
    };
    CommunicatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommunicatorService_Factory() { return new CommunicatorService(i0.ɵɵinject(i1.HttpClient)); }, token: CommunicatorService, providedIn: "root" });
    return CommunicatorService;
}());
export { CommunicatorService };
