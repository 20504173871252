import { HelperService } from '@app/services/helper.service';
import { IDefaultDict } from './../../../interfaces/dictionary.interface';
import { Component, OnInit } from '@angular/core';
import { EmployerService } from '@app/services/employer.service';
import { DictionariesService } from '@app/services/dictionaries.service';
import { zip, BehaviorSubject } from 'rxjs';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { DynamicDialogConfig, SelectItem } from 'primeng/api';
import { find, filter } from 'lodash';
import { IUserEmployer } from '@app/interfaces/user-profile.interface';

@Component({
  selector: 'app-employer-registration',
  templateUrl: './employer-registration.component.html',
  styleUrls: ['./employer-registration.component.scss']
})
export class EmployerRegistrationComponent implements OnInit {
  isProfileLoaded = new BehaviorSubject<boolean>(false);
  isPreviewMode: boolean;
  configData: any;
  employerId: number;

  userEmployer: IUserEmployer = {
    id: null,
    username: null,
    email: null,
    companyName: null,
    communeId: null,
    city: null,
    phoneNumber: null,
    intelligentSpecialisationId: null,
    leadingSpecialisationId: null,
    scienceId: null,
    scienceDomainId: null,
    specialisations: []
  };

  // Dictionaries
  sciencesDict: SelectItem[];
  scienceDomainSelectDict: SelectItem[];
  specialisationsDict: SelectItem[];
  allSpecialisationDict: SelectItem[];
  aditionalSpecialisationDict: SelectItem[];
  intelligentSpecialisationDict: SelectItem[];
  statusesDict: SelectItem[];
  communesDict: SelectItem[];

  // All dictionaries
  scienceDomainsDict: any;
  specialisations: any;

  constructor(
    private _employerService: EmployerService,
    private _dictionariesService: DictionariesService,
    private _dialogService: DialogBaseService,
    private _notificationService: NotificationService,
    public config: DynamicDialogConfig,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this._getAllDictionaries();

    this.configData = this.config.data;
    if (this.configData && this.configData.id) {
      this.isPreviewMode = this.configData.preview;
      this.getEmployerUserProfile(this.configData.id);
    }
  }

  getEmployerUserProfile(id: number) {
    this._employerService.getEmployer(id).subscribe(employerProfile => {
      this.employerId = employerProfile.id;
      this.userEmployer = employerProfile;
      if (employerProfile.specialisations.length === 0) {
        this.addSpecialisation();
      }
      this.isProfileLoaded.next(true);
    });
  }

  addSpecialisation() {
    const emptySpecialisation: any = {
      id: null,
      name: null
    };

    this.userEmployer.specialisations.push(emptySpecialisation);
  }

  removeEmptySpecialisation() {
    const isNotEmptySpecialisation = this.userEmployer.specialisations.some(specialisation => {
      return specialisation.id !== null;
    });

    if (!isNotEmptySpecialisation) {
      this.userEmployer.specialisations = [];
    }
  }

  save() {
    this.removeEmptySpecialisation();

    this._employerService.updateEmployer(this.employerId, this.userEmployer).subscribe((response: any) => {
      this._dialogService.closeModal();
      this._notificationService.sendMessage({
        type: 'success',
        title: 'Aktualizacja profilu',
        message: 'Pomyślnie zaktualizowano profil przedsiębiorcy'
      });
    });
  }

  isOtherCommune(communeId: number) {
    return this.helperService.checkIsOtherCommune(communeId);
  }

  getSciencesAndClasification() {
    const filteredSpecialisation = filter(this.specialisations, specialisation => {
      return (
        specialisation.intelligentSpecialisationId === this.userEmployer.intelligentSpecialisationId &&
        specialisation.id === this.userEmployer.leadingSpecialisationId
      );
    });

    this.userEmployer.scienceDomainId = filteredSpecialisation && filteredSpecialisation[0].scienceDomainId;
    this.userEmployer.scienceId = find(this.scienceDomainsDict, { id: this.userEmployer.scienceDomainId }).scienceId;
  }

  setInteligentSpecialisation(specialisationId: number) {
    if (specialisationId) {
      const specialisation = find(this.specialisations, { id: specialisationId });
      if (specialisation) {
        const intelligentSpecialisation: any = find(this.intelligentSpecialisationDict, {
          value: specialisation.intelligentSpecialisationId
        });
        this.userEmployer.intelligentSpecialisationId = intelligentSpecialisation.value;
      }
      this.aditionalSpecialisationDict = this.aditionalSpecialisationDict.filter((itemDict: SelectItem) => {
        return itemDict.value !== specialisationId;
      });
    }
  }

  changeModelScienceDomainDict(scienceId: number) {
    this.userEmployer.scienceDomainId = null;
    this.userEmployer.leadingSpecialisationId = null;

    this.scienceDomainSelectDict = this.mapScienceDomains(scienceId);
  }

  changeModelSpecialisationDict(scienceDomainId: number) {
    this.userEmployer.leadingSpecialisationId = null;

    this.specialisationsDict = this.mapSpecialisations(scienceDomainId);
    this.aditionalSpecialisationDict = this.mapSpecialisations(scienceDomainId);
  }

  mapScienceDomains(scienceId: number): SelectItem[] {
    if (this.scienceDomainsDict && scienceId) {
      return this.scienceDomainsDict
        .filter((scienceDomain: any) => {
          return scienceDomain.scienceId === scienceId;
        })
        .map((scienceDomain: any) => {
          return {
            label: scienceDomain.name,
            value: scienceDomain.id
          };
        });
    } else {
      return null;
    }
  }

  mapSpecialisations(scienceDomainId: number): SelectItem[] {
    if (this.specialisations && scienceDomainId) {
      return this.specialisations
        .filter((specialisation: any) => {
          return specialisation.scienceDomainId === scienceDomainId;
        })
        .map((specialisation: any) => {
          return {
            label: specialisation.name,
            value: specialisation.id
          };
        });
    } else {
      return null;
    }
  }

  _getAllDictionaries() {
    zip(
      this._dictionariesService.getAllScienceDomains(),
      this._dictionariesService.getAllSciences(),
      this._dictionariesService.getAllSpecialisations(),
      this._dictionariesService.getAllIntelligentSpecialisations(),
      this._dictionariesService.getAllStatuses(),
      this._dictionariesService.getAllCommunes()
    ).subscribe(([ScienceDomains, Sciences, Specialisations, IntelligentSpecialisations, Statuses, Communes]) => {
      this.sciencesDict = (Sciences as IDefaultDict[]).map(sciences => {
        return {
          label: sciences.name,
          value: sciences.id
        };
      });
      this.scienceDomainSelectDict = (ScienceDomains as IDefaultDict[]).map(scienceDomain => {
        return {
          label: scienceDomain.name,
          value: scienceDomain.id
        };
      });
      this.specialisationsDict = (Specialisations as IDefaultDict[]).map(specialisation => {
        return {
          label: specialisation.name,
          value: specialisation.id
        };
      });

      this.allSpecialisationDict = (Specialisations as IDefaultDict[]).map(spec => {
        return {
          label: spec.name,
          value: spec.id
        };
      });

      this.intelligentSpecialisationDict = (IntelligentSpecialisations as IDefaultDict[]).map(
        intelligentSpecialisation => {
          return {
            label: intelligentSpecialisation.name,
            value: intelligentSpecialisation.id
          };
        }
      );

      this.statusesDict = (Statuses as IDefaultDict[]).map(status => {
        return {
          label: status.name,
          value: status.id
        };
      });

      this.communesDict = (Communes as IDefaultDict[]).map(commune => {
        return {
          label: commune.name,
          value: commune.id
        };
      });

      // All dictionaries
      this.specialisations = Specialisations;
      this.scienceDomainsDict = ScienceDomains;

      this.isProfileLoaded.subscribe((isLoaded: boolean) => {
        if (isLoaded) {
          this.getSciencesAndClasification();

          this.scienceDomainSelectDict = this.mapScienceDomains(this.userEmployer.scienceId);
          this.specialisationsDict = this.mapSpecialisations(this.userEmployer.scienceDomainId);
          this.aditionalSpecialisationDict = this.mapSpecialisations(this.userEmployer.scienceDomainId);
          this.setInteligentSpecialisation(this.userEmployer.leadingSpecialisationId);
        }
      });
    });
  }
}
