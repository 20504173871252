// tslint:disable: max-line-length
import { RegeneratePasswordComponent } from '@app/entities/registration-dialogs/regenerate-password/regenerate-password.component';
import { CreateAccountComponent } from '@app/entities/registration-dialogs/create-account/create-account.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogBaseComponent } from '@app/shared/dialog/dialog-base.component';
import { DialogDynamicTemplateComponent } from '@app/shared/dialog/dialog-dynamic-template/dialog-dynamic-template.component';
import { ButtonModule } from 'primeng/button';

///// dynamic components used in dialogs
import { UserTypeComponent } from '@app/entities/admin-app/user-management/reqister-dialogs/user-type/user-type.component';
import { AccountTypeSelectionComponent } from '@app/entities/registration-dialogs/account-type-selection/account-type-selection.component';
import { ScientistRegistrationComponent } from '@app/entities/registration-dialogs/scientist-registration/scientist-registration.component';
import { EmployerRegistrationComponent } from '@app/entities/registration-dialogs/employer-registration/employer-registration.component';
import { PasswordRuleDialogComponent } from '@app/entities/admin-app/configuration-app/password-rule-dialog/password-rule-dialog.component';
import { SendMessageComponent } from '@app/entities/communicator/send-message/send-message.component';
import { ShowUserProfileComponent } from '@app/entities/search/show-user-profile/show-user-profile.component';
import { DictionaryEditComponent } from '@app/entities/admin-app/configuration-app/dictionary-edit/dictionary-edit.component';

import { DialogService } from 'primeng/api';

@NgModule({
  imports: [CommonModule, DynamicDialogModule, ButtonModule],
  declarations: [DialogBaseComponent, DialogDynamicTemplateComponent],
  entryComponents: [
    DialogDynamicTemplateComponent,
    UserTypeComponent,
    AccountTypeSelectionComponent,
    ScientistRegistrationComponent,
    CreateAccountComponent,
    RegeneratePasswordComponent,
    EmployerRegistrationComponent,
    SendMessageComponent,
    ShowUserProfileComponent,
    PasswordRuleDialogComponent,
    DictionaryEditComponent
  ],
  exports: [DialogBaseComponent],
  providers: [DialogService]
})
export class DialogBaseModule {}
