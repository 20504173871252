import { MultitagSelectComponent } from './multitag-select.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [MultitagSelectComponent],
  imports: [FormsModule, CommonModule, MultiSelectModule],
  exports: [MultitagSelectComponent]
})
export class MultitagSelectModule {}
