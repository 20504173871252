import { EmployerRegisterComponent } from './employer-register.component';
import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
var ɵ0 = { title: extract('Rejestracja przedsiębiorcy') };
var routes = [
    Shell.childRoutes([
        {
            path: 'employer-register',
            component: EmployerRegisterComponent,
            data: ɵ0
        }
    ])
];
var EmployerRegisterRoutingModule = /** @class */ (function () {
    function EmployerRegisterRoutingModule() {
    }
    return EmployerRegisterRoutingModule;
}());
export { EmployerRegisterRoutingModule };
export { ɵ0 };
