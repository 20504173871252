import { IDefaultDict } from './../../../interfaces/dictionary.interface';
import { Component, OnInit } from '@angular/core';
import { DictionariesService } from '@app/services/dictionaries.service';
import { zip } from 'rxjs';
import { DynamicDialogConfig, SelectItem } from 'primeng/api';
import { IAdminCreateUserAccount, IModifyAccount } from '@app/interfaces/user-management.interface';
import { UserManagementService } from '@app/services/user-management.service';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
import { TranslateService } from '@ngx-translate/core';
import { flatten } from 'lodash';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  editMode = false;
  userAccountId: number;
  createUserAccount: IAdminCreateUserAccount = {
    email: null,
    passwordChangeInFirstLoginRequired: null,
    passwordRuleId: null,
    rolesId: null,
    username: null
  };

  modifyAccountModel: IModifyAccount = {
    status: null,
    passwordRuleId: null,
    passwordChangeRequired: null,
    rolesIdentifiers: null
  };

  modifyAccountUserRoleSelectedId: number;

  // Dictionaries
  userRoles: SelectItem[];
  passwordRules: SelectItem[];
  userEnabledDict: SelectItem[];

  constructor(
    private _dictionariesService: DictionariesService,
    private userManagementService: UserManagementService,
    private _notificationService: NotificationService,
    private _dialogService: DialogBaseService,
    private translate: TranslateService,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    if (this.config && this.config.data) {
      this.editMode = this.config.data.editMode;
      this.userAccountId = this.config.data.id;
      if (this.userAccountId) {
        this.getAccount();
      }
    }
    this._getAllDictionaries();
  }

  getAccount() {
    this.userManagementService.getUserAccount(this.userAccountId).subscribe((response: IModifyAccount) => {
      this.modifyAccountModel = response;
      this.modifyAccountUserRoleSelectedId = this.modifyAccountModel.rolesIdentifiers.filter(
        (userRoleId: any) => userRoleId !== 6
      )[0];
    });
  }

  createAccount() {
    this.createUserAccount.rolesId = flatten([this.createUserAccount.rolesId]);
    this.userManagementService.createAccount(this.createUserAccount).subscribe(
      resp => {
        this._dialogService.closeModal();
        this._notificationService.sendMessage({
          type: 'success',
          title: 'Tworzenie użytkownika',
          message: 'Pomyślnie dodano nowe konto'
        });
      },
      err => {
        this.createUserAccount.rolesId = null;
      }
    );
  }

  modifyAccount() {
    const userRoleIdentifiers = [];
    if (this.modifyAccountModel.rolesIdentifiers.indexOf(6) !== -1) {
      userRoleIdentifiers.push(this.modifyAccountUserRoleSelectedId);
      userRoleIdentifiers.push(6);
    } else {
      userRoleIdentifiers.push(this.modifyAccountUserRoleSelectedId);
    }

    this.modifyAccountModel.rolesIdentifiers = userRoleIdentifiers;

    this.userManagementService.modifyUserAccount(this.userAccountId, this.modifyAccountModel).subscribe(resp => {
      this._notificationService.sendMessage({
        type: 'success',
        title: 'Zarządzanie użytkownikiem',
        message: 'Pomyślnie zmodyfikowano konto'
      });
    });
  }

  private _getAllDictionaries(): void {
    zip(
      this._dictionariesService.getAllLimitedRoles(),
      this._dictionariesService.getAllPasswordRules(),
      this._dictionariesService.getAllUserStatus()
    ).subscribe(([userRoles, passwordRules, UserStatus]) => {
      this.userRoles = (userRoles as IDefaultDict[]).map((userRole: IDefaultDict) => {
        return {
          label: this.translate.instant('userRoles.' + userRole.name),
          value: userRole.id
        };
      });
      this.passwordRules = (passwordRules as IDefaultDict[]).map((passwordRule: IDefaultDict) => {
        return {
          label: this.translate.instant(passwordRule.name),
          value: passwordRule.id
        };
      });

      this.userEnabledDict = UserStatus as SelectItem[];
    });
  }
}
