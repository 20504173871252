import { environment } from '@env/environment';
import * as i0 from "@angular/core";
/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
var ApiPrefixInterceptor = /** @class */ (function () {
    function ApiPrefixInterceptor() {
    }
    ApiPrefixInterceptor.prototype.intercept = function (request, next) {
        if (!/^(http|https):/i.test(request.url) && request.url[0] !== '.') {
            request = request.clone({ url: environment.serverUrl + request.url });
        }
        return next.handle(request);
    };
    ApiPrefixInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiPrefixInterceptor_Factory() { return new ApiPrefixInterceptor(); }, token: ApiPrefixInterceptor, providedIn: "root" });
    return ApiPrefixInterceptor;
}());
export { ApiPrefixInterceptor };
