<fieldset [disabled]="isPreviewMode" class="ui-dialog-content">
  <div class="row">
    <div class="col-md-6 col-12">
      <div class="form-group">
        <label for="configuration_app_min_pass_len">Minimalna długość:</label>
        <input
          id="configuration_app_min_pass_len"
          class="form-control"
          type="number"
          min="8"
          name="configuration_app_min_pass_len"
          [(ngModel)]="passwordRule.minPasswordLength"
        />
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div class="form-group">
        <label for="configuration_app_min_spec_char">Minimalna ilość znaków specjalnych:</label>
        <input
          id="configuration_app_min_spec_char"
          class="form-control"
          type="number"
          name="configuration_app_min_spec_char"
          [(ngModel)]="passwordRule.minSpecialChar"
        />
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div class="form-group">
        <label for="configuration_app_min_num">Minimalna ilość cyfr:</label>
        <input
          id="configuration_app_min_num"
          class="form-control"
          type="number"
          name="configuration_app_min_num"
          [(ngModel)]="passwordRule.minDigit"
        />
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div class="form-group">
        <label for="configuration_app_min_upper_letter">Minimalna ilość dużych liter:</label>
        <input
          id="configuration_app_min_upper_letter"
          class="form-control"
          type="number"
          name="configuration_app_min_upper_letter"
          [(ngModel)]="passwordRule.minCapitalLetters"
        />
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div class="form-group">
        <label for="configuration_app_config_name">Nazwa konfiguracji:</label>
        <input
          id="configuration_app_config_name"
          class="form-control"
          type="text"
          name="configuration_app_config_name"
          translate
          [(ngModel)]="passwordRule.name"
        />
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div class="form-group">
        <label for="configuration_app_min_pass_lifecycle">Ważność hasła (dni):</label>
        <input
          id="configuration_app_min_pass_lifecycle"
          class="form-control"
          type="text"
          name="configuration_app_min_pass_lifecycle"
          [(ngModel)]="passwordRule.passwordExpirationTimeInDays"
        />
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div class="form-group">
        <label for="configuration_app_disable_rule_for">Włączenie reguły dla:</label>
        <p-multiSelect
          id="configuration_app_disable_rule_for"
          [options]="users"
          [(ngModel)]="passwordRule.usersId"
          styleClass="form-control"
          selectedItemsLabel="{0} wybranych opcji"
          defaultLabel="Wybierz z listy"
          [disabled]="isPreviewMode"
          baseZIndex="100009"
        ></p-multiSelect>
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div class="form-group">
        <label for="configuration_app_count_remember_pass">Liczba zapamiętanych haseł:</label>
        <input
          id="configuration_app_count_remember_pass"
          class="form-control"
          type="text"
          name="configuration_app_count_remember_pass"
          [(ngModel)]="passwordRule.numberOfPreviousPasswordsToCheck"
        />
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div class="form-group h-100 d-flex align-items-center">
        <p-checkbox
          binary="true"
          label="Przypomnienie o zmianie hasła"
          [(ngModel)]="passwordRule.passwordChangeReminder"
          [disabled]="isPreviewMode"
          class="ui-checkbox-container"
        ></p-checkbox>
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div class="form-group h-100 d-flex align-items-center">
        <p-checkbox
          binary="true"
          label="Wymagana zmiana hasła przy pierwszym logowaniu"
          [(ngModel)]="passwordRule.requiredPasswordChangeAtFirstLogin"
          [disabled]="isPreviewMode"
          class="ui-checkbox-container"
        ></p-checkbox>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-end align-items-end">
      <div class="form-group">
        <button class="btn btn-primary" type="button" (click)="savePasswordRule()" aria-label="Przycisk zapisz">
          Zapisz
        </button>
      </div>
    </div>
  </div>
</fieldset>
