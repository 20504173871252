import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RegisterService = /** @class */ (function () {
    function RegisterService(_http) {
        this._http = _http;
    }
    RegisterService.prototype.activateAccount = function (token) {
        return this._http.get("/accounts/active/" + token);
    };
    RegisterService.prototype.requestResetPass = function (email) {
        return this._http.get('/accounts/password/reset', {
            params: { email: email }
        });
    };
    RegisterService.prototype.resetPassword = function (token, payload) {
        return this._http.post("/accounts/password/reset/" + token, payload);
    };
    RegisterService.prototype.registerEmployer = function (employer) {
        return this._http.post('/employers/register', employer);
    };
    RegisterService.prototype.registerScientist = function (scientist) {
        return this._http.post('/scientists/register', scientist);
    };
    RegisterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegisterService_Factory() { return new RegisterService(i0.ɵɵinject(i1.HttpClient)); }, token: RegisterService, providedIn: "root" });
    return RegisterService;
}());
export { RegisterService };
