<div class="container">
  <div class="row">
    <div class="col-12 text-justify">
      <h5 class="text-center mb-4">
        Załącznik nr 1 do Regulaminu korzystania z usługi świadczonej drogą elektroniczną przy użyciu Aplikacji
        „Małopolska nauka” bazy danych kompetencji pracowników naukowych powstałej w związku z realizacją projektu pn.
        SPIN - Małopolskie Centra Transferu Wiedzy wsparciem dla przedsiębiorców w ramach Regionalnego Programu
        Operacyjnego Województwa Małopolskiego na lata 2014 – 2020. pn. Polityka prywatności
      </h5>
      <ol>
        <li>
          <strong>Polityka prywatności Województwo Małopolskie.</strong>
          <p>
            Postanowienia poniższe stanowią Politykę Prywatności, która określa informacje dotyczące zbierania,
            przetwarzania i ochrony danych dotyczących użytkowników usługi pod nazwą „Małopolska nauka” - baza danych
            kompetencji pracowników naukowych (dalej: „System”) świadczonej przez: Województwo Małopolskie z siedzibą
            przy ulicy Basztowej 22, 31-156 Kraków, NIP 676-21-78-337 („Usługodawca”).
          </p>
          <p>
            Polityka stanowi integralną część Regulaminu świadczenia usług drogą elektroniczną dla Systemu (dalej:
            Regulamin). Warunkiem korzystania z Usługi jest zaakceptowanie niniejszej Polityki.
          </p>
        </li>
        <li>
          <strong>Rodzaje danych i cel ich przetwarzania</strong>
          <p><u>Cel przetwarzania danych</u></p>
          <p>
            Celem zebrania danych jest realizacja zadania, jakim jest budowa i prowadzenie bazy naukowców do
            nawiązywania współpracy naukowców z przedsiębiorcami.
          </p>
          <p><u>Dane dotyczące użytkownika</u></p>
          <p>
            Podczas rejestracji użytkownik w trakcie procesu rejestracji jest proszony o podanie, za jego zgodą,
            wskazanych danych osobowych. Administrator przetwarza dane osobowe, w celu świadczenia usługi oraz
            niezbędnych czynności związanych ze świadczeniem usługi. Dane osobowe wprowadzone przez użytkownika są
            udostępniane innym użytkownikom (zarejestrowanym/zalogowanym), mogą być również wykorzystywane do udzielania
            wsparcia technicznego i komunikacji z użytkownikiem.
          </p>
          <p>
            Zgodnie z Załącznikiem Nr 1 do Uchwały Nr 319/20 Zarządu Województwa Małopolskiego z dnia 3 marca 2020 r. w
            sprawie podjęcia decyzji o dofinansowaniu Projektu, dla którego Beneficjentem jest Województwo Małopolskie w
            ramach Osi Priorytetowej 1 Gospodarka wiedzy, Działanie 1.3 Małopolskie Centra Innowacji B. Regionalny
            program rozwijania centrów transferu wiedzy Regionalnego Programu Operacyjnego Województwa Małopolskiego na
            lata 2014–2020 - pn. Zasady realizacji projektu, dla którego Beneficjentem jest Województwo Małopolskie w
            ramach Regionalnego Programu Operacyjnego Województwa Małopolskiego na lata 2014-2020 oraz zapisami
            Załącznika nr 4 wskazanej uchwały, Lider Projektu, może powierzyć przetwarzanie danych osobowych, o których
            mowa w niniejszej polityce prywatności podmiotom realizującym badania ewaluacyjne na zlecenie Instytucji
            Zarządzającej/ Instytucji Pośredniczącej. Dane osobowe mogą zostać również powierzone podmiotom realizującym
            na zlecenie IZ/IP kontrole i audyt w ramach RPO WM. Są to podmioty uprawnione do przeprowadzenia kontroli, w
            tym organy publiczne (m. in.: Małopolskie Centrum Przedsiębiorczości, Departament Zarządzania Programami
            Operacyjnymi Urzędu Marszałkowskiego Województwa Małopolskiego, Regionalna Izba Obrachunkowa, Krajowa
            Administracja Skarbowa, Najwyższa Izba Kontroli, Europejski Urząd ds. Zwalczania Nadużyć Finansowych
            (OLAF)).
          </p>
          <p>
            Szczegółowe informacje o ochronie danych osobowych zawarto w sekcji Klauzula informacyjna niniejszej
            Polityki Prywatności.
          </p>
          <p><u>Rodzaje danych</u></p>
          <p>
            Przetwarzanymi przez Administratora danymi użytkownika, na co Użytkownik wyraża zgodę, są następujące dane,
            w tym dane osobowe:
          </p>
          <ol>
            <li>
              Przedsiębiorca:
              <ol type="a">
                <li>Login</li>
                <li>Nazwa firmy</li>
                <li>Gmina</li>
                <li>Miejscowość</li>
                <li>Numer telefonu</li>
                <li>Adres email</li>
              </ol>
            </li>
            <li>
              Naukowiec:
              <ol type="a">
                <li>Login</li>
                <li>Imię</li>
                <li>Nazwisko</li>
                <li>Adres email</li>
                <li>Numer telefonu</li>
                <li>Tytuł naukowy</li>
                <li>Uczelnia</li>
                <li>Wydział</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p><strong>Dane zbierane automatycznie</strong></p>
          <p>
            Usługodawca gromadzi informacje dotyczące połączenia Urządzenia Użytkownika z systemem teleinformatycznym
            Usługodawcy w tym: informacje dotyczące komputera i logowania tzw. logi systemowe zawierające datę, czas
            wizyty i numer IP Urządzenia, z którego nastąpiło połączenie oraz dane na temat statystyk oglądalności stron
            i ruchu na stronach.
          </p>
        </li>
        <li>
          <p><strong>Aplikacje osób trzecich</strong></p>
          <p>
            Usługodawca zastrzega, że w ramach Systemu mogą zostać zamieszczone linki umożliwiające użytkownikom
            bezpośrednie dotarcie do stron internetowych lub aplikacji osób trzecich. Usługodawca nie ma wpływu na
            prowadzoną przez administratorów tych stron politykę prywatności, ani politykę wykorzystywania plików
            cookies i nie ponosi za nie odpowiedzialności. Przed skorzystaniem z zasobów oferowanych przez te osoby
            prosimy zapoznać się z dokumentem dot. polityki prywatności, jak i dokumentem stosowania plików cookies.
          </p>
        </li>
        <li>
          <p><strong>Pliki cookies</strong></p>
          <p>
            W celu świadczenia usługi System stosuje pliki cookies tj. pliki które służą identyfikacji przeglądarki
            użytkownika Systemu podczas korzystania z usługi.
          </p>
        </li>
        <li>
          <p id="rodo">
            <strong
              ><u
                >Klauzula informacyjna w przypadku pozyskiwania danych od osoby, której dane dotyczą zgodnie z art. 13,
                ustęp 1 RODO.</u
              ></strong
            >
          </p>
          <p>
            Zgodnie z art. 13 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.04.2016 r. w sprawie
            ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich
            danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) zwanego dalej RODO –
            informuje się, że:
          </p>
          <ol>
            <li>
              <p>Administrator danych osobowych</p>
              <p>
                Administratorem Pani/Pana danych osobowych jest Województwo Małopolskie z siedzibą w Krakowie, ul.
                Basztowa 22, 31-156 Kraków, adres do korespondencji ul. Racławicka 56, 30-017 Kraków).
              </p>
            </li>
            <li>
              <p>Inspektor Ochrony Danych</p>
              <p>
                Dane kontaktowe Inspektora Ochrony Danych – adres do korespondencji: Inspektor Ochrony Danych UMWM,
                Urząd Marszałkowski Województwa Małopolskiego, ul. Racławicka 56, 30-017 Kraków; email:
                iodo@umwm.malopolska.pl.
              </p>
            </li>
            <li>
              <p>Cele przetwarzania danych i podstawy przetwarzania</p>
              <p>
                Administrator będzie przetwarzać Pani/Pana dane na podstawie art. 6 ust. 1 lit. a) RODO (wyrażenie zgody
                na przetwarzanie danych osobowych) w celu
                <strong
                  >budowy i prowadzenia bazy naukowców do nawiązywania współpracy naukowców z przedsiębiorcami.
                </strong>
              </p>
            </li>
            <li>
              <p>Kategorie danych osobowych</p>
              <p>
                Administrator będzie przetwarzać kategorie Pani/Pana danych wskazanych
                <strong>w punkcie 3 niniejszego dokumentu.</strong>
              </p>
            </li>
            <li>
              <p>Okres przechowywania danych osobowych</p>
              <p>
                Pani/Pana dane osobowe będą przechowywane przez okres Zgodnie z art. 140 rozporządzenia Parlamentu
                Europejskiego i Rady (UE) nr 1303/2013 z 17 grudnia 2013 r do 31.12.2029 oraz zgodnie z Ustawą z dnia 14
                lipca 1983 r. o narodowym zasobie archiwalnym i archiwach
              </p>
              <p>
                Dane osobowe będą przechowywane do momentu zakończenia realizacji i rozliczenia projektu i zamknięcie i
                rozliczenia Regionalnego Programu Operacyjnego Województwa Małopolskiego 2014-2020 oraz zakończenia
                okresu trwałości dla projektu i okresu archiwizacyjnego, w zależności od tego, która z tych dat nastąpi
                później.
              </p>
            </li>
            <li>
              <p>Prawa osób, których dane dotyczą</p>
              <p>
                Posiada Pani/Pan prawo dostępu do treści swoich danych oraz prawo ich: sprostowania, ograniczenia
                przetwarzania i usunięcia oraz prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z
                prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem. Szczegółowe zasady
                korzystania z ww. uprawnień regulują art. 15-18 RODO.
              </p>
              <p>
                Podanie danych ma charakter dobrowolny, jednak niezbędny do założenia konta w aplikacji „Małopolska
                nauka” - baza danych kompetencji pracowników naukowych. Niepodanie danych osobowych będzie powodowało
                brak możliwości założenia konta.
              </p>
            </li>
            <li>
              <p>Prawo wniesienia skargi do organu nadzorczego</p>
              <p>
                Ma Pani/Pan prawo wniesienia skargi do organu nadzorczego, którym w Polsce jest Prezes
                <strong>Urzędu Ochrony Danych Osobowych.</strong>
              </p>
            </li>
            <li>
              <p>Odbiorcy danych</p>
              <p>
                Pani/Pana dane osobowe mogą zostać ujawnione - w bazie pn. Małopolska nauka innym osobom.
              </p>
            </li>
            <li>
              <p>
                Zgodnie z Załącznikiem Nr 1 do Uchwały Nr 319/20 Zarządu Województwa Małopolskiego z dnia 3 marca 2020
                r. pn. Zasady realizacji projektu, dla którego Beneficjentem jest Województwo Małopolskie w ramach
                Regionalnego Programu Operacyjnego Województwa Małopolskiego na lata 2014-2020, zapisami załącznika nr 4
                - Lider Projektu, może powierzyć przetwarzanie danych osobowych, o których mowa w niniejszym wniosku,
                podmiotom realizującym badania ewaluacyjne na zlecenie IZ/IP. Dane osobowe mogą zostać również
                powierzone podmiotom realizującym na zlecenie IZ/IP kontrole i audyt w ramach RPO WM. Są to podmioty
                uprawnione do przeprowadzenia kontroli, w tym organy publiczne (m. in.: Małopolskie Centrum
                Przedsiębiorczości, Departament Zarządzania Programami Operacyjnymi Urzędu Marszałkowskiego Województwa
                Małopolskiego, Regionalna Izba Obrachunkowa, Krajowa Administracja Skarbowa, Najwyższa Izba Kontroli,
                Europejski Urząd ds. Zwalczania Nadużyć Finansowych (OLAF). Źródło danych zgodnie z art. 13 RODO -
                przetwarzanie danych na podstawie zgody osoby, której dane dotyczą
              </p>
            </li>
            <li>
              <p>Informacja dotycząca zautomatyzowanego przetwarzania danych osobowych, w tym profilowania</p>
              <p>
                Pani/Pana dane nie będą przetwarzane w sposób zautomatyzowany, w tym również profilowane.
              </p>
            </li>
            <li>
              <p>
                Informacja dotycząca ewentualnego przekazywania danych odbiorcy w państwie trzecim lub organizacji
                międzynarodowej (jeżeli dotyczy) <strong>nie dotyczy</strong>
              </p>
              <p>
                Pani/Pana dane będą przekazywane do ………………………… (należy wskazać państwo trzecie lub organizację
                międzynarodową). Jednocześnie informujemy, że ………………………………… (należy podać informację o stwierdzeniu lub
                braku stwierdzenia przez Komisję odpowiedniego stopnia ochrony lub w przypadku przekazania, o którym
                mowa w art. 46, art. 47 lub art. 49 ust. 1 akapit drugi RODO, wzmiankę o odpowiednich lub właściwych
                zabezpieczeniach oraz o możliwościach uzyskania kopii danych lub o miejscu udostępnienia danych).
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p><strong>Zmiany Polityki Prywatności</strong></p>
          <p>
            Polityka Prywatności może ulegać zmianom, o których Usługodawca będzie informować użytkowników poprzez
            zawiadomienie o zmianie Regulaminu. Dalsze korzystanie z Systemu będzie wymagało akceptacji zmian Polityki
            Prywatności (poprzez akceptację zmiany Regulaminu).
          </p>
        </li>
      </ol>
      <p><strong>Aktualizacja z dnia 27.08.2020r.</strong></p>
    </div>
  </div>
</div>
