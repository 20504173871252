import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IPaginatorServerConfig } from '@app/interfaces/table.interface';

@Injectable({
  providedIn: 'root'
})
export class EventLogService {
  private _eventLogUrl = '/admin/event-logs';
  constructor(private _http: HttpClient) {}

  getEventLogs(paginatorConfig: IPaginatorServerConfig): Observable<any> {
    return this._http.get<any>(this._eventLogUrl, paginatorConfig);
  }
}
