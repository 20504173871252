import { HelperService } from './helper.service';
import { merge as _merge, isNil as _isNil } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "./helper.service";
var TableService = /** @class */ (function () {
    function TableService(helperService) {
        this.helperService = helperService;
        this.defaultPaginatorConfig = {
            direction: 'ASC',
            page: 0,
            size: 10,
            sort: true
        };
    }
    TableService.prototype.getPaginatorConfig = function () {
        return this.paginatorConfig || this.defaultPaginatorConfig;
    };
    TableService.prototype.restorePaginatorConfig = function () {
        this.paginatorConfig = this.defaultPaginatorConfig;
    };
    TableService.prototype.prepareParamsToServer = function (filters) {
        if (filters === void 0) { filters = null; }
        var preparedParams = {
            params: {}
        };
        preparedParams.params = this.getPaginatorConfig();
        if (!_isNil(filters)) {
            preparedParams.params = _merge(filters, preparedParams.params);
        }
        this.helperService.removeNullPropertyFromObject(preparedParams.params);
        return preparedParams;
    };
    TableService.prototype.setPaginatorConfigFormServer = function (paginatorData) {
        var paginatorConfig = {
            direction: this.paginatorConfig ? this.paginatorConfig.direction : 'ASC',
            page: paginatorData.number,
            sort: paginatorData.sort.sorted || false,
            size: paginatorData.size,
            totalElements: paginatorData.totalElements
        };
        if (this.paginatorConfig && this.paginatorConfig.properties && this.paginatorConfig.properties.length) {
            paginatorConfig.properties = this.paginatorConfig.properties;
        }
        this.paginatorConfig = paginatorConfig;
    };
    TableService.prototype.setPaginatorConfig = function (paginatorConfig) {
        this.paginatorConfig = {
            direction: paginatorConfig.direction,
            page: paginatorConfig.page,
            sort: paginatorConfig.sort || false,
            size: paginatorConfig.size
        };
        if (paginatorConfig.properties && paginatorConfig.properties.length) {
            this.paginatorConfig.properties = paginatorConfig.properties;
        }
    };
    TableService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TableService_Factory() { return new TableService(i0.ɵɵinject(i1.HelperService)); }, token: TableService, providedIn: "root" });
    return TableService;
}());
export { TableService };
