import * as tslib_1 from "tslib";
import { HelperService } from '@app/services/helper.service';
import { AuthenticationService } from '@app/core';
import { Router } from '@angular/router';
import { Logger } from '../logger.service';
import { CredentialsService } from './credentials.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./credentials.service";
import * as i3 from "./authentication.service";
import * as i4 from "../../services/helper.service";
var log = new Logger('AuthenticationGuard');
var AuthenticationGuard = /** @class */ (function () {
    function AuthenticationGuard(router, credentialsService, authenticationService, helperService) {
        this.router = router;
        this.credentialsService = credentialsService;
        this.authenticationService = authenticationService;
        this.helperService = helperService;
    }
    AuthenticationGuard.prototype.canActivate = function (route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var expectedRole, userRoles_1, hasUserPermission;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        expectedRole = route.data.expectedRole;
                        if (!this.credentialsService.isAuthenticated()) return [3 /*break*/, 2];
                        this.helperService.isLimitedUser().subscribe(function (isUserLimitedProfile) {
                            if (isUserLimitedProfile) {
                                if (route.routeConfig.path !== 'profile') {
                                    _this.router.navigate(['/profile'], { replaceUrl: true });
                                    return false;
                                }
                            }
                        });
                        return [4 /*yield*/, this.authenticationService.getUserRoles()];
                    case 1:
                        userRoles_1 = _a.sent();
                        hasUserPermission = expectedRole !== null || expectedRole.length
                            ? expectedRole.some(function (role) {
                                return userRoles_1.indexOf(role) !== -1;
                            })
                            : null;
                        if (hasUserPermission || expectedRole === null || !expectedRole.length) {
                            return [2 /*return*/, true];
                        }
                        else {
                            this.router.navigate(['/search'], { replaceUrl: true });
                            return [2 /*return*/, false];
                        }
                        _a.label = 2;
                    case 2:
                        log.debug('Not authenticated, redirecting and adding redirect url...');
                        this.router.navigate(['/search'], { replaceUrl: true });
                        return [2 /*return*/, false];
                }
            });
        });
    };
    AuthenticationGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationGuard_Factory() { return new AuthenticationGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.CredentialsService), i0.ɵɵinject(i3.AuthenticationService), i0.ɵɵinject(i4.HelperService)); }, token: AuthenticationGuard, providedIn: "root" });
    return AuthenticationGuard;
}());
export { AuthenticationGuard };
