import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IPasswordRule, IConfigWebsite } from '@app/interfaces/configuration-app.interface';
import { IPaginatorServerConfig, ITableData } from '@app/interfaces/table.interface';
import { IEditDictionary } from '@app/interfaces/dictionary.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationAppService {
  public globalMaintenance = new BehaviorSubject<boolean>(false);
  private passwordRuleUrl = '/admin/password-rule';

  constructor(private _http: HttpClient) {}

  setMaintenance(maintenance: boolean) {
    this.globalMaintenance.next(maintenance);
  }

  getPasswordRules(paginatorConfig: IPaginatorServerConfig): Observable<ITableData> {
    return this._http.get<ITableData>(`${this.passwordRuleUrl}/page`, paginatorConfig);
  }

  getPasswordRule(id: number = 1): Observable<IPasswordRule> {
    return this._http.get<IPasswordRule>(`${this.passwordRuleUrl}/${id}`);
  }

  getDefaultPasswordRule(): Observable<IPasswordRule> {
    return this._http.get<IPasswordRule>(`${this.passwordRuleUrl}/default`);
  }

  getGlobalWebsiteConfig(): Observable<any> {
    return this._http.get<any>('/websites');
  }

  getWebsiteConfig(): Observable<IConfigWebsite> {
    return this._http.get<IConfigWebsite>('/admin/websites');
  }

  updateWebsiteConfig(payload: IConfigWebsite): Observable<IConfigWebsite> {
    return this._http.put<IConfigWebsite>('/admin/websites', payload);
  }

  updatePasswordRule(passwordRule: IPasswordRule, passwordRuleId: number): Observable<IPasswordRule> {
    return this._http.put<IPasswordRule>(`${this.passwordRuleUrl}/${passwordRuleId}`, passwordRule);
  }

  updateDefaultPasswordRule(passwordRule: IPasswordRule): Observable<IPasswordRule> {
    return this._http.put<IPasswordRule>(`${this.passwordRuleUrl}/default`, passwordRule);
  }

  createPasswordRule(passwordRule: IPasswordRule): Observable<IPasswordRule> {
    return this._http.post<IPasswordRule>(this.passwordRuleUrl, passwordRule);
  }

  removePasswordRule(id: number): Observable<IPasswordRule> {
    return this._http.delete<IPasswordRule>(`${this.passwordRuleUrl}/${id}`);
  }

  changeDefaultPasswordRule(id: number): Observable<IPasswordRule> {
    return this._http.put<IPasswordRule>(`${this.passwordRuleUrl}/change-default/${id}`, null);
  }

  updateDictionary(dictionaryName: string, body: IEditDictionary): Observable<IEditDictionary> {
    return this._http.put<IEditDictionary>(`/dictionaries/${dictionaryName}`, body);
  }

  createItemDictionary(dictionaryName: string, body: IEditDictionary): Observable<IEditDictionary> {
    return this._http.post<IEditDictionary>(`/dictionaries/${dictionaryName}`, body);
  }
}
