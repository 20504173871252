import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployerRegisterModule } from './employer-register/employer-register.module';
import { ScientistRegisterModule } from './scientist-register/scientist-register.module';
import { ManageAccountModule } from './manage-account/manage-account.module';
import { RouterModule } from '@angular/router';
import { RegisterComponent } from './register.component';
import { RegisterRoutingModule } from './register-routing.module';
import { WcagModule } from '@app/shared/wcag/wcag.module';

@NgModule({
  declarations: [RegisterComponent],
  imports: [
    CommonModule,
    RouterModule,
    WcagModule,
    EmployerRegisterModule,
    ScientistRegisterModule,
    ManageAccountModule,
    RegisterRoutingModule
  ]
})
export class RegisterModule {}
