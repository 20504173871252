import { IDefaultDict } from './../../../../interfaces/dictionary.interface';
import { SelectItem, Message } from 'primeng/api';
import { DictionariesService } from './../../../../services/dictionaries.service';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
import { ConfigurationAppService } from '@app/services/configuration-app.service';
import { zip, Subscriber } from 'rxjs';

export interface IDependDict {
  dependDictionary: SelectItem[];
  dependFieldName: string;
  dependFieldModel: number;
}

@Component({
  selector: 'app-dictionary-edit',
  templateUrl: './dictionary-edit.component.html',
  styleUrls: ['./dictionary-edit.component.scss']
})
export class DictionaryEditComponent implements OnInit {
  msgs: Message[] = [];
  listEditableDictionaries: SelectItem[];

  selectedDictForEdit: number;

  editDictItemId: number;
  isEditMode: boolean;
  showAddButton = true;

  listItemToView: any[] = [];
  baseNewItem: any = {};

  rootDictionaryName: string;

  // All dictionaries
  allEditableDict: any;
  dependEditDict: IDependDict[] = [];

  constructor(
    private _notificationService: NotificationService,
    private _dialogService: DialogBaseService,
    private configurationAppService: ConfigurationAppService,
    private dictionariesService: DictionariesService
  ) {}

  ngOnInit() {
    this._getRequiredDictionaries();
    this.showInfoFillRequired();
  }

  setEditMode(dictionaryItem: any) {
    this.editDictItemId = dictionaryItem.id;
    this.isEditMode = true;
  }

  updateItemDict(dictionaryItem: any) {
    if (!dictionaryItem.id) {
      const prepareToSave = { ...dictionaryItem, ...this.baseNewItem };
      delete prepareToSave.id;
      this.configurationAppService.createItemDictionary(this.rootDictionaryName, prepareToSave).subscribe(response => {
        this.listItemToView[0] = response;
        this._notificationService.sendMessage({
          type: 'success',
          title: 'Edycja słownika',
          message: 'Pomyślnie dodano wpis'
        });
      });
    } else {
      this.configurationAppService.updateDictionary(this.rootDictionaryName, dictionaryItem).subscribe(response => {
        this._notificationService.sendMessage({
          type: 'success',
          title: 'Edycja słownika',
          message: 'Pomyślnie edytowano wpis'
        });
      });
    }

    // Reset
    if (dictionaryItem.name) {
      delete this.baseNewItem.id;
      this.editDictItemId = null;
      this.isEditMode = false;
    }
  }

  addNewItemDict() {
    this.editDictItemId = 0;
    this.baseNewItem.id = 0;

    const preparedItemModel = { ...this.baseNewItem };
    this.listItemToView.unshift(preparedItemModel);
    this.setEditMode(this.baseNewItem);
  }

  loadDictionary(dictionaryId: number) {
    this.dependEditDict = [];
    const getEditDict = (this.allEditableDict as any[]).find(editableDict => {
      return editableDict.id === dictionaryId;
    });

    this.rootDictionaryName = getEditDict.id;

    if (getEditDict.nestedDict.length) {
      getEditDict.nestedDict.forEach((nestedDict: any, index: number) => {
        nestedDict.command.subscribe((dictionary: any) => {
          this.dependEditDict[index] = {
            dependDictionary: [],
            dependFieldName: null,
            dependFieldModel: null
          };

          this.dependEditDict[index].dependDictionary = (dictionary as IDefaultDict[]).map((dict: any) => {
            return {
              label: dict.name,
              value: dict.id
            };
          });

          this.dependEditDict[index].dependFieldName = nestedDict.field;
        });
      });
      this.showInfoWithoutData(true);
    } else {
      getEditDict.command.subscribe((dictionary: any) => {
        this.listItemToView = dictionary;
      });
      this.showInfoWithoutData();
    }

    this.listItemToView = [];
  }

  filterRootDictionary(index: number) {
    if (this.dependEditDict.length - 1 === index) {
      const getEditDict = (this.allEditableDict as any[]).find(editableDict => {
        return editableDict.id === this.selectedDictForEdit;
      });

      getEditDict.command.subscribe((dictionary: any) => {
        this.listItemToView = dictionary;

        this.dependEditDict.forEach(dependDict => {
          this.listItemToView = this.listItemToView.filter((item: any) => {
            return item[dependDict.dependFieldName] === dependDict.dependFieldModel;
          });

          if (!this.listItemToView.length) {
            this.showInfoWithoutData();
          }

          this.baseNewItem[dependDict.dependFieldName] = dependDict.dependFieldModel;
        });
      });
    } else {
      this.listItemToView = [];
      if (this.dependEditDict.every(dependDict => !!dependDict.dependFieldModel)) {
        this.showInfoWithoutData();
      } else {
        this.showInfoWithoutData(true);
      }
    }
  }

  showInfoWithoutData(isNestedDictionary: boolean = false) {
    this.msgs = [];
    this.msgs.push({
      severity: 'info',
      summary: 'Informacja',
      detail: isNestedDictionary ? 'Wybierz słowniki powiązane' : 'Brak danych do wyświetlenia'
    });
    this.showAddButton = true;
  }

  showInfoFillRequired() {
    this.msgs = [];
    this.msgs.push({ severity: 'info', summary: 'Informacja', detail: 'Wybierz dane do wyświetlenia' });
    this.showAddButton = false;
  }

  _getRequiredDictionaries() {
    zip(this.dictionariesService.getAllEditableDictionaries()).subscribe(([EditableDictionaries]) => {
      this.allEditableDict = EditableDictionaries;

      this.listEditableDictionaries = (EditableDictionaries as IDefaultDict[]).map(editDict => {
        return {
          label: editDict.name,
          value: editDict.id
        };
      });
    });
  }
}
