import { HelperService } from '@app/services/helper.service';
import {
  ISearchAnonymousResponse,
  ISearchScientistAnonymous,
  ISearchEmployer
} from './../../interfaces/search.interface';
import { AuthenticationService } from '@app/core';
import { SearchService } from './../../services/search.service';
import { IDefaultDict } from './../../interfaces/dictionary.interface';
import { SelectItem, Message } from 'primeng/api';
import { DictionariesService } from './../../services/dictionaries.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ISearchQuery, ISearchTableData, ISearchScientist } from '@app/interfaces/search.interface';
import { zip } from 'rxjs';
import { IPaginatorConfig } from '@app/interfaces/table.interface';
import { TableService } from '@app/services/table.service';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  ref: any;
  isLoading = true;
  isLogged: boolean;
  isEmployer: boolean;
  isScientist: boolean;
  isAnnonymousSearched = false;
  activeTabId = 0;
  maxDescriptionLength = 50;
  paginatorScientist: IPaginatorConfig = null;
  paginatorEmployer: IPaginatorConfig = null;
  msgs: Message[] = [];
  scientistsData: ISearchScientist[];
  employerData: ISearchEmployer[];
  userData: any;

  searchScientistModel: ISearchQuery = {
    keyWords: null,
    scienceDomainId: null,
    scienceId: null,
    specialisationIdentifiers: null,
    description: null,
    isSearchConfidential: false
  };

  searchEmployerModel: ISearchQuery = {
    scienceDomainId: null,
    scienceId: null,
    specialisationIdentifiers: null
  };

  anonymousSearchModel: ISearchScientistAnonymous[];

  searchEngineEntryId: number;
  sendMessageListId: string[] = [];

  // Dictionaries
  sciencesDict: SelectItem[];
  scienceDomainDict: any;
  specialisationDict: any;

  scienceDropdownModel: SelectItem[];
  specialisationDropdownModel: SelectItem[];

  constructor(
    private searchService: SearchService,
    private tableService: TableService,
    private _dialogBaseService: DialogBaseService,
    private _dictionariesService: DictionariesService,
    private authenticationService: AuthenticationService,
    private helperService: HelperService
  ) {}

  ngOnDestroy(): void {
    this.restorePaginator();
  }

  ngOnInit() {
    this._getAllDictionaries();
    this.authenticationService.isLogged.subscribe(isLogged => {
      if (this.isLogged && !isLogged) {
        this.activeTabId = 0;
        this.msgs = [];
        this.scientistsData = null;
        this.employerData = null;
        this.searchScientistModel = {
          keyWords: null,
          scienceDomainId: null,
          scienceId: null,
          specialisationIdentifiers: null,
          description: null,
          isSearchConfidential: false
        };

        this.searchEmployerModel = {
          scienceDomainId: null,
          scienceId: null,
          specialisationIdentifiers: null
        };

        this.searchEngineEntryId = null;
        this.sendMessageListId = [];

        this.isAnnonymousSearched = null;
        this.anonymousSearchModel = null;
        this.isEmployer = false;
        this.isScientist = false;
      } else {
        if (this.isLogged !== undefined) {
          this.userData = this.authenticationService.getUserData();
          if (this.userData) {
            this.isEmployer = this.userData.roles.some((role: string) => {
              return role === 'EMPLOYER';
            });
            this.isScientist = this.userData.roles.some((role: string) => {
              return role === 'SCIENTIST';
            });
            this.isAnnonymousSearched = null;
          } else {
            this.isEmployer = false;
            this.isScientist = false;
          }
        }
      }

      this.isLogged = isLogged;

      this.authenticationService.userData$.subscribe((userData: any) => {
        if (userData) {
          this.userData = userData;
          this.isEmployer = this.userData.roles.some((role: string) => {
            return role === 'EMPLOYER';
          });
          this.isScientist = this.userData.roles.some((role: string) => {
            return role === 'SCIENTIST';
          });
          this.isAnnonymousSearched = null;
        } else {
          this.isEmployer = false;
          this.isScientist = false;
        }
      });
    });

    const defaultPaginatorConfig = this.tableService.getPaginatorConfig();
    defaultPaginatorConfig.sort = true;
    this.tableService.setPaginatorConfig(defaultPaginatorConfig);
  }

  restorePaginator() {
    this.tableService.restorePaginatorConfig();
  }

  clearFilter() {
    this.searchScientistModel = {
      keyWords: null,
      scienceDomainId: null,
      scienceId: null,
      specialisationIdentifiers: null,
      description: null,
      isSearchConfidential: false
    };

    this.searchEmployerModel = {
      scienceDomainId: null,
      scienceId: null,
      specialisationIdentifiers: null
    };
  }

  keyWordsOnKeyDown(event: KeyboardEvent) {
    if (event.key === ',') {
      event.preventDefault();
      const element = event.target as HTMLElement;
      element.blur();
      element.focus();
    }
  }

  _getAllDictionaries(): void {
    zip(
      this._dictionariesService.getAllSciences(),
      this._dictionariesService.getAllScienceDomains(),
      this._dictionariesService.getAllSpecialisations()
    ).subscribe(([Sciences, ScienceDomain, Specialisation]) => {
      this.sciencesDict = (Sciences as IDefaultDict[]).map((sciences: any) => {
        return {
          label: sciences.name,
          value: sciences.id
        };
      });

      this.scienceDomainDict = ScienceDomain;
      this.specialisationDict = Specialisation;

      this.isLoading = false;
    });
  }

  onChangeTab(event: any) {
    this.activeTabId = event.index;
    this.scientistsData = null;
    this.employerData = null;
    this.searchEngineEntryId = null;
    this.sendMessageListId = [];
    this.anonymousSearchModel = [];
    this.isAnnonymousSearched = false;
    this.restorePaginator();
  }

  isSearchQueryFill() {
    if (this.activeTabId === 0) {
      return this.helperService.checkAnyObjectFieldIsFill(this.searchScientistModel, [
        'isSearchConfidential',
        'description',
        'direction',
        'page',
        'size',
        'sort'
      ]);
    } else if (this.activeTabId === 1) {
      return this.helperService.checkAnyObjectFieldIsFill(this.searchEmployerModel, [
        'direction',
        'page',
        'size',
        'sort'
      ]);
    }
  }

  searchEngine(fromPaginate: boolean = false) {
    if (this.activeTabId === 0) {
      const prepareQuery = fromPaginate
        ? {
            ...this.searchScientistModel,
            searchEngineEntryIdentifier: this.searchEngineEntryId
          }
        : this.searchScientistModel;

      if (prepareQuery.description === null) {
        prepareQuery.description = ' ';
      }

      if (this.isLogged) {
        this.searchService
          .searchScientist(this.tableService.prepareParamsToServer(prepareQuery))
          .subscribe((searchResult: ISearchTableData) => {
            this.scientistsData = searchResult.searchedPage.content;
            this.searchEngineEntryId = searchResult.searchEngineEntryIdentifier;
            this.tableService.setPaginatorConfigFormServer(searchResult.searchedPage);
            this.paginatorScientist = this.tableService.getPaginatorConfig();
            if (!searchResult.searchedPage.content.length) {
              this.showEmptyDataAlert();
            }
          });
      } else {
        this.searchService
          .searchScientistAnnonumous(this.tableService.prepareParamsToServer(prepareQuery))
          .subscribe((searchResult: ISearchAnonymousResponse) => {
            this.anonymousSearchModel = searchResult.searchResponse;
            this.isAnnonymousSearched = true;
            if (!this.anonymousSearchModel.length) {
              this.showEmptyDataAlert();
            }
          });
      }
    } else if (this.activeTabId === 1) {
      const prepareQuery = fromPaginate
        ? {
            ...this.searchEmployerModel,
            searchEngineEntryIdentifier: this.searchEngineEntryId
          }
        : this.searchEmployerModel;

      if (this.isLogged) {
        this.searchService
          .searchEmployer(this.tableService.prepareParamsToServer(prepareQuery))
          .subscribe((searchResult: ISearchTableData) => {
            this.employerData = searchResult.searchedPage.content;
            this.searchEngineEntryId = searchResult.searchEngineEntryIdentifier;
            this.tableService.setPaginatorConfigFormServer(searchResult.searchedPage);
            this.paginatorEmployer = this.tableService.getPaginatorConfig();
            if (!searchResult.searchedPage.content.length) {
              this.showEmptyDataAlert();
            }
          });
      } else {
        this.searchService
          .searchEmployerAnnonumous(this.tableService.prepareParamsToServer(prepareQuery))
          .subscribe((searchResult: ISearchAnonymousResponse) => {
            this.anonymousSearchModel = searchResult.searchResponse;
            this.isAnnonymousSearched = true;
            if (!this.anonymousSearchModel.length) {
              this.showEmptyDataAlert();
            }
          });
      }
    }
  }

  showUserProfile(userId: number, userType: string) {
    this._dialogBaseService.setSwitchTemplateStatus('ShowUserProfileComponent', {
      data: {
        userId,
        userType,
        searchEntryId: this.searchEngineEntryId,
        activeTabId: this.activeTabId
      },
      closable: true,
      header: 'Profil użytkownika',
      styleClass: 'medium-modal'
    });

    this.ref = this._dialogBaseService.getInstance();
    this.ref.onClose.subscribe(() => {
      // this.getListMessage();
    });
  }

  showEmptyDataAlert(): void {
    this.msgs = [];
    this.msgs.push({ severity: 'info', summary: 'Informacja', detail: 'Brak wyników wyszukiwania' });
  }

  paginateScientist(paginatorEvent: any): void {
    const paginator = {
      direction: 'ASC',
      page: paginatorEvent.page,
      size: paginatorEvent.rows,
      sort: true
    };
    this.tableService.setPaginatorConfig(paginator);
    this.searchEngine(true);
  }

  sendMessage(isContactWithScientist: boolean = false) {
    const multiselectScientistDict: SelectItem[] = this.prepareMsgScientistDictionary();
    this._dialogBaseService.setSwitchTemplateStatus('SendMessageComponent', {
      data: {
        isContactFromSearch: true,
        multiselectDict: multiselectScientistDict,
        searchEngineEntryId: this.searchEngineEntryId,
        activeTabId: this.activeTabId,
        isContactWithScientist
      },
      closable: true,
      header: 'Skontaktuj się z użytkownikiem',
      contentStyle: { overflow: 'auto' },
      styleClass: 'big-modal'
    });

    this.ref = this._dialogBaseService.getInstance();
    this.ref.onClose.subscribe(() => {
      this.searchEngine();
    });
  }

  prepareMsgScientistDictionary(): SelectItem[] {
    if (this.activeTabId === 0) {
      return this.scientistsData
        .filter(scientist => this.sendMessageListId.indexOf(scientist.userId.toString()) !== -1)
        .map(scientist => {
          return {
            label: `${scientist.academicTitle} ${scientist.firstName} ${scientist.surname}`,
            value: scientist.userId
          };
        });
    } else {
      return this.employerData
        .filter(employer => this.sendMessageListId.indexOf(employer.userId.toString()) !== -1)
        .map(employer => {
          return {
            label: `${employer.companyName}`,
            value: employer.userId
          };
        });
    }
  }

  // Mapers
  getModelDictScience(scienceId: number) {
    this.scienceDropdownModel = this.mapScienceDomains(scienceId);
    this.searchScientistModel.scienceDomainId = null;
    this.searchScientistModel.specialisationIdentifiers = null;
    this.searchEmployerModel.scienceDomainId = null;
    this.searchEmployerModel.specialisationIdentifiers = null;
  }

  getModelDictSpecialisation(scienceDomainId: number) {
    this.specialisationDropdownModel = this.mapSpecialisations(scienceDomainId);
    this.searchScientistModel.specialisationIdentifiers = null;
    this.searchEmployerModel.specialisationIdentifiers = null;
  }

  mapScienceDomains(scienceId: number): SelectItem[] {
    if (this.scienceDomainDict && scienceId) {
      return this.scienceDomainDict
        .filter((scienceDomain: any) => {
          return scienceDomain.scienceId === scienceId;
        })
        .map((scienceDomain: any) => {
          return {
            label: scienceDomain.name,
            value: scienceDomain.id
          };
        });
    } else {
      return null;
    }
  }

  mapSpecialisations(scienceDomainId: number): SelectItem[] {
    if (this.specialisationDict && scienceDomainId) {
      return this.specialisationDict
        .filter((specialisation: any) => {
          return specialisation.scienceDomainId === scienceDomainId;
        })
        .map((specialisation: any) => {
          return {
            label: specialisation.name,
            value: specialisation.id
          };
        });
    } else {
      return null;
    }
  }

  keyUpAccessibility() {}
}
