import * as tslib_1 from "tslib";
import { AuthenticationService } from './../../../core/authentication/authentication.service';
import { DictionariesService } from '@app/services/dictionaries.service';
import { TranslateService } from '@ngx-translate/core';
import { DictionaryService } from './../../../services/dictionary.service';
import { TableService } from './../../../services/table.service';
import { UserManagementService } from './../../../services/user-management.service';
import { OnInit, OnDestroy } from '@angular/core';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
import { Message, SelectItem, ConfirmationService } from 'primeng/api';
import { some, join } from 'lodash';
import { NotificationService } from '@app/shared/notifications/notification.service';
var UserManagementComponent = /** @class */ (function () {
    function UserManagementComponent(userManagementService, _dialogBaseService, tableService, translateService, dictionaryService, dictionariesService, _authenticationService, _notificationService, confirmationService) {
        this.userManagementService = userManagementService;
        this._dialogBaseService = _dialogBaseService;
        this.tableService = tableService;
        this.translateService = translateService;
        this.dictionaryService = dictionaryService;
        this.dictionariesService = dictionariesService;
        this._authenticationService = _authenticationService;
        this._notificationService = _notificationService;
        this.confirmationService = confirmationService;
        this.userTableData = null;
        this.paginator = null;
        this.msgs = [];
        this.filters = {
            id: null,
            displayName: null,
            companyName: null,
            status: null,
            username: null,
            role: null,
            email: null,
            enabled: null,
            firstName: null,
            surname: null
        };
        this.numberLoggedUsers = 1;
    }
    UserManagementComponent.prototype.ngOnDestroy = function () {
        this.tableService.restorePaginatorConfig();
    };
    UserManagementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.actualUserData = this._authenticationService.getUserData();
        this.getUserData();
        this.userTableCols = [
            { field: 'id', header: 'ID' },
            { field: 'username', header: 'Login' },
            { field: 'email', header: 'E-mail' },
            { field: 'displayName', header: 'Imię i Nazwisko / Firma' },
            { field: 'rolesIdentifiers', header: 'Rola / funkcja', custom: 'isUserRole', disabledSort: true },
            { field: 'status', header: 'Status', custom: 'accountStatus' },
            {
                field: 'registrationTime',
                header: 'Data rejestracji',
                custom: 'isDate',
                sortProperty: 'createTime'
            },
            {
                field: 'lastModificationTime',
                header: 'Data ostatniej zmiany',
                custom: 'isDate',
                sortProperty: 'modificationTime'
            },
            { field: 'lastLoginTime', header: 'Data ostatniego logowania', custom: 'isDate' }
        ];
        this.getRoleDict().then(function (userRolesDict) {
            _this.rolesDict = userRolesDict;
        });
        this.getUserEnabledDict();
        this.getActualLoggedUser();
    };
    UserManagementComponent.prototype.getActualLoggedUser = function () {
        var _this = this;
        this.userManagementService.getLoggedUser().subscribe(function (numberOfUsers) {
            _this.numberLoggedUsers = numberOfUsers;
        });
    };
    UserManagementComponent.prototype.showUserRoles = function (userData) {
        var _this = this;
        var roles = userData.map(function (role) {
            return _this.translateService.instant('userRoles.' + role.name);
        });
        return join(roles, ', ');
    };
    UserManagementComponent.prototype.getUserData = function (isFilter) {
        var _this = this;
        if (isFilter === void 0) { isFilter = false; }
        if (isFilter) {
            this.tableService.restorePaginatorConfig();
        }
        this.userManagementService
            .getUsers(this.tableService.prepareParamsToServer(this.filters))
            .subscribe(function (userData) {
            _this.userTableData = userData.content;
            _this.tableService.setPaginatorConfigFormServer(userData);
            _this.paginator = _this.tableService.getPaginatorConfig();
            if (!userData.content.length) {
                _this.showEmptyDataAlert();
            }
        });
    };
    UserManagementComponent.prototype.getRoleDict = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.translateService.get('userRoles').toPromise()];
                    case 1:
                        _a.sent();
                        promise = new Promise(function (resolve) {
                            var userRoles = null;
                            _this.dictionaryService.getRoles().subscribe(function (roles) {
                                userRoles = roles.map(function (role) {
                                    return {
                                        label: _this.translateService.instant('userRoles.' + role.name),
                                        value: role.name
                                    };
                                });
                                resolve(userRoles);
                            });
                        });
                        return [2 /*return*/, promise];
                }
            });
        });
    };
    UserManagementComponent.prototype.getUserEnabledDict = function () {
        var _this = this;
        this.dictionariesService.getAllUserStatus().subscribe(function (statuses) {
            _this.userEnabledDict = statuses;
        });
    };
    UserManagementComponent.prototype.clearFilter = function () {
        this.filters = {
            id: null,
            displayName: null,
            companyName: null,
            status: null,
            username: null,
            role: null,
            email: null,
            enabled: null,
            firstName: null,
            surname: null
        };
    };
    UserManagementComponent.prototype.paginate = function (paginatorEvent) {
        var currentPaginator = this.tableService.getPaginatorConfig();
        var paginator = {
            direction: currentPaginator.direction,
            page: paginatorEvent.page,
            size: paginatorEvent.rows,
            properties: currentPaginator.properties,
            sort: currentPaginator.sort
        };
        this.tableService.setPaginatorConfig(paginator);
        this.getUserData();
    };
    UserManagementComponent.prototype.customSort = function (event) {
        if (event.sortField) {
            var currentPaginator = this.tableService.getPaginatorConfig();
            var paginator = {
                direction: event.sortOrder === -1 ? 'DESC' : 'ASC',
                page: currentPaginator.page,
                size: currentPaginator.size,
                properties: [event.sortField],
                sort: true
            };
            this.tableService.setPaginatorConfig(paginator);
            this.getUserData();
        }
    };
    UserManagementComponent.prototype.showEmptyDataAlert = function () {
        this.msgs = [];
        this.msgs.push({ severity: 'info', summary: 'Informacja', detail: 'Brak danych' });
    };
    UserManagementComponent.prototype.showModal = function (user, edit) {
        var _this = this;
        if (user === void 0) { user = null; }
        if (edit === void 0) { edit = ''; }
        this._dialogBaseService.setSwitchTemplateStatus('CreateAccountComponent', {
            data: {
                editMode: !!edit,
                id: user ? user.id : null
            },
            closable: true,
            styleClass: 'big-modal',
            header: edit ? 'Edytuj konto użytkownika' : 'Dodaj konto użytkownika',
            contentStyle: { overflow: 'inherit' }
        });
        this.ref = this._dialogBaseService.getInstance();
        this.ref.onClose.subscribe(function () {
            _this.getUserData();
        });
    };
    UserManagementComponent.prototype.checkIsUserAdmin = function (user) {
        return some(user.rolesIdentifiers, function (role) {
            return role.name === 'ADMIN' || role.name === 'CONTACT_ADMIN';
        });
    };
    UserManagementComponent.prototype.regeneratePassword = function (user) {
        this._dialogBaseService.setSwitchTemplateStatus('RegeneratePasswordComponent', {
            data: {
                userId: user.id
            },
            closable: true,
            header: 'Regeneracja hasła',
            styleClass: 'small-modal'
        });
    };
    UserManagementComponent.prototype.hideUser = function (user) {
        var _this = this;
        this.confirmationService.confirm({
            message: "Czy na pewno chcesz usun\u0105\u0107 u\u017Cytkownika " + (user.displayName || user.username) + "?",
            accept: function () {
                _this.userManagementService.hideUser(user.id).subscribe(function (resp) {
                    _this._notificationService.sendMessage({
                        type: 'success',
                        title: 'Aktualizacja konta',
                        message: 'Pomyślnie oznaczono konto jako usunięte'
                    });
                    _this.getUserData();
                });
            }
        });
    };
    UserManagementComponent.prototype.editUser = function (user, isPreviewMode) {
        var _this = this;
        if (isPreviewMode === void 0) { isPreviewMode = ''; }
        var isScientist = some(user.rolesIdentifiers, function (rolesIdentifier) {
            return rolesIdentifier.name === 'SCIENTIST' || rolesIdentifier.name === 'LIMITED_SCIENTIST';
        });
        var isEmployer = some(user.rolesIdentifiers, function (rolesIdentifier) {
            return rolesIdentifier.name === 'EMPLOYER' || rolesIdentifier.name === 'LIMITED_EMPLOYER';
        });
        if (isScientist) {
            this._dialogBaseService.setSwitchTemplateStatus('ScientistRegistrationComponent', {
                data: {
                    id: user.scientistId,
                    userId: user.id,
                    preview: !!isPreviewMode
                },
                closable: true,
                header: 'Edycja naukowca',
                styleClass: 'medium-modal'
            });
        }
        else if (isEmployer) {
            this._dialogBaseService.setSwitchTemplateStatus('EmployerRegistrationComponent', {
                data: {
                    id: user.employerId,
                    userId: user.id,
                    preview: !!isPreviewMode
                },
                closable: true,
                header: 'Edycja przedsiębiorcy',
                styleClass: 'medium-modal'
            });
        }
        this.ref = this._dialogBaseService.getInstance();
        this.ref.onClose.subscribe(function () {
            _this.getUserData();
        });
    };
    UserManagementComponent.prototype.checkUserRole = function (userData, userRole, forceRole) {
        if (forceRole === void 0) { forceRole = false; }
        if (!forceRole) {
            return userData.rolesIdentifiers.some(function (role) {
                return role.name === userRole;
            });
        }
        else {
            return userData.rolesIdentifiers.length === 1 && userData.rolesIdentifiers[0].name === userRole;
        }
    };
    UserManagementComponent.prototype.markAsContactAdmin = function (id) {
        var _this = this;
        this.userManagementService.markAsContactAdmin(id).subscribe(function () {
            _this._notificationService.sendMessage({
                type: 'success',
                title: 'Aktualizacja konta',
                message: 'Pomyślnie oznaczono konto jako administrator kontaktowy'
            });
            _this.getUserData();
        });
    };
    return UserManagementComponent;
}());
export { UserManagementComponent };
