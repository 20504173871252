import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { MailNotificationComponent } from './mail-notification.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'notifications/cooperation',
      component: MailNotificationComponent,
      data: { title: extract('Potwierdzenie współpracy') }
    },
    {
      path: 'notifications/cooperation/:token',
      component: MailNotificationComponent,
      data: { title: extract('Potwierdzenie współpracy') }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class MailNotificationRoutingModule {}
