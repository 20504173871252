import { HelperService } from './helper.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./helper.service";
var UserProfileService = /** @class */ (function () {
    function UserProfileService(_http, helperService) {
        this._http = _http;
        this.helperService = helperService;
        this.resourceUrl = '/';
    }
    UserProfileService.prototype.getScientistProfile = function (scientistId) {
        return this._http.get(this.resourceUrl + "scientists/" + scientistId);
    };
    UserProfileService.prototype.getEmployerProfile = function (employerId) {
        return this._http.get(this.resourceUrl + "employers/" + employerId);
    };
    UserProfileService.prototype.updateScientistProfile = function (userId, payload) {
        return this._http.put(this.resourceUrl + "scientists/" + userId, payload);
    };
    UserProfileService.prototype.updateEmployerProfile = function (userId, payload) {
        return this._http.put(this.resourceUrl + "employers/" + userId, payload);
    };
    UserProfileService.prototype.updateScientistLimitedProfile = function (userId, payload) {
        return this._http.put(this.resourceUrl + "scientists/first-login/" + userId, payload);
    };
    UserProfileService.prototype.updateEmployerLimitedProfile = function (userId, payload) {
        return this._http.put(this.resourceUrl + "employers/first-login/" + userId, payload);
    };
    UserProfileService.prototype.changePassword = function (userId, payload) {
        return this._http.put("/accounts/change-password/" + userId, payload);
    };
    UserProfileService.prototype.changeEmail = function (userId, payload) {
        return this._http.put("/accounts/change-email/" + userId, payload);
    };
    UserProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserProfileService_Factory() { return new UserProfileService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HelperService)); }, token: UserProfileService, providedIn: "root" });
    return UserProfileService;
}());
export { UserProfileService };
