import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { UserManagementComponent } from './user-management.component';
import { AuthenticationGuard } from '@app/core/authentication/authentication.guard';
var ɵ0 = { title: extract('Zarządzanie użytkownikami'), expectedRole: ['ADMIN', 'CONTACT_ADMIN'] };
var routes = [
    Shell.childRoutes([
        {
            path: 'user-management',
            component: UserManagementComponent,
            data: ɵ0,
            canActivate: [AuthenticationGuard]
        }
    ])
];
var UserManagementRoutingModule = /** @class */ (function () {
    function UserManagementRoutingModule() {
    }
    return UserManagementRoutingModule;
}());
export { UserManagementRoutingModule };
export { ɵ0 };
