import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _loaderStatus$: Subject<boolean> = new Subject();

  public setLoderStatus(status: boolean): void {
    this._loaderStatus$.next(status);
  }

  public getLoaderStatus(): Observable<boolean> {
    return this._loaderStatus$.asObservable();
  }
}
