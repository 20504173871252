import { DictionariesService } from './../../services/dictionaries.service';
import { ITableData, IPaginatorConfig } from './../../interfaces/table.interface';
import { CommunicatorService } from './../../services/communicator.service';
import { ICommunicatorMessage, ICommunicatorMessageList } from '@app/interfaces/communicator.interface';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CredentialsService, AuthenticationService } from '@app/core';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
import { sortBy, orderBy } from 'lodash';
import { TableService } from '@app/services/table.service';
import { Message, MenuItem, ConfirmationService } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { NotificationService } from '@app/shared/notifications/notification.service';
import * as _ from 'lodash';
// import { ContextMenu } from 'primeng/contextmenu';

@Component({
  selector: 'app-communicator',
  templateUrl: './communicator.component.html',
  styleUrls: ['./communicator.component.scss'],
  providers: [ConfirmationService]
})
export class CommunicatorComponent implements OnInit, OnDestroy {
  @ViewChild('messageContextMenu', { static: false }) messageContextMenu: ContextMenu;

  ref: any;
  userData: any;
  isLogged: boolean;
  isUserScientist: boolean;
  isUserEmployer: boolean;
  activeMessageTab = 1;
  paginator: IPaginatorConfig = null;
  msgs: Message[] = [];
  messagesList: ICommunicatorMessageList[];
  lastMessageId: number;
  basicUserData: any;
  adminSelectedUserId: number;
  isShowedUsersMessage: boolean;

  // Preview message
  previewMessage: boolean;
  messagesThread: ICommunicatorMessage[];
  isAdmin: boolean;
  isContactAdmin: boolean;
  parentId: number;
  isAnyAdmin: boolean;

  constructor(
    private _credentialsService: CredentialsService,
    private _authenticationService: AuthenticationService,
    private _dialogBaseService: DialogBaseService,
    private tableService: TableService,
    private _notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private communicatorService: CommunicatorService,
    private _dictionaries: DictionariesService
  ) {}

  ngOnDestroy(): void {
    this.tableService.restorePaginatorConfig();
  }

  ngOnInit() {
    // Prepare paginator
    const paginator = {
      direction: 'DESC',
      page: 0,
      size: 10,
      sort: true
    };
    this.tableService.setPaginatorConfig(paginator);

    this.isLogged = this._credentialsService.isAuthenticated();
    this.getAccountInformation();
    this.isAnyAdmin = this.isAdmin || this.isContactAdmin;

    this.getListMessage();

    if (this.isAnyAdmin) {
      this.getBasicUserData();
    }
  }

  getBasicUserData() {
    this._dictionaries.getAllUsersBasicInfo().subscribe((response: any) => {
      this.basicUserData = response
        .map((user: any) => {
          return {
            label: user.username,
            value: user.id
          };
        })
        .filter((user: any) => {
          return user.value !== this.userData.id;
        });
    });
  }

  getShownUsername() {
    if (this.adminSelectedUserId && this.isShowedUsersMessage) {
      return this.basicUserData.find((user: any) => user.value === this.adminSelectedUserId).label;
    } else {
      this.isShowedUsersMessage = false;
      return null;
    }
  }

  detectChangeSelectedUser(event: any) {
    if (event.value === null) {
      switch (this.activeMessageTab) {
        case 1:
          this.getListMessage();
          break;
        case 2:
          this.getListSentMessage();
          break;
        case 3:
          this.getListArchiveMessage();
          break;
        default:
          this.getListMessage();
          break;
      }
    }
  }

  showAdminSelectedUserMessage() {
    let isNotSelectedUser = false;
    if (!this.adminSelectedUserId) {
      this._notificationService.sendMessage({
        type: 'error',
        title: 'Błąd!',
        message: 'Nie wybrano użytkownika'
      });

      isNotSelectedUser = true;
    } else {
      this.isShowedUsersMessage = true;
    }

    switch (this.activeMessageTab) {
      case 1:
        this.getListMessage();
        break;
      case 2:
        this.getListSentMessage();
        break;
      case 3:
        this.getListArchiveMessage();
        break;
      default:
        this.getListMessage();
        break;
    }
  }

  onLinkRightClicked(messageId: number, e: any): void {
    if (this.adminSelectedUserId) {
      return;
    }

    if (this.messageContextMenu) {
      this.messageContextMenu.model = [
        {
          label: 'Przenieś do archiwalnych',
          command: event => this.markAsArchive(messageId)
        },
        { separator: true },
        {
          label: 'Usuń wiadomość',
          command: event => this.deleteMessage(messageId)
        }
      ];

      if (this.activeMessageTab === 1 || this.activeMessageTab === 2) {
        this.messageContextMenu.show(e);
      }
    }
  }

  markAsArchive(messageId: number) {
    this.communicatorService.markMessageAsArchive(this.userData.id, messageId).subscribe(response => {
      this._notificationService.sendMessage({
        type: 'success',
        title: 'Aktualizacja konta',
        message: 'Pomyślnie przeniesiono wiadomość do archiwum'
      });

      if (this.activeMessageTab === 1) {
        this.getListMessage();
      } else if (this.activeMessageTab === 2) {
        this.getListSentMessage();
      }
    });
  }

  deleteMessage(messageId: number) {
    this.confirmationService.confirm({
      message: 'Czy jesteś pewien, że chcesz usunąć wiadomość?',
      accept: () => {
        this.markAsDeleted(messageId);
      }
    });
  }

  markAsDeleted(messageId: number) {
    this.communicatorService.markMessageAsDeleted(this.userData.id, messageId).subscribe(response => {
      this._notificationService.sendMessage({
        type: 'success',
        title: 'Aktualizacja konta',
        message: 'Pomyślnie usunięto wiadomość'
      });

      if (this.activeMessageTab === 1) {
        this.getListMessage();
      } else if (this.activeMessageTab === 2) {
        this.getListSentMessage();
      }
    });
  }

  getAccountInformation() {
    if (this.isLogged) {
      this.userData = this._authenticationService.getUserData();
      this.isUserScientist = !!this.userData.scientistId;
      this.isUserEmployer = !!this.userData.employerId;

      this.isAdmin = this.userData.roles.some((role: string) => {
        return role === 'ADMIN';
      });
      this.isContactAdmin = this.userData.roles.some((role: string) => {
        return role === 'CONTACT_ADMIN';
      });
    }
  }

  getListArchiveMessage() {
    this.activeMessageTab = 3;

    this.communicatorService
      .getListArchiveMessage(
        this.tableService.prepareParamsToServer({
          userId: this.adminSelectedUserId || this.userData.id
        })
      )
      .subscribe((response: ITableData) => {
        this.messagesList = orderBy(response.content, ['createTime'], ['desc']);
        this.tableService.setPaginatorConfigFormServer(response);
        this.paginator = this.tableService.getPaginatorConfig();

        if (!response.content.length) {
          this.showEmptyDataAlert();
        }
      });
  }

  getListSentMessage() {
    this.activeMessageTab = 2;

    this.communicatorService
      .getListSendMessage(
        this.tableService.prepareParamsToServer({
          userId: this.adminSelectedUserId || this.userData.id
        })
      )
      .subscribe((response: ITableData) => {
        this.messagesList = orderBy(response.content, ['createTime'], ['desc']);
        this.tableService.setPaginatorConfigFormServer(response);
        this.paginator = this.tableService.getPaginatorConfig();

        if (!response.content.length) {
          this.showEmptyDataAlert();
        }
      });
  }

  getListMessage() {
    this.activeMessageTab = 1;

    this.communicatorService
      .getListMessage(
        this.tableService.prepareParamsToServer({
          userId: this.adminSelectedUserId || this.userData.id
        })
      )
      .subscribe((response: ITableData) => {
        this.messagesList = orderBy(response.content, ['createTime'], ['desc']);
        this.tableService.setPaginatorConfigFormServer(response);
        this.paginator = this.tableService.getPaginatorConfig();

        if (!response.content.length) {
          this.showEmptyDataAlert();
        }
      });
  }

  getMessage(id: number) {
    this.lastMessageId = id;
    this.communicatorService.getMessage(id).subscribe((message: ICommunicatorMessage[]) => {
      this.messagesThread = orderBy(message, ['sendDate'], ['desc']);
      this.parentId = message[0].id || 1;
    });
  }

  showEmptyDataAlert(): void {
    this.msgs = [];
    this.msgs.push({ severity: 'info', summary: 'Informacja', detail: 'Brak wiadomości' });
  }

  restorePaginator(): void {
    const paginator = {
      direction: 'DESC',
      page: 0,
      size: 10,
      sort: true
    };
    this.tableService.setPaginatorConfig(paginator);
  }

  paginate(paginatorEvent: any): void {
    const paginator = {
      direction: 'DESC',
      page: paginatorEvent.page,
      size: paginatorEvent.rows,
      sort: true
    };
    this.tableService.setPaginatorConfig(paginator);

    switch (this.activeMessageTab) {
      case 1:
        this.getListMessage();
        break;
      case 2:
        this.getListSentMessage();
        break;
      case 3:
        this.getListArchiveMessage();
        break;
      default:
        this.getListMessage();
        break;
    }
  }

  contactWithAdmin() {
    this._dialogBaseService.setSwitchTemplateStatus('SendMessageComponent', {
      data: {
        isContactWithAdmin: true
      },
      closable: true,
      header: 'Skontaktuj się z Administratorem',
      styleClass: 'big-modal',
      contentStyle: { overflow: 'auto' }
    });

    this.ref = this._dialogBaseService.getInstance();
    this.ref.onClose.subscribe(() => {
      this.getListMessage();
    });
  }

  contactWithUsers() {
    this._dialogBaseService.setSwitchTemplateStatus('SendMessageComponent', {
      data: {
        isContactWithUsers: true
      },
      closable: true,
      header: 'Skontaktuj się z Użytkownikami',
      styleClass: 'big-modal',
      contentStyle: { overflow: 'auto' }
    });

    this.ref = this._dialogBaseService.getInstance();
    this.ref.onClose.subscribe(() => {
      this.getListMessage();
    });
  }

  replyMessageFormList(id: number) {
    this.lastMessageId = id;
    this.communicatorService.getMessage(id).subscribe((message: ICommunicatorMessage[]) => {
      this.messagesThread = orderBy(message, ['sendDate'], ['desc']);
      this.parentId = message[0].id || 1;

      this.replyMessage();
    });
  }

  replyMessage() {
    const lastMessageData = this.messagesThread.find((messageFromThread: any) => {
      return messageFromThread.receiverId !== this.userData.id;
    });

    if (lastMessageData) {
      this._dialogBaseService.setSwitchTemplateStatus('SendMessageComponent', {
        data: {
          isReply: true,
          parentId: this.parentId,
          lastMessage: lastMessageData
        },
        closable: true,
        header: 'Odpowiedz',
        styleClass: 'big-modal',
        contentStyle: { overflow: 'auto' }
      });

      this.ref = this._dialogBaseService.getInstance();
      this.ref.onClose.subscribe(() => {
        this.getListMessage();
        this.getMessage(this.lastMessageId);
      });
    } else {
      this._notificationService.sendMessage({
        type: 'error',
        title: 'Wystąpił błąd',
        message: 'Brak danych adresata'
      });
    }
  }

  getStartUserConversationName(): string {
    const lastMessage = _.last(this.messagesThread);
    return lastMessage ? lastMessage.receiverDisplayName : null;
  }

  openMessage(id: number) {
    this.previewMessage = true;
    this.getMessage(id);
  }

  backToMainList() {
    this.previewMessage = false;

    if (this.adminSelectedUserId) {
      this.showAdminSelectedUserMessage();
    } else {
      if (this.activeMessageTab === 1) {
        this.getListMessage();
      } else if (this.activeMessageTab === 2) {
        this.getListSentMessage();
      }
    }
  }

  keyUpAccessibility() {}
}
