<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="eventLogTableData?.length">
        <p-table
          [columns]="eventLogTableCols"
          [value]="eventLogTableData"
          [responsive]="true"
          [autoLayout]="true"
          styleClass="mn-table"
          [lazy]="true"
          sortMode="single"
          (onLazyLoad)="customSort($event)"
          [customSort]="true"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                <div class="d-flex">
                  {{ col.header }}
                  <p-sortIcon
                    [field]="col.field"
                    ariaLabel="Kliknij, aby posortować"
                    ariaLabelDesc="Kliknij, aby sortować w kolejności malejącej"
                    ariaLabelAsc="Kliknij, aby sortować w kolejności rosnącej"
                  ></p-sortIcon>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td *ngFor="let col of columns">
                <span *ngIf="col.isDate">{{ rowData[col.field] | date: 'dd/MM/yyyy HH:mm:ss' }}</span>
                <span *ngIf="!col.isDate">{{ rowData[col.field] }}</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-paginator
          [rows]="paginator.size"
          [totalRecords]="paginator.totalElements"
          (onPageChange)="paginate($event)"
          [rowsPerPageOptions]="[10, 20, 50, 100]"
        ></p-paginator>
      </ng-container>
    </div>
  </div>
  <div class="row" *ngIf="eventLogTableData?.length">
    <div class="col-12">
      <div class="d-flex justify-content-end">
        <p class="text-muted my-4">Liczba zdarzeń: {{ paginator?.totalElements }}</p>
      </div>
    </div>
  </div>
</div>
