import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IUserEmployer, IUserResetPassword, IUserScientist } from '@app/interfaces/user-profile.interface';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(private _http: HttpClient) {}

  activateAccount(token: string): Observable<any> {
    return this._http.get<any>(`/accounts/active/${token}`);
  }

  requestResetPass(email: string): Observable<any> {
    return this._http.get<any>('/accounts/password/reset', {
      params: { email }
    });
  }

  resetPassword(token: string, payload: IUserResetPassword): Observable<IUserResetPassword> {
    return this._http.post<IUserResetPassword>(`/accounts/password/reset/${token}`, payload);
  }

  registerEmployer(employer: IUserEmployer): Observable<IUserEmployer> {
    return this._http.post<IUserEmployer>('/employers/register', employer);
  }

  registerScientist(scientist: IUserScientist): Observable<IUserScientist> {
    return this._http.post<IUserScientist>('/scientists/register', scientist);
  }
}
