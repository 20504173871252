import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProblemBaseService = /** @class */ (function () {
    function ProblemBaseService(_http) {
        this._http = _http;
        this.resourceUrl = '/public-search-entry';
    }
    ProblemBaseService.prototype.removeProblem = function (problemId) {
        return this._http.delete(this.resourceUrl + "/" + problemId);
    };
    ProblemBaseService.prototype.getProblems = function (paginatorConfig) {
        return this._http.get(this.resourceUrl, paginatorConfig);
    };
    ProblemBaseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProblemBaseService_Factory() { return new ProblemBaseService(i0.ɵɵinject(i1.HttpClient)); }, token: ProblemBaseService, providedIn: "root" });
    return ProblemBaseService;
}());
export { ProblemBaseService };
