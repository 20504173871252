import { CommunicatorComponent } from './communicator.component';
import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { AuthenticationGuard } from '@app/core/authentication/authentication.guard';
var ɵ0 = {
    title: extract('Komunikator'),
    expectedRole: ['ADMIN', 'CONTACT_ADMIN', 'SCIENTIST', 'EMPLOYER']
};
var routes = [
    Shell.childRoutes([
        {
            path: 'communicator',
            component: CommunicatorComponent,
            data: ɵ0,
            canActivate: [AuthenticationGuard]
        }
    ])
];
var CommunicatorRoutingModule = /** @class */ (function () {
    function CommunicatorRoutingModule() {
    }
    return CommunicatorRoutingModule;
}());
export { CommunicatorRoutingModule };
export { ɵ0 };
