import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommunicatorComponent } from './communicator.component';
import { CommunicatorRoutingModule } from './communicator-routing.module';
import { SendMessageComponent } from './send-message/send-message.component';

import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { EditorModule } from 'primeng/editor';
import { PaginatorModule } from 'primeng/paginator';
import { MessagesModule } from 'primeng/messages';
import { TooltipModule } from 'primeng/tooltip';
import { ContextMenuModule } from 'primeng/contextmenu';
import { WcagModule } from '@app/shared/wcag/wcag.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  declarations: [CommunicatorComponent, SendMessageComponent],
  imports: [
    CommonModule,
    FormsModule,
    WcagModule,
    CheckboxModule,
    MultiSelectModule,
    EditorModule,
    PaginatorModule,
    TooltipModule,
    MessagesModule,
    ContextMenuModule,
    ConfirmDialogModule,
    CommunicatorRoutingModule
  ]
})
export class CommunicatorModule {}
