import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { SearchRoutingModule } from './search-routing.module';
import { SearchComponent } from './search.component';
import { TabViewModule } from 'primeng/tabview';
import { ChipsModule } from 'primeng/chips';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { WcagModule } from '@app/shared/wcag/wcag.module';
import { ShowUserProfileComponent } from './show-user-profile/show-user-profile.component';
import { CalendarModule } from 'primeng/calendar';
import { NgxPrintModule } from 'ngx-print';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    WcagModule,
    CoreModule,
    SharedModule,
    TabViewModule,
    ChipsModule,
    DropdownModule,
    TableModule,
    PaginatorModule,
    MessagesModule,
    CheckboxModule,
    CalendarModule,
    TooltipModule,
    NgxPrintModule,
    SearchRoutingModule
  ],
  declarations: [SearchComponent, ShowUserProfileComponent]
})
export class SearchModule {}
