import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { LoaderService } from '@app/shared/loader/loader.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  public shouldShowLoader = false;
  private _loaderSubscription$: Subject<boolean> = new Subject();

  constructor(private _loaderService: LoaderService, private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscribeToLoaderStatus();
  }

  ngOnDestroy(): void {
    this._loaderSubscription$.next();
    this._loaderSubscription$.complete();
  }

  private subscribeToLoaderStatus() {
    this._loaderService
      .getLoaderStatus()
      .pipe(takeUntil(this._loaderSubscription$))
      .subscribe(status => {
        this.shouldShowLoader = status;
        this.cdRef.detectChanges();
      });
  }
}
