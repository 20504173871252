import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventLogService } from '@app/services/event-log.service';
import { IPaginatorConfig, ITableData, ITableInterface } from '@app/interfaces/table.interface';
import { TableService } from '@app/services/table.service';
import { IEventLogsFilters, IEventLogsTable } from '@app/interfaces/event-logs.interface';

@Component({
  selector: 'app-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.scss']
})
export class EventLogComponent implements OnInit, OnDestroy {
  paginator: IPaginatorConfig = null;
  eventLogTableData: IEventLogsTable[] = null;
  eventLogTableCols: ITableInterface[];
  filters: IEventLogsFilters = {
    username: null,
    type: null,
    date: null
  };

  constructor(private _eventLogService: EventLogService, private tableService: TableService) {}

  ngOnDestroy(): void {
    this.tableService.restorePaginatorConfig();
  }

  ngOnInit() {
    this.eventLogTableCols = [
      { field: 'id', header: 'ID' },
      { field: 'createDateTime', header: 'Data', isDate: true },
      { field: 'userEmail', header: 'Uzytkownik' },
      { field: 'modificationKind', header: 'Rodzaj zmiany' },
      { field: 'description', header: 'Opis' }
    ];

    this.getEventLogsData();
  }

  paginate(paginatorEvent: any): void {
    const currentPaginator = this.tableService.getPaginatorConfig();
    const paginator = {
      direction: currentPaginator.direction,
      page: paginatorEvent.page,
      size: paginatorEvent.rows,
      properties: currentPaginator.properties,
      sort: currentPaginator.sort
    };
    this.tableService.setPaginatorConfig(paginator);
    this.getEventLogsData();
  }

  customSort(event: any) {
    if (event.sortField) {
      const currentPaginator = this.tableService.getPaginatorConfig();
      const paginator = {
        direction: event.sortOrder === -1 ? 'DESC' : 'ASC',
        page: currentPaginator.page,
        size: currentPaginator.size,
        properties: [event.sortField],
        sort: true
      };
      this.tableService.setPaginatorConfig(paginator);
      this.getEventLogsData();
    }
  }

  private getEventLogsData() {
    this._eventLogService
      .getEventLogs(this.tableService.prepareParamsToServer(this.filters))
      .subscribe((userData: ITableData) => {
        this.eventLogTableData = userData.content;
        this.tableService.setPaginatorConfigFormServer(userData);
        this.paginator = this.tableService.getPaginatorConfig();
      });
  }
}
