import { Component, OnInit } from '@angular/core';
import { ConfigurationAppService } from '@app/services/configuration-app.service';
import { CredentialsService, AuthenticationService, Credentials } from '@app/core';
import { Router } from '@angular/router';
import { NotificationService } from '@app/shared/notifications/notification.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {
  maintenanceAccess: boolean;
  globalMaintenance: boolean;
  isGetMaintenanceAccessPending: boolean;
  isLogged: boolean;
  userLogin: any = {
    login: null,
    password: null
  };

  constructor(
    private configurationAppService: ConfigurationAppService,
    private authenticationService: AuthenticationService,
    private _credentialsService: CredentialsService,
    private router: Router,
    private _notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.configurationAppService.globalMaintenance.subscribe((maintenance: boolean) => {
      this.globalMaintenance = maintenance;

      if (this.globalMaintenance) {
        this.authenticationService.isLogged.subscribe((isLogged: boolean) => {
          if (isLogged && !this.isGetMaintenanceAccessPending) {
            this.getMaintenanceAccess();
          }
        });
        this.isLogged = this._credentialsService.isAuthenticated();
      }
    });
  }

  getMaintenanceAccess() {
    this.isGetMaintenanceAccessPending = true;
    this.authenticationService.reloadUserInfo().subscribe(respone => {
      const userData = respone;

      if (userData) {
        this.maintenanceAccess =
          userData.roles.indexOf('ADMIN') !== -1 || userData.roles.indexOf('CONTACT_ADMIN') !== -1;
        if (this.maintenanceAccess) {
          this.router.navigate(['/configuration-app']);
          this.globalMaintenance = false;
        }
      }
    });
  }

  login() {
    this.authenticationService
      .login(this.userLogin.login, this.userLogin.password)
      .subscribe((credentials: Credentials) => {
        this._credentialsService.setCredentials(credentials);
        this._notificationService.sendMessage({
          type: 'success',
          title: 'Logowanie',
          message: 'Logowanie przebiegło pomyślnie'
        });
        this.getMaintenanceAccess();
      });
  }
}
