import { DeclarationAvailabilityComponent } from './declaration-availability.component';
import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
var ɵ0 = { title: extract('Deklaracja dostępności') };
var routes = [
    Shell.childRoutes([
        {
            path: 'declaration-availability',
            component: DeclarationAvailabilityComponent,
            data: ɵ0
        }
    ])
];
var DeclarationAvailabilityRoutingModule = /** @class */ (function () {
    function DeclarationAvailabilityRoutingModule() {
    }
    return DeclarationAvailabilityRoutingModule;
}());
export { DeclarationAvailabilityRoutingModule };
export { ɵ0 };
