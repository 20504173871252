import { IPaginatorServerConfig, ITableData } from '@app/interfaces/table.interface';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  IModifyAccount,
  IRegeneratePassword,
  IAdminCreateUserAccount,
  IUserHideAccount
} from '@app/interfaces/user-management.interface';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  private resourceUrl = '/admin/users/extended';

  constructor(private _http: HttpClient) {}

  getUsers(paginatorConfig: IPaginatorServerConfig): Observable<ITableData> {
    return this._http.get<ITableData>(this.resourceUrl, paginatorConfig);
  }

  modifyUserAccount(id: number, data: IModifyAccount): Observable<IModifyAccount> {
    return this._http.put<IModifyAccount>(`/admin/accounts/modify/${id}`, data);
  }

  regeneratePassword(id: number, passwordChange: boolean): Observable<IRegeneratePassword> {
    return this._http.put<IRegeneratePassword>(
      `/admin/accounts/regenerate-password/${id}?passwordChangeRequired=${passwordChange}`,
      null
    );
  }

  getUserAccount(id: number): Observable<IModifyAccount> {
    return this._http.get<IModifyAccount>(`/admin/accounts/${id}`);
  }

  createAccount(user: IAdminCreateUserAccount): Observable<IAdminCreateUserAccount> {
    return this._http.post<IAdminCreateUserAccount>('/admin/users', user);
  }

  getLoggedUser(): Observable<number> {
    return this._http.get<number>('/admin/users/loggedUsers');
  }

  hideUser(id: number): Observable<IUserHideAccount> {
    return this._http.put<IUserHideAccount>('/admin/accounts/hide/' + id, { id });
  }

  markAsContactAdmin(newContactAdminId: number): Observable<any> {
    return this._http.put<any>(`/admin/users/contact-admin/${newContactAdminId}`, {
      newContactAdminId
    });
  }
}
