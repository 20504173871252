import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IEmployer } from '@app/interfaces/employer.interface';

@Injectable({
  providedIn: 'root'
})
export class EmployerService {
  private resourceUrl = '/employers';

  constructor(private _http: HttpClient) {}

  updateEmployer(userId: number, employer: IEmployer): Observable<IEmployer> {
    return this._http.put<IEmployer>(`/admin/employers/${userId}`, employer);
  }

  getEmployer(id: number): Observable<any> {
    return this._http.get<any>(this.resourceUrl + '/' + id);
  }
}
