import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DictionariesService = /** @class */ (function () {
    function DictionariesService(_http) {
        this._http = _http;
        this._dictionariesUrl = '/dictionaries/';
    }
    DictionariesService.prototype.getAllAcademicTitles = function () {
        return this._http.get(this._dictionariesUrl + 'academic-titles');
    };
    DictionariesService.prototype.getAllIntelligentSpecialisations = function () {
        return this._http.get(this._dictionariesUrl + 'intelligent-specialisations');
    };
    DictionariesService.prototype.getAllScienceDomains = function () {
        return this._http.get(this._dictionariesUrl + 'science-domains');
    };
    DictionariesService.prototype.getAllSciences = function () {
        return this._http.get(this._dictionariesUrl + 'sciences');
    };
    DictionariesService.prototype.getAllSpecialisations = function () {
        return this._http.get(this._dictionariesUrl + 'specialisations');
    };
    DictionariesService.prototype.getAllCommunes = function () {
        return this._http.get(this._dictionariesUrl + 'communes');
    };
    DictionariesService.prototype.getAllRoles = function () {
        return this._http.get(this._dictionariesUrl + 'roles');
    };
    DictionariesService.prototype.getAllUniversities = function () {
        return this._http.get(this._dictionariesUrl + 'universities');
    };
    DictionariesService.prototype.getAllDepartments = function () {
        return this._http.get(this._dictionariesUrl + 'departments');
    };
    DictionariesService.prototype.getAllPasswordRules = function () {
        return this._http.get('/admin/password-rule');
    };
    DictionariesService.prototype.getAllUsersFromAdmin = function () {
        return this._http.get('/admin/users');
    };
    DictionariesService.prototype.getAllStatuses = function () {
        return of([
            {
                name: 'Dostępny',
                id: 'AVAILABLE'
            },
            {
                name: 'Zajęty',
                id: 'BUSY'
            }
        ]);
    };
    DictionariesService.prototype.getAllLoginMethod = function () {
        return of([
            {
                name: 'Poprzez login',
                id: 'LOGIN'
            },
            {
                name: 'Poprzez e-mail',
                id: 'EMAIL'
            },
            {
                name: 'Poprzez login lub e-mail',
                id: 'LOGIN_EMAIL'
            }
        ]);
    };
    DictionariesService.prototype.getAllKindOfReport = function () {
        return of([
            {
                name: 'Komunikator',
                id: 'COMMUNICATOR'
            },
            {
                name: 'Wyszukiwarka',
                id: 'SEARCH_ENGINE'
            },
            {
                name: 'Profil administratora',
                id: 'ADMIN_PROFILE'
            },
            {
                name: 'Profil naukowca',
                id: 'SCIENTIST_PROFILE'
            },
            {
                name: 'Profil przedsiębiorcy',
                id: 'EMPLOYER_PROFILE'
            },
            {
                name: 'Dziennik zdarzeń',
                id: 'EVENT_LOG'
            }
        ]);
    };
    DictionariesService.prototype.getAllKindUserGenerateReport = function () {
        return of([
            {
                name: 'Główny raport',
                id: 'GENERAL'
            },
            {
                name: 'Raport aktywnych profili',
                id: 'ACTIVE_PROFILES'
            },
            {
                name: 'Raport nieaktywnych profili',
                id: 'INACTIVE_PROFILES'
            }
        ]);
    };
    DictionariesService.prototype.getAllKindOfSearchEntries = function () {
        return of([
            {
                name: 'Liczba pytań',
                id: 'SEARCH_ENTRY_COUNT'
            },
            {
                name: 'Data złożenia pytania',
                id: 'SEARCH_ENTRY_DATE'
            },
            {
                name: 'Liczba nawiązanych współpracy',
                id: 'ESTABLISHED_COOPERATION_COUNT'
            },
            {
                name: 'Liczba aktywnych współpracy po 30 dniach',
                id: 'ACTIVE_COOPERATION_AFTER_30_DAYS_COUNT'
            },
            {
                name: 'Liczba aktywnych współpracy po 6 miesiącach',
                id: 'ACTIVE_COOPERATION_AFTER_6_MONTH_COUNT'
            },
            {
                name: 'Liczba krótkich współpracy trwających mniej niż 30 dni',
                id: 'SHORT_ACTIVE_COOPERATION_COUNT'
            },
            {
                name: 'Liczba współpracy trwających pomiędzy 30 dni a 6 miesięcy',
                id: 'LONG_ACTIVE_COOPERATION_COUNT '
            }
        ]);
    };
    DictionariesService.prototype.getAllUsersBasicInfo = function () {
        return this._http.get('/admin/users/basic-info');
    };
    DictionariesService.prototype.getAllEditableDictionaries = function () {
        return of([
            {
                name: 'Tytuły akademickie',
                id: 'ACADEMIC_TITLE',
                command: this.getAllAcademicTitles(),
                nestedDict: []
            },
            {
                name: 'Gminy',
                id: 'COMMUNES',
                command: this.getAllCommunes(),
                nestedDict: []
            },
            {
                name: 'Wydziały',
                id: 'DEPARTMENTS',
                command: this.getAllDepartments(),
                nestedDict: [
                    {
                        name: 'UNIVERSITIES',
                        field: 'universityId',
                        command: this.getAllUniversities()
                    }
                ]
            },
            {
                name: 'Inteligentna specjalizacja',
                id: 'INTELLIGENT_SPECIALISATION',
                command: this.getAllIntelligentSpecialisations(),
                nestedDict: []
            },
            {
                name: 'Dziedzina',
                id: 'SCIENCE_DOMAIN',
                command: this.getAllScienceDomains(),
                nestedDict: [
                    {
                        name: 'SCIENCES',
                        field: 'scienceId',
                        command: this.getAllSciences()
                    }
                ]
            },
            {
                name: 'Klasyfikacja dziedzin i nauk',
                id: 'SCIENCE',
                command: this.getAllSciences(),
                nestedDict: []
            },
            {
                name: 'Specjalizacje',
                id: 'SPECIALISATION',
                command: this.getAllSpecialisations(),
                nestedDict: [
                    {
                        name: 'SCIENCE-DOMAINS',
                        field: 'scienceDomainId',
                        command: this.getAllScienceDomains()
                    },
                    {
                        name: 'INTELLIGENT-SPECIALISATIONS',
                        field: 'intelligentSpecialisationId',
                        command: this.getAllIntelligentSpecialisations()
                    }
                ]
            },
            {
                name: 'Uczelnie',
                id: 'UNIVERSITY',
                command: this.getAllUniversities(),
                nestedDict: []
            }
        ]);
    };
    DictionariesService.prototype.getSimpleUserStatus = function () {
        return of([
            { label: 'Aktywny', value: true },
            { label: 'Zajęty', value: false }
        ]);
    };
    DictionariesService.prototype.getAllUserStatus = function () {
        return of([
            {
                value: 'ACTIVE',
                label: 'Aktywny'
            },
            {
                value: 'INACTIVE',
                label: 'Nieaktywny'
            },
            {
                value: 'ACTIVE_COOPERATION',
                label: 'Współpracujący'
            },
            {
                value: 'BLOCKED',
                label: 'Zablokowany'
            }
        ]);
    };
    DictionariesService.prototype.getAllLimitedRoles = function () {
        return of([
            {
                id: 1,
                name: 'ADMIN'
            },
            {
                id: 5,
                name: 'LIMITED_EMPLOYER'
            },
            {
                id: 4,
                name: 'LIMITED_SCIENTIST'
            },
            {
                id: 2,
                name: 'SCIENTIST'
            },
            {
                id: 3,
                name: 'EMPLOYER'
            }
        ]);
    };
    DictionariesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DictionariesService_Factory() { return new DictionariesService(i0.ɵɵinject(i1.HttpClient)); }, token: DictionariesService, providedIn: "root" });
    return DictionariesService;
}());
export { DictionariesService };
