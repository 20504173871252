import { Subject } from 'rxjs';
import { merge } from 'lodash';
import { DialogService } from 'primeng/api';
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/dynamicdialog/dialogservice";
var DialogBaseService = /** @class */ (function () {
    function DialogBaseService(dialogService) {
        this.dialogService = dialogService;
        this.dialogInstance = null;
        this._dialogBaseStatus$ = new Subject();
        this._dialogTemplateStatus$ = new Subject();
    }
    DialogBaseService.prototype.setDialogBaseStatus = function (status) {
        this._dialogBaseStatus$.next(status);
    };
    DialogBaseService.prototype.getDialogBaseStatus = function () {
        return this._dialogBaseStatus$.asObservable();
    };
    DialogBaseService.prototype.setSwitchTemplateStatus = function (component, config) {
        var _this = this;
        if (this.dialogInstance) {
            if (config) {
                this.mergeConfig(config);
            }
            this._dialogTemplateStatus$.next(component);
        }
        else {
            this._dialogBaseStatus$.next(true);
            setTimeout(function () {
                if (config) {
                    _this.mergeConfig(config);
                }
                _this._dialogTemplateStatus$.next(component);
            }, 1);
        }
    };
    DialogBaseService.prototype.getSwitchTemplateStatus = function () {
        return this._dialogTemplateStatus$.asObservable();
    };
    DialogBaseService.prototype.createInstance = function (instance) {
        this.dialogInstance = instance;
    };
    DialogBaseService.prototype.getInstance = function () {
        return this.dialogInstance;
    };
    DialogBaseService.prototype.closeModal = function () {
        this.dialogInstance.close();
    };
    DialogBaseService.prototype.mergeConfig = function (config) {
        var previousConfig = this.dialogService.dialogComponentRef.instance.config;
        this.dialogService.dialogComponentRef.instance.config = merge(previousConfig, config);
    };
    DialogBaseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DialogBaseService_Factory() { return new DialogBaseService(i0.ɵɵinject(i1.DialogService)); }, token: DialogBaseService, providedIn: "root" });
    return DialogBaseService;
}());
export { DialogBaseService };
