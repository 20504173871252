import { AccountService } from './../../services/account.service';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { CredentialsService } from './credentials.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "./credentials.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../services/account.service";
/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(credentialsService, _http, accountService) {
        this.credentialsService = credentialsService;
        this._http = _http;
        this.accountService = accountService;
        this.isLogged = new BehaviorSubject(false);
        this.userData$ = new BehaviorSubject(null);
        this.authUrl = '/auth/sign-in';
        this.userData = null;
    }
    /**
     * Authenticates the user.
     * @param context The login parameters.
     * @return The user credentials.
     */
    AuthenticationService.prototype.login = function (username, password) {
        var payload = {
            login: username,
            password: password
        };
        return this._http.post(this.authUrl, payload);
    };
    /**
     * Logs out the user and clear credentials.
     * @return True if the user was logged out successfully.
     */
    AuthenticationService.prototype.logout = function () {
        // Customize credentials invalidation here
        sessionStorage.removeItem('userData');
        this.userData = null;
        this.credentialsService.setCredentials(null, null, true);
        this.isLogged.next(false);
        this.userData$.next(null);
        return of(true);
    };
    AuthenticationService.prototype.getUserRoles = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.userData$.subscribe(function (userData) {
                var userRoles = userData ? userData.roles : [];
                resolve(userRoles);
            });
        });
    };
    AuthenticationService.prototype.getUserData = function () {
        if (!this.userData) {
            this.userData = JSON.parse(sessionStorage.getItem('userData'));
        }
        this.userData$.next(this.userData);
        if (this.credentialsService.isAuthenticated() && !this.userData) {
            this.logout();
            return null;
        }
        return this.userData;
    };
    AuthenticationService.prototype.setUserData = function (userData) {
        this.userData = userData;
        sessionStorage.removeItem('userData');
        sessionStorage.setItem('userData', JSON.stringify(userData));
        if (this.credentialsService.isAuthenticated()) {
            this.isLogged.next(true);
        }
    };
    AuthenticationService.prototype.reloadUserInfo = function () {
        var _this = this;
        var reloadUserData = new Observable(function (observer) {
            _this.accountService.getAccount().subscribe(function (response) {
                _this.setUserData(response);
                observer.next(_this.getUserData());
            });
        });
        return reloadUserData;
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.CredentialsService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.AccountService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
