import { HelperService } from '@app/services/helper.service';
import { IUserChangeEmail } from './../../../interfaces/user-profile.interface';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { IDefaultDict } from './../../../interfaces/dictionary.interface';
import { DictionariesService } from '@app/services/dictionaries.service';
import { Component, OnInit } from '@angular/core';
import { IUserScientist, IUserEmployer, IUserChangePass } from '@app/interfaces/user-profile.interface';
import { UserProfileService } from '@app/services/user-profile.service';
import { CredentialsService, AuthenticationService } from '@app/core';
import { Router } from '@angular/router';
import { SelectItem, Message, MessageService } from 'primeng/api';
import { filter, find, indexOf } from 'lodash';
import { zip, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  providers: [MessageService]
})
export class UserProfileComponent implements OnInit {
  isProfileLoaded = new BehaviorSubject<boolean>(false);
  userData: any;
  isUserScientist: boolean;
  isUserEmployer: boolean;
  isUserScientistFillRequired: boolean;
  isUserEmployerFillRequired: boolean;
  maxTextareaLength = 2000;
  msgs: Message[] = [];

  userScientist: IUserScientist = {
    username: null,
    email: null,
    firstName: null,
    surname: null,
    department: null,
    universityId: null,
    projectsDescription: null,
    scientificWorksDescription: null,
    descriptionOfCooperationWithEmployers: null,
    phoneNumber: null,
    academicTitleId: null,
    keyWords: null,
    numberOfProjects: null,
    leadingSpecialisationId: null,
    intelligentSpecialisationId: null,
    availableForCooperation: true,
    specialisations: [],
    rodoAccepted: false,
    privacyPolicyAccepted: false,
    sendingInformationAccepted: false,
    dataProcessingAccepted: false
  };

  userEmployer: IUserEmployer = {
    id: null,
    username: null,
    email: null,
    companyName: null,
    communeId: null,
    city: null,
    phoneNumber: null,
    intelligentSpecialisationId: null,
    leadingSpecialisationId: null,
    scienceId: null,
    scienceDomainId: null,
    specialisations: [],
    rodoAccepted: false,
    privacyPolicyAccepted: false,
    sendingInformationAccepted: false,
    dataProcessingAccepted: false
  };

  userPassword: IUserChangePass = {
    oldPassword: null,
    password: null,
    repeatedPassword: null
  };

  userEmial: IUserChangeEmail = {
    email: null
  };

  showMoreRequirements = {
    rodoAccepted: false,
    privacyPolicyAccepted: false,
    sendingInformationAccepted: false,
    dataProcessingAccepted: false
  };

  isSelectedAllRequirements = false;

  // Dictionaries model
  academicTitlesDict: SelectItem[];
  universitiesDict: SelectItem[];
  specialisationsDict: SelectItem[];
  intelligentSpecialisationDict: SelectItem[];
  statusesDict: SelectItem[];
  departmentDict: SelectItem[];
  communesDict: SelectItem[];
  tradesDict: SelectItem[];
  sciencesDict: SelectItem[];
  scienceDomainSelectDict: SelectItem[];
  allSpecialisationDict: SelectItem[];
  aditionalSpecialisationDict: SelectItem[];

  scienceDomainsDict: any;
  specialisations: any;
  departments: any;

  isLogged: boolean;

  constructor(
    private userProfileService: UserProfileService,
    private _credentialsService: CredentialsService,
    private _authenticationService: AuthenticationService,
    private dictionaries: DictionariesService,
    private _router: Router,
    private _notificationService: NotificationService,
    private messageService: MessageService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.userData = {};
    this.isLogged = this._credentialsService.isAuthenticated();
    this.getAccountInformation();

    this.msgs = [];
    if (this.userData.profileFillRequired) {
      this.msgs.push({
        severity: 'info',
        summary: 'Uwaga!',
        detail: 'Aby móc korzystać z aplikacji wymagane jest uzupełnienie profilu'
      });
    }

    if (this.userData.passwordChangeRequired) {
      this.msgs.push({
        severity: 'info',
        summary: 'Uwaga!',
        detail: 'Aby móc korzystać z aplikacji wymagana jest zmiana hasła'
      });
    }

    if (this.isUserScientist || this.isUserScientistFillRequired) {
      this.getScientistUserProfile();

      if (this.userData.profileFillRequired) {
        this.addSpecialisation();
      }
    } else if (this.isUserEmployer || this.isUserEmployerFillRequired) {
      this.getEmployerUserProfile();

      if (this.userData.profileFillRequired) {
        this.addSpecialisation();
      }
    }

    // Get common dictionaries
    this._getAllDictionaries();
  }

  isSelectedAll(): boolean {
    if (this.isUserScientistFillRequired) {
      return (
        this.userScientist.privacyPolicyAccepted &&
        this.userScientist.rodoAccepted &&
        this.userScientist.sendingInformationAccepted &&
        this.userScientist.dataProcessingAccepted
      );
    }

    if (this.isUserEmployerFillRequired) {
      return (
        this.userEmployer.privacyPolicyAccepted &&
        this.userEmployer.rodoAccepted &&
        this.userEmployer.sendingInformationAccepted &&
        this.userEmployer.dataProcessingAccepted
      );
    }
  }

  selectAllRequirements(): void {
    if (this.isUserScientistFillRequired) {
      this.userScientist.privacyPolicyAccepted = this.isSelectedAllRequirements;
      this.userScientist.rodoAccepted = this.isSelectedAllRequirements;
      this.userScientist.sendingInformationAccepted = this.isSelectedAllRequirements;
      this.userScientist.dataProcessingAccepted = this.isSelectedAllRequirements;
    }

    if (this.isUserEmployerFillRequired) {
      this.userEmployer.privacyPolicyAccepted = this.isSelectedAllRequirements;
      this.userEmployer.rodoAccepted = this.isSelectedAllRequirements;
      this.userEmployer.sendingInformationAccepted = this.isSelectedAllRequirements;
      this.userEmployer.dataProcessingAccepted = this.isSelectedAllRequirements;
    }
  }

  changeShowMoreLess(type: string): void {
    this.showMoreRequirements[type] = !this.showMoreRequirements[type];
  }

  getAccountInformation() {
    if (this.isLogged) {
      this.userData = this._authenticationService.getUserData();
      this.isUserScientist = !!this.userData.scientistId;
      this.isUserEmployer = !!this.userData.employerId;

      if (this.userData.profileFillRequired) {
        if (indexOf(this.userData.roles, 'LIMITED_SCIENTIST') !== -1) {
          this.isUserScientistFillRequired = true;
          this.userScientist.username = this.userData.username;
          this.userScientist.email = this.userData.email;
        } else if (indexOf(this.userData.roles, 'LIMITED_EMPLOYER') !== -1) {
          this.isUserEmployerFillRequired = true;
          this.userEmployer.username = this.userData.username;
          this.userEmployer.email = this.userData.email;
        }
      }
    }
  }

  getScientistUserProfile() {
    if (this.isUserScientistFillRequired !== true) {
      this.userProfileService.getScientistProfile(this.userData.scientistId).subscribe(scientistProfile => {
        this.userScientist = scientistProfile;
        this.userScientist.availableForCooperation = !!scientistProfile.availableForCooperation;
        if (scientistProfile.specialisations.length === 0) {
          this.addSpecialisation();
        }
        this.isProfileLoaded.next(true);
      });
    } else {
      this.userScientist.id = this.userData.id;
    }
  }

  getEmployerUserProfile() {
    if (this.isUserEmployerFillRequired !== true) {
      this.userProfileService.getEmployerProfile(this.userData.employerId).subscribe(employerProfile => {
        this.userEmployer = employerProfile;
        if (employerProfile.specialisations.length === 0) {
          this.addSpecialisation();
        }
        this.isProfileLoaded.next(true);
      });
    } else {
      this.userEmployer.id = this.userData.id;
    }
  }

  getSciencesAndClasification() {
    if (this.isUserEmployer) {
      const filteredSpecialisation = filter(this.specialisations, specialisation => {
        return (
          specialisation.intelligentSpecialisationId === this.userEmployer.intelligentSpecialisationId &&
          specialisation.id === this.userEmployer.leadingSpecialisationId
        );
      });

      this.userEmployer.scienceDomainId = filteredSpecialisation && filteredSpecialisation[0].scienceDomainId;
      this.userEmployer.scienceId = find(this.scienceDomainsDict, { id: this.userEmployer.scienceDomainId }).scienceId;
    } else if (this.isUserScientist) {
      const filteredSpecialisation = filter(this.specialisations, specialisation => {
        return (
          specialisation.intelligentSpecialisationId === this.userScientist.intelligentSpecialisationId &&
          specialisation.id === this.userScientist.leadingSpecialisationId
        );
      });

      this.userScientist.scienceDomainId = filteredSpecialisation && filteredSpecialisation[0].scienceDomainId;
      this.userScientist.scienceId = find(this.scienceDomainsDict, {
        id: this.userScientist.scienceDomainId
      }).scienceId;
    }
  }

  changeIntelligentSpecialisation() {
    if (this.isUserScientist || this.isUserScientistFillRequired) {
      const findInteligentSpecialisation = find(this.specialisations, [
        'id',
        this.userScientist.leadingSpecialisationId
      ]);
      this.userScientist.intelligentSpecialisationId = findInteligentSpecialisation.intelligentSpecialisationId;
    } else if (this.isUserEmployer || this.isUserEmployerFillRequired) {
      const findInteligentSpecialisation = find(this.specialisations, [
        'id',
        this.userEmployer.leadingSpecialisationId
      ]);
      this.userEmployer.intelligentSpecialisationId = findInteligentSpecialisation.intelligentSpecialisationId;
    }
  }

  keyWordsOnKeyDown(event: KeyboardEvent) {
    if (event.key === ',') {
      event.preventDefault();
      const element = event.target as HTMLElement;
      element.blur();
      element.focus();
    }
  }

  addSpecialisation() {
    const emptySpecialisation: any = {
      id: null,
      name: null
    };

    if (this.isUserScientist || this.isUserScientistFillRequired) {
      this.userScientist.specialisations.push(emptySpecialisation);
    } else if (this.isUserEmployer || this.isUserEmployerFillRequired) {
      this.userEmployer.specialisations.push(emptySpecialisation);
    }
  }

  reloadUserData() {
    this._authenticationService.reloadUserInfo().subscribe(userData => {
      this.userData = userData;
      this.ngOnInit();
      this.getAccountInformation();
    });
  }

  removeEmptySpecialisation() {
    if (this.isUserScientist || this.isUserScientistFillRequired) {
      this.userScientist.specialisations = this.userScientist.specialisations.filter(specialisation => {
        return specialisation.id !== null;
      });

      if (
        !this.userScientist.specialisations ||
        (this.userScientist.specialisations && !this.userScientist.specialisations.length)
      ) {
        this.userScientist.specialisations = [];
      }
    } else if (this.isUserEmployer || this.isUserEmployerFillRequired) {
      this.userEmployer.specialisations = this.userEmployer.specialisations.filter(specialisation => {
        return specialisation.id !== null;
      });

      if (
        !this.userEmployer.specialisations ||
        (this.userEmployer.specialisations && !this.userEmployer.specialisations.length)
      ) {
        this.userEmployer.specialisations = [];
      }
    }
  }

  showUpdateAlert(_accountType: string, resp: any) {
    this._notificationService.sendMessage({
      type: 'success',
      title: 'Aktualizacja konta',
      message: `Pomyślnie zaktualizowano profil ${_accountType === 'scientist' ? 'naukowca' : 'przedsiębiorcy'}`
    });
    this.reloadUserData();
    if (resp.specialisations.length === 0) {
      this.addSpecialisation();
    }
  }

  save() {
    this.removeEmptySpecialisation();

    if (this.isUserScientistFillRequired) {
      this.userProfileService.updateScientistLimitedProfile(this.userData.id, this.userScientist).subscribe(resp => {
        this.showUpdateAlert('scientist', resp);
      });
    } else if (this.isUserScientist) {
      this.userProfileService.updateScientistProfile(this.userData.id, this.userScientist).subscribe(resp => {
        this.showUpdateAlert('scientist', resp);
      });
    } else if (this.isUserEmployerFillRequired) {
      this.userEmployer.id = this.userData.id;
      this.userProfileService.updateEmployerLimitedProfile(this.userData.id, this.userEmployer).subscribe(resp => {
        this.showUpdateAlert('employer', resp);
      });
    } else if (this.isUserEmployer) {
      this.userEmployer.id = this.userData.id;
      this.userProfileService.updateEmployerProfile(this.userData.id, this.userEmployer).subscribe(resp => {
        this.showUpdateAlert('employer', resp);
      });
    }
  }

  changePassword() {
    this.userProfileService.changePassword(this.userData.id, this.userPassword).subscribe(resp => {
      this._notificationService.sendMessage({
        type: 'success',
        title: 'Aktualizacja konta',
        message: 'Hasło zmienione pomyślnie'
      });

      this.reloadUserData();
    });
  }

  changeEmail() {
    this.userProfileService.changeEmail(this.userData.id, this.userEmial).subscribe(resp => {
      this._notificationService.sendMessage({
        type: 'success',
        title: 'Aktualizacja konta',
        message: 'Zmiana adresu e-mail przebiegła pomyślnie'
      });

      this.reloadUserData();
    });
  }

  filteredDepartmentByUniversity(universityId: number) {
    if (this.departments) {
      this.departmentDict = (this.departments as any[])
        .filter((department: any) => {
          return department.universityId === universityId;
        })
        .map((department: any) => {
          return {
            label: department.name,
            value: department.name
          };
        });
    }
  }

  isOtherCommune(communeId: number) {
    return this.helperService.checkIsOtherCommune(communeId);
  }

  setInteligentSpecialisation(specialisationId: number) {
    if (specialisationId) {
      const specialisation = find(this.specialisations, { id: specialisationId });
      if (specialisation) {
        const intelligentSpecialisation: any = find(this.intelligentSpecialisationDict, {
          value: specialisation.intelligentSpecialisationId
        });
        if (this.isUserEmployer || this.isUserEmployerFillRequired) {
          this.userEmployer.intelligentSpecialisationId = intelligentSpecialisation.value;
        } else if (this.isUserScientist || this.isUserScientistFillRequired) {
          this.userScientist.intelligentSpecialisationId = intelligentSpecialisation.value;
        }
      }
      this.aditionalSpecialisationDict = this.aditionalSpecialisationDict.filter((itemDict: SelectItem) => {
        return itemDict.value !== specialisationId;
      });
    }
  }

  changeModelScienceDomainDict(scienceId: number) {
    this.userScientist.scienceDomainId = null;
    this.userScientist.leadingSpecialisationId = null;
    this.userEmployer.scienceDomainId = null;
    this.userEmployer.leadingSpecialisationId = null;

    this.scienceDomainSelectDict = this.mapScienceDomains(scienceId);
  }

  changeModelSpecialisationDict(scienceDomainId: number) {
    this.userScientist.leadingSpecialisationId = null;
    this.userEmployer.leadingSpecialisationId = null;

    this.specialisationsDict = this.mapSpecialisations(scienceDomainId);
    this.aditionalSpecialisationDict = this.mapSpecialisations(scienceDomainId);
  }

  mapScienceDomains(scienceId: number): SelectItem[] {
    if (this.scienceDomainsDict && scienceId) {
      return this.scienceDomainsDict
        .filter((scienceDomain: any) => {
          return scienceDomain.scienceId === scienceId;
        })
        .map((scienceDomain: any) => {
          return {
            label: scienceDomain.name,
            value: scienceDomain.id
          };
        });
    } else {
      return null;
    }
  }

  mapSpecialisations(scienceDomainId: number): SelectItem[] {
    if (this.specialisations && scienceDomainId) {
      return this.specialisations
        .filter((specialisation: any) => {
          return specialisation.scienceDomainId === scienceDomainId;
        })
        .map((specialisation: any) => {
          return {
            label: specialisation.name,
            value: specialisation.id
          };
        });
    } else {
      return null;
    }
  }

  // Dictionaries
  _getAllDictionaries() {
    zip(
      this.dictionaries.getAllAcademicTitles(),
      this.dictionaries.getAllUniversities(),
      this.dictionaries.getAllScienceDomains(),
      this.dictionaries.getAllSciences(),
      this.dictionaries.getAllSpecialisations(),
      this.dictionaries.getAllIntelligentSpecialisations(),
      this.dictionaries.getSimpleUserStatus(),
      this.dictionaries.getAllDepartments(),
      this.dictionaries.getAllCommunes()
    ).subscribe(
      ([
        AcademicTitles,
        Universities,
        ScienceDomains,
        Sciences,
        Specialisations,
        IntelligentSpecialisations,
        Statuses,
        Departments,
        Communes
      ]) => {
        this.academicTitlesDict = (AcademicTitles as IDefaultDict[]).map(academicTitle => {
          return {
            label: academicTitle.name,
            value: academicTitle.id
          };
        });
        this.universitiesDict = (Universities as IDefaultDict[]).map(university => {
          return {
            label: university.name,
            value: university.id
          };
        });
        this.sciencesDict = (Sciences as IDefaultDict[]).map(sciences => {
          return {
            label: sciences.name,
            value: sciences.id
          };
        });
        this.scienceDomainSelectDict = (ScienceDomains as IDefaultDict[]).map(scienceDomain => {
          return {
            label: scienceDomain.name,
            value: scienceDomain.id
          };
        });
        this.specialisationsDict = (Specialisations as IDefaultDict[]).map(specialisation => {
          return {
            label: specialisation.name,
            value: specialisation.id
          };
        });

        this.allSpecialisationDict = (Specialisations as IDefaultDict[]).map(spec => {
          return {
            label: spec.name,
            value: spec.id
          };
        });

        this.intelligentSpecialisationDict = (IntelligentSpecialisations as IDefaultDict[]).map(
          intelligentSpecialisation => {
            return {
              label: intelligentSpecialisation.name,
              value: intelligentSpecialisation.id
            };
          }
        );

        this.statusesDict = Statuses as SelectItem[];

        this.communesDict = (Communes as IDefaultDict[]).map(commune => {
          return {
            label: commune.name,
            value: commune.id
          };
        });

        // All dictionaries
        this.specialisations = Specialisations;
        this.scienceDomainsDict = ScienceDomains;
        this.departments = Departments;

        this.isProfileLoaded.subscribe((isLoaded: boolean) => {
          if (isLoaded) {
            this.getSciencesAndClasification();

            if (this.isUserScientist) {
              this.scienceDomainSelectDict = this.mapScienceDomains(this.userScientist.scienceId);
              this.specialisationsDict = this.mapSpecialisations(this.userScientist.scienceDomainId);
              this.aditionalSpecialisationDict = this.mapSpecialisations(this.userScientist.scienceDomainId);
              this.setInteligentSpecialisation(this.userScientist.leadingSpecialisationId);

              if (this.userScientist.universityId) {
                this.filteredDepartmentByUniversity(this.userScientist.universityId);
              }
            } else {
              this.scienceDomainSelectDict = this.mapScienceDomains(this.userEmployer.scienceId);
              this.specialisationsDict = this.mapSpecialisations(this.userEmployer.scienceDomainId);
              this.aditionalSpecialisationDict = this.mapSpecialisations(this.userEmployer.scienceDomainId);
              this.setInteligentSpecialisation(this.userEmployer.leadingSpecialisationId);
            }
          }
        });
      }
    );
  }
}
