import { ConfigurationAppComponent } from './configuration-app.component';
import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { AuthenticationGuard } from '@app/core/authentication/authentication.guard';
var ɵ0 = { title: extract('Konfiguracja aplikacji'), expectedRole: ['ADMIN', 'CONTACT_ADMIN'] };
var routes = [
    Shell.childRoutes([
        {
            path: 'configuration-app',
            component: ConfigurationAppComponent,
            data: ɵ0,
            canActivate: [AuthenticationGuard]
        }
    ])
];
var ConfigurationAppRoutingModule = /** @class */ (function () {
    function ConfigurationAppRoutingModule() {
    }
    return ConfigurationAppRoutingModule;
}());
export { ConfigurationAppRoutingModule };
export { ɵ0 };
