<div class="container">
  <div class="row">
    <div class="col-12">
      <h2 class="text-center">Nie znaleziono strony.</h2>
    </div>
    <div class="col-12">
      <p class="text-center pt-4">
        Nie udało się odnaleźć wskazanej strony. <a routerLink="/search">Kliknij tutaj</a>, aby powrócić na stronę
        główną.
      </p>
    </div>
  </div>
</div>
