import { AuthenticationService } from './../../../core/authentication/authentication.service';
import { DictionariesService } from '@app/services/dictionaries.service';
import { IDefaultDict } from './../../../interfaces/dictionary.interface';
import { TranslateService } from '@ngx-translate/core';
import { DictionaryService } from './../../../services/dictionary.service';
import { IPaginatorConfig } from '@app/interfaces/table.interface';
import { TableService } from './../../../services/table.service';
import { UserManagementService } from './../../../services/user-management.service';
import { ITableInterface, ITableData } from '../../../interfaces/table.interface';
import {
  IUserManagementTable,
  IUserManagementFilters,
  RolesIdentifiersEntity
} from '../../../interfaces/user-management.interface';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
import { Message, MessageService, SelectItem, ConfirmationService } from 'primeng/api';
import { map } from 'rxjs/operators';
import { some, join } from 'lodash';
import { NotificationService } from '@app/shared/notifications/notification.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class UserManagementComponent implements OnInit, OnDestroy {
  ref: any;
  userTableData: IUserManagementTable[] = null;
  userTableCols: ITableInterface[];
  paginator: IPaginatorConfig = null;
  msgs: Message[] = [];
  filters: IUserManagementFilters = {
    id: null,
    displayName: null,
    companyName: null,
    status: null,
    username: null,
    role: null,
    email: null,
    enabled: null,
    firstName: null,
    surname: null
  };

  numberLoggedUsers = 1;
  actualUserData: any;

  // Options for select
  rolesDict: SelectItem[];
  userEnabledDict: SelectItem[];

  constructor(
    private userManagementService: UserManagementService,
    private _dialogBaseService: DialogBaseService,
    private tableService: TableService,
    private translateService: TranslateService,
    private dictionaryService: DictionaryService,
    private dictionariesService: DictionariesService,
    private _authenticationService: AuthenticationService,
    private _notificationService: NotificationService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnDestroy(): void {
    this.tableService.restorePaginatorConfig();
  }

  ngOnInit() {
    this.actualUserData = this._authenticationService.getUserData();

    this.getUserData();

    this.userTableCols = [
      { field: 'id', header: 'ID' },
      { field: 'username', header: 'Login' },
      { field: 'email', header: 'E-mail' },
      { field: 'displayName', header: 'Imię i Nazwisko / Firma' },
      { field: 'rolesIdentifiers', header: 'Rola / funkcja', custom: 'isUserRole', disabledSort: true },
      { field: 'status', header: 'Status', custom: 'accountStatus' },
      {
        field: 'registrationTime',
        header: 'Data rejestracji',
        custom: 'isDate',
        sortProperty: 'createTime'
      },
      {
        field: 'lastModificationTime',
        header: 'Data ostatniej zmiany',
        custom: 'isDate',
        sortProperty: 'modificationTime'
      },
      { field: 'lastLoginTime', header: 'Data ostatniego logowania', custom: 'isDate' }
    ];

    this.getRoleDict().then((userRolesDict: any) => {
      this.rolesDict = userRolesDict;
    });
    this.getUserEnabledDict();
    this.getActualLoggedUser();
  }

  getActualLoggedUser() {
    this.userManagementService.getLoggedUser().subscribe((numberOfUsers: number) => {
      this.numberLoggedUsers = numberOfUsers;
    });
  }

  showUserRoles(userData: RolesIdentifiersEntity[]): string {
    const roles = userData.map(role => {
      return this.translateService.instant('userRoles.' + role.name);
    });
    return join(roles, ', ');
  }

  getUserData(isFilter: boolean = false): void {
    if (isFilter) {
      this.tableService.restorePaginatorConfig();
    }

    this.userManagementService
      .getUsers(this.tableService.prepareParamsToServer(this.filters))
      .subscribe((userData: ITableData) => {
        this.userTableData = userData.content;
        this.tableService.setPaginatorConfigFormServer(userData);
        this.paginator = this.tableService.getPaginatorConfig();

        if (!userData.content.length) {
          this.showEmptyDataAlert();
        }
      });
  }

  async getRoleDict(): Promise<any> {
    await this.translateService.get('userRoles').toPromise();

    const promise = new Promise(resolve => {
      let userRoles = null;
      this.dictionaryService.getRoles().subscribe((roles: any) => {
        userRoles = (roles as IDefaultDict[]).map(role => {
          return {
            label: this.translateService.instant('userRoles.' + role.name),
            value: role.name
          };
        });

        resolve(userRoles);
      });
    });

    return promise;
  }

  getUserEnabledDict(): void {
    this.dictionariesService.getAllUserStatus().subscribe((statuses: SelectItem[]) => {
      this.userEnabledDict = statuses;
    });
  }

  clearFilter() {
    this.filters = {
      id: null,
      displayName: null,
      companyName: null,
      status: null,
      username: null,
      role: null,
      email: null,
      enabled: null,
      firstName: null,
      surname: null
    };
  }

  paginate(paginatorEvent: any): void {
    const currentPaginator = this.tableService.getPaginatorConfig();
    const paginator = {
      direction: currentPaginator.direction,
      page: paginatorEvent.page,
      size: paginatorEvent.rows,
      properties: currentPaginator.properties,
      sort: currentPaginator.sort
    };
    this.tableService.setPaginatorConfig(paginator);
    this.getUserData();
  }

  customSort(event: any) {
    if (event.sortField) {
      const currentPaginator = this.tableService.getPaginatorConfig();
      const paginator = {
        direction: event.sortOrder === -1 ? 'DESC' : 'ASC',
        page: currentPaginator.page,
        size: currentPaginator.size,
        properties: [event.sortField],
        sort: true
      };
      this.tableService.setPaginatorConfig(paginator);
      this.getUserData();
    }
  }

  showEmptyDataAlert(): void {
    this.msgs = [];
    this.msgs.push({ severity: 'info', summary: 'Informacja', detail: 'Brak danych' });
  }

  showModal(user: any = null, edit: string = ''): void {
    this._dialogBaseService.setSwitchTemplateStatus('CreateAccountComponent', {
      data: {
        editMode: !!edit,
        id: user ? user.id : null
      },
      closable: true,
      styleClass: 'big-modal',
      header: edit ? 'Edytuj konto użytkownika' : 'Dodaj konto użytkownika',
      contentStyle: { overflow: 'inherit' }
    });

    this.ref = this._dialogBaseService.getInstance();
    this.ref.onClose.subscribe(() => {
      this.getUserData();
    });
  }

  checkIsUserAdmin(user: any): boolean {
    return some(user.rolesIdentifiers, (role: RolesIdentifiersEntity) => {
      return role.name === 'ADMIN' || role.name === 'CONTACT_ADMIN';
    });
  }

  regeneratePassword(user: any) {
    this._dialogBaseService.setSwitchTemplateStatus('RegeneratePasswordComponent', {
      data: {
        userId: user.id
      },
      closable: true,
      header: 'Regeneracja hasła',
      styleClass: 'small-modal'
    });
  }

  hideUser(user: IUserManagementTable): void {
    this.confirmationService.confirm({
      message: `Czy na pewno chcesz usunąć użytkownika ${user.displayName || user.username}?`,
      accept: () => {
        this.userManagementService.hideUser(user.id).subscribe(resp => {
          this._notificationService.sendMessage({
            type: 'success',
            title: 'Aktualizacja konta',
            message: 'Pomyślnie oznaczono konto jako usunięte'
          });
          this.getUserData();
        });
      }
    });
  }

  editUser(user: any, isPreviewMode: string = ''): void {
    const isScientist = some(user.rolesIdentifiers, rolesIdentifier => {
      return rolesIdentifier.name === 'SCIENTIST' || rolesIdentifier.name === 'LIMITED_SCIENTIST';
    });

    const isEmployer = some(user.rolesIdentifiers, rolesIdentifier => {
      return rolesIdentifier.name === 'EMPLOYER' || rolesIdentifier.name === 'LIMITED_EMPLOYER';
    });

    if (isScientist) {
      this._dialogBaseService.setSwitchTemplateStatus('ScientistRegistrationComponent', {
        data: {
          id: user.scientistId,
          userId: user.id,
          preview: !!isPreviewMode
        },
        closable: true,
        header: 'Edycja naukowca',
        styleClass: 'medium-modal'
      });
    } else if (isEmployer) {
      this._dialogBaseService.setSwitchTemplateStatus('EmployerRegistrationComponent', {
        data: {
          id: user.employerId,
          userId: user.id,
          preview: !!isPreviewMode
        },
        closable: true,
        header: 'Edycja przedsiębiorcy',
        styleClass: 'medium-modal'
      });
    }

    this.ref = this._dialogBaseService.getInstance();
    this.ref.onClose.subscribe(() => {
      this.getUserData();
    });
  }

  checkUserRole(userData: any, userRole: string, forceRole: boolean = false) {
    if (!forceRole) {
      return userData.rolesIdentifiers.some((role: any) => {
        return role.name === userRole;
      });
    } else {
      return userData.rolesIdentifiers.length === 1 && userData.rolesIdentifiers[0].name === userRole;
    }
  }

  markAsContactAdmin(id: number) {
    this.userManagementService.markAsContactAdmin(id).subscribe(() => {
      this._notificationService.sendMessage({
        type: 'success',
        title: 'Aktualizacja konta',
        message: 'Pomyślnie oznaczono konto jako administrator kontaktowy'
      });
      this.getUserData();
    });
  }
}
