import { of, Observable, BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var WcagService = /** @class */ (function () {
    function WcagService() {
        this.removeImages = new BehaviorSubject(false);
        this.isContrastVesrion = new BehaviorSubject(false);
    }
    WcagService.prototype.setWcagConfig = function (websiteModeName, modeValue) {
        localStorage.removeItem(websiteModeName);
        var preparedMode = JSON.stringify(modeValue);
        localStorage.setItem(websiteModeName, preparedMode);
    };
    WcagService.prototype.getWcagConfig = function (websiteModeName) {
        var getItemFromStorage = localStorage.getItem(websiteModeName);
        if (getItemFromStorage) {
            return of(JSON.parse(getItemFromStorage));
        }
        else {
            return of(null);
        }
    };
    WcagService.prototype.setRemoveImages = function (value) {
        this.removeImages.next(value);
    };
    WcagService.prototype.setContrastVersion = function (value) {
        this.isContrastVesrion.next(value);
    };
    WcagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WcagService_Factory() { return new WcagService(); }, token: WcagService, providedIn: "root" });
    return WcagService;
}());
export { WcagService };
