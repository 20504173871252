import { MailNotificationService } from './../../../services/mail-notification.service';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

@Component({
  selector: 'app-mail-notification',
  templateUrl: './mail-notification.component.html',
  styleUrls: ['./mail-notification.component.scss']
})
export class MailNotificationComponent implements OnInit {
  constructor(
    private _notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private mailNotificationService: MailNotificationService
  ) {}

  ngOnInit() {
    this.route.url.subscribe(url => {
      if (url[1] && url[1].path === 'cooperation') {
        this.route.paramMap.subscribe((params: ParamMap) => {
          const tokenCooperation = params.get('token');
          if (tokenCooperation) {
            this.route.queryParamMap.subscribe((queryParams: ParamMap) => {
              const confirmCooperation = queryParams.get('confirm') === 'true';
              this.confirmCooperation(tokenCooperation, confirmCooperation);
            });
          }
        });
      }
    });
  }

  confirmCooperation(token: string, confirm: boolean) {
    this.mailNotificationService.confirmCooperation(token, confirm).subscribe(
      () => {
        this._notificationService.sendMessage({
          type: 'success',
          title: 'Informacja',
          message: 'Odpowiedź zapisana pomyślnie'
        });
        this.router.navigate(['/search']);
      },
      () => {
        this.router.navigate(['/search']);
      }
    );
  }
}
