import { TranslateService } from '@ngx-translate/core';
import { ConfigurationAppService } from './../../../../services/configuration-app.service';
import { IPasswordRule } from './../../../../interfaces/configuration-app.interface';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, SelectItem } from 'primeng/api';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';

@Component({
  selector: 'app-password-rule-dialog',
  templateUrl: './password-rule-dialog.component.html',
  styleUrls: ['./password-rule-dialog.component.scss']
})
export class PasswordRuleDialogComponent implements OnInit {
  users: SelectItem[];
  passwordRule: IPasswordRule = {
    minPasswordLength: null,
    minCapitalLetters: null,
    minDigit: null,
    minSpecialChar: null,
    name: null,
    numberOfPreviousPasswordsToCheck: null,
    passwordChangeReminder: null,
    passwordExpirationTimeInDays: null,
    requiredPasswordChangeAtFirstLogin: null
  };

  isPreviewMode = false;
  isEditMode = false;
  passwordRuleId: number;

  constructor(
    public config: DynamicDialogConfig,
    private _notificationService: NotificationService,
    private _dialogService: DialogBaseService,
    private configurationAppService: ConfigurationAppService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (this.config.data) {
      this.isPreviewMode = this.config.data.preview;
      this.isEditMode = !!this.config.data.id;
      this.passwordRuleId = this.config.data.id;
      this.users = this.config.data.usersDict;
    }

    if (this.isEditMode) {
      this.configurationAppService.getPasswordRule(this.passwordRuleId).subscribe((passwordRule: IPasswordRule) => {
        this.passwordRule = passwordRule;
        this.passwordRule.name = this.translateService.instant(passwordRule.name);
        this.passwordRule.usersId = passwordRule.userData.map(passRule => {
          return passRule.id;
        });
      });
    }
  }

  savePasswordRule(): void {
    if (this.isEditMode && this.passwordRule.id) {
      this.configurationAppService.updatePasswordRule(this.passwordRule, this.passwordRule.id).subscribe(() => {
        this._dialogService.closeModal();
        this._notificationService.sendMessage({
          type: 'success',
          title: 'Edycja reguły',
          message: 'Pomyślnie edytowano regułę'
        });
      });
    } else {
      this.configurationAppService.createPasswordRule(this.passwordRule).subscribe(() => {
        this._dialogService.closeModal();
        this._notificationService.sendMessage({
          type: 'success',
          title: 'Dodanie reguły',
          message: 'Pomyślnie dodano regułę'
        });
      });
    }
  }
}
