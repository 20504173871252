import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DialogService } from 'primeng/api';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// tslint:disable-next-line: max-line-length
import { DialogDynamicTemplateComponent } from '@app/shared/dialog/dialog-dynamic-template/dialog-dynamic-template.component';

@Component({
  selector: 'app-dialog-base',
  templateUrl: './dialog-base.component.html',
  styleUrls: ['./dialog-base.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogBaseComponent implements OnInit, OnDestroy {
  ref: any;
  private _dialogBaseSubscription$: Subject<any> = new Subject();

  constructor(public dialogService: DialogService, public _dialogBaseService: DialogBaseService) {}

  ngOnInit(): void {
    this.subscribeToDialogBaseStatus();
  }

  ngOnDestroy(): void {
    this._dialogBaseSubscription$.next();
    this._dialogBaseSubscription$.complete();
  }

  showDialog() {
    this.ref = this.dialogService.open(DialogDynamicTemplateComponent, {
      closable: true,
      header: '',
      width: '50%',
      baseZIndex: 100001,
      contentStyle: { 'max-height': '80vh', overflow: 'auto' }
    });

    this._dialogBaseService.createInstance(this.ref);
    this.ref.onClose.subscribe(() => {
      this._dialogBaseService.createInstance(null);
    });
  }

  private subscribeToDialogBaseStatus() {
    this._dialogBaseService
      .getDialogBaseStatus()
      .pipe(takeUntil(this._dialogBaseSubscription$))
      .subscribe(() => {
        this.showDialog();
      });
  }
}
