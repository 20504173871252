import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDefaultDict } from '@app/interfaces/dictionary.interface';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {
  private resourceUrl = '/dictionaries/roles';

  constructor(private _http: HttpClient) {}

  getRoles(): Observable<IDefaultDict[]> {
    return this._http.get<IDefaultDict[]>(this.resourceUrl);
  }
}
