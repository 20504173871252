/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./dialog-dynamic-template.component";
import * as i2 from "../dialog-base.service";
var styles_DialogDynamicTemplateComponent = [];
var RenderType_DialogDynamicTemplateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogDynamicTemplateComponent, data: {} });
export { RenderType_DialogDynamicTemplateComponent as RenderType_DialogDynamicTemplateComponent };
function View_DialogDynamicTemplateComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_DialogDynamicTemplateComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { childContainer: 0 }), (_l()(), i0.ɵand(16777216, [[1, 3], ["childContainer", 2]], null, 0, null, View_DialogDynamicTemplateComponent_1)), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, null); }
export function View_DialogDynamicTemplateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_DialogDynamicTemplateComponent_0, RenderType_DialogDynamicTemplateComponent)), i0.ɵdid(1, 114688, null, 0, i1.DialogDynamicTemplateComponent, [i0.ComponentFactoryResolver, i2.DialogBaseService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogDynamicTemplateComponentNgFactory = i0.ɵccf("ng-component", i1.DialogDynamicTemplateComponent, View_DialogDynamicTemplateComponent_Host_0, {}, {}, []);
export { DialogDynamicTemplateComponentNgFactory as DialogDynamicTemplateComponentNgFactory };
