import { PrivacyPolicyModule } from './privacy-policy/privacy-policy.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegulationsModule } from './regulations/regulations.module';
import { DeclarationAvailabilityModule } from './declaration-availability/declaration-availability.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, PrivacyPolicyModule, RegulationsModule, DeclarationAvailabilityModule]
})
export class StaticFooterModule {}
