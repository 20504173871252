import { Injectable } from '@angular/core';
import { CredentialsService, AuthenticationService } from '@app/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private _accountUrl = '/accounts/info';

  constructor(private _http: HttpClient) {}

  getAccount(): Observable<object> {
    return this._http.get<object>(this._accountUrl);
  }
}
