import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { UserManagementComponent } from './user-management.component';
import { AuthenticationGuard } from '@app/core/authentication/authentication.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'user-management',
      component: UserManagementComponent,
      data: { title: extract('Zarządzanie użytkownikami'), expectedRole: ['ADMIN', 'CONTACT_ADMIN'] },
      canActivate: [AuthenticationGuard]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class UserManagementRoutingModule {}
