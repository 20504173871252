import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CredentialsService, AuthenticationService } from '@app/core';
import { LoaderService } from '@app/shared/loader/loader.service';
import { delay, finalize, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public credentialsService: CredentialsService,
    private _loaderService: LoaderService,
    private _authenticationService: AuthenticationService,
    private router: Router
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._loaderService.setLoderStatus(true);
    if (this.credentialsService.isAuthenticated()) {
      const credentials = this.credentialsService.credentials();
      request = request.clone({
        setHeaders: {
          Authorization: `${credentials.accessToken}`
        }
      });
    }
    return next.handle(request).pipe(
      delay(500),
      finalize(() => this._loaderService.setLoderStatus(false)),
      tap(
        () => {},
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this._authenticationService.logout().subscribe(isLogouted => {
                if (isLogouted) {
                  this.router.navigate(['search'], { replaceUrl: true });
                }
              });
            }
          }
        }
      )
    );
  }
}
