import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnowledgeTransferCentersComponent } from './knowledge-transfer-centers.component';
import { KnowledgeTransferCentersRoutingModule } from './knowledge-transfer-centers-routing.module';
import { WcagModule } from '@app/shared/wcag/wcag.module';

@NgModule({
  declarations: [KnowledgeTransferCentersComponent],
  imports: [CommonModule, WcagModule, KnowledgeTransferCentersRoutingModule]
})
export class KnowledgeTransferCentersModule {}
