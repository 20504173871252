/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/primeng/components/toast/toast.ngfactory";
import * as i2 from "primeng/components/toast/toast";
import * as i3 from "primeng/components/common/messageservice";
import * as i4 from "./notifications.component";
import * as i5 from "./notification.service";
import * as i6 from "@ngx-translate/core";
var styles_NotificationsComponent = [];
var RenderType_NotificationsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificationsComponent, data: {} });
export { RenderType_NotificationsComponent as RenderType_NotificationsComponent };
export function View_NotificationsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "p-toast", [["baseZIndex", "99999"]], null, null, null, i1.View_Toast_0, i1.RenderType_Toast)), i0.ɵdid(1, 1294336, null, 1, i2.Toast, [i3.MessageService], { baseZIndex: [0, "baseZIndex"] }, null), i0.ɵqud(603979776, 1, { templates: 1 }), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = "99999"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NotificationsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-notifications", [], null, null, null, View_NotificationsComponent_0, RenderType_NotificationsComponent)), i0.ɵprd(512, null, i3.MessageService, i3.MessageService, []), i0.ɵdid(2, 245760, null, 0, i4.NotificationsComponent, [i3.MessageService, i5.NotificationService, i6.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var NotificationsComponentNgFactory = i0.ɵccf("app-notifications", i4.NotificationsComponent, View_NotificationsComponent_Host_0, {}, {}, []);
export { NotificationsComponentNgFactory as NotificationsComponentNgFactory };
