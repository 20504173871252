import { IDefaultDict } from '../../../interfaces/dictionary.interface';
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { DictionariesService } from '@app/services/dictionaries.service';
import { combineLatest } from 'rxjs';
import { DynamicDialogConfig, SelectItem } from 'primeng/api';
import { IAdminCreateUserAccount, IModifyAccount } from '@app/interfaces/user-management.interface';
import { UserManagementService } from '@app/services/user-management.service';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';

@Component({
  selector: 'app-regenerate-password',
  templateUrl: './regenerate-password.component.html',
  styleUrls: ['./regenerate-password.component.scss']
})
export class RegeneratePasswordComponent implements OnInit {
  userAccountId: number;
  regeneratePass = false;
  constructor(
    private _dictionariesService: DictionariesService,
    private userManagementService: UserManagementService,
    private _notificationService: NotificationService,
    private _dialogService: DialogBaseService,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    if (this.config && this.config.data) {
      this.userAccountId = this.config.data.userId;
    } else {
      this._dialogService.closeModal();
    }
  }

  regeneratePassword() {
    this.userManagementService.regeneratePassword(this.userAccountId, this.regeneratePass).subscribe(resp => {
      this._notificationService.sendMessage({
        type: 'success',
        title: 'Aktualizacja konta',
        message: 'Pomyślnie wygenerowano nowe hasło dla użytkownika'
      });
      this._dialogService.closeModal();
    });
  }
}
