import { UserProfileRoutingModule } from './user-profile-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileComponent } from './user-profile.component';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ChipsModule } from 'primeng/chips';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { MessagesModule } from 'primeng/messages';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { WcagModule } from '@app/shared/wcag/wcag.module';

@NgModule({
  declarations: [UserProfileComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    WcagModule,
    FormsModule,
    DropdownModule,
    ChipsModule,
    CalendarModule,
    TooltipModule,
    MessagesModule,
    UserProfileRoutingModule
  ]
})
export class UserProfileModule {}
