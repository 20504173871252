import { TooltipModule } from 'primeng/tooltip';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AccountTypeSelectionComponent } from './account-type-selection/account-type-selection.component';
import { ScientistRegistrationComponent } from './scientist-registration/scientist-registration.component';
import { EmployerRegistrationComponent } from './employer-registration/employer-registration.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { CreateAccountComponent } from './create-account/create-account.component';
import { CalendarModule } from 'primeng/calendar';
import { ChipsModule } from 'primeng/chips';
import { RegeneratePasswordComponent } from './regenerate-password/regenerate-password.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    AccountTypeSelectionComponent,
    ScientistRegistrationComponent,
    EmployerRegistrationComponent,
    CreateAccountComponent,
    RegeneratePasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MultiSelectModule,
    InputSwitchModule,
    CheckboxModule,
    CalendarModule,
    ChipsModule,
    TranslateModule,
    TooltipModule,
    DropdownModule
  ]
})
export class RegistrationDialogsModule {}
