import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DictionariesService } from '@app/services/dictionaries.service';
import { RegisterService } from '@app/services/register.service';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { HelperService } from '@app/services/helper.service';
import { Router } from '@angular/router';
import { zip } from 'rxjs';
import { find } from 'lodash';
var ScientistRegisterComponent = /** @class */ (function () {
    function ScientistRegisterComponent(_dictionariesService, registerService, _notificationService, helperService, router) {
        this._dictionariesService = _dictionariesService;
        this.registerService = registerService;
        this._notificationService = _notificationService;
        this.helperService = helperService;
        this.router = router;
        this.userScientist = {
            username: null,
            password: null,
            passwordRepeat: null,
            email: null,
            firstName: null,
            surname: null,
            department: null,
            universityId: null,
            projectsDescription: null,
            scientificWorksDescription: null,
            descriptionOfCooperationWithEmployers: null,
            phoneNumber: null,
            academicTitleId: null,
            keyWords: null,
            numberOfProjects: null,
            leadingSpecialisationId: null,
            intelligentSpecialisationId: null,
            specialisations: [],
            rodoAccepted: false,
            privacyPolicyAccepted: false,
            sendingInformationAccepted: false,
            dataProcessingAccepted: false
        };
        this.intelligentSpecialisationImageId = 9;
        this.maxTextareaLength = 2000;
        this.isSelectedAllRequirements = false;
        this.showMoreRequirements = {
            rodoAccepted: false,
            privacyPolicyAccepted: false,
            sendingInformationAccepted: false,
            dataProcessingAccepted: false
        };
    }
    ScientistRegisterComponent.prototype.ngOnInit = function () {
        this._getDictionaries();
        this.addSpecialisation();
    };
    ScientistRegisterComponent.prototype.removeEmptySpecialisation = function () {
        var isNotEmptySpecialisation = this.userScientist.specialisations.some(function (specialisation) {
            return specialisation.id !== null;
        });
        if (!isNotEmptySpecialisation) {
            this.userScientist.specialisations = [];
        }
    };
    ScientistRegisterComponent.prototype.changeShowMoreLess = function (type) {
        this.showMoreRequirements[type] = !this.showMoreRequirements[type];
    };
    ScientistRegisterComponent.prototype.isSelectedAll = function () {
        return (this.userScientist.privacyPolicyAccepted &&
            this.userScientist.rodoAccepted &&
            this.userScientist.sendingInformationAccepted &&
            this.userScientist.dataProcessingAccepted);
    };
    ScientistRegisterComponent.prototype.selectAllRequirements = function () {
        this.userScientist.privacyPolicyAccepted = this.isSelectedAllRequirements;
        this.userScientist.rodoAccepted = this.isSelectedAllRequirements;
        this.userScientist.sendingInformationAccepted = this.isSelectedAllRequirements;
        this.userScientist.dataProcessingAccepted = this.isSelectedAllRequirements;
    };
    ScientistRegisterComponent.prototype.register = function () {
        var _this = this;
        this.removeEmptySpecialisation();
        if (this.helperService.comparePassword(this.userScientist.password, this.userScientist.passwordRepeat)) {
            this.registerService.registerScientist(this.userScientist).subscribe(function (resp) {
                _this._notificationService.sendMessage({
                    type: 'success',
                    title: 'Rejestracja',
                    message: 'Utworzenie konta przebiegło pomyślnie'
                });
                _this.router.navigate(['/register', 'scientist']);
            });
        }
        else {
            this._notificationService.sendMessage({
                type: 'error',
                title: 'Rejestracja',
                message: 'Hasła nie są zgodne'
            });
        }
    };
    ScientistRegisterComponent.prototype.addSpecialisation = function () {
        var emptySpecialisation = {
            id: null,
            name: null
        };
        this.userScientist.specialisations.push(emptySpecialisation);
    };
    ScientistRegisterComponent.prototype.setInteligentSpecialisation = function (specialisationId) {
        if (specialisationId) {
            var specialisation = find(this.allSpecialisationsDict, { id: specialisationId });
            if (specialisation) {
                var intelligentSpecialisation = find(this.intelligentSpecialisationDict, {
                    value: specialisation.intelligentSpecialisationId
                });
                this.intelligentSpecialisationImageId = intelligentSpecialisation.value;
                this.userScientist.intelligentSpecialisationId = intelligentSpecialisation.value;
            }
            this.aditionalSpecialisationDict = this.aditionalSpecialisationDict.filter(function (itemDict) {
                return itemDict.value !== specialisationId;
            });
        }
    };
    ScientistRegisterComponent.prototype.keyWordsOnKeyDown = function (event) {
        if (event.key === ',') {
            event.preventDefault();
            var element = event.target;
            element.blur();
            element.focus();
        }
    };
    // Get dictionaries
    ScientistRegisterComponent.prototype._getDictionaries = function () {
        var _this = this;
        zip(this._dictionariesService.getAllAcademicTitles(), this._dictionariesService.getAllUniversities(), this._dictionariesService.getAllDepartments(), this._dictionariesService.getAllScienceDomains(), this._dictionariesService.getAllIntelligentSpecialisations(), this._dictionariesService.getAllSciences(), this._dictionariesService.getAllSpecialisations(), this._dictionariesService.getAllStatuses()).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 8), AcademicTitles = _b[0], Universities = _b[1], Departments = _b[2], ScienceDomains = _b[3], IntelligentSpecialisations = _b[4], Sciences = _b[5], Specialisations = _b[6], Statuses = _b[7];
            _this.academicTitlesDict = AcademicTitles.map(function (dict) {
                return {
                    label: dict.name,
                    value: dict.id
                };
            });
            _this.universitiesDict = Universities.map(function (dict) {
                return {
                    label: dict.name,
                    value: dict.id
                };
            });
            _this.intelligentSpecialisationDict = IntelligentSpecialisations.map(function (dict) {
                return {
                    label: dict.name,
                    value: dict.id
                };
            });
            _this.sciencesDict = Sciences.map(function (dict) {
                return {
                    label: dict.name,
                    value: dict.id
                };
            });
            _this.specialisationsDict = Specialisations.map(function (dict) {
                return {
                    label: dict.name,
                    value: dict.id
                };
            });
            _this.statusesDict = Statuses.map(function (dict) {
                return {
                    label: dict.name,
                    value: dict.id
                };
            });
            // Full data dictionary
            _this.departmentDict = Departments;
            _this.scienceDomainsDict = ScienceDomains;
            _this.allSpecialisationsDict = Specialisations;
        });
    };
    // Prepare options
    ScientistRegisterComponent.prototype.changeModelScienceDomainDict = function (scienceId) {
        this.userScientist.scienceDomainId = null;
        this.userScientist.leadingSpecialisationId = null;
        this.scienceDomainSelectDict = this.mapScienceDomains(scienceId);
    };
    ScientistRegisterComponent.prototype.changeModelSpecialisationDict = function (scienceDomainId) {
        this.userScientist.leadingSpecialisationId = null;
        this.specialisationSelectDict = this.mapSpecialisations(scienceDomainId);
        this.aditionalSpecialisationDict = this.mapSpecialisations(scienceDomainId);
    };
    ScientistRegisterComponent.prototype.changeModelDepartmentDict = function (universityId) {
        this.departmentSelectDict = this.mapDepartment(universityId);
    };
    // Mapers
    ScientistRegisterComponent.prototype.mapDepartment = function (universityId) {
        if (this.departmentDict && universityId) {
            return this.departmentDict
                .filter(function (department) {
                return department.universityId === universityId;
            })
                .map(function (department) {
                return {
                    label: department.name,
                    value: department.name
                };
            });
        }
        else {
            return null;
        }
    };
    ScientistRegisterComponent.prototype.mapScienceDomains = function (scienceId) {
        if (this.scienceDomainsDict && scienceId) {
            return this.scienceDomainsDict
                .filter(function (scienceDomain) {
                return scienceDomain.scienceId === scienceId;
            })
                .map(function (scienceDomain) {
                return {
                    label: scienceDomain.name,
                    value: scienceDomain.id
                };
            });
        }
        else {
            return null;
        }
    };
    ScientistRegisterComponent.prototype.mapSpecialisations = function (scienceDomainId) {
        if (this.allSpecialisationsDict && scienceDomainId) {
            return this.allSpecialisationsDict
                .filter(function (specialisation) {
                return specialisation.scienceDomainId === scienceDomainId;
            })
                .map(function (specialisation) {
                return {
                    label: specialisation.name,
                    value: specialisation.id
                };
            });
        }
        else {
            return null;
        }
    };
    return ScientistRegisterComponent;
}());
export { ScientistRegisterComponent };
