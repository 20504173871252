import { AuthenticationService } from '@app/core';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { isNil, isPlainObject } from 'lodash';
import { Router } from '@angular/router';
import * as DomToImage from 'dom-to-image';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  documentToImage: any;
  constructor(private _authenticationService: AuthenticationService, private router: Router) {
    this.documentToImage = DomToImage;
  }
  removeNullPropertyFromObject(object: any): void {
    Object.keys(object).forEach(key => (object[key] == null || object[key] === '') && delete object[key]);
  }

  removeNullNestedPropertyFromObject(object: any): void {
    Object.keys(object)
      .filter(k => object[k] != null)
      .reduce(
        (newObj, k) =>
          typeof object[k] === 'object'
            ? { ...newObj, [k]: this.removeNullNestedPropertyFromObject(object[k]) }
            : { ...newObj, [k]: object[k] },
        {}
      );
  }

  checkAnyObjectFieldIsFill(object: object, exceptionFields: string[] = []): boolean {
    return !Object.keys(object)
      .filter(key => {
        return exceptionFields.indexOf(key) === -1;
      })
      .some(key => {
        return Array.isArray(object[key]) ? object[key].length : object[key];
      });
  }

  convertLocalDateToServerWithoutTimezone(date: string | Date) {
    const momentDate = moment(date);
    return momentDate.isValid() ? momentDate.format('YYYY-MM-DDTHH:mm:ss') + '.000Z' : null;
  }

  convertLocalDateToServerOnlyDate(date: string | Date) {
    const momentDate = moment(date);
    return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : null;
  }

  comparePassword(password: string, passwordRepeat: string) {
    return password === passwordRepeat;
  }

  buildQueryParams(source: object): HttpParams {
    let queryParams: HttpParams = new HttpParams();
    Object.keys(source).forEach((key: string) => {
      let value: any = source[key];
      if (isNil(value)) {
        return;
      }
      value = isPlainObject(value) ? JSON.stringify(value) : value.toString();
      queryParams = queryParams.append(key, value);
    });
    return queryParams;
  }

  checkIsOtherCommune(communeId: number): boolean {
    return communeId === 3;
  }

  isLimitedUser(): Observable<boolean> {
    return new Observable(observer => {
      this._authenticationService.userData$.subscribe(async userData => {
        if (userData) {
          const isUserLimited$ = await this._authenticationService.getUserRoles();

          let isUserLimited = isUserLimited$.some(role => {
            return role.indexOf('LIMITED_EMPLOYER') !== -1 || role.indexOf('LIMITED_SCIENTIST') !== -1;
          });

          if (!isUserLimited && userData) {
            isUserLimited = userData.profileFillRequired || userData.passwordChangeRequired;
          }

          observer.next(isUserLimited);
        }
      });
    });
  }

  limitedUserRedirect() {
    // Redirect user to fill profile data
    this.isLimitedUser().subscribe((isLimited: boolean) => {
      if (isLimited) {
        this.router.navigate(['/profile']);
      }
    });
  }

  printFromImage(printEl: HTMLElement) {
    this.documentToImage.toPng(printEl).then((dataUrl: any) => {
      const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
      <html>
        <body onload="window.print();window.close()"><img src="${dataUrl}"></body>
      </html>`);
      popupWin.document.close();
    });
  }
}
