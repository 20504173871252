<div class="container-fluid my-4">
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-end space-between-btn">
        <button class="btn btn-primary" type="button" (click)="showModal()" aria-label="Przycisk dodawania użytkownika">
          Dodaj użytkownika
        </button>
      </div>
    </div>
  </div>
</div>
<div class="main-form-group">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 col-6">
        <div class="form-group">
          <label for="user_management_search_id">ID:</label>
          <input
            id="user_management_search_id"
            class="form-control"
            type="text"
            name="user_management_search_id"
            [(ngModel)]="filters.id"
          />
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="form-group">
          <label for="user_management_search_login">Login:</label>
          <input
            id="user_management_search_login"
            class="form-control"
            type="text"
            name="user_management_search_login"
            [(ngModel)]="filters.username"
          />
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="form-group">
          <label for="user_management_search_email">E-mail:</label>
          <input
            id="user_management_search_email"
            class="form-control"
            type="text"
            name="user_management_search_email"
            [(ngModel)]="filters.email"
          />
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="form-group">
          <label for="user_management_search_first_name">Imię:</label>
          <input
            id="user_management_search_first_name"
            class="form-control"
            type="text"
            name="user_management_search_first_name"
            [(ngModel)]="filters.firstName"
          />
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="form-group">
          <label for="user_management_search_last_name">Nazwisko:</label>
          <input
            id="user_management_search_last_name"
            class="form-control"
            type="text"
            name="user_management_search_last_name"
            [(ngModel)]="filters.surname"
          />
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="form-group">
          <label for="user_management_search_company">Firma:</label>
          <input
            id="user_management_search_company"
            class="form-control"
            type="text"
            name="user_management_search_company"
            [(ngModel)]="filters.companyName"
          />
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="mn-form-group">
          <label for="user_management_search_roles">Rola / funkcja:</label>
          <p-dropdown
            [options]="rolesDict"
            [(ngModel)]="filters.role"
            [showClear]="true"
            placeholder="Wybierz z listy"
            styleClass="form-control"
            filter="true"
            id="user_management_search_roles"
            name="user_management_search_roles"
            ariaLabelledBy="user_management_search_roles"
            inputId="user_management_search_roles"
            ariaFilterLabel="Filtrowanie wyników"
            emptyFilterMessage="Brak wyników wyszukiwania"
          ></p-dropdown>
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="mn-form-group">
          <label for="user_management_search_status">Status:</label>
          <p-dropdown
            [options]="userEnabledDict"
            [showClear]="true"
            [(ngModel)]="filters.status"
            placeholder="Wybierz z listy"
            styleClass="form-control"
            filter="true"
            id="user_management_search_status"
            name="user_management_search_status"
            ariaLabelledBy="user_management_search_status"
            inputId="user_management_search_status"
            ariaFilterLabel="Filtrowanie wyników"
            emptyFilterMessage="Brak wyników wyszukiwania"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary mr-2"
            type="button"
            (click)="clearFilter()"
            aria-label="Przycisk czyszczenia filtrów"
          >
            Wyczyść filtry
          </button>
          <button class="btn btn-primary" type="button" (click)="getUserData(true)" aria-label="Przycisk wyszukiwania">
            Wyszukaj
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="userTableData?.length">
        <p-table
          [columns]="userTableCols"
          [value]="userTableData"
          styleClass="mn-table"
          [responsive]="true"
          [autoLayout]="true"
          [lazy]="true"
          sortMode="single"
          (onLazyLoad)="customSort($event)"
          [customSort]="true"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                *ngFor="let col of columns"
                [pSortableColumn]="col.sortProperty || col.field"
                [pSortableColumnDisabled]="col.disabledSort"
              >
                <div class="d-flex">
                  {{ col.header }}
                  <p-sortIcon
                    *ngIf="!col.disabledSort"
                    [field]="col.field"
                    ariaLabel="Kliknij, aby posortować"
                    ariaLabelDesc="Kliknij, aby sortować w kolejności malejącej"
                    ariaLabelAsc="Kliknij, aby sortować w kolejności rosnącej"
                  ></p-sortIcon>
                </div>
              </th>
              <th class="mn-table-column-action"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td *ngFor="let col of columns">
                <ng-container [ngSwitch]="col.custom">
                  <span *ngSwitchCase="'isUserRole'">
                    {{ showUserRoles(rowData[col.field]) }}
                  </span>
                  <span *ngSwitchCase="'isDate'">
                    {{ rowData[col.field] | date: 'dd/MM/yyyy HH:mm:ss' }}
                  </span>
                  <span *ngSwitchCase="'accountStatus'">
                    {{ 'accountStatus.' + rowData[col.field] | translate }}
                  </span>
                  <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                </ng-container>
              </td>
              <td>
                <fieldset class="d-block">
                  <div class="mn-table-btn-actions">
                    <button
                      type="button"
                      class="btn btn-default"
                      pTooltip="Administrator kontaktowy"
                      tooltipPosition="top"
                      tooltipStyleClass="text-center"
                      aria-label="Oznaczenie administratora jako kontaktowy"
                      (click)="markAsContactAdmin(rowData.id)"
                      [disabled]="!checkUserRole(rowData, 'ADMIN', true)"
                    >
                      <i class="fas fa-at"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-default"
                      pTooltip="Edycja konta"
                      tooltipPosition="top"
                      aria-label="Przycisk edycji konta"
                      (click)="showModal(rowData, 'edit')"
                      [disabled]="actualUserData.id === rowData.id || rowData.status === 'HIDDEN'"
                    >
                      <i class="fas fa-user-edit"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-default"
                      pTooltip="Podgląd"
                      tooltipPosition="top"
                      aria-label="Przycisk podglądu użytkownika"
                      (click)="editUser(rowData, 'preview')"
                      [disabled]="
                        (rowData.employerId === null && rowData.scientistId === null) ||
                        checkIsUserAdmin(rowData) ||
                        rowData.status === 'HIDDEN'
                      "
                    >
                      <i class="fas fa-eye"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-default"
                      pTooltip="Edytuj profil"
                      tooltipPosition="top"
                      aria-label="Przycisk edycji profilu"
                      (click)="editUser(rowData)"
                      [disabled]="
                        (rowData.employerId === null && rowData.scientistId === null) ||
                        checkIsUserAdmin(rowData) ||
                        rowData.status === 'HIDDEN'
                      "
                    >
                      <i class="fas fa-pen"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-default"
                      pTooltip="Odnów hasło"
                      tooltipPosition="top"
                      aria-label="Przycisk odnowienia hasła"
                      (click)="regeneratePassword(rowData)"
                      [disabled]="rowData.status === 'HIDDEN'"
                    >
                      <i class="fas fa-lock"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-default"
                      pTooltip="Usuń profil"
                      tooltipPosition="top"
                      aria-label="Usuń profil"
                      (click)="hideUser(rowData)"
                      [disabled]="actualUserData.id === rowData.id || rowData.status === 'HIDDEN'"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </fieldset>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-paginator
          [rows]="paginator.size"
          [totalRecords]="paginator.totalElements"
          (onPageChange)="paginate($event)"
          [rowsPerPageOptions]="[10, 20, 50, 100]"
        ></p-paginator>
      </ng-container>
      <ng-container *ngIf="!userTableData?.length">
        <p-messages [(value)]="msgs" [closable]="false"></p-messages>
      </ng-container>
    </div>
  </div>
  <div class="row" *ngIf="userTableData?.length">
    <div class="col-12">
      <div class="d-flex justify-content-end">
        <p class="text-muted mt-4">Liczba użytkowników: {{ paginator?.totalElements }}</p>
      </div>
      <div class="d-flex justify-content-end">
        <p class="text-muted mb-4">Liczba aktualnie zalogowanych użytkowników: {{ numberLoggedUsers }}</p>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog header="Potwierdź operację" icon="pi pi-exclamation-triangle" acceptLabel="Tak" rejectLabel="Nie">
</p-confirmDialog>
