import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ScientistService = /** @class */ (function () {
    function ScientistService(_http) {
        this._http = _http;
        this.resourceUrl = '/scientists/';
    }
    ScientistService.prototype.createScientist = function (scientist) {
        return this._http.post(this.resourceUrl + 'register', scientist, { observe: 'response' });
    };
    ScientistService.prototype.updateScientist = function (scientist, id) {
        return this._http.put('/admin/scientists/' + id, scientist);
    };
    ScientistService.prototype.getScientist = function (id) {
        return this._http.get(this.resourceUrl + id);
    };
    ScientistService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScientistService_Factory() { return new ScientistService(i0.ɵɵinject(i1.HttpClient)); }, token: ScientistService, providedIn: "root" });
    return ScientistService;
}());
export { ScientistService };
