import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScientistRegisterComponent } from './scientist-register.component';
import { ScientistRegisterRoutingModule } from './scientist-register-routing.module';
import { WcagModule } from '@app/shared/wcag/wcag.module';

@NgModule({
  declarations: [ScientistRegisterComponent],
  imports: [
    CommonModule,
    FormsModule,
    WcagModule,
    DropdownModule,
    ChipsModule,
    CalendarModule,
    TooltipModule,
    ScientistRegisterRoutingModule
  ]
})
export class ScientistRegisterModule {}
