<div class="container">
  <div class="row">
    <div class="w-100">
      <div class="card">
        <div class="card-header">
          <h3>Rejestracja</h3>
        </div>
        <div class="card-body reset-padding-y">
          <div class="row py-4" *ngIf="!showSuccessRegistration">
            <div class="col-6 border-right">
              <div class="d-flex w-60 justify-content-center align-items-center flex-column">
                <div>
                  <img src="assets/images/scientist-icon.png" class="img-fluid" alt="Rejestracja naukowca" />
                </div>
                <span class="font-weight-bold my-3">Rejestracja naukowca</span>
                <p class="m-0">Rejestracja jest przeznaczona dla naukowców.</p>
                <p class="m-0">Kolejnym krokiem jest wypełnienie formularza.</p>
                <button
                  class="btn btn-blue mt-4"
                  routerLink="/scientist-register"
                  aria-label="Przycisk rejestracji jako naukowiec"
                >
                  Zarejestruj jako naukowiec
                </button>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex w-60 justify-content-center align-items-center flex-column">
                <div>
                  <img src="assets/images/employer-icon.png" class="img-fluid" alt="Rejestracja przedsiębiorcy" />
                </div>
                <span class="font-weight-bold my-3">Rejestracja przedsiębiorcy</span>
                <p class="m-0">Rejestracja jest przeznaczona dla przedsiębiorców.</p>
                <p class="m-0">Kolejnym krokiem jest wypełnienie formularza.</p>
                <button
                  class="btn btn-green mt-4"
                  routerLink="/employer-register"
                  aria-label="Przycisk rejestracji jako przedsiębiorca"
                >
                  Zarejestruj jako przedsiębiorca
                </button>
              </div>
            </div>
          </div>
          <div class="row py-4" *ngIf="showSuccessRegistration">
            <div class="col-12">
              <div class="col-12">
                <div class="d-flex flex-column justify-content-center align-items-center pb-4">
                  <div class="border-bottom pb-4 mb-4">
                    <img
                      src="assets/images/employer-icon.png"
                      class="img-fluid"
                      alt="Przedsiębiorca"
                      *ngIf="successUserType === 'employer'"
                    />
                    <img
                      src="assets/images/scientist-icon.png"
                      class="img-fluid"
                      alt="Naukowiec"
                      *ngIf="successUserType === 'scientist'"
                    />
                  </div>
                  <h3 class="mb-4">Dziękujemy za rejestracje</h3>
                  <p>
                    Link do aktywacji konta został wysłany na podany w formularzu adres e-mail.
                  </p>
                  <p>Aktywuj konto, aby zobaczyć szczegóły wyszukiwania.</p>
                  <button class="btn btn-primary" routerLink="/search">Powrót do strony głównej</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
