import { ReportsModule } from './reports/reports.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManagementModule } from './user-management/user-management.module';
import { EventLogModule } from './event-log/event-log.module';
import { ConfigurationAppModule } from './configuration-app/configuration-app.module';
import { WcagModule } from '@app/shared/wcag/wcag.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, UserManagementModule, ConfigurationAppModule, EventLogModule, WcagModule, ReportsModule]
})
export class AdminAppModule {}
