import { HelperService } from './helper.service';
import { Injectable } from '@angular/core';
import { ITableData, IPaginatorConfig } from '@app/interfaces/table.interface';
import { merge as _merge, isNil as _isNil } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  private defaultPaginatorConfig: IPaginatorConfig = {
    direction: 'ASC',
    page: 0,
    size: 10,
    sort: true
  };

  private paginatorConfig: IPaginatorConfig;

  constructor(private helperService: HelperService) {}

  getPaginatorConfig() {
    return this.paginatorConfig || this.defaultPaginatorConfig;
  }

  restorePaginatorConfig() {
    this.paginatorConfig = this.defaultPaginatorConfig;
  }

  prepareParamsToServer(filters: any = null) {
    const preparedParams = {
      params: {}
    };
    preparedParams.params = this.getPaginatorConfig();

    if (!_isNil(filters)) {
      preparedParams.params = _merge(filters, preparedParams.params);
    }

    this.helperService.removeNullPropertyFromObject(preparedParams.params);
    return preparedParams;
  }

  setPaginatorConfigFormServer(paginatorData: ITableData): void {
    const paginatorConfig: IPaginatorConfig = {
      direction: this.paginatorConfig ? this.paginatorConfig.direction : 'ASC',
      page: paginatorData.number,
      sort: paginatorData.sort.sorted || false,
      size: paginatorData.size,
      totalElements: paginatorData.totalElements
    };

    if (this.paginatorConfig && this.paginatorConfig.properties && this.paginatorConfig.properties.length) {
      paginatorConfig.properties = this.paginatorConfig.properties;
    }

    this.paginatorConfig = paginatorConfig;
  }

  setPaginatorConfig(paginatorConfig: IPaginatorConfig): void {
    this.paginatorConfig = {
      direction: paginatorConfig.direction,
      page: paginatorConfig.page,
      sort: paginatorConfig.sort || false,
      size: paginatorConfig.size
    };

    if (paginatorConfig.properties && paginatorConfig.properties.length) {
      this.paginatorConfig.properties = paginatorConfig.properties;
    }
  }
}
