import { HelperService } from '@app/services/helper.service';
import {
  ISearchScientistAnonymous,
  ISearchQuery,
  IScientistLimitedSearchProfile,
  IScientistSearchProfile,
  IRequestEstablishCooperation,
  IEmployerLimitedSearchProfile
} from './../interfaces/search.interface';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IPaginatorServerConfig } from '@app/interfaces/table.interface';
import { ISearchTableData } from '@app/interfaces/search.interface';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private _http: HttpClient, private helperService: HelperService) {}

  searchScientist(paginatorConfig: IPaginatorServerConfig): Observable<ISearchTableData> {
    return this._http.get<ISearchTableData>('/search/scientists', paginatorConfig);
  }

  searchEmployer(paginatorConfig: IPaginatorServerConfig): Observable<ISearchTableData> {
    return this._http.get<ISearchTableData>('/search/employers', paginatorConfig);
  }

  searchScientistAnnonumous(query: IPaginatorServerConfig): Observable<ISearchTableData> {
    return this._http.get<ISearchTableData>('/search/scientists/anonymous', query);
  }

  searchEmployerAnnonumous(query: IPaginatorServerConfig): Observable<ISearchTableData> {
    return this._http.get<ISearchTableData>('/search/employers/anonymous', query);
  }

  getScientistLimitedProfile(userId: number): Observable<IScientistLimitedSearchProfile> {
    return this._http.get<IScientistLimitedSearchProfile>(`/scientists/without-contact-data/${userId}`);
  }

  getEmployerLimitedProfile(userId: number): Observable<IEmployerLimitedSearchProfile> {
    return this._http.get<IEmployerLimitedSearchProfile>(`/employers/without-contact-data/${userId}`);
  }

  requestEstablishCooperation(payload: IRequestEstablishCooperation): Observable<IScientistSearchProfile> {
    const queryParams = this.helperService.buildQueryParams(payload);
    return this._http.post<IScientistSearchProfile>('/scientists/establish-cooperation', payload, {
      params: queryParams
    });
  }
}
