<div class="ui-dialog-content">
  <form #editDict="ngForm">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="mn-form-group">
          <label for="listEditableDictionaries" class="required">Wybierz słownik do edycji:</label>
          <p-dropdown
            [options]="listEditableDictionaries"
            name="listEditableDictionaries"
            id="listEditableDictionaries"
            [(ngModel)]="selectedDictForEdit"
            (onChange)="loadDictionary(selectedDictForEdit)"
            placeholder="Wybierz z listy"
            styleClass="form-control"
            filter="true"
            ariaLabelledBy="listEditableDictionaries"
            inputId="listEditableDictionaries"
            ariaFilterLabel="Filtrowanie wyników"
            baseZIndex="100009"
            emptyFilterMessage="Brak wyników wyszukiwania"
            required
          ></p-dropdown>
        </div>
      </div>
      <ng-container *ngIf="dependEditDict.length">
        <div class="col-md-6 col-12" *ngFor="let dependDict of dependEditDict; let idx = index">
          <div class="mn-form-group">
            <label for="dependDict_{{ idx }}" class="required">Wybierz słownik powiązany:</label>
            <p-dropdown
              [options]="dependDict.dependDictionary"
              name="dependDict_{{ idx }}"
              id="dependDict_{{ idx }}"
              [(ngModel)]="dependDict.dependFieldModel"
              [disabled]="idx >= 1 && !dependEditDict[idx - 1].dependFieldModel"
              (onChange)="filterRootDictionary(idx)"
              placeholder="Wybierz z listy"
              styleClass="form-control"
              filter="true"
              ariaLabelledBy="dependDict"
              inputId="dependDict_{{ idx }}"
              ariaFilterLabel="Filtrowanie wyników"
              baseZIndex="100009"
              emptyFilterMessage="Brak wyników wyszukiwania"
              required
            ></p-dropdown>
          </div>
        </div>
      </ng-container>
      <div class="col-12">
        <div class="mn-form-group">
          <label for="edit-dict">Edytuj słownik:</label>
          <div class="edit-dict-container" id="edit-dict">
            <div class="edit-dict-container-list" *ngIf="listItemToView.length">
              <div class="edit-dict-container-list-item" *ngFor="let dictionaryItem of listItemToView">
                <div class="edit-dict-container-list-item-actions">
                  <button
                    class="btn btn-action"
                    *ngIf="dictionaryItem.id !== editDictItemId"
                    (click)="setEditMode(dictionaryItem)"
                    [disabled]="isEditMode"
                  >
                    <i class="fas fa-pen"></i>
                  </button>
                  <button
                    class="btn btn-action"
                    *ngIf="dictionaryItem.id === editDictItemId"
                    [disabled]="!dictionaryItem.name"
                    (click)="updateItemDict(dictionaryItem)"
                  >
                    <i class="fas fa-check"></i>
                  </button>
                </div>
                <div class="edit-dict-container-list-item-name w-100">
                  <span *ngIf="dictionaryItem.id !== editDictItemId">{{ dictionaryItem.name }}</span>
                  <input
                    *ngIf="dictionaryItem.id === editDictItemId"
                    type="text"
                    class="form-control"
                    name="dictionaryItemName"
                    [(ngModel)]="dictionaryItem.name"
                  />
                </div>
              </div>
            </div>
            <ng-container *ngIf="!listItemToView.length">
              <p-messages [(value)]="msgs" [closable]="false"></p-messages>
            </ng-container>
            <div class="edit-dict-container-actions" *ngIf="editDict.form.valid">
              <button class="btn btn-primary" [disabled]="isEditMode" (click)="addNewItemDict()">
                Dodaj wpis
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
