<div class="ui-dialog-content">
  <form #sendMessageModal="ngForm" id="send-message-modal">
    <div class="row">
      <div class="col-12" *ngIf="!isContactWithAdmin && !isSelfResponse">
        <div class="mn-form-group">
          <label for="receiverIdentifiers" class="required">Do:</label>
          <p-multiSelect
            styleClass="form-control"
            [options]="userDict"
            [(ngModel)]="messageModel.receiverIdentifiers"
            selectedItemsLabel="{0} wybranych opcji"
            defaultLabel="Wybierz z listy"
            id="receiverIdentifiers"
            name="receiverIdentifiers"
            *ngIf="!isReply && isContactWithUsers"
            baseZIndex="100009"
            required
          ></p-multiSelect>
          <!-- send msg form search multiselect -->
          <p-multiSelect
            styleClass="form-control"
            [options]="userDict"
            [(ngModel)]="messageModel.receiverIdentifiers"
            selectedItemsLabel="{0} wybranych opcji"
            defaultLabel="Wybierz z listy"
            id="receiverIdentifiersWithUser"
            name="receiverIdentifiers"
            *ngIf="isSendMsgFromSearch"
            baseZIndex="100009"
            required
          ></p-multiSelect>
          <span *ngIf="isReply" class="d-block">{{ receiverUserName }}</span>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="title" class="required">Temat:</label>
          <input type="text" class="form-control" [(ngModel)]="messageModel.title" name="title" id="title" required />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <p-checkbox
            binary="true"
            [(ngModel)]="messageModel.confidentialMessage"
            label="Treść poufna"
            id="confidentialMessage"
            name="confidentialMessage"
            class="ui-checkbox-container"
          ></p-checkbox>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="content" class="required">Wiadomość:</label>
          <p-editor
            [style]="{ height: '320px' }"
            [(ngModel)]="messageModel.content"
            (onTextChange)="onTextChange($event)"
            id="content"
            name="content"
            required
            #quill
          >
            <p-header>
              <span class="ql-formats">
                <span class="ql-format-separator"></span>
                <button class="ql-bold" aria-label="Pogrubiony">Pogrubiony</button>
                <button class="ql-italic" aria-label="Kursywa">Kursywa</button>
                <button class="ql-underline" aria-label="Podkreślenie">Podkreślenie</button>
              </span>
              <span class="ql-formats">
                <span class="ql-format-separator"></span>
                <button class="ql-list" value="ordered" aria-label="Uporządkowana lista">Uporządkowana lista</button>
                <button class="ql-list" value="bullet" aria-label="Lista nieuporządkowana">
                  Lista nieuporządkowana
                </button>
              </span>
            </p-header>
          </p-editor>
        </div>
        <span class="text-small">Limit 2000 znaków</span>
      </div>
      <div class="col-12 mt-2">
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary"
            type="button"
            (click)="sendMail()"
            [disabled]="!sendMessageModal.form.valid"
            aria-label="Przycisk wyślij wiadomość"
          >
            Wyślij
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
