import { WcagService } from './wcag.service';
import { Directive, OnInit, Host, Self, Optional, ElementRef, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'img'
})
export class WcagImgDirective implements OnInit {
  constructor(public image: ElementRef, @Inject(DOCUMENT) private document: any, private wcagService: WcagService) {}
  ngOnInit() {
    const isReloadedPage = false;
    this.wcagService.removeImages.subscribe(sholudRemoveImages => {
      if (sholudRemoveImages) {
        // Replace all img tag with alt attribute
        this.image.nativeElement.outerHTML = `<div>${this.image.nativeElement.alt}</div>`;
        // Replace all CSS images as text
        const mnLogo = this.document.querySelector('.mn-logo');
        mnLogo.style.backgroundImage = 'none';
        mnLogo.innerHTML = '<h2>Logo małopolska nauka</h2>';
        const mnLogoPattern = this.document.querySelector('.mn-logo-pattern');
        mnLogoPattern.style.backgroundImage = 'none';
      }
    });
  }
}
