<div class="ui-dialog-content">
  <div class="row">
    <div class="col-12 text-center">
      <img src="assets/images/employer-icon.png" class="img-fluid" alt="Przedsiębiorca" />
    </div>
  </div>
  <div class="spacer"></div>
  <form #employerProfileEdit="ngForm" id="employer_profile_edit">
    <fieldset [disabled]="isPreviewMode">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="login" class="required">Login:</label>
                <input
                  id="login"
                  class="form-control"
                  type="text"
                  name="login"
                  [(ngModel)]="userEmployer.username"
                  disabled
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="companyName" class="required">Nazwa firmy:</label>
                <input
                  id="companyName"
                  class="form-control"
                  type="text"
                  name="companyName"
                  [(ngModel)]="userEmployer.companyName"
                  required
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="phoneNumber" class="required">Numer telefonu:</label>
                <input
                  id="phoneNumber"
                  class="form-control"
                  type="text"
                  name="phoneNumber"
                  [(ngModel)]="userEmployer.phoneNumber"
                  required
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="email" class="required">E-mail:</label>
                <input
                  id="email"
                  class="form-control"
                  type="text"
                  name="email"
                  [(ngModel)]="userEmployer.email"
                  disabled
                />
              </div>
            </div>
            <div class="col-12">
              <div class="mn-form-group">
                <label for="communeId" class="required">Gmina:</label>
                <p-dropdown
                  [options]="communesDict"
                  [(ngModel)]="userEmployer.communeId"
                  placeholder="Wybierz z listy"
                  styleClass="form-control"
                  filter="true"
                  id="communeId"
                  name="communeId"
                  ariaLabelledBy="communeId"
                  inputId="communeId"
                  ariaFilterLabel="Filtrowanie wyników"
                  emptyFilterMessage="Brak wyników wyszukiwania"
                  baseZIndex="100009"
                  required
                  [disabled]="isPreviewMode"
                ></p-dropdown>
              </div>
            </div>
            <div class="col-12" *ngIf="isOtherCommune(userEmployer.communeId)">
              <div class="form-group">
                <label for="city" class="required">Miejscowość:</label>
                <input
                  id="city"
                  class="form-control"
                  type="text"
                  name="city"
                  [(ngModel)]="userEmployer.city"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="row">
            <div class="col-12">
              <div class="mn-form-group">
                <label for="scienceId" class="required">Klasyfikacja branży:</label>
                <p-dropdown
                  [options]="sciencesDict"
                  [(ngModel)]="userEmployer.scienceId"
                  (onChange)="changeModelScienceDomainDict(userEmployer.scienceId)"
                  placeholder="Wybierz z listy"
                  styleClass="form-control"
                  filter="true"
                  id="scienceId"
                  name="scienceId"
                  ariaLabelledBy="scienceId"
                  inputId="scienceId"
                  ariaFilterLabel="Filtrowanie wyników"
                  emptyFilterMessage="Brak wyników wyszukiwania"
                  baseZIndex="100009"
                  required
                  [disabled]="isPreviewMode"
                ></p-dropdown>
              </div>
            </div>
            <div class="col-12">
              <div class="mn-form-group">
                <label for="scienceDomainId" class="required">Dziedzina:</label>
                <p-dropdown
                  [options]="scienceDomainSelectDict"
                  [(ngModel)]="userEmployer.scienceDomainId"
                  (onChange)="changeModelSpecialisationDict(userEmployer.scienceDomainId)"
                  placeholder="Wybierz z listy"
                  styleClass="form-control"
                  filter="true"
                  [disabled]="!userEmployer.scienceId || isPreviewMode"
                  id="scienceDomainId"
                  name="scienceDomainId"
                  ariaLabelledBy="scienceDomainId"
                  inputId="scienceDomainId"
                  ariaFilterLabel="Filtrowanie wyników"
                  emptyFilterMessage="Brak wyników wyszukiwania"
                  baseZIndex="100009"
                  required
                >
                </p-dropdown>
              </div>
            </div>
            <div class="col-12">
              <div class="mn-form-group">
                <label for="leadingSpecialisationId" class="required">Wiodąca specjalizacja:</label>
                <p-dropdown
                  [options]="specialisationsDict"
                  [(ngModel)]="userEmployer.leadingSpecialisationId"
                  placeholder="Wybierz z listy"
                  styleClass="form-control"
                  filter="true"
                  [disabled]="!userEmployer.scienceId || !userEmployer.scienceDomainId || isPreviewMode"
                  (onChange)="setInteligentSpecialisation(userEmployer.leadingSpecialisationId)"
                  id="leadingSpecialisationId"
                  name="leadingSpecialisationId"
                  ariaLabelledBy="leadingSpecialisationId"
                  inputId="leadingSpecialisationId"
                  ariaFilterLabel="Filtrowanie wyników"
                  emptyFilterMessage="Brak wyników wyszukiwania"
                  baseZIndex="100009"
                  required
                ></p-dropdown>
              </div>
            </div>
            <div class="col-12">
              <div class="mn-form-group">
                <label for="intelligentSpecialisationId">Inteligentna specjalizacja:</label>
                <p-dropdown
                  [options]="intelligentSpecialisationDict"
                  [(ngModel)]="userEmployer.intelligentSpecialisationId"
                  styleClass="form-control"
                  placeholder="Wybór automatyczny"
                  filter="true"
                  [disabled]="true"
                  id="intelligentSpecialisationId"
                  name="intelligentSpecialisationId"
                  ariaLabelledBy="intelligentSpecialisationId"
                  inputId="intelligentSpecialisationId"
                  ariaFilterLabel="Filtrowanie wyników"
                  emptyFilterMessage="Brak wyników wyszukiwania"
                  baseZIndex="100009"
                ></p-dropdown>
              </div>
            </div>
            <div class="col-12">
              <ng-container *ngIf="aditionalSpecialisationDict?.length && userEmployer.leadingSpecialisationId">
                <div class="row">
                  <div class="col-12" *ngFor="let specialisation of userEmployer.specialisations; let idx = index">
                    <div class="mn-form-group">
                      <label for="specialisation_{{ idx }}">Specjalizacja dodatkowa: </label>
                      <p-dropdown
                        [options]="aditionalSpecialisationDict"
                        [(ngModel)]="specialisation.id"
                        styleClass="form-control"
                        [showClear]="true"
                        filter="true"
                        placeholder="Wybierz z listy"
                        name="specialisation_{{ idx }}"
                        id="specialisation_{{ idx }}"
                        ariaLabelledBy="specialisation"
                        inputId="specialisation"
                        ariaFilterLabel="Filtrowanie wyników"
                        emptyFilterMessage="Brak wyników wyszukiwania"
                        baseZIndex="100009"
                        [disabled]="isPreviewMode"
                      ></p-dropdown>
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-start" *ngIf="!isPreviewMode">
                    <div class="form-group">
                      <button
                        type="button"
                        class="btn btn-primary"
                        aria-label="Przycisk dodawania specjalizacji"
                        (click)="addSpecialisation()"
                      >
                        Dodaj specjalizację
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!isPreviewMode">
        <div class="col-12">
          <div class="d-flex justify-content-end">
            <div
              pTooltip="Uzupełnij wszystkie pola"
              tooltipPosition="top"
              [tooltipDisabled]="employerProfileEdit.form.valid"
            >
              <button
                class="btn btn-primary"
                type="button"
                aria-label="Przycisk zapisania zmian"
                (click)="save()"
                [disabled]="!employerProfileEdit.form.valid"
              >
                Zapisz
              </button>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>
