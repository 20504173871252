<div class="ui-dialog-content">
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <p>Czy jesteś pewien, że chcesz wygenerować nowe hasło dla użytkownika?</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex align-items-center mt-2">
      <div class="form-group">
        <p-checkbox
          binary="true"
          [(ngModel)]="regeneratePass"
          label="Wymagana zmiana hasła"
          class="ui-checkbox-container"
        ></p-checkbox>
      </div>
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-primary"
          type="submit"
          aria-label="Przycisk generowania nowego hasła"
          (click)="regeneratePassword()"
        >
          Generuj nowe hasło
        </button>
      </div>
    </div>
  </div>
</div>
