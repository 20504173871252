<div class="row">
  <div class="col-12">
    <button
      class="btn mr-2"
      aria-label="Drukuj profil użytkownika"
      pTooltip="Drukuj profil użytkownika"
      tooltipStyleClass="text-center"
      tooltipPosition="bottom"
      tooltipZIndex="100002"
      (click)="print()"
    >
      <i class="fas fa-print"></i>
    </button>
  </div>
</div>
<div #printProfileResult>
  <fieldset disabled *ngIf="isUserScientist" class="colorized">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="firstName">Imię:</label>
              <input
                id="firstName"
                class="form-control"
                type="text"
                name="firstName"
                [(ngModel)]="scientistLimitedProfile.firstName"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="surname">Nazwisko:</label>
              <input
                id="surname"
                class="form-control"
                type="text"
                name="surname"
                [(ngModel)]="scientistLimitedProfile.surname"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="mn-form-group">
              <label for="academicTitleId">Tytuł naukowy:</label>
              <p-dropdown
                [options]="academicTitlesDict"
                [(ngModel)]="scientistLimitedProfile.academicTitleId"
                placeholder="Wybierz z listy"
                styleClass="form-control"
                filter="true"
                id="academicTitleId"
                name="academicTitleId"
                ariaLabelledBy="academicTitleId"
                inputId="academicTitleId"
                emptyFilterMessage="Brak wyników wyszukiwania"
                ariaFilterLabel="Filtrowanie wyników"
                disabled
              ></p-dropdown>
            </div>
          </div>
          <div class="col-12">
            <div class="mn-form-group">
              <label for="universityId">Uczelnia:</label>
              <p-dropdown
                [options]="universitiesDict"
                [(ngModel)]="scientistLimitedProfile.universityId"
                placeholder="Wybierz z listy"
                styleClass="form-control"
                filter="true"
                id="universityId"
                name="universityId"
                ariaLabelledBy="universityId"
                inputId="universityId"
                emptyFilterMessage="Brak wyników wyszukiwania"
                ariaFilterLabel="Filtrowanie wyników"
                disabled
              ></p-dropdown>
            </div>
          </div>
          <div class="col-12">
            <div class="mn-form-group">
              <label for="department">Wydział:</label>
              <p-dropdown
                [options]="departmentsDict"
                [(ngModel)]="scientistLimitedProfile.department"
                editable="true"
                placeholder="Wybierz z listy"
                styleClass="form-control"
                filter="true"
                id="department"
                name="department"
                ariaLabelledBy="department"
                inputId="department"
                emptyFilterMessage="Brak wyników wyszukiwania"
                ariaFilterLabel="Filtrowanie wyników"
                disabled
              ></p-dropdown>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="scientificWorksDescription">Opis prac naukowych:</label>
              <textarea
                id="scientificWorksDescription"
                class="form-control"
                name="scientificWorksDescription"
                [(ngModel)]="scientistLimitedProfile.scientificWorksDescription"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="row">
          <div class="col-12">
            <div class="mn-form-group">
              <label for="scienceId">Klasyfikacja dziedzin i nauki:</label>
              <p-dropdown
                [options]="sciencesDict"
                [(ngModel)]="scientistLimitedProfile.scienceId"
                (onChange)="changeModelScienceDomainDict(scientistLimitedProfile.scienceId)"
                placeholder="Wybierz z listy"
                styleClass="form-control"
                filter="true"
                id="scienceId"
                name="scienceId"
                ariaLabelledBy="scienceId"
                inputId="scienceId"
                ariaFilterLabel="Filtrowanie wyników"
                emptyFilterMessage="Brak wyników wyszukiwania"
                disabled
              ></p-dropdown>
            </div>
          </div>
          <div class="col-12">
            <div class="mn-form-group">
              <label for="scienceDomainId">Dziedzina:</label>
              <p-dropdown
                [options]="scienceDomainSelectDict"
                [(ngModel)]="scientistLimitedProfile.scienceDomainId"
                (onChange)="changeModelSpecialisationDict(scientistLimitedProfile.scienceDomainId)"
                placeholder="Wybierz z listy"
                styleClass="form-control"
                filter="true"
                id="scienceDomainId"
                name="scienceDomainId"
                ariaLabelledBy="scienceDomainId"
                inputId="scienceDomainId"
                ariaFilterLabel="Filtrowanie wyników"
                emptyFilterMessage="Brak wyników wyszukiwania"
                disabled
              >
              </p-dropdown>
            </div>
          </div>
          <div class="col-12">
            <div class="mn-form-group">
              <label for="leadingSpecialisationId">Wiodąca specjalizacja:</label>
              <p-dropdown
                [options]="specialisationsDict"
                [(ngModel)]="scientistLimitedProfile.leadingSpecialisationId"
                placeholder="Wybierz z listy"
                styleClass="form-control"
                filter="true"
                (onChange)="setInteligentSpecialisation(scientistLimitedProfile.leadingSpecialisationId)"
                id="leadingSpecialisationId"
                name="leadingSpecialisationId"
                ariaLabelledBy="leadingSpecialisationId"
                inputId="leadingSpecialisationId"
                ariaFilterLabel="Filtrowanie wyników"
                emptyFilterMessage="Brak wyników wyszukiwania"
                disabled
              ></p-dropdown>
            </div>
          </div>
          <div class="col-12">
            <div class="mn-form-group">
              <label for="intelligentSpecialisationId">Inteligentna specjalizacja:</label>
              <p-dropdown
                [options]="intelligentSpecialisationsDict"
                [(ngModel)]="scientistLimitedProfile.intelligentSpecialisationId"
                styleClass="form-control"
                placeholder="Wybór automatyczny"
                filter="true"
                id="intelligentSpecialisationId"
                name="intelligentSpecialisationId"
                ariaLabelledBy="intelligentSpecialisationId"
                inputId="intelligentSpecialisationId"
                ariaFilterLabel="Filtrowanie wyników"
                emptyFilterMessage="Brak wyników wyszukiwania"
                disabled
              ></p-dropdown>
            </div>
          </div>
          <div class="col-12" *ngFor="let specialisation of scientistLimitedProfile.specialisations; let idx = index">
            <div class="mn-form-group">
              <label for="specialisation_{{ idx }}">Specjalizacja dodatkowa: </label>
              <p-dropdown
                [options]="specialisationsDict"
                [(ngModel)]="specialisation.id"
                styleClass="form-control"
                [showClear]="true"
                filter="true"
                placeholder="Wybierz specjalizację"
                name="specialisation_{{ idx }}"
                id="specialisation_{{ idx }}"
                ariaLabelledBy="specialisation"
                inputId="specialisation"
                ariaFilterLabel="Filtrowanie wyników"
                emptyFilterMessage="Brak wyników wyszukiwania"
                disabled
              ></p-dropdown>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="keyWords">Słowa kluczowe:</label>
              <p-chips
                [(ngModel)]="scientistLimitedProfile.keyWords"
                [addOnTab]="true"
                [addOnBlur]="true"
                styleClass="form-control"
                id="keyWords"
                name="keyWords"
                disabled
              ></p-chips>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="numberOfProjects">Liczba projektów:</label>
              <input
                id="numberOfProjects"
                class="form-control"
                type="number"
                name="numberOfProjects"
                [(ngModel)]="scientistLimitedProfile.numberOfProjects"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="mn-form-group">
              <label for="scientist_status_profile">Status:</label>
              <p-dropdown
                [options]="statusesDict"
                [showClear]="false"
                [(ngModel)]="scientistLimitedProfile.availableForCooperation"
                placeholder="Wybierz z listy"
                styleClass="form-control"
                filter="true"
                id="scientist_status_profile"
                name="scientist_status_profile"
                ariaLabelledBy="scientist_status_profile"
                inputId="scientist_status_profile"
                ariaFilterLabel="Filtrowanie wyników"
                emptyFilterMessage="Brak wyników wyszukiwania"
                disabled
              ></p-dropdown>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="projectsDescription">Opis zrealizowanych projektów:</label>
              <textarea
                id="projectsDescription"
                class="form-control"
                name="projectsDescription"
                [(ngModel)]="scientistLimitedProfile.projectsDescription"
              ></textarea>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="descriptionOfCooperationWithEmployers">Opis współpracy z przedsiębiorcami:</label>
              <textarea
                id="descriptionOfCooperationWithEmployers"
                class="form-control"
                name="descriptionOfCooperationWithEmployers"
                [(ngModel)]="scientistLimitedProfile.descriptionOfCooperationWithEmployers"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
  <fieldset disabled *ngIf="isUserEmployer" class="colorized">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="companyName">Nazwa firmy:</label>
              <input
                id="companyName"
                class="form-control"
                type="text"
                name="companyName"
                [(ngModel)]="employerLimitedProfile.companyName"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="mn-form-group">
              <label for="communeId">Gmina:</label>
              <p-dropdown
                [options]="communesDict"
                [(ngModel)]="employerLimitedProfile.communeId"
                placeholder="Wybierz z listy"
                styleClass="form-control"
                filter="true"
                id="communeId"
                name="communeId"
                ariaLabelledBy="communeId"
                inputId="communeId"
                ariaFilterLabel="Filtrowanie wyników"
                emptyFilterMessage="Brak wyników wyszukiwania"
                disabled
              ></p-dropdown>
            </div>
          </div>
          <div class="col-12" *ngIf="employerLimitedProfile.city">
            <div class="form-group">
              <label for="city">Miejscowość:</label>
              <input id="city" class="form-control" type="text" name="city" [(ngModel)]="employerLimitedProfile.city" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="row">
          <div class="col-12">
            <div class="mn-form-group">
              <label for="scienceId">Klasyfikacja branży:</label>
              <p-dropdown
                [options]="sciencesDict"
                [(ngModel)]="employerLimitedProfile.scienceId"
                (onChange)="changeModelScienceDomainDict(employerLimitedProfile.scienceId)"
                placeholder="Wybierz z listy"
                styleClass="form-control"
                filter="true"
                id="scienceId"
                name="scienceId"
                ariaLabelledBy="scienceId"
                inputId="scienceId"
                ariaFilterLabel="Filtrowanie wyników"
                emptyFilterMessage="Brak wyników wyszukiwania"
                disabled
              ></p-dropdown>
            </div>
          </div>
          <div class="col-12">
            <div class="mn-form-group">
              <label for="scienceDomainId">Dziedzina:</label>
              <p-dropdown
                [options]="scienceDomainSelectDict"
                [(ngModel)]="employerLimitedProfile.scienceDomainId"
                (onChange)="changeModelSpecialisationDict(employerLimitedProfile.scienceDomainId)"
                placeholder="Wybierz z listy"
                styleClass="form-control"
                filter="true"
                disabled
                id="scienceDomainId"
                name="scienceDomainId"
                ariaLabelledBy="scienceDomainId"
                inputId="scienceDomainId"
                ariaFilterLabel="Filtrowanie wyników"
                emptyFilterMessage="Brak wyników wyszukiwania"
              >
              </p-dropdown>
            </div>
          </div>
          <div class="col-12">
            <div class="mn-form-group">
              <label for="leadingSpecialisationId">Wiodąca specjalizacja:</label>
              <p-dropdown
                [options]="specialisationsDict"
                [(ngModel)]="employerLimitedProfile.leadingSpecialisationId"
                placeholder="Wybierz z listy"
                styleClass="form-control"
                filter="true"
                (onChange)="setInteligentSpecialisation(employerLimitedProfile.leadingSpecialisationId)"
                id="leadingSpecialisationId"
                name="leadingSpecialisationId"
                ariaLabelledBy="leadingSpecialisationId"
                inputId="leadingSpecialisationId"
                ariaFilterLabel="Filtrowanie wyników"
                emptyFilterMessage="Brak wyników wyszukiwania"
                disabled
              ></p-dropdown>
            </div>
          </div>
          <div class="col-12">
            <div class="mn-form-group">
              <label for="intelligentSpecialisationId">Inteligentna specjalizacja:</label>
              <p-dropdown
                [options]="intelligentSpecialisationsDict"
                [(ngModel)]="employerLimitedProfile.intelligentSpecialisationId"
                styleClass="form-control"
                placeholder="Wybór automatyczny"
                filter="true"
                id="intelligentSpecialisationId"
                name="intelligentSpecialisationId"
                ariaLabelledBy="intelligentSpecialisationId"
                inputId="intelligentSpecialisationId"
                ariaFilterLabel="Filtrowanie wyników"
                emptyFilterMessage="Brak wyników wyszukiwania"
                disabled
              ></p-dropdown>
            </div>
          </div>
          <div class="col-12" *ngFor="let specialisation of employerLimitedProfile.specialisations; let idx = index">
            <div class="mn-form-group">
              <label for="specialisation_{{ idx }}">Specjalizacja: </label>
              <p-dropdown
                [options]="specialisationsDict"
                [(ngModel)]="specialisation.id"
                styleClass="form-control"
                [showClear]="true"
                filter="true"
                placeholder="Wybierz z listy"
                name="specialisation_{{ idx }}"
                id="specialisation_{{ idx }}"
                ariaLabelledBy="specialisation"
                inputId="specialisation"
                ariaFilterLabel="Filtrowanie wyników"
                emptyFilterMessage="Brak wyników wyszukiwania"
                disabled
              ></p-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
  <form #establishCooperation="ngForm">
    <div class="row border-top py-2 align-items-center" *ngIf="!establishedCooperation && canEstablishCooperation">
      <div class="col-12 mb-2">
        <p>Dane kontaktowe ukryte! W celu ich pokazania podaj powód i kliknij przycisk nawiąż współpracę.</p>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="establishCooperationReason" class="required">Temat współpracy:</label>
          <input
            type="text"
            [(ngModel)]="requestForEstablishCooperation.title"
            class="form-control"
            id="establishCooperationReason"
            name="establishCooperationReason"
            required
          />
        </div>
      </div>
      <div class="col-12 d-flex justify-content-end">
        <button
          class="btn btn-primary"
          (click)="getScientistProfile()"
          aria-label="Przycisk nawiązania współpracy"
          [disabled]="establishCooperation.form.invalid"
        >
          Nawiąż współpracę
        </button>
      </div>
    </div>
  </form>
  <div class="row border-top py-2" *ngIf="establishedCooperation && canEstablishCooperation">
    <div class="col-md-6 col-12">
      <div class="form-group">
        <label for="email">E-mail:</label>
        <input id="email" class="form-control" type="text" name="email" [(ngModel)]="scientistProfile.email" disabled />
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div class="form-group">
        <label for="phoneNumber">Telefon:</label>
        <input
          id="phoneNumber"
          class="form-control"
          type="text"
          name="phoneNumber"
          [(ngModel)]="scientistProfile.phoneNumber"
          disabled
        />
      </div>
    </div>
    <div class="col-12">
      <p class="contact-request">W celu nawiązania kontaktu proszę skorzystać z komunikatora.</p>
    </div>
  </div>
</div>
