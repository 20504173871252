import * as tslib_1 from "tslib";
import { UserManagementService } from './../../../services/user-management.service';
import { OnInit } from '@angular/core';
import { DictionariesService } from '@app/services/dictionaries.service';
import { zip, BehaviorSubject } from 'rxjs';
import { ScientistService } from '@app/services/scientist.service';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { DynamicDialogConfig } from 'primeng/api';
import { filter, find } from 'lodash';
var ScientistRegistrationComponent = /** @class */ (function () {
    function ScientistRegistrationComponent(_dictionariesService, _scientistService, _dialogService, _notificationService, _userManagementService, config) {
        this._dictionariesService = _dictionariesService;
        this._scientistService = _scientistService;
        this._dialogService = _dialogService;
        this._notificationService = _notificationService;
        this._userManagementService = _userManagementService;
        this.config = config;
        this.isProfileLoaded = new BehaviorSubject(false);
        this.maxTextareaLength = 2000;
        this.intelligentSpecialisationImageId = 9;
        this.userScientist = {
            username: null,
            email: null,
            firstName: null,
            surname: null,
            department: null,
            universityId: null,
            projectsDescription: null,
            scientificWorksDescription: null,
            descriptionOfCooperationWithEmployers: null,
            phoneNumber: null,
            academicTitleId: null,
            keyWords: null,
            numberOfProjects: null,
            leadingSpecialisationId: null,
            intelligentSpecialisationId: null,
            specialisations: []
        };
    }
    ScientistRegistrationComponent.prototype.ngOnInit = function () {
        this._getAllDictionaries();
        this.configData = this.config.data;
        if (this.configData && this.configData.id) {
            this.isPreviewMode = this.configData.preview;
            this.getScientistUserProfile(this.configData.id);
        }
    };
    ScientistRegistrationComponent.prototype.getScientistUserProfile = function (id) {
        var _this = this;
        this._scientistService.getScientist(id).subscribe(function (response) {
            _this.userScientist = response;
            _this.scientistsId = response.id;
            _this.intelligentSpecialisationImageId = response.intelligentSpecialisationId;
            if (response.specialisations.length === 0) {
                _this.addSpecialisation();
            }
            _this.isProfileLoaded.next(true);
        });
    };
    ScientistRegistrationComponent.prototype.keyWordsOnKeyDown = function (event) {
        if (event.key === ',') {
            event.preventDefault();
            var element = event.target;
            element.blur();
            element.focus();
        }
    };
    ScientistRegistrationComponent.prototype.addSpecialisation = function () {
        var emptySpecialisation = {
            id: null,
            name: null
        };
        this.userScientist.specialisations.push(emptySpecialisation);
    };
    ScientistRegistrationComponent.prototype.removeEmptySpecialisation = function () {
        var isNotEmptySpecialisation = this.userScientist.specialisations.some(function (specialisation) {
            return specialisation.id !== null;
        });
        if (!isNotEmptySpecialisation) {
            this.userScientist.specialisations = [];
        }
    };
    ScientistRegistrationComponent.prototype.save = function () {
        var _this = this;
        this.removeEmptySpecialisation();
        this._scientistService.updateScientist(this.userScientist, this.scientistsId).subscribe(function (response) {
            _this._dialogService.closeModal();
            _this._notificationService.sendMessage({
                type: 'success',
                title: 'Aktualizacja użytkownika',
                message: 'Pomyślnie zaktualizowano naukowca'
            });
        });
    };
    ScientistRegistrationComponent.prototype.getSciencesAndClasification = function () {
        var _this = this;
        var filteredSpecialisation = filter(this.specialisations, function (specialisation) {
            return (specialisation.intelligentSpecialisationId === _this.userScientist.intelligentSpecialisationId &&
                specialisation.id === _this.userScientist.leadingSpecialisationId);
        });
        this.userScientist.scienceDomainId = filteredSpecialisation && filteredSpecialisation[0].scienceDomainId;
        this.userScientist.scienceId = find(this.scienceDomainsDict, {
            id: this.userScientist.scienceDomainId
        }).scienceId;
    };
    ScientistRegistrationComponent.prototype.setInteligentSpecialisation = function (specialisationId) {
        if (specialisationId) {
            var specialisation = find(this.specialisations, { id: specialisationId });
            if (specialisation) {
                var intelligentSpecialisation = find(this.intelligentSpecialisationDict, {
                    value: specialisation.intelligentSpecialisationId
                });
                this.userScientist.intelligentSpecialisationId = intelligentSpecialisation.value;
                this.intelligentSpecialisationImageId = intelligentSpecialisation.value;
            }
            this.aditionalSpecialisationDict = this.aditionalSpecialisationDict.filter(function (itemDict) {
                return itemDict.value !== specialisationId;
            });
        }
    };
    ScientistRegistrationComponent.prototype.filteredDepartmentByUniversity = function (universityId) {
        if (this.departments) {
            this.departmentDict = this.departments
                .filter(function (department) {
                return department.universityId === universityId;
            })
                .map(function (department) {
                return {
                    label: department.name,
                    value: department.name
                };
            });
        }
    };
    ScientistRegistrationComponent.prototype.changeModelScienceDomainDict = function (scienceId) {
        this.userScientist.scienceDomainId = null;
        this.userScientist.leadingSpecialisationId = null;
        this.scienceDomainSelectDict = this.mapScienceDomains(scienceId);
    };
    ScientistRegistrationComponent.prototype.changeModelSpecialisationDict = function (scienceDomainId) {
        this.userScientist.leadingSpecialisationId = null;
        this.specialisationsDict = this.mapSpecialisations(scienceDomainId);
        this.aditionalSpecialisationDict = this.mapSpecialisations(scienceDomainId);
    };
    ScientistRegistrationComponent.prototype.mapScienceDomains = function (scienceId) {
        if (this.scienceDomainsDict && scienceId) {
            return this.scienceDomainsDict
                .filter(function (scienceDomain) {
                return scienceDomain.scienceId === scienceId;
            })
                .map(function (scienceDomain) {
                return {
                    label: scienceDomain.name,
                    value: scienceDomain.id
                };
            });
        }
        else {
            return null;
        }
    };
    ScientistRegistrationComponent.prototype.mapSpecialisations = function (scienceDomainId) {
        if (this.specialisations && scienceDomainId) {
            return this.specialisations
                .filter(function (specialisation) {
                return specialisation.scienceDomainId === scienceDomainId;
            })
                .map(function (specialisation) {
                return {
                    label: specialisation.name,
                    value: specialisation.id
                };
            });
        }
        else {
            return null;
        }
    };
    ScientistRegistrationComponent.prototype._getAllDictionaries = function () {
        var _this = this;
        zip(this._dictionariesService.getAllAcademicTitles(), this._dictionariesService.getAllUniversities(), this._dictionariesService.getAllScienceDomains(), this._dictionariesService.getAllSciences(), this._dictionariesService.getAllSpecialisations(), this._dictionariesService.getAllIntelligentSpecialisations(), this._dictionariesService.getAllStatuses(), this._dictionariesService.getAllDepartments()).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 8), AcademicTitles = _b[0], Universities = _b[1], ScienceDomains = _b[2], Sciences = _b[3], Specialisations = _b[4], IntelligentSpecialisations = _b[5], Statuses = _b[6], Departments = _b[7];
            _this.academicTitlesDict = AcademicTitles.map(function (academicTitle) {
                return {
                    label: academicTitle.name,
                    value: academicTitle.id
                };
            });
            _this.universitiesDict = Universities.map(function (university) {
                return {
                    label: university.name,
                    value: university.id
                };
            });
            _this.sciencesDict = Sciences.map(function (sciences) {
                return {
                    label: sciences.name,
                    value: sciences.id
                };
            });
            _this.scienceDomainSelectDict = ScienceDomains.map(function (scienceDomain) {
                return {
                    label: scienceDomain.name,
                    value: scienceDomain.id
                };
            });
            _this.specialisationsDict = Specialisations.map(function (specialisation) {
                return {
                    label: specialisation.name,
                    value: specialisation.id
                };
            });
            _this.allSpecialisationDict = Specialisations.map(function (spec) {
                return {
                    label: spec.name,
                    value: spec.id
                };
            });
            _this.intelligentSpecialisationDict = IntelligentSpecialisations.map(function (intelligentSpecialisation) {
                return {
                    label: intelligentSpecialisation.name,
                    value: intelligentSpecialisation.id
                };
            });
            _this.statusesDict = Statuses.map(function (status) {
                return {
                    label: status.name,
                    value: status.id
                };
            });
            // All dictionaries
            _this.specialisations = Specialisations;
            _this.scienceDomainsDict = ScienceDomains;
            _this.departments = Departments;
            _this.isProfileLoaded.subscribe(function (isLoaded) {
                if (isLoaded) {
                    _this.getSciencesAndClasification();
                    _this.scienceDomainSelectDict = _this.mapScienceDomains(_this.userScientist.scienceId);
                    _this.specialisationsDict = _this.mapSpecialisations(_this.userScientist.scienceDomainId);
                    _this.aditionalSpecialisationDict = _this.mapSpecialisations(_this.userScientist.scienceDomainId);
                    _this.setInteligentSpecialisation(_this.userScientist.leadingSpecialisationId);
                    if (_this.userScientist.universityId) {
                        _this.filteredDepartmentByUniversity(_this.userScientist.universityId);
                    }
                }
            });
        });
    };
    return ScientistRegistrationComponent;
}());
export { ScientistRegistrationComponent };
