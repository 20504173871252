import { Directive, OnInit, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[wcagAria]'
})
export class WcagAriaDirective implements OnInit {
  @Input() wcagAriaFor: string;
  @Input() wcagAriaSelectName: string;
  constructor() {}

  ngOnInit() {
    const elem: any = document.getElementsByName(this.wcagAriaSelectName);
    setTimeout(() => {
      elem.forEach((element: any) => {
        element.setAttribute('aria-label', this.wcagAriaFor);
      });
    });
  }
}
