import { KnowledgeTransferCentersComponent } from './knowledge-transfer-centers.component';
import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
var ɵ0 = { title: extract('O Centrach transferu wiedzy') };
var routes = [
    Shell.childRoutes([
        {
            path: 'knowledge-transfer-centers',
            component: KnowledgeTransferCentersComponent,
            data: ɵ0
        }
    ])
];
var KnowledgeTransferCentersRoutingModule = /** @class */ (function () {
    function KnowledgeTransferCentersRoutingModule() {
    }
    return KnowledgeTransferCentersRoutingModule;
}());
export { KnowledgeTransferCentersRoutingModule };
export { ɵ0 };
