import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MailNotificationComponent } from './mail-notification.component';
import { MailNotificationRoutingModule } from './mail-notification-routing.module';

@NgModule({
  declarations: [MailNotificationComponent],
  imports: [CommonModule, MailNotificationRoutingModule]
})
export class MailNotificationModule {}
