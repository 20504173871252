import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var NotificationService = /** @class */ (function () {
    function NotificationService() {
        this._messageStatus$ = new Subject();
    }
    NotificationService.prototype.sendMessage = function (messageObject) {
        this._messageStatus$.next(messageObject);
    };
    NotificationService.prototype.getMessage = function () {
        return this._messageStatus$.asObservable();
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
