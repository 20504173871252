import { HelperService } from '@app/services/helper.service';
import { IScientistLimitedSearchProfile, IScientistSearchProfile, IRequestEstablishCooperation, IEmployerLimitedSearchProfile } from './../interfaces/search.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./helper.service";
var SearchService = /** @class */ (function () {
    function SearchService(_http, helperService) {
        this._http = _http;
        this.helperService = helperService;
    }
    SearchService.prototype.searchScientist = function (paginatorConfig) {
        return this._http.get('/search/scientists', paginatorConfig);
    };
    SearchService.prototype.searchEmployer = function (paginatorConfig) {
        return this._http.get('/search/employers', paginatorConfig);
    };
    SearchService.prototype.searchScientistAnnonumous = function (query) {
        return this._http.get('/search/scientists/anonymous', query);
    };
    SearchService.prototype.searchEmployerAnnonumous = function (query) {
        return this._http.get('/search/employers/anonymous', query);
    };
    SearchService.prototype.getScientistLimitedProfile = function (userId) {
        return this._http.get("/scientists/without-contact-data/" + userId);
    };
    SearchService.prototype.getEmployerLimitedProfile = function (userId) {
        return this._http.get("/employers/without-contact-data/" + userId);
    };
    SearchService.prototype.requestEstablishCooperation = function (payload) {
        var queryParams = this.helperService.buildQueryParams(payload);
        return this._http.post('/scientists/establish-cooperation', payload, {
            params: queryParams
        });
    };
    SearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchService_Factory() { return new SearchService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HelperService)); }, token: SearchService, providedIn: "root" });
    return SearchService;
}());
export { SearchService };
