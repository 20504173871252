import { NgForm } from '@angular/forms';
import { IUserScientist } from '@app/interfaces/user-profile.interface';
import { IDefaultDict } from './../../../../interfaces/dictionary.interface';
import { SelectItem } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { DictionariesService } from '@app/services/dictionaries.service';
import { RegisterService } from '@app/services/register.service';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { HelperService } from '@app/services/helper.service';
import { Router } from '@angular/router';
import { zip } from 'rxjs';
import { find } from 'lodash';

@Component({
  selector: 'app-scientist-register',
  templateUrl: './scientist-register.component.html',
  styleUrls: ['./scientist-register.component.scss']
})
export class ScientistRegisterComponent implements OnInit {
  scientistRegisterForm: NgForm;
  userScientist: IUserScientist = {
    username: null,
    password: null,
    passwordRepeat: null,
    email: null,
    firstName: null,
    surname: null,
    department: null,
    universityId: null,
    projectsDescription: null,
    scientificWorksDescription: null,
    descriptionOfCooperationWithEmployers: null,
    phoneNumber: null,
    academicTitleId: null,
    keyWords: null,
    numberOfProjects: null,
    leadingSpecialisationId: null,
    intelligentSpecialisationId: null,
    specialisations: [],
    rodoAccepted: false,
    privacyPolicyAccepted: false,
    sendingInformationAccepted: false,
    dataProcessingAccepted: false
  };

  intelligentSpecialisationImageId = 9;
  maxTextareaLength = 2000;
  isSelectedAllRequirements = false;

  showMoreRequirements = {
    rodoAccepted: false,
    privacyPolicyAccepted: false,
    sendingInformationAccepted: false,
    dataProcessingAccepted: false
  };

  // Dictionaries
  academicTitlesDict: SelectItem[];
  universitiesDict: SelectItem[];
  departmentDict: any;
  scienceDomainsDict: any;
  intelligentSpecialisationDict: SelectItem[];
  sciencesDict: SelectItem[];
  specialisationsDict: SelectItem[];
  allSpecialisationsDict: any;
  statusesDict: SelectItem[];
  aditionalSpecialisationDict: SelectItem[];

  scienceDomainSelectDict: SelectItem[];
  specialisationSelectDict: SelectItem[];
  departmentSelectDict: SelectItem[];

  constructor(
    private _dictionariesService: DictionariesService,
    private registerService: RegisterService,
    private _notificationService: NotificationService,
    private helperService: HelperService,
    private router: Router
  ) {}

  ngOnInit() {
    this._getDictionaries();
    this.addSpecialisation();
  }

  removeEmptySpecialisation() {
    const isNotEmptySpecialisation = this.userScientist.specialisations.some(specialisation => {
      return specialisation.id !== null;
    });

    if (!isNotEmptySpecialisation) {
      this.userScientist.specialisations = [];
    }
  }

  changeShowMoreLess(type: string) {
    this.showMoreRequirements[type] = !this.showMoreRequirements[type];
  }

  isSelectedAll(): boolean {
    return (
      this.userScientist.privacyPolicyAccepted &&
      this.userScientist.rodoAccepted &&
      this.userScientist.sendingInformationAccepted &&
      this.userScientist.dataProcessingAccepted
    );
  }

  selectAllRequirements(): void {
    this.userScientist.privacyPolicyAccepted = this.isSelectedAllRequirements;
    this.userScientist.rodoAccepted = this.isSelectedAllRequirements;
    this.userScientist.sendingInformationAccepted = this.isSelectedAllRequirements;
    this.userScientist.dataProcessingAccepted = this.isSelectedAllRequirements;
  }

  register() {
    this.removeEmptySpecialisation();

    if (this.helperService.comparePassword(this.userScientist.password, this.userScientist.passwordRepeat)) {
      this.registerService.registerScientist(this.userScientist).subscribe(resp => {
        this._notificationService.sendMessage({
          type: 'success',
          title: 'Rejestracja',
          message: 'Utworzenie konta przebiegło pomyślnie'
        });
        this.router.navigate(['/register', 'scientist']);
      });
    } else {
      this._notificationService.sendMessage({
        type: 'error',
        title: 'Rejestracja',
        message: 'Hasła nie są zgodne'
      });
    }
  }

  addSpecialisation() {
    const emptySpecialisation: any = {
      id: null,
      name: null
    };

    this.userScientist.specialisations.push(emptySpecialisation);
  }

  setInteligentSpecialisation(specialisationId: number) {
    if (specialisationId) {
      const specialisation = find(this.allSpecialisationsDict, { id: specialisationId });
      if (specialisation) {
        const intelligentSpecialisation: any = find(this.intelligentSpecialisationDict, {
          value: specialisation.intelligentSpecialisationId
        });
        this.intelligentSpecialisationImageId = intelligentSpecialisation.value;
        this.userScientist.intelligentSpecialisationId = intelligentSpecialisation.value;
      }

      this.aditionalSpecialisationDict = this.aditionalSpecialisationDict.filter((itemDict: SelectItem) => {
        return itemDict.value !== specialisationId;
      });
    }
  }

  keyWordsOnKeyDown(event: KeyboardEvent) {
    if (event.key === ',') {
      event.preventDefault();
      const element = event.target as HTMLElement;
      element.blur();
      element.focus();
    }
  }

  // Get dictionaries
  _getDictionaries() {
    zip(
      this._dictionariesService.getAllAcademicTitles(),
      this._dictionariesService.getAllUniversities(),
      this._dictionariesService.getAllDepartments(),
      this._dictionariesService.getAllScienceDomains(),
      this._dictionariesService.getAllIntelligentSpecialisations(),
      this._dictionariesService.getAllSciences(),
      this._dictionariesService.getAllSpecialisations(),
      this._dictionariesService.getAllStatuses()
    ).subscribe(
      ([
        AcademicTitles,
        Universities,
        Departments,
        ScienceDomains,
        IntelligentSpecialisations,
        Sciences,
        Specialisations,
        Statuses
      ]) => {
        this.academicTitlesDict = (AcademicTitles as IDefaultDict[]).map(dict => {
          return {
            label: dict.name,
            value: dict.id
          };
        });
        this.universitiesDict = (Universities as IDefaultDict[]).map(dict => {
          return {
            label: dict.name,
            value: dict.id
          };
        });
        this.intelligentSpecialisationDict = (IntelligentSpecialisations as IDefaultDict[]).map(dict => {
          return {
            label: dict.name,
            value: dict.id
          };
        });
        this.sciencesDict = (Sciences as IDefaultDict[]).map(dict => {
          return {
            label: dict.name,
            value: dict.id
          };
        });
        this.specialisationsDict = (Specialisations as IDefaultDict[]).map(dict => {
          return {
            label: dict.name,
            value: dict.id
          };
        });
        this.statusesDict = (Statuses as IDefaultDict[]).map(dict => {
          return {
            label: dict.name,
            value: dict.id
          };
        });

        // Full data dictionary
        this.departmentDict = Departments;
        this.scienceDomainsDict = ScienceDomains;
        this.allSpecialisationsDict = Specialisations;
      }
    );
  }

  // Prepare options
  changeModelScienceDomainDict(scienceId: number) {
    this.userScientist.scienceDomainId = null;
    this.userScientist.leadingSpecialisationId = null;
    this.scienceDomainSelectDict = this.mapScienceDomains(scienceId);
  }

  changeModelSpecialisationDict(scienceDomainId: number) {
    this.userScientist.leadingSpecialisationId = null;
    this.specialisationSelectDict = this.mapSpecialisations(scienceDomainId);
    this.aditionalSpecialisationDict = this.mapSpecialisations(scienceDomainId);
  }

  changeModelDepartmentDict(universityId: number) {
    this.departmentSelectDict = this.mapDepartment(universityId);
  }

  // Mapers
  mapDepartment(universityId: number): SelectItem[] {
    if (this.departmentDict && universityId) {
      return this.departmentDict
        .filter((department: any) => {
          return department.universityId === universityId;
        })
        .map((department: any) => {
          return {
            label: department.name,
            value: department.name
          };
        });
    } else {
      return null;
    }
  }

  mapScienceDomains(scienceId: number): SelectItem[] {
    if (this.scienceDomainsDict && scienceId) {
      return this.scienceDomainsDict
        .filter((scienceDomain: any) => {
          return scienceDomain.scienceId === scienceId;
        })
        .map((scienceDomain: any) => {
          return {
            label: scienceDomain.name,
            value: scienceDomain.id
          };
        });
    } else {
      return null;
    }
  }

  mapSpecialisations(scienceDomainId: number): SelectItem[] {
    if (this.allSpecialisationsDict && scienceDomainId) {
      return this.allSpecialisationsDict
        .filter((specialisation: any) => {
          return specialisation.scienceDomainId === scienceDomainId;
        })
        .map((specialisation: any) => {
          return {
            label: specialisation.name,
            value: specialisation.id
          };
        });
    } else {
      return null;
    }
  }
}
