import { indexOf } from 'lodash';
import { DictionariesService } from '@app/services/dictionaries.service';
import {
  ICommunicatorMessageToAdmin,
  ICommunicatorSendMsgToUsers,
  ICommunicatorMessageScientist
} from './../../../interfaces/communicator.interface';
import { CommunicatorService } from './../../../services/communicator.service';
import { HelperService } from './../../../services/helper.service';
import { ICommunicatorMessageModel, ICommunicatorMessageReplay } from '@app/interfaces/communicator.interface';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
import { DynamicDialogConfig, SelectItem } from 'primeng/api';
import { AuthenticationService } from '@app/core';

@Component({
  selector: 'app-sned-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit {
  userData: any;
  messageModel: ICommunicatorMessageModel = {
    content: null,
    title: null,
    receiverIdentifiers: null,
    confidentialMessage: null,
    parentId: null,
    receiverId: null,
    searchEngineEntryId: null
  };
  @ViewChild('quill', { static: false }) quillEditor: any;

  isReply: boolean;
  isContactWithAdmin: boolean;
  isContactWithUsers: boolean;
  isSendMsgFromSearch: boolean;
  isContactWithScientist: boolean;
  isSelfResponse = false;

  // Dictionaries
  userDict: SelectItem[];

  receiverId: number;
  parentId: number;
  receiverUserName: string;
  receiverRole: string;

  constructor(
    private _notificationService: NotificationService,
    private _dialogService: DialogBaseService,
    public config: DynamicDialogConfig,
    private _authenticationService: AuthenticationService,
    private helperService: HelperService,
    private communicatorService: CommunicatorService,
    private dictionaryService: DictionariesService
  ) {}

  ngOnInit() {
    this.userData = this._authenticationService.getUserData();

    if (this.config && this.config.data) {
      this.isReply = this.config.data.isReply || false;

      if (this.isReply) {
        this.receiverId = this.config.data.lastMessage.receiverId;
        this.receiverUserName = this.config.data.lastMessage.receiverDisplayName;
        this.messageModel.title = `Re: ${this.config.data.lastMessage.title}`;
        this.parentId = this.config.data.parentId;
        this.isSelfResponse = this.userData.id === this.config.data.lastMessage.receiverId;
      }

      this.isContactWithAdmin = this.config.data.isContactWithAdmin || false;
      this.isContactWithUsers = this.config.data.isContactWithUsers || false;

      if (this.isContactWithUsers) {
        this.getUsersDict();
      }

      this.isSendMsgFromSearch = this.config.data.isContactFromSearch || false;
      this.isContactWithScientist = this.config.data.isContactWithScientist || false;
      this.receiverRole = this.config.data.activeTabId === 0 ? 'SCIENTIST' : 'EMPLOYER';

      if (this.isSendMsgFromSearch) {
        this.messageModel.searchEngineEntryId = this.config.data.searchEngineEntryId;
        this.messageModel.receiverIdentifiers = this.config.data.multiselectDict.map((item: SelectItem) => {
          return item.value;
        });
        this.userDict = this.config.data.multiselectDict;
      }
    }
  }

  onTextChange(event: any) {
    if (event.htmlValue.length > 1000) {
      this.quillEditor.quill.deleteText(event.htmlValue.length - event.textValue.length, event.htmlValue.length);
      this.quillEditor.onModelChange(event.htmlValue);
      this.quillEditor.onModelTouched();
    }
  }

  getUsersDict() {
    this.dictionaryService.getAllUsersBasicInfo().subscribe((resp: any) => {
      this.userDict = resp
        .filter((users: any) => {
          return users.roles.indexOf('CONTACT_ADMIN') === -1 && users.roles.indexOf('ADMIN') === -1;
        })
        .map((user: any) => {
          return {
            label: user.username,
            value: user.id
          };
        });
    });
  }

  sendMail() {
    if (this.isReply) {
      this.messageModel.parentId = this.parentId;
      this.messageModel.receiverId = this.receiverId;
      this.helperService.removeNullPropertyFromObject(this.messageModel);

      this.communicatorService.replayMessage(this.messageModel as ICommunicatorMessageReplay).subscribe(resp => {
        this._dialogService.closeModal();
        this._notificationService.sendMessage({
          type: 'success',
          title: 'Sukces!',
          message: 'Wiadomość została wysłana poprawnie'
        });
      });
    }

    if (this.isContactWithAdmin) {
      this.helperService.removeNullPropertyFromObject(this.messageModel);

      this.communicatorService.sendMessageToAdmin(this.messageModel as ICommunicatorMessageToAdmin).subscribe(resp => {
        this._dialogService.closeModal();
        this._notificationService.sendMessage({
          type: 'success',
          title: 'Sukces!',
          message: 'Wiadomość została wysłana poprawnie'
        });
      });
    }

    if (this.isContactWithUsers) {
      this.helperService.removeNullPropertyFromObject(this.messageModel);

      this.communicatorService.sendMessageToUsers(this.messageModel as ICommunicatorSendMsgToUsers).subscribe(resp => {
        this._dialogService.closeModal();
        this._notificationService.sendMessage({
          type: 'success',
          title: 'Sukces!',
          message: 'Wiadomość została wysłana poprawnie'
        });
      });
    }

    if (this.isSendMsgFromSearch) {
      this.helperService.removeNullPropertyFromObject(this.messageModel);

      if (this.isContactWithScientist && this.userData.employerId !== null) {
        this.communicatorService
          .sendMessageToScientist(this.messageModel as ICommunicatorMessageScientist)
          .subscribe(resp => {
            this._dialogService.closeModal();
            this._notificationService.sendMessage({
              type: 'success',
              title: 'Sukces!',
              message: 'Wiadomość została wysłana poprawnie'
            });
          });
      } else {
        this.messageModel.receiverRole = this.receiverRole;
        this.communicatorService.sendMessageToUser(this.messageModel as ICommunicatorSendMsgToUsers).subscribe(resp => {
          this._dialogService.closeModal();
          this._notificationService.sendMessage({
            type: 'success',
            title: 'Sukces!',
            message: 'Wiadomość została wysłana poprawnie'
          });
        });
      }
    }
  }
}
