import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeclarationAvailabilityComponent } from './declaration-availability.component';
import { DeclarationAvailabilityRoutingModule } from './declaration-availability-routing.module';

@NgModule({
  declarations: [DeclarationAvailabilityComponent],
  imports: [CommonModule, DeclarationAvailabilityRoutingModule]
})
export class DeclarationAvailabilityModule {}
