<div class="container">
  <div class="row">
    <div class="w-100">
      <div class="card">
        <div class="card-header">
          <h3>Rejestracja naukowca</h3>
        </div>
        <div class="card-body reset-padding-y pt-0">
          <form #scientistRegisterForm="ngForm" autocomplete="off" id="registerForm">
            <div class="row px-3 pb-4">
              <div class="col-12 text-center py-4 border-bottom">
                <img
                  src="assets/images/register-icons/scientist-icon_{{ intelligentSpecialisationImageId }}.svg"
                  alt="Inteligenta specjalizacja naukowca"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="login" class="required">Login:</label>
                      <input
                        id="login"
                        class="form-control"
                        type="text"
                        name="login"
                        [(ngModel)]="userScientist.username"
                        autocomplete="off"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="required" for="pass">Hasło:</label>
                      <input
                        class="form-control"
                        type="password"
                        [(ngModel)]="userScientist.password"
                        autocomplete="new-password"
                        id="pass"
                        name="pass"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="required" for="passwordRepeat">Powtórz hasło:</label>
                      <input
                        class="form-control"
                        type="password"
                        [(ngModel)]="userScientist.passwordRepeat"
                        id="passwordRepeat"
                        name="passwordRepeat"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="firstName" class="required">Imię:</label>
                      <input
                        id="firstName"
                        class="form-control"
                        type="text"
                        name="firstName"
                        [(ngModel)]="userScientist.firstName"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="surname" class="required">Nazwisko:</label>
                      <input
                        id="surname"
                        class="form-control"
                        type="text"
                        name="surname"
                        [(ngModel)]="userScientist.surname"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="phoneNumber" class="required">Telefon:</label>
                      <input
                        id="phoneNumber"
                        class="form-control"
                        type="text"
                        name="phoneNumber"
                        [(ngModel)]="userScientist.phoneNumber"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="email" class="required">E-mail:</label>
                      <input
                        id="email"
                        class="form-control"
                        [class.is-invalid]="
                          scientistRegisterForm.form.get('email')?.invalid &&
                          scientistRegisterForm.form.get('email').touched
                        "
                        email
                        type="email"
                        name="email"
                        [(ngModel)]="userScientist.email"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="mn-form-group"
                      wcagAria
                      [wcagAriaFor]="'academicTitleId'"
                      [wcagAriaSelectName]="'academicTitleIdSelect'"
                    >
                      <label class="required" for="academicTitleId">Tytuł naukowy:</label>
                      <p-dropdown
                        [options]="academicTitlesDict"
                        [(ngModel)]="userScientist.academicTitleId"
                        placeholder="Wybierz z listy"
                        styleClass="form-control"
                        filter="true"
                        id="academicTitleId"
                        name="academicTitleIdSelect"
                        ariaLabelledBy="academicTitleId"
                        inputId="academicTitleId"
                        ariaFilterLabel="Filtrowanie wyników"
                        emptyFilterMessage="Brak wyników wyszukiwania"
                        required
                      ></p-dropdown>
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="mn-form-group"
                      wcagAria
                      [wcagAriaFor]="'universityId'"
                      [wcagAriaSelectName]="'universityIdSelect'"
                    >
                      <label class="required" for="universityId">Uczelnia:</label>
                      <p-dropdown
                        [options]="universitiesDict"
                        [(ngModel)]="userScientist.universityId"
                        (onChange)="changeModelDepartmentDict(userScientist.universityId)"
                        placeholder="Wybierz z listy"
                        styleClass="form-control"
                        filter="true"
                        id="universityId"
                        name="universityIdSelect"
                        ariaLabelledBy="universityId"
                        inputId="universityId"
                        ariaFilterLabel="Filtrowanie wyników"
                        emptyFilterMessage="Brak wyników wyszukiwania"
                        required
                      ></p-dropdown>
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="mn-form-group"
                      wcagAria
                      [wcagAriaFor]="'department'"
                      [wcagAriaSelectName]="'departmentSelect'"
                    >
                      <label for="department" class="required">Wydział:</label>
                      <p-dropdown
                        [options]="departmentSelectDict"
                        [(ngModel)]="userScientist.department"
                        editable="true"
                        placeholder="Wybierz z listy"
                        styleClass="form-control"
                        filter="true"
                        [disabled]="!userScientist.universityId"
                        id="department"
                        name="departmentSelect"
                        ariaLabelledBy="department"
                        inputId="department"
                        ariaFilterLabel="Filtrowanie wyników"
                        emptyFilterMessage="Brak wyników wyszukiwania"
                        required
                      ></p-dropdown>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="scientificWorksDescription" class="required">Opis prac naukowych:</label>
                      <textarea
                        id="scientificWorksDescription"
                        class="form-control"
                        name="scientificWorksDescription"
                        [(ngModel)]="userScientist.scientificWorksDescription"
                        [maxlength]="maxTextareaLength"
                        required
                      ></textarea>
                      <div class="mn-field-description text-right w-100">
                        <span *ngIf="userScientist.scientificWorksDescription" class="text-small text-muted"
                          >{{ userScientist.scientificWorksDescription.length }} / {{ maxTextareaLength }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="row">
                  <div class="col-12">
                    <div
                      class="mn-form-group"
                      wcagAria
                      [wcagAriaFor]="'scienceId'"
                      [wcagAriaSelectName]="'scienceIdSelect'"
                    >
                      <label for="scienceId" class="required">Klasyfikacja dziedzin i nauki:</label>
                      <p-dropdown
                        [options]="sciencesDict"
                        [(ngModel)]="userScientist.scienceId"
                        (onChange)="changeModelScienceDomainDict(userScientist.scienceId)"
                        [showClear]="true"
                        filter="true"
                        placeholder="Wybierz z listy"
                        styleClass="form-control"
                        ariaLabelledBy="scienceId"
                        inputId="scienceId"
                        ariaFilterLabel="Filtrowanie wyników"
                        emptyFilterMessage="Brak wyników wyszukiwania"
                        id="scienceId"
                        name="scienceIdSelect"
                        required
                      ></p-dropdown>
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="mn-form-group"
                      wcagAria
                      [wcagAriaFor]="'scienceDomainId'"
                      [wcagAriaSelectName]="'scienceDomainIdSelect'"
                    >
                      <label for="scienceDomainId" class="required">Dziedzina:</label>
                      <p-dropdown
                        [options]="scienceDomainSelectDict"
                        [(ngModel)]="userScientist.scienceDomainId"
                        (onChange)="changeModelSpecialisationDict(userScientist.scienceDomainId)"
                        placeholder="Wybierz z listy"
                        styleClass="form-control"
                        filter="true"
                        [disabled]="!userScientist.scienceId"
                        id="scienceDomainId"
                        name="scienceDomainIdSelect"
                        ariaLabelledBy="scienceDomainId"
                        inputId="scienceDomainId"
                        ariaFilterLabel="Filtrowanie wyników"
                        emptyFilterMessage="Brak wyników wyszukiwania"
                        required
                      >
                      </p-dropdown>
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="mn-form-group"
                      wcagAria
                      [wcagAriaFor]="'leadingSpecialisationId'"
                      [wcagAriaSelectName]="'leadingSpecialisationIdSelect'"
                    >
                      <label for="leadingSpecialisationId" class="required">Wiodąca specjalizacja:</label>
                      <p-dropdown
                        [options]="specialisationSelectDict"
                        [(ngModel)]="userScientist.leadingSpecialisationId"
                        placeholder="Wybierz z listy"
                        styleClass="form-control"
                        filter="true"
                        [disabled]="!userScientist.scienceId || !userScientist.scienceDomainId"
                        (onChange)="setInteligentSpecialisation(userScientist.leadingSpecialisationId)"
                        id="leadingSpecialisationId"
                        name="leadingSpecialisationIdSelect"
                        ariaLabelledBy="leadingSpecialisationId"
                        inputId="leadingSpecialisationId"
                        ariaFilterLabel="Filtrowanie wyników"
                        emptyFilterMessage="Brak wyników wyszukiwania"
                        required
                      ></p-dropdown>
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="mn-form-group"
                      wcagAria
                      [wcagAriaFor]="'intelligentSpecialisationId'"
                      [wcagAriaSelectName]="'intelligentSpecialisationIdSelect'"
                    >
                      <label for="intelligentSpecialisationId">Inteligentna specjalizacja:</label>
                      <p-dropdown
                        [options]="intelligentSpecialisationDict"
                        [(ngModel)]="userScientist.intelligentSpecialisationId"
                        styleClass="form-control"
                        placeholder="Wybór automatyczny"
                        filter="true"
                        [disabled]="true"
                        id="intelligentSpecialisationId"
                        name="intelligentSpecialisationIdSelect"
                        ariaLabelledBy="intelligentSpecialisationId"
                        inputId="intelligentSpecialisationId"
                        ariaFilterLabel="Filtrowanie wyników"
                        emptyFilterMessage="Brak wyników wyszukiwania"
                      ></p-dropdown>
                    </div>
                  </div>
                  <ng-container *ngIf="aditionalSpecialisationDict?.length && userScientist.leadingSpecialisationId">
                    <div class="col-12" *ngFor="let specialisation of userScientist.specialisations; let idx = index">
                      <div class="mn-form-group">
                        <label for="specialisation_{{ idx }}">Specjalizacja dodatkowa: </label>
                        <p-dropdown
                          [options]="aditionalSpecialisationDict"
                          [(ngModel)]="specialisation.id"
                          styleClass="form-control"
                          [showClear]="true"
                          filter="true"
                          placeholder="Wybierz z listy"
                          id="specialisation_{{ idx }}"
                          name="specialisation_{{ idx }}"
                          ariaLabelledBy="specialisation"
                          inputId="specialisation"
                          ariaFilterLabel="Filtrowanie wyników"
                          emptyFilterMessage="Brak wyników wyszukiwania"
                        ></p-dropdown>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group d-flex justify-content-start">
                        <button
                          type="button"
                          class="btn btn-primary"
                          pTooltip="Dodaj specjalizację"
                          aria-label="Przycisk dodawania specjalizacji"
                          tooltipPosition="top"
                          (click)="addSpecialisation()"
                        >
                          Dodaj specjalizację
                        </button>
                      </div>
                    </div>
                  </ng-container>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="keyWords" class="required">Słowa kluczowe:</label>
                      <p-chips
                        [(ngModel)]="userScientist.keyWords"
                        [addOnTab]="true"
                        [addOnBlur]="true"
                        (keydown)="keyWordsOnKeyDown($event)"
                        styleClass="form-control"
                        id="keyWords"
                        name="keyWords"
                        ariaLabelledBy="keyWords"
                        inputId="keyWords"
                        inputStyleClass="control"
                        required
                      ></p-chips>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="numberOfProjects" class="required">Liczba projektów:</label>
                      <input
                        class="form-control"
                        type="number"
                        [(ngModel)]="userScientist.numberOfProjects"
                        id="numberOfProjects"
                        name="numberOfProjects"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projectsDescription" class="required">Opis zrealizowanych projektów:</label>
                      <textarea
                        id="projectsDescription"
                        class="form-control"
                        name="projectsDescription"
                        [(ngModel)]="userScientist.projectsDescription"
                        [maxlength]="maxTextareaLength"
                        required
                      ></textarea>
                      <div class="mn-field-description text-right w-100">
                        <span *ngIf="userScientist.projectsDescription" class="text-small text-muted"
                          >{{ userScientist.projectsDescription.length }} / {{ maxTextareaLength }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="descriptionOfCooperationWithEmployers" class="required"
                        >Opis współpracy z przedsiębiorcami:</label
                      >
                      <textarea
                        id="descriptionOfCooperationWithEmployers"
                        class="form-control"
                        name="descriptionOfCooperationWithEmployers"
                        [(ngModel)]="userScientist.descriptionOfCooperationWithEmployers"
                        [maxlength]="maxTextareaLength"
                        required
                      ></textarea>
                      <div class="mn-field-description text-right w-100">
                        <span *ngIf="userScientist.descriptionOfCooperationWithEmployers" class="text-small text-muted"
                          >{{ userScientist.descriptionOfCooperationWithEmployers.length }} /
                          {{ maxTextareaLength }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-4 text-justify">
              <div class="col-12 mb-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="isSelectedAll()"
                    (change)="selectAllRequirements()"
                    [(ngModel)]="isSelectedAllRequirements"
                    id="selectAll"
                    name="selectAll"
                  />
                  <label class="form-check-label required" for="selectAll">
                    Zaznacz wszystkie
                  </label>
                </div>
              </div>
              <div class="col-12 mb-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [(ngModel)]="userScientist.rodoAccepted"
                    id="rodoAccepted"
                    name="rodoAccepted"
                    required
                  />
                  <label class="form-check-label" for="rodoAccepted">
                    Na postawie art. 6 ust.1 lit a) RODO oświadczam, iż wyrażam zgodę na przetwarzanie przez
                    administratora, którym jest Województwo Małopolskie z siedzibą w Krakowie, ul. Basztowa 22, 31-156
                    Kraków, adres do korespondencji ul. Racławicka 56, 30-017 Kraków, moich danych osobowych<span
                      *ngIf="showMoreRequirements.rodoAccepted"
                      >, zawartych w profilu użytkownika aplikacji i serwisu „Małopolska nauka” - baza danych
                      kompetencji pracowników naukowych” i przyjmuję do wiadomości, że moje dane osobowe będą
                      przetwarzane w celu budowy i prowadzenia bazy naukowców do nawiązywania współpracy naukowców z
                      przedsiębiorcami.</span
                    >
                    <p class="p-0 m-0">
                      <a
                        href="javascript:void(0)"
                        title="Rozwiń"
                        *ngIf="!showMoreRequirements.rodoAccepted"
                        (click)="changeShowMoreLess('rodoAccepted')"
                        ><i>Czytaj wiecej...</i></a
                      >
                      <a
                        href="javascript:void(0)"
                        title="Zwiń"
                        *ngIf="showMoreRequirements.rodoAccepted"
                        (click)="changeShowMoreLess('rodoAccepted')"
                        ><i>Czytaj mniej...</i></a
                      >
                    </p>
                  </label>
                </div>
              </div>
              <div class="col-12 mb-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [(ngModel)]="userScientist.sendingInformationAccepted"
                    id="sendingInformationAccepted"
                    name="sendingInformationAccepted"
                    required
                  />
                  <label class="form-check-label" for="sendingInformationAccepted">
                    Wyrażam zgodę na otrzymywanie informacji drogą elektroniczną zgodnie z art. 10 ust. 2 ustawy z dnia
                    18 lipca 2002 roku oświadczeniu usług drogą elektroniczną (t.j. Dz. U. z 2020 roku, poz. 344).
                  </label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [(ngModel)]="userScientist.dataProcessingAccepted"
                    id="dataProcessingAccepted"
                    name="dataProcessingAccepted"
                    required
                  />
                  <label class="form-check-label" for="dataProcessingAccepted">
                    Zapoznałem się z informacją że:
                    <ul class="mb-0">
                      <li>
                        Administratorem moich danych osobowych jest Województwo Małopolskie z siedzibą w Krakowie, ul.
                        Basztowa 22, 31-156 Kraków, adres do korespondencji ul. Racławicka 56, 30-017 Kraków.
                      </li>
                      <li>
                        Wyznaczono Inspektora Ochrony Danych, z którym można się skontaktować w sprawach ochrony moich
                        danych osobowych pod adresem e-mail: iodo@umwm.malopolska.pl lub pisemnie na adres: Inspektor
                        Ochrony Danych UMWM, Urząd Marszałkowski Województwa Małopolskiego, ul. Racławicka 56, 30-017
                        Kraków.
                      </li>
                    </ul>
                    <ul *ngIf="showMoreRequirements.dataProcessingAccepted">
                      <li>
                        Administrator będzie przetwarzać moje dane w celu budowy i prowadzenia bazy naukowców do
                        nawiązywania współpracy naukowców z przedsiębiorcami.
                      </li>
                      <li>
                        Podanie danych ma charakter dobrowolny, jednak niezbędny do założenia konta w aplikacji
                        „Małopolska nauka” - baza danych kompetencji pracowników naukowych. Niepodanie danych osobowych
                        będzie powodowało brak możliwości założenia konta.
                      </li>
                      <li>
                        Moje dane osobowe będą przechowywane przez okres wskazany w art. 140 rozporządzenia Parlamentu
                        Europejskiego i Rady (UE) nr 1303/2013 z 17 grudnia 2013 r., czyli do 31.12.2029 r. oraz zgodnie
                        z Ustawą z dnia 14 lipca 1983 r. o narodowym zasobie archiwalnym i archiwach.
                      </li>
                    </ul>

                    <span *ngIf="showMoreRequirements.dataProcessingAccepted"
                      >Dane osobowe będą przechowywane do momentu zakończenia realizacji i rozliczenia projektu oraz
                      zamknięcia i rozliczenia Regionalnego Programu Operacyjnego Województwa Małopolskiego 2014-2020
                      oraz zakończenia okresu trwałości dla projektu i okresu archiwizacyjnego, w zależności od tego,
                      która z tych dat nastąpi później.</span
                    >
                    <ul *ngIf="showMoreRequirements.dataProcessingAccepted">
                      <li>
                        Posiadam prawo dostępu do treści swoich danych oraz prawo ich: sprostowania, ograniczenia
                        przetwarzania i usunięcia oraz prawo do cofnięcia zgody w dowolnym momencie bez wpływu na
                        zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.
                      </li>
                      <li>
                        Mam prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, gdy uznam, iż
                        przetwarzanie danych osobowych mnie dotyczących narusza przepisy RODO.
                      </li>
                      <li>Moje dane nie będą przetwarzane w sposób zautomatyzowany, w tym również profilowane.</li>
                      <li>Moje dane osobowe mogą zostać ujawnione w bazie pn. Małopolska nauka innym osobom.</li>
                      <li>
                        Zgodnie z Załącznikiem Nr 1 do Uchwały Nr 319/20 Zarządu Województwa Małopolskiego z dnia 3
                        marca 2020 r. pn. Zasady realizacji projektu, dla którego Beneficjentem jest Województwo
                        Małopolskie w ramach Regionalnego Programu Operacyjnego Województwa Małopolskiego na lata
                        2014-2020, zapisami załącznika nr 4 - Lider Projektu, może powierzyć przetwarzanie danych
                        osobowych, o których mowa w niniejszym wniosku, podmiotom realizującym badania ewaluacyjne na
                        zlecenie IZ/IP. Dane osobowe mogą zostać również powierzone podmiotom realizującym na zlecenie
                        IZ/IP kontrole i audyt w ramach RPO WM. Są to podmioty uprawnione do przeprowadzenia kontroli, w
                        tym organy publiczne (m. in.: Małopolskie Centrum Przedsiębiorczości, Departament Zarządzania
                        Programami Operacyjnymi Urzędu Marszałkowskiego Województwa Małopolskiego, Regionalna Izba
                        Obrachunkowa, Krajowa Administracja Skarbowa, Najwyższa Izba Kontroli, Europejski Urząd ds.
                        Zwalczania Nadużyć Finansowych (OLAF).
                      </li>
                    </ul>
                    <p class="p-0 m-0">
                      <a
                        href="javascript:void(0)"
                        title="Rozwiń"
                        *ngIf="!showMoreRequirements.dataProcessingAccepted"
                        (click)="changeShowMoreLess('dataProcessingAccepted')"
                        ><i>Czytaj wiecej...</i></a
                      >
                      <a
                        href="javascript:void(0)"
                        title="Zwiń"
                        *ngIf="showMoreRequirements.dataProcessingAccepted"
                        (click)="changeShowMoreLess('dataProcessingAccepted')"
                        ><i>Czytaj mniej...</i></a
                      >
                    </p>
                  </label>
                </div>
              </div>
              <div class="col-12 mb-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [(ngModel)]="userScientist.privacyPolicyAccepted"
                    id="privacyPolicyAccepted"
                    name="privacyPolicyAccepted"
                    required
                  />
                  <label class="form-check-label" for="privacyPolicyAccepted">
                    Zapoznałem/-am się i akceptuję <a href="/#/regulations" target="_blank">Regulamin</a> oraz
                    <a href="/#/privacy-policy" target="_blank">Politykę prywatności</a>.
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="d-flex justify-content-end">
                  <div
                    pTooltip="Uzupełnij wszystkie pola"
                    tooltipPosition="top"
                    [tooltipDisabled]="scientistRegisterForm.form.valid"
                  >
                    <button
                      class="btn btn-primary"
                      type="button"
                      aria-label="Przycisk rejestracji"
                      (click)="register()"
                      [disabled]="!scientistRegisterForm.form.valid"
                    >
                      Zarejestruj się
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
