import { TableService } from './../../../services/table.service';
import { HelperService } from '@app/services/helper.service';
import { ReportsService } from './../../../services/reports.service';
import { IDefaultDict } from './../../../interfaces/dictionary.interface';
import { SelectItem, Message } from 'primeng/api';
import { combineLatest, forkJoin, zip } from 'rxjs';
import { DictionariesService } from './../../../services/dictionaries.service';
import {
  IReportsEventLogRequest,
  IReportsSearchEntriesRequest,
  IEventLogReportTable,
  ISearchEntriesReportTable,
  IUsersGenerateReport
} from './../../../interfaces/reports.interface';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import { IPaginatorConfig, ITableInterface, ITableData } from '@app/interfaces/table.interface';
import { join } from 'lodash';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
  msgs: Message[] = [];
  paginatorEventLog: IPaginatorConfig = null;
  reportEventLogTableCols: ITableInterface[];
  eventLogTableData: IEventLogReportTable[] = null;

  paginatorSearchEntries: IPaginatorConfig = null;
  searchEntriesTableCols: ITableInterface[];
  searchEntriesTableData: ISearchEntriesReportTable[] = null;

  userOverlay: any[];

  reportEventLog: IReportsEventLogRequest = {
    startDate: null,
    endDate: null,
    userEmail: null,
    kindOfReport: null
  };

  reportSearchEntries: IReportsSearchEntriesRequest = {
    startDate: null,
    endDate: null,
    userEmail: null,
    kind: null
  };

  reportUsers: IUsersGenerateReport = {
    kind: null
  };

  // Dictionaries
  kindOfReportDict: SelectItem[];
  kindOfSearchEntries: SelectItem[];
  kindOfUsersReport: SelectItem[];

  constructor(
    private _dictionariesService: DictionariesService,
    private reportsService: ReportsService,
    private fileSaverService: FileSaverService,
    private helperService: HelperService,
    private tableService: TableService
  ) {}

  ngOnDestroy(): void {
    this.tableService.restorePaginatorConfig();
  }

  ngOnInit() {
    this.reportEventLogTableCols = [
      { field: 'id', header: 'ID' },
      { field: 'createDateTime', header: 'Data', custom: 'isDate' },
      { field: 'userEmail', header: 'Adres e-mail' },
      { field: 'modificationKind', header: 'Rodzaj zmiany' },
      { field: 'description', header: 'Opis' }
    ];

    this.searchEntriesTableCols = [
      { field: 'id', header: 'ID' },
      { field: 'entryDate', header: 'Data', custom: 'isDate', sortProperty: 'createTime' },
      { field: 'userEmail', header: 'Adres e-mail' },
      { field: 'entryContent', header: 'Treść zapytania', sortProperty: 'content' },
      { field: 'participants', header: 'Wynik wyszukiwania', custom: 'isHoverable', disabledSort: true },
      {
        field: 'isCooperationEstablished',
        header: 'Nawiazanie współpracy',
        custom: 'boolean',
        disabledSort: true
      },
      {
        field: 'isCooperationActiveAfter30Days',
        header: 'Aktywna współpraca po 30 dniach',
        custom: 'boolean',
        disabledSort: true
      },
      {
        field: 'isCooperationActiveAfter6Months',
        header: 'Aktywna współpraca po 6 miesiącach',
        custom: 'boolean',
        disabledSort: true
      }
    ];

    this.getEventLogsData();
    this.getSearchEntriesData();
    this._getDictionaries();
  }

  paginateEventLogReport(paginatorEvent: any): void {
    const currentPaginator = this.tableService.getPaginatorConfig();
    const paginator = {
      direction: currentPaginator.direction,
      page: paginatorEvent.page,
      size: paginatorEvent.rows,
      properties: currentPaginator.properties,
      sort: currentPaginator.sort
    };
    this.tableService.setPaginatorConfig(paginator);
    this.getEventLogsData();
  }

  customSortEventLogReport(event: any) {
    if (event.sortField) {
      const currentPaginator = this.tableService.getPaginatorConfig();
      const paginator = {
        direction: event.sortOrder === -1 ? 'DESC' : 'ASC',
        page: currentPaginator.page,
        size: currentPaginator.size,
        properties: [event.sortField],
        sort: true
      };
      this.tableService.setPaginatorConfig(paginator);
      this.getEventLogsData();
    }
  }

  paginateSearchEntriesReport(paginatorEvent: any): void {
    const currentPaginator = this.tableService.getPaginatorConfig();
    const paginator = {
      direction: currentPaginator.direction,
      page: paginatorEvent.page,
      size: paginatorEvent.rows,
      properties: currentPaginator.properties,
      sort: currentPaginator.sort
    };
    this.tableService.setPaginatorConfig(paginator);
    this.getSearchEntriesData();
  }

  customSortSearchEntriesReport(event: any) {
    if (event.sortField) {
      const currentPaginator = this.tableService.getPaginatorConfig();
      const paginator = {
        direction: event.sortOrder === -1 ? 'DESC' : 'ASC',
        page: currentPaginator.page,
        size: currentPaginator.size,
        properties: [event.sortField],
        sort: true
      };
      this.tableService.setPaginatorConfig(paginator);
      this.getSearchEntriesData();
    }
  }

  getEventLogsData() {
    const paramsToServer = {
      startDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportEventLog.startDate),
      endDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportEventLog.endDate),
      userEmail: this.reportEventLog.userEmail,
      kindOfReport: this.reportEventLog.kindOfReport
    };

    this.helperService.removeNullPropertyFromObject(paramsToServer);
    this.reportsService
      .getTableEventLogReport(this.tableService.prepareParamsToServer(paramsToServer))
      .subscribe((eventLogReport: ITableData) => {
        this.eventLogTableData = eventLogReport.content;
        this.tableService.setPaginatorConfigFormServer(eventLogReport);
        this.paginatorEventLog = this.tableService.getPaginatorConfig();

        if (!eventLogReport.content.length) {
          this.showEmptyDataAlert();
        }
      });
  }

  getSearchEntriesData() {
    const paramsToServer = {
      startDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportSearchEntries.startDate),
      endDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportSearchEntries.endDate),
      userEmail: this.reportSearchEntries.userEmail,
      kind: this.reportSearchEntries.kind
    };

    this.helperService.removeNullPropertyFromObject(paramsToServer);
    this.reportsService
      .getTableSearchEntriesReport(this.tableService.prepareParamsToServer(paramsToServer))
      .subscribe((searchEntriesReport: ITableData) => {
        this.searchEntriesTableData = searchEntriesReport.content.map((searchEntries: any) => {
          searchEntries.isCooperationEstablished = searchEntries.cooperationStatus.isCooperationEstablished;
          searchEntries.isCooperationActiveAfter30Days = searchEntries.cooperationStatus.isCooperationActiveAfter30Days;
          searchEntries.isCooperationActiveAfter6Months =
            searchEntries.cooperationStatus.isCooperationActiveAfter6Months;

          return searchEntries;
        });

        this.tableService.setPaginatorConfigFormServer(searchEntriesReport);
        this.paginatorSearchEntries = this.tableService.getPaginatorConfig();

        if (!searchEntriesReport.content.length) {
          this.showEmptyDataAlert();
        }
      });
  }

  showOverlayPanel(event: any, userData: any, overlaypanel: OverlayPanel) {
    this.userOverlay = userData;
    if (userData && userData.length) {
      overlaypanel.show(event);
    }
  }

  hideOverlayPanel(overlaypanel: OverlayPanel) {
    overlaypanel.hide();
  }

  clearFilter(filtersGroupName: string) {
    switch (filtersGroupName) {
      case 'eventLog':
        this.reportEventLog = {
          startDate: null,
          endDate: null,
          userEmail: null,
          kindOfReport: null
        };
        break;
      case 'searchEntries':
        this.reportSearchEntries = {
          startDate: null,
          endDate: null,
          userEmail: null,
          kind: null
        };
        break;
    }
  }

  generateEventLogReport() {
    this.reportsService
      .generateEventLogReports({
        startDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportEventLog.startDate),
        endDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportEventLog.endDate),
        userEmail: this.reportEventLog.userEmail,
        kindOfReport: this.reportEventLog.kindOfReport
      })
      .subscribe(response => {
        const headers = response.headers;
        const contentDisposition = headers.get('content-disposition').split('=');
        this.fileSaverService.save(response.body, contentDisposition[1]);
      });
  }

  generateSearchEntriesReport() {
    this.reportsService
      .generateSearchEntriesReports({
        startDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportSearchEntries.startDate),
        endDate: this.helperService.convertLocalDateToServerOnlyDate(this.reportSearchEntries.endDate),
        userEmail: this.reportSearchEntries.userEmail,
        kind: this.reportSearchEntries.kind
      })
      .subscribe(response => {
        const headers = response.headers;
        const contentDisposition = headers.get('content-disposition').split('=');
        this.fileSaverService.save(response.body, contentDisposition[1]);
      });
  }

  generateUserReport() {
    this.reportsService.generateUserReport(this.reportUsers).subscribe(response => {
      const headers = response.headers;
      const contentDisposition = headers.get('content-disposition').split('=');
      this.fileSaverService.save(response.body, contentDisposition[1]);
    });
  }

  isFilterFill(objectToCheck: any) {
    return this.helperService.checkAnyObjectFieldIsFill(objectToCheck);
  }

  showEmptyDataAlert(): void {
    this.msgs = [];
    this.msgs.push({ severity: 'info', summary: 'Informacja', detail: 'Brak danych' });
  }

  _getDictionaries() {
    zip(
      this._dictionariesService.getAllKindOfReport(),
      this._dictionariesService.getAllKindOfSearchEntries(),
      this._dictionariesService.getAllKindUserGenerateReport()
    ).subscribe(([KindOfReport, KindOfSearchEntries, UserGenerateReport]) => {
      this.kindOfUsersReport = (UserGenerateReport as IDefaultDict[]).map(usersReport => {
        return {
          label: usersReport.name,
          value: usersReport.id
        };
      });

      this.kindOfReportDict = (KindOfReport as IDefaultDict[]).map(kindOfReport => {
        return {
          label: kindOfReport.name,
          value: kindOfReport.id
        };
      });

      this.kindOfSearchEntries = (KindOfSearchEntries as IDefaultDict[]).map(kindOfSearchEntry => {
        return {
          label: kindOfSearchEntry.name,
          value: kindOfSearchEntry.id
        };
      });
    });
  }
}
