<div class="container-fluid my-4">
  <div class="row">
    <div class="col-md-6 col-3">
      <h4>Raporty o użytkownikach</h4>
    </div>
  </div>
</div>
<div class="main-form-group">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 col-6">
        <div class="mn-form-group">
          <label for="report_type">Typ raportu:</label>
          <p-dropdown
            [options]="kindOfUsersReport"
            [(ngModel)]="reportUsers.kind"
            [showClear]="true"
            placeholder="Wybierz z listy"
            styleClass="form-control"
            filter="true"
            id="report_type"
            name="report_type"
            ariaLabelledBy="report_type"
            inputId="report_type"
            ariaFilterLabel="Filtrowanie wyników"
            emptyFilterMessage="Brak wyników wyszukiwania"
          ></p-dropdown>
        </div>
      </div>
      <div class="col-md-9 col-6">
        <div class="d-flex justify-content-end mt-4">
          <button
            class="btn btn-primary"
            type="button"
            (click)="generateUserReport()"
            aria-label="Przycisk generowania raportu o użytkownikach"
            [disabled]="isFilterFill(reportUsers)"
          >
            Generuj raport
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid my-4">
  <div class="row">
    <div class="col-md-6 col-3">
      <h4>Raporty zdarzeń</h4>
    </div>
  </div>
</div>
<div class="main-form-group">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 col-6">
        <div class="form-group">
          <label for="reports_date_start">Data od:</label>
          <p-calendar
            [(ngModel)]="reportEventLog.startDate"
            styleClass="form-control"
            id="reports_date_start"
            name="reports_date_start"
          ></p-calendar>
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="form-group">
          <label for="reports_date_end">Data do:</label>
          <p-calendar
            [(ngModel)]="reportEventLog.endDate"
            styleClass="form-control"
            id="reports_date_end"
            name="reports_date_end"
          ></p-calendar>
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="form-group">
          <label for="reports_user">Adres e-mail użytkownika:</label>
          <input
            id="reports_user"
            class="form-control"
            type="text"
            [(ngModel)]="reportEventLog.userEmail"
            name="reports_user"
          />
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="mn-form-group">
          <label for="reports_cooperation">Współpraca:</label>
          <p-dropdown
            [options]="kindOfReportDict"
            [(ngModel)]="reportEventLog.kindOfReport"
            [showClear]="true"
            placeholder="Wybierz z listy"
            styleClass="form-control"
            filter="true"
            ariaLabelledBy="reports_cooperation"
            inputId="reports_cooperation"
            ariaFilterLabel="Filtrowanie wyników"
            emptyFilterMessage="Brak wyników wyszukiwania"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary mr-2"
            type="button"
            (click)="clearFilter('eventLog')"
            aria-label="Przycisk czyszczenia filtrów"
          >
            Wyczyść filtry
          </button>
          <button
            class="btn btn-primary mr-2"
            type="button"
            (click)="getEventLogsData()"
            aria-label="Przycisk pokazywania tabeli raportów zdarzeń"
          >
            Pokaż w tabeli
          </button>
          <button
            class="btn btn-primary"
            type="button"
            (click)="generateEventLogReport()"
            aria-label="Przycisk generowania raportów"
            [disabled]="isFilterFill(reportEventLog)"
          >
            Generuj raport
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="eventLogTableData?.length">
        <p-table
          [columns]="reportEventLogTableCols"
          [value]="eventLogTableData"
          [responsive]="true"
          [autoLayout]="true"
          styleClass="mn-table"
          [lazy]="true"
          sortMode="single"
          (onLazyLoad)="customSortEventLogReport($event)"
          [customSort]="true"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                <div class="d-flex">
                  {{ col.header }}
                  <p-sortIcon
                    [field]="col.field"
                    ariaLabel="Kliknij, aby posortować"
                    ariaLabelDesc="Kliknij, aby sortować w kolejności malejącej"
                    ariaLabelAsc="Kliknij, aby sortować w kolejności rosnącej"
                  ></p-sortIcon>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td *ngFor="let col of columns">
                <ng-container [ngSwitch]="col.custom">
                  <span *ngSwitchCase="'isDate'">
                    {{ rowData[col.field] | date: 'dd/MM/yyyy HH:mm:ss' }}
                  </span>
                  <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                </ng-container>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-paginator
          [rows]="paginatorEventLog.size"
          [totalRecords]="paginatorEventLog.totalElements"
          (onPageChange)="paginateEventLogReport($event)"
          [rowsPerPageOptions]="[10, 20, 50, 100]"
        ></p-paginator>
      </ng-container>
      <ng-container *ngIf="!eventLogTableData?.length">
        <p-messages [(value)]="msgs" [closable]="false"></p-messages>
      </ng-container>
    </div>
  </div>
</div>
<div class="container-fluid my-4">
  <div class="row">
    <div class="col-md-6 col-3">
      <h4>Raporty wyszukiwarki</h4>
    </div>
  </div>
</div>
<div class="main-form-group">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 col-6">
        <div class="form-group">
          <label for="reports_date_start">Data od:</label>
          <p-calendar
            [(ngModel)]="reportSearchEntries.startDate"
            styleClass="form-control"
            id="reports_date_start"
            name="reports_date_start"
          ></p-calendar>
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="form-group">
          <label for="reports_date_end">Data do:</label>
          <p-calendar
            [(ngModel)]="reportSearchEntries.endDate"
            styleClass="form-control"
            id="reports_date_end"
            name="reports_date_end"
          ></p-calendar>
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="form-group">
          <label for="reports_user">Adres e-mail użytkownika:</label>
          <input
            id="reports_user"
            class="form-control"
            type="text"
            [(ngModel)]="reportSearchEntries.userEmail"
            name="reports_user"
          />
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="mn-form-group">
          <label for="report_search_type">Typ:</label>
          <p-dropdown
            [options]="kindOfSearchEntries"
            [(ngModel)]="reportSearchEntries.kind"
            [showClear]="true"
            placeholder="Wybierz z listy"
            styleClass="form-control"
            filter="true"
            id="report_search_type"
            name="report_search_type"
            ariaLabelledBy="report_search_type"
            inputId="report_search_type"
            ariaFilterLabel="Filtrowanie wyników"
            emptyFilterMessage="Brak wyników wyszukiwania"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary mr-2"
            type="button"
            (click)="clearFilter('searchEntries')"
            aria-label="Przycisk czyszczenia filtrów"
          >
            Wyczyść filtry
          </button>
          <button
            class="btn btn-primary mr-2"
            type="button"
            (click)="getSearchEntriesData()"
            aria-label="Przycisk pokazywania tabeli raportów wyszukiwarki"
          >
            Pokaż w tabeli
          </button>
          <button
            class="btn btn-primary"
            type="button"
            (click)="generateSearchEntriesReport()"
            aria-label="Przycisk generowania raportów z wyszukiwarki"
            [disabled]="isFilterFill(reportSearchEntries)"
          >
            Generuj raport
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="searchEntriesTableData?.length">
        <p-table
          [columns]="searchEntriesTableCols"
          [value]="searchEntriesTableData"
          [responsive]="true"
          [autoLayout]="true"
          styleClass="mn-table"
          [lazy]="true"
          sortMode="single"
          (onLazyLoad)="customSortSearchEntriesReport($event)"
          [customSort]="true"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                *ngFor="let col of columns"
                [pSortableColumn]="col.sortProperty || col.field"
                [pSortableColumnDisabled]="col.disabledSort"
              >
                <div class="d-flex">
                  {{ col.header }}
                  <p-sortIcon
                    [field]="col.field"
                    *ngIf="!col.disabledSort"
                    ariaLabel="Kliknij, aby posortować"
                    ariaLabelDesc="Kliknij, aby sortować w kolejności malejącej"
                    ariaLabelAsc="Kliknij, aby sortować w kolejności rosnącej"
                  ></p-sortIcon>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td *ngFor="let col of columns">
                <ng-container [ngSwitch]="col.custom">
                  <span *ngSwitchCase="'isDate'">
                    {{ rowData[col.field] | date: 'dd/MM/yyyy HH:mm:ss' }}
                  </span>
                  <span *ngSwitchCase="'isHoverable'">
                    <span
                      *ngIf="rowData[col.field] && rowData[col.field].length"
                      (mouseenter)="showOverlayPanel($event, rowData[col.field], tableHoverUserList)"
                      (mouseleave)="hideOverlayPanel(tableHoverUserList)"
                      >{{ rowData[col.field].length }} użytkownik(ów)</span
                    >
                  </span>
                  <span *ngSwitchCase="'boolean'">{{ 'yesOrNoValue.' + rowData[col.field] | translate }}</span>
                  <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
                </ng-container>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-paginator
          [rows]="paginatorSearchEntries.size"
          [totalRecords]="paginatorSearchEntries.totalElements"
          (onPageChange)="paginateSearchEntriesReport($event)"
          [rowsPerPageOptions]="[10, 20, 50, 100]"
        ></p-paginator>
        <p-overlayPanel #tableHoverUserList appendTo="body">
          <ng-container *ngIf="userOverlay?.length">
            <div class="d-block" *ngFor="let users of userOverlay">
              <span>{{ users.username }} &lt;{{ users.email }}&gt;</span>
            </div>
          </ng-container>
        </p-overlayPanel>
      </ng-container>
      <ng-container *ngIf="!searchEntriesTableData?.length">
        <p-messages [(value)]="msgs" [closable]="false"></p-messages>
      </ng-container>
    </div>
  </div>
</div>
