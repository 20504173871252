<div class="ui-dialog-content">
  <div class="row">
    <div class="col-12 text-center">
      <img
        src="assets/images/register-icons/scientist-icon_{{ intelligentSpecialisationImageId }}.svg"
        alt="Specjalizacja naukowca"
        class="img-fluid"
      />
    </div>
  </div>
  <div class="spacer"></div>
  <form #scientistProfileEdit="ngForm" id="scientist_profile_edit">
    <fieldset [disabled]="isPreviewMode">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="login" class="required">Login:</label>
                <input
                  id="login"
                  class="form-control"
                  type="text"
                  name="login"
                  [(ngModel)]="userScientist.username"
                  disabled
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="firstName" class="required">Imię:</label>
                <input
                  id="firstName"
                  class="form-control"
                  type="text"
                  name="firstName"
                  [(ngModel)]="userScientist.firstName"
                  required
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="surname" class="required">Nazwisko:</label>
                <input
                  id="surname"
                  class="form-control"
                  type="text"
                  name="surname"
                  [(ngModel)]="userScientist.surname"
                  required
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="phoneNumber" class="required">Telefon:</label>
                <input
                  id="phoneNumber"
                  class="form-control"
                  type="text"
                  name="phoneNumber"
                  [(ngModel)]="userScientist.phoneNumber"
                  required
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="email" class="required">E-mail:</label>
                <input
                  id="email"
                  class="form-control"
                  type="text"
                  name="email"
                  [(ngModel)]="userScientist.email"
                  disabled
                />
              </div>
            </div>
            <div class="col-12">
              <div class="mn-form-group">
                <label class="required" for="academicTitleId">Tytuł naukowy:</label>
                <p-dropdown
                  [options]="academicTitlesDict"
                  [(ngModel)]="userScientist.academicTitleId"
                  placeholder="Wybierz z listy"
                  styleClass="form-control"
                  filter="true"
                  id="academicTitleId"
                  name="academicTitleId"
                  [disabled]="isPreviewMode"
                  ariaLabelledBy="academicTitleId"
                  inputId="academicTitleId"
                  ariaFilterLabel="Filtrowanie wyników"
                  emptyFilterMessage="Brak wyników wyszukiwania"
                  baseZIndex="100009"
                  required
                ></p-dropdown>
              </div>
            </div>
            <div class="col-12">
              <div class="mn-form-group">
                <label class="required" for="universityId">Uczelnia:</label>
                <p-dropdown
                  [options]="universitiesDict"
                  [(ngModel)]="userScientist.universityId"
                  (onChange)="filteredDepartmentByUniversity(userScientist.universityId)"
                  placeholder="Wybierz z listy"
                  styleClass="form-control"
                  filter="true"
                  id="universityId"
                  name="universityId"
                  ariaLabelledBy="universityId"
                  inputId="universityId"
                  ariaFilterLabel="Filtrowanie wyników"
                  emptyFilterMessage="Brak wyników wyszukiwania"
                  baseZIndex="100009"
                  required
                  [disabled]="isPreviewMode"
                ></p-dropdown>
              </div>
            </div>
            <div class="col-12">
              <div class="mn-form-group">
                <label for="department" class="required">Wydział:</label>
                <p-dropdown
                  [options]="departmentDict"
                  [(ngModel)]="userScientist.department"
                  editable="true"
                  placeholder="Wybierz z listy"
                  styleClass="form-control"
                  filter="true"
                  id="department"
                  name="department"
                  ariaLabelledBy="department"
                  inputId="department"
                  ariaFilterLabel="Filtrowanie wyników"
                  emptyFilterMessage="Brak wyników wyszukiwania"
                  [disabled]="!userScientist.universityId || isPreviewMode"
                  baseZIndex="100009"
                  required
                ></p-dropdown>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="scientificWorksDescription" class="required">Opis prac naukowych:</label>
                <textarea
                  id="scientificWorksDescription"
                  class="form-control"
                  name="scientificWorksDescription"
                  [(ngModel)]="userScientist.scientificWorksDescription"
                  [maxlength]="maxTextareaLength"
                ></textarea>
                <div class="mn-field-description text-right w-100">
                  <span *ngIf="userScientist.scientificWorksDescription" class="text-small text-muted"
                    >{{ userScientist.scientificWorksDescription.length }} / {{ maxTextareaLength }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="row">
            <div class="col-12">
              <div class="mn-form-group">
                <label for="scienceId" class="required">Klasyfikacja dziedzin i nauki:</label>
                <p-dropdown
                  [options]="sciencesDict"
                  [(ngModel)]="userScientist.scienceId"
                  (onChange)="changeModelScienceDomainDict(userScientist.scienceId)"
                  placeholder="Wybierz z listy"
                  styleClass="form-control"
                  filter="true"
                  id="scienceId"
                  name="scienceId"
                  ariaLabelledBy="scienceId"
                  inputId="scienceId"
                  ariaFilterLabel="Filtrowanie wyników"
                  emptyFilterMessage="Brak wyników wyszukiwania"
                  baseZIndex="100009"
                  required
                  [disabled]="isPreviewMode"
                ></p-dropdown>
              </div>
            </div>
            <div class="col-12">
              <div class="mn-form-group">
                <label for="scienceDomainId" class="required">Dziedzina:</label>
                <p-dropdown
                  [options]="scienceDomainSelectDict"
                  [(ngModel)]="userScientist.scienceDomainId"
                  (onChange)="changeModelSpecialisationDict(userScientist.scienceDomainId)"
                  placeholder="Wybierz z listy"
                  styleClass="form-control"
                  filter="true"
                  [disabled]="!userScientist.scienceId || isPreviewMode"
                  id="scienceDomainId"
                  name="scienceDomainId"
                  ariaLabelledBy="scienceDomainId"
                  inputId="scienceDomainId"
                  ariaFilterLabel="Filtrowanie wyników"
                  emptyFilterMessage="Brak wyników wyszukiwania"
                  baseZIndex="100009"
                  required
                >
                </p-dropdown>
              </div>
            </div>
            <div class="col-12">
              <div class="mn-form-group">
                <label for="leadingSpecialisationId" class="required">Wiodąca specjalizacja:</label>
                <p-dropdown
                  [options]="specialisationsDict"
                  [(ngModel)]="userScientist.leadingSpecialisationId"
                  placeholder="Wybierz z listy"
                  styleClass="form-control"
                  filter="true"
                  [disabled]="!userScientist.scienceId || !userScientist.scienceDomainId || isPreviewMode"
                  (onChange)="setInteligentSpecialisation(userScientist.leadingSpecialisationId)"
                  id="leadingSpecialisationId"
                  name="leadingSpecialisationId"
                  ariaLabelledBy="leadingSpecialisationId"
                  inputId="leadingSpecialisationId"
                  ariaFilterLabel="Filtrowanie wyników"
                  emptyFilterMessage="Brak wyników wyszukiwania"
                  baseZIndex="100009"
                  required
                ></p-dropdown>
              </div>
            </div>
            <div class="col-12">
              <div class="mn-form-group">
                <label for="intelligentSpecialisationId">Inteligentna specjalizacja:</label>
                <p-dropdown
                  [options]="intelligentSpecialisationDict"
                  [(ngModel)]="userScientist.intelligentSpecialisationId"
                  styleClass="form-control"
                  placeholder="Wybór automatyczny"
                  filter="true"
                  [disabled]="true"
                  id="intelligentSpecialisationId"
                  name="intelligentSpecialisationId"
                  ariaLabelledBy="intelligentSpecialisationId"
                  inputId="intelligentSpecialisationId"
                  ariaFilterLabel="Filtrowanie wyników"
                  emptyFilterMessage="Brak wyników wyszukiwania"
                  baseZIndex="100009"
                ></p-dropdown>
              </div>
            </div>
            <ng-container *ngIf="aditionalSpecialisationDict?.length && userScientist.leadingSpecialisationId">
              <div class="col-12" *ngFor="let specialisation of userScientist.specialisations; let idx = index">
                <div class="mn-form-group">
                  <label for="specialisation_{{ idx }}">Specjalizacja dodatkowa: </label>
                  <p-dropdown
                    [options]="aditionalSpecialisationDict"
                    [(ngModel)]="specialisation.id"
                    styleClass="form-control"
                    [showClear]="true"
                    filter="true"
                    placeholder="Wybierz specjalizację"
                    name="specialisation_{{ idx }}"
                    id="specialisation_{{ idx }}"
                    ariaLabelledBy="specialisation"
                    inputId="specialisation"
                    ariaFilterLabel="Filtrowanie wyników"
                    emptyFilterMessage="Brak wyników wyszukiwania"
                    baseZIndex="100009"
                    [disabled]="isPreviewMode"
                  ></p-dropdown>
                </div>
              </div>
              <div class="col-12" *ngIf="!isPreviewMode">
                <div class="form-group d-flex justify-content-start">
                  <button
                    type="button"
                    class="btn btn-primary"
                    aria-label="Przycisk dodaj specjalizację"
                    (click)="addSpecialisation()"
                  >
                    Dodaj specjalizację
                  </button>
                </div>
              </div>
            </ng-container>
            <div class="col-12">
              <div class="form-group">
                <label for="keyWords" class="required">Słowa kluczowe:</label>
                <p-chips
                  [(ngModel)]="userScientist.keyWords"
                  [addOnTab]="true"
                  [addOnBlur]="true"
                  (keydown)="keyWordsOnKeyDown($event)"
                  styleClass="form-control"
                  id="keyWords"
                  name="keyWords"
                  [disabled]="isPreviewMode"
                  required
                ></p-chips>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="numberOfProjects" class="required">Liczba projektów:</label>
                <input
                  id="numberOfProjects"
                  class="form-control"
                  type="number"
                  name="numberOfProjects"
                  [(ngModel)]="userScientist.numberOfProjects"
                  required
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="projectsDescription" class="required">Opis zrealizowanych projektów:</label>
                <textarea
                  id="projectsDescription"
                  class="form-control"
                  name="projectsDescription"
                  [(ngModel)]="userScientist.projectsDescription"
                  [maxlength]="maxTextareaLength"
                ></textarea>
                <div class="mn-field-description text-right w-100">
                  <span *ngIf="userScientist.projectsDescription" class="text-small text-muted"
                    >{{ userScientist.projectsDescription.length }} / {{ maxTextareaLength }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="descriptionOfCooperationWithEmployers" class="required"
                  >Opis współpracy z przedsiębiorcami:</label
                >
                <textarea
                  id="descriptionOfCooperationWithEmployers"
                  class="form-control"
                  name="descriptionOfCooperationWithEmployers"
                  [(ngModel)]="userScientist.descriptionOfCooperationWithEmployers"
                  [maxlength]="maxTextareaLength"
                ></textarea>
                <div class="mn-field-description text-right w-100">
                  <span *ngIf="userScientist.descriptionOfCooperationWithEmployers" class="text-small text-muted"
                    >{{ userScientist.descriptionOfCooperationWithEmployers.length }} / {{ maxTextareaLength }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!isPreviewMode">
        <div class="col-12">
          <div class="d-flex justify-content-end">
            <div
              pTooltip="Uzupełnij wszystkie pola"
              tooltipPosition="top"
              [tooltipDisabled]="scientistProfileEdit.form.valid"
            >
              <button
                class="btn btn-primary"
                type="button"
                aria-label="Przycisk zapisania zmian"
                (click)="save()"
                [disabled]="!scientistProfileEdit.form.valid"
              >
                Zapisz
              </button>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>
