import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { NotificationService } from '@app/shared/notifications/notification.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private _httpNotificationHandler: NotificationService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: any): Observable<any> {
    switch (response.status) {
      case 500:
        this._httpNotificationHandler.sendMessage({
          type: 'error',
          title: 'Wewnętrzny błąd serwera',
          message: 'Skontaktuj się z serwisem',
          error: response.error || null
        });
        break;
      case 401:
        this._httpNotificationHandler.sendMessage({
          type: 'error',
          title: 'Autoryzacja',
          message: response.error && response.error.message ? response.error.message : 'Niepoprawne dane logowania',
          error: response.error || null
        });

        break;
      case 404:
        this._httpNotificationHandler.sendMessage({
          type: 'error',
          title: 'Błąd!',
          message: 'Nie znaleziono strony',
          error: response.error || null
        });
        break;
      default:
        this._httpNotificationHandler.sendMessage({
          type: 'error',
          title: response.error.error,
          message: response.error.message,
          error: response.status === 400 ? response.error : null
        });
        break;
    }
    throw response;
  }
}
