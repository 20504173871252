import { WcagModule } from './../../shared/wcag/wcag.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileModule } from './user-profile/user-profile.module';
import { RouterModule } from '@angular/router';
import { RegisterModule } from './register/register.module';
import { MailNotificationModule } from './mail-notification/mail-notification.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule, UserProfileModule, RegisterModule, MailNotificationModule, WcagModule]
})
export class UserAppModule {}
