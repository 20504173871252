<div class="container">
  <div class="row">
    <div class="col-12">
      <h2 class="mb-3">Deklaracja dostępności</h2>
      <h3>Wstęp do Deklaracji</h3>
      <p id="a11y-wstep">
        <span id="a11y-podmiot">Urząd Marszałkowski Województwa Małopolskiego</span> zobowiązuje się zapewnić dostępność
        swojej strony internetowej zgodnie z przepisami ustawy z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron
        internetowych i aplikacji mobilnych podmiotów publicznych. Oświadczenie w sprawie dostępności ma zastosowanie do
        strony internetowej Wiedza Małopolska
        <a id="a11y-url" href="https://wiedza.malopolska.pl">www.wiedza.malopolska.pl</a>.
      </p>
      <p>Data publikacji strony internetowej: <span id="a11y-data-publikacja">2020-04-14</span>.</p>
      <p>Data ostatniej istotnej aktualizacji: <span id="a11y-data-aktualizacja">2022-01-04</span>.</p>
      <p id="a11y-status">
        Strona internetowa jest <strong> częściowo zgodna </strong> z ustawą z dnia 4 kwietnia 2019 r. o dostępności
        cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych.
      </p>
      <p><strong>Niezgodności:</strong></p>
      <ul>
        <li>Pominięty poziom nagłówka</li>
      </ul>
      <p>Oświadczenie sporządzono dnia <span id="a11y-data-sporzadzenie">2022-01-04</span>.</p>
      <p>Data ostatniego przeglądu i aktualizacji 2023-03-08.</p>
      <p>Deklarację sporządzono na podstawie samooceny przeprowadzonej przez podmiot publiczny.</p>
      <h3 id="a11y-kontakt">Informacje zwrotne i dane kontaktowe</h3>
      <p>W przypadku problemów z dostępnością strony internetowej prosimy o kontakt:</p>
      <p><span id="a11y-osoba">Marek Pietras</span> - koordynator do spraw dostępności cyfrowej w Urzędzie:</p>
      <p style="padding-left: 30px;">
        Adres mailowy: <a href="mailto: dostepnosc@umwm.malopolska.pl" id="a11y-email">dostepnosc@umwm.malopolska.pl</a
        ><br />Telefon: <span id="a11y-telefon">12 63 03 502</span><br />Telefon komórkowy:
        <span id="a11y-telefon">516 676 510</span> (SMS).
      </p>
      <p>
        Uwaga! Tą samą drogą można składać wnioski o udostępnienie informacji niedostępnej oraz składać żądania
        zapewnienia dostępności cyfrowej.
      </p>
      <p id="a11y-procedura">
        Uprzejmie informujemy, że zgodnie z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych
        i aplikacji mobilnych podmiotów publicznych każdy ma prawo wystąpić do podmiotu publicznego z żądaniem
        udostępnienia cyfrowego wskazanej strony internetowej, aplikacji mobilnej lub ich elementów, ewentualnie
        zapewnienia dostępu alternatywnego, na warunkach określonych w ustawie. W przypadku odmowy wnoszący żądanie
        możne złożyć skargę z zastosowaniem przepisów ustawy z dnia 14 czerwca 1960 r. Kodeks postępowania
        administracyjnego, a także powiadomić Rzecznika Praw Obywatelskich:
        <a
          href="https://www.rpo.gov.pl/"
          target="_blank"
          rel="noopener"
          title="Strona internetowa Rzecznika Praw Obywatelskich"
          >www.rpo.gov.pl</a
        >
      </p>
      <h3 id="a11y-architektura">Dostępność architektoniczna</h3>
      <p>Wykaz lokalizacji/budynków użytkowanych przez Urząd:</p>
      <p><strong>Kraków:</strong></p>
      <ol>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1495135,siedziba-przy-ulicy-raclawickiej-56.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >ul. Racławicka 56 budynek A</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1495135,siedziba-przy-ulicy-raclawickiej-56.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >ul. Racławicka 56 budynek B</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1603826,siedziba-przy-ulicy-basztowej-22.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >ul. Basztowa 22</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1968730,siedziba-przy-ul-zacisze-5-i-7.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >ul. Zacisze 5</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1968730,siedziba-przy-ul-zacisze-5-i-7.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >ul. Zacisze 7</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1737637,siedziba-przy-ulicy-krolewskiej-57.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >ul. Królewska 57</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1549007,siedziba-przy-ulicy-wielickiej-72.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >ul. Wielicka 72 budynek A</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1549007,siedziba-przy-ulicy-wielickiej-72.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >ul. Wielicka 72 budynek B</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1549369,siedziba-na-osiedlu-teatralnym-4a.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >os. Teatralne 4a</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,2143604,siedziba-przy-ulicy-radziwillowskiej-1.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >ul. Radziwiłowska 1</a
          >
        </li>
      </ol>
      <p><strong>Pozostałe:</strong></p>
      <ol>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1633231,agenda-zamiejscowa-w-tarnowie.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >Agenda zamiejscowa w Tarnowie, al. Solidarności 5-9</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1633225,agenda-zamiejscowa-w-nowym-saczu.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >Agenda zamiejscowa w Nowym Sączu, ul. Jagiellońska 52</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1633218,agenda-zamiejscowa-w-oswiecimiu.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >Agenda zamiejscowa w Oświęcimiu, ul. Górnickiego 1</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1628285,lokalny-punkt-informacyjny-funduszy-europejskich-w-nowym-targu.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >Agenda zamiejscowa w Nowym Targu, al. 1000-lecia 44</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1938127,agenda-zamiejscowa-w-miechowie.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >Agenda zamiejscowa w Miechowie, ul. Warszawska 10</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,2075395,agenda-zamiejscowa-w-zakopanem.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >Agenda zamiejscowa w Zakopanem, ul. Kościeliska 7</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1628290,lokalny-punkt-informacyjny-funduszy-europejskich-w-tarnowie.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >Lokalny Punkt Informacyjny Funduszy Europejskich w Tarnowie, al. Solidarności 5-9</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1628281,lokalny-punkt-informacyjny-funduszy-europejskich-w-nowy-saczu.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >Lokalny Punkt Informacyjny Funduszy Europejskich w Nowym Sączu, ul. Wazów 3</a
          >
        </li>
        <li>
          <a
            href="https://bip.malopolska.pl/umwm,a,1628285,lokalny-punkt-informacyjny-funduszy-europejskich-w-nowym-targu.html"
            target="_blank"
            rel="noopener"
            title="Link otwiera się w nowym oknie"
            >Lokalny Punktu Informacyjny Funduszy Europejskich w Nowym Targu, al. 1000-lecia 44</a
          >
        </li>
      </ol>
      <p>
        <a href="https://bip.malopolska.pl/umwm,m,307958,lokalizacje.html" target="_blank" rel="noopener"
          >Charakterystyka poszczególnych budynków uwzględniająca wymagania minimalne dostępności architektonicznej</a
        >
        zgodne z art. 6 ustawy z dnia jest 19 lipca 2019 r. o zapewnieniu dostępności osobom ze szczególnymi potrzebami
        zamieszczona jest na stronach Biuletynu Informacji Publicznej (BIP) Urzędu.
      </p>
      <p>
        W każdej z lokalizacji Urzędu znajduje się zewnętrzna
        <strong>tablica informacyjna. Ponadto wewnątrz budynków znajdują się </strong
        ><strong>tablice informacyjne wraz z numerami pięter i pokoi </strong>zajmowanych przez Urząd. W budynkach
        wielopiętrowych podobne informacje znajdują się w windach osobowych.W większości lokalizacji na poziomie parteru
        znajduje się
        <strong>punkt obsługi klienta i/lub portiernia, których pracownicy udzielają informacji nt. </strong>rozkładu
        pomieszczeń w budynku. Informacja głosowa znajduje się również przy opisie
        <a
          href="https://bip.malopolska.pl/umwm,m,307958,lokalizacje.html"
          target="_blank"
          rel="noopener"
          title="Link otwiera się w nowym oknie przeglądarki"
          >dostępności architektonicznej budynków</a
        >
        zamieszczonym na stronie BIP Urzędu.
      </p>
      <p>Urząd zapewnia wstęp do budynków osobom korzystającym z <strong>psa asystującego</strong>.</p>
      <p>
        W przypadku ogłoszenia ewakuacji w budynkach uruchamiane są, w zależności od lokalizacji, Dźwiękowy System
        Ostrzegawczy (DSO), sygnalizacja dźwiękowa lub alarmowa. W pozostałych lokalizacjach w celu sygnalizacji alarmu
        wykorzystywane są tuby nagłaśniające i komunikaty głosowe.
      </p>
      <p>
        We wszystkich lokalizacjach Urzędu ewakuacja osób ze szczególnymi potrzebami opiera się na wsparciu udzielanym
        przez osoby funkcyjne wyznaczone do ewakuacji w każdej lokalizacji/na każdym piętrze budynku.
      </p>
      <p>
        W lokalizacjach w Krakowie przy ul. Racławickiej 56, ul. Basztowej 22, ul. Radziwiłłowskiej 1, ul. Wielickiej 72
        oraz w Nowym Sączu przy ul. Jagiellońskiej 52 i w Tarnowie przy al. Solidarności 5-9 znajdują się dodatkowo
        krzesła do ewakuacji osób ze szczególnymi potrzebami.
      </p>
      <h3>Dostępność informacyjno-komunikacyjna</h3>
      <p>
        Urząd zapewnia usługę <strong>tłumacza języka migowego</strong> dostępną z poziomu strony internetowej
        <a
          href="https://www.malopolska.pl/deklaracja-dostepnosci/wideotlumacz"
          target="_blank"
          rel="noopener"
          title="Link otwiera się w nowym oknie przeglądarki"
          >www.malopolska.pl</a
        >. Klient korzystający z takiej formy komunikacji może samodzielnie wykonać zdalne połączenie z tłumaczem lub
        skorzystać z tej usługi w siedzibie Urzędu, wszędzie tam, gdzie znajdują się odpowiednio wyposażone stanowiska
        pracy - komputer z kamerą, dostęp do sieci internetowej, mikrofon/głośnik. Usługa dostępna jest we wszystkich
        lokalizacjach Urzędu. Oznaczenie usługi tłumacza języka migowego - piktogram migających dłoni - jest umieszczone
        w punktach obsługi klienta oraz przy sekretariatach w lokalizacjach nie posiadających wyodrębnionych stref
        obsługi.
      </p>
      <p>
        Wsparciem w komunikacji dla osób słabosłyszących są pętle indukcyjne znajdujące się w następujących
        lokalizacjach:
      </p>
      <ul>
        <li>
          przy ul. Wielickiej 72 (budynek B) - Główny Punkt Informacyjny Funduszy Europejskich oraz sala konferencyjna
          nr 106,
        </li>
        <li>
          przy ul. Racławickiej 56 (budynek B) – punkt obsługi klienta (parter) oraz sala Sejmiku Województwa
          Małopolskiego (siódme piętro),
        </li>
        <li>przy ul. Basztowej 22 – punkt obsługi klienta (parter),</li>
        <li>w Lokalnych Punktach Funduszy Europejskich – stanowiska obsługi klienta.</li>
      </ul>
      <p>
        Na stronie internetowej Urzędu znajduje się
        <a
          href="https://www.malopolska.pl/deklaracja-dostepnosci/informacje-dla-osob-gluchych"
          target="_blank"
          rel="noopener"
          title="Link otwiera się w nowym oknie przeglądarki"
          >informacja o zakresie działalności Urzędu w Polskim Języku Migowym</a
        >
        oraz plik zawierający ten sam tekst odczytywalny maszynowo.
      </p>
      <p>
        Na stronie internetowej Urzędu znajduje się
        <a
          href="https://www.malopolska.pl/deklaracja-dostepnosci/informacja-o-urzedzie-marszalkowskim-w-tekscie-latwym-do-czytania-i-zrozumienia"
          target="_blank"
          rel="noopener"
          title="Link otwiera się w nowym oknie przeglądarki"
          >informacja o zakresie działalności Urzędu w tekście łatwym do czytania i zrozumienia (ETR)</a
        >.
      </p>
      <p>Na wniosek osoby ze szczególnymi potrzebami Urząd zapewnia obsługę w formie określonej w tym wniosku.</p>
      <p>
        W przypadku wniosku dotyczącego obsługi klienta ze szczególnymi potrzebami w pomieszczeniu zlokalizowanym
        bezpośrednio przy wejściu do budynku Urzędu pracownik realizujący usługę schodzi do klienta. Wniosek o ten
        rodzaj obsługi można zgłosić za pośrednictwem portierni, telefonicznie
        <a href="tel:+48126303333">12 630 33 33</a> lub za pośrednictwem wiadomości e-mail
        <a href="mailto:urzad@umwm.malopolska.pl">urzad@umwm.malopolska.pl</a>
      </p>
      <h3>Koordynatorzy Dostępności w Urzędzie Marszałkowskim</h3>
      <p>
        <strong>Marta Zając - koordynator do spraw dostępności architektonicznej i informacyjno-komunikacyjnej</strong>
      </p>
      <p style="padding-left: 30px;">
        Telefon: <a href="tel:+48126303250">12 63 03 250</a><br />Adres mailowy:
        <a href="mailto:Marta.Zajac@umwm.malopolska.pl">Marta.Zajac@umwm.malopolska.pl</a>
      </p>
      <p><strong>Marek Pietras - koordynator do spraw dostępności cyfrowej</strong></p>
      <p style="padding-left: 30px;">
        Telefon: <a href="tel:+48126303502">12 63 03 502</a><br />Telefon komórkowy:
        <a href="tel:+48516676510">516 676 510</a> (SMS)<br />Adres mailowy:
        <a href="mailto:Marek.Pietras@umwm.malopolska.pl">Marek.Pietras@umwm.malopolska.pl</a>.
      </p>
    </div>
  </div>
</div>
