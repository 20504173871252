import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-declaration-availability',
  templateUrl: './declaration-availability.component.html',
  styleUrls: ['./declaration-availability.component.scss']
})
export class DeclarationAvailabilityComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
