import { RegisterComponent } from './register.component';
import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
var ɵ0 = { title: extract('Rejestracja') }, ɵ1 = { title: extract('Rejestracja') };
var routes = [
    Shell.childRoutes([
        {
            path: 'register',
            component: RegisterComponent,
            data: ɵ0
        },
        {
            path: 'register/:successUserType',
            component: RegisterComponent,
            data: ɵ1
        }
    ])
];
var RegisterRoutingModule = /** @class */ (function () {
    function RegisterRoutingModule() {
    }
    return RegisterRoutingModule;
}());
export { RegisterRoutingModule };
export { ɵ0, ɵ1 };
