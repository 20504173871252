import { HelperService } from '@app/services/helper.service';
import {
  IReportsEventLogRequest,
  IReportsSearchEntriesRequest,
  IUsersGenerateReport
} from './../interfaces/reports.interface';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { IPaginatorServerConfig, ITableData } from '@app/interfaces/table.interface';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  constructor(private _http: HttpClient, private helperService: HelperService) {}

  generateEventLogReports(requestParams: IReportsEventLogRequest): Observable<HttpResponse<any>> {
    const queryParams: HttpParams = this.helperService.buildQueryParams(requestParams);

    return this._http.get('/admin/reports/logs/generate', {
      params: queryParams,
      responseType: 'blob',
      observe: 'response'
    });
  }

  generateSearchEntriesReports(requestParams: IReportsSearchEntriesRequest): Observable<HttpResponse<any>> {
    const queryParams: HttpParams = this.helperService.buildQueryParams(requestParams);

    return this._http.get('/admin/reports/search-entries/generate', {
      params: queryParams,
      responseType: 'blob',
      observe: 'response'
    });
  }

  generateUserReport(reportUsers: IUsersGenerateReport): Observable<HttpResponse<any>> {
    const queryParam = this.helperService.buildQueryParams(reportUsers);
    return this._http.get('/admin/reports/users/generate', {
      responseType: 'blob',
      observe: 'response',
      params: queryParam
    });
  }

  getTableEventLogReport(paginatorConfig: IPaginatorServerConfig): Observable<ITableData> {
    return this._http.get<ITableData>('/admin/reports/logs', paginatorConfig);
  }

  getTableSearchEntriesReport(paginatorConfig: IPaginatorServerConfig): Observable<ITableData> {
    return this._http.get<ITableData>('/admin/reports/search-entries', paginatorConfig);
  }
}
