import { HelperService } from '@app/services/helper.service';
import { ConfigurationAppService } from './services/configuration-app.service';
import { CredentialsService, Credentials } from './core/authentication/credentials.service';
import { AuthenticationService } from './core/authentication/authentication.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, I18nService, untilDestroyed } from '@app/core';
import { NotificationService } from './shared/notifications/notification.service';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  maintenanceAccess: boolean;
  globalMaintenance: boolean;
  isLogged: boolean;
  userLogin: any = {
    login: null,
    password: null
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private configurationAppService: ConfigurationAppService,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    // App version
    log.info('1.21v');

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    this.configurationAppService.getGlobalWebsiteConfig().subscribe(config => {
      this.configurationAppService.setMaintenance(config.maintenance);

      // Set meta tags
      this.metaService.addTags([
        { name: 'keywords', content: config.keyWords },
        { name: 'description', content: config.description },
        { name: 'robots', content: 'index, follow' }
      ]);

      // Setup logger
      if (environment.production) {
        Logger.enableProductionMode();
      }

      log.debug('init');

      const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

      // Change page title on navigation or language change, based on route data
      merge(this.translateService.onLangChange, onNavigationEnd)
        .pipe(
          map(() => {
            let route = this.activatedRoute;
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter(route => route.outlet === 'primary'),
          switchMap(route => route.data),
          untilDestroyed(this)
        )
        .subscribe(event => {
          // Check is user Limited, if yes => redirect to fill profile
          this.helperService.limitedUserRedirect();

          const title = event.title;
          if (title) {
            this.titleService.setTitle(config.name + ' ' + this.translateService.instant(title));
          }
        });
    });
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }
}
