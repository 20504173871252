<div class="container" *ngIf="!maintenanceAccess && globalMaintenance">
  <div class="row mt-4">
    <div class="col-12 text-center mt-4">
      <h2>Trwa konserwacja witryny.</h2>
      <p>Zapraszamy ponownie później.</p>
      <p *ngIf="!isLogged">Jeśli jesteś administratorem, możesz się zalogować.</p>
    </div>
  </div>
  <div class="row mt-5" *ngIf="!isLogged">
    <div class="col-6">
      <div class="form-group">
        <label for="login">Login</label>
        <input type="text" [(ngModel)]="userLogin.login" class="form-control" id="login" name="login" />
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="password">Hasło</label>
        <input type="password" [(ngModel)]="userLogin.password" class="form-control" id="password" name="password" />
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <button aria-label="Przycisk logowania" class="btn btn-primary" (click)="login()">Zaloguj</button>
      </div>
    </div>
  </div>
</div>

<app-header *ngIf="maintenanceAccess || !globalMaintenance"></app-header>
<main *ngIf="maintenanceAccess || !globalMaintenance">
  <router-outlet></router-outlet>
</main>
<app-loader></app-loader>
<app-notifications></app-notifications>
<app-dialog-base></app-dialog-base>
<app-footer *ngIf="maintenanceAccess || !globalMaintenance"></app-footer>
