import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-base',
  templateUrl: './about-base.component.html',
  styleUrls: ['./about-base.component.scss']
})
export class AboutBaseComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
