import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationDialogsModule } from '@app/entities/registration-dialogs/registration-dialogs.module';
import { AboutBaseModule } from './about-base/about-base.module';
import { KnowledgeTransferCentersModule } from './knowledge-transfer-centers/knowledge-transfer-centers.module';
import { ProblemBaseModule } from './problem-base/problem-base.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, AboutBaseModule, ProblemBaseModule, KnowledgeTransferCentersModule, RegistrationDialogsModule]
})
export class EntitiesModule {}
