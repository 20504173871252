<div class="slidenav-fixed-heigh"></div>
<div class="slidenav" tabindex="1" *ngIf="isMobileLayout">
  <div
    class="slidenav-backdrop hide-menu animated"
    [ngClass]="{ fadeInLeft: !menuHidden, fadeOutLeft: menuHidden, 'hide-menu': startHideMenu }"
  >
    <div class="slidenav-menu animated" [ngClass]="{ fadeInLeft: !menuHidden, fadeOutLeft: menuHidden }">
      <div class="col-12 d-flex justify-content-end slidenav-menu-cross">
        <button class="btn" (click)="toggleMenu()" aria-label="Przycisk schowaj menu" tabindex="3">
          <i class="fas fa-times"></i><span class="sr-only">Schowaj menu</span>
        </button>
      </div>
      <div class="col-12 slidenav-menu-info">
        <div class="d-flex justify-content-center">
          <a routerLink="/search" (click)="toggleMenu()" title="Małopolska nauka - strona główna" tabindex="4">
            <img [src]="mobileLogo" alt="Małopolska nauka - logo" class="img-fluid" />
          </a>
        </div>
      </div>
      <div class="col-12 px-0 pb-3 slidenav-menu-nav">
        <ul class="navbar-nav mr-auto mt-3 mt-lg-0">
          <li class="nav-item">
            <a class="nav-link" routerLink="/about-base" routerLinkActive="active" (click)="toggleMenu()">
              O bazie
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/knowledge-transfer-centers"
              routerLinkActive="active"
              (click)="toggleMenu()"
            >
              O centrach transferu wiedzy
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/problem-base" routerLinkActive="active">
              <span>Baza problemów</span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link" routerLink="/user-management" routerLinkActive="active" (click)="toggleMenu()"
              >Użytkownicy
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link" routerLink="/configuration-app" routerLinkActive="active" (click)="toggleMenu()"
              >Konfiguracja
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
          <li class="nav-item" *ngIf="isAdmin" (click)="toggleMenu()">
            <a class="nav-link" routerLink="/event-log" routerLinkActive="active"
              >Dziennik zdarzeń
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
          <li class="nav-item" *ngIf="isAdmin" (click)="toggleMenu()">
            <a class="nav-link" routerLink="/raports" routerLinkActive="active"
              >Raporty
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
        </ul>
      </div>
      <form
        #loginForm="ngForm"
        *ngIf="!isLogged"
        (ngSubmit)="login(loginForm.form.value.username_slidenav, loginForm.form.value.password_slidenav)"
      >
        <div class="col-12">
          <div class="form-group">
            <label for="username_slidenav">Login:</label>
            <span class="input-icon" *ngIf="loginForm.form.value.username">
              <i class="fas fa-check text-primary" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              class="form-control login-field"
              id="username_slidenav"
              name="username_slidenav"
              required
              aria-labelledby="username_slidenav"
              ngModel
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="password_slidenav">Hasło:</label>
            <span class="input-icon" *ngIf="loginForm.form.value.password">
              <i class="fas fa-check text-primary" aria-hidden="true"></i>
            </span>
            <input
              type="password"
              class="form-control login-field"
              id="password_slidenav"
              name="password_slidenav"
              aria-labelledby="password_slidenav"
              required
              ngModel
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group login-button">
            <button
              type="submit"
              class="btn btn-primary w-100"
              aria-label="Przycisk logowania"
              [disabled]="loginForm.form.invalid"
              (click)="toggleMenu()"
            >
              Zaloguj
            </button>
          </div>
        </div>
      </form>
      <p class="col-12 text-right" *ngIf="!isLogged">
        Jeśli nie pamiętasz hasła, <a routerLink="/reset" (click)="toggleMenu()">kliknij tutaj</a>
      </p>
      <div class="col-12 d-flex justify-content-center" *ngIf="isLogged">
        <div ngbDropdown class="d-inline-block" display="dynamic">
          <button
            class="btn d-flex align-items-center"
            id="userLoggedSlidenav"
            ngbDropdownToggle
            aria-label="Przycisk ułatwiania dostępu"
          >
            <div class="d-flex align-items-center">
              <div class="mr-3">
                <img
                  src="assets/images/scientist-icon.png"
                  class="img-fluid profile-icon"
                  alt="Profil naukowca"
                  *ngIf="user.scientistId !== null"
                />
                <img
                  src="assets/images/employer-icon.png"
                  class="img-fluid profile-icon"
                  alt="Profil przedsiębiorcy"
                  *ngIf="user.employerId !== null"
                />
                <i
                  class="fas fa-user-alt admin-profile"
                  *ngIf="user.scientistId === null && user.employerId === null"
                ></i>
              </div>
              <div class="mr-2">
                <span>{{ user.username }}</span>
              </div>
            </div>
          </button>
          <div ngbDropdownMenu aria-labelledby="userLoggedSlidenav">
            <button
              ngbDropdownItem
              class="btn w-100 d-flex justify-content-start align-items-center py-1"
              routerLink="/profile"
              aria-label="Przycisk podglądu profilu"
              (click)="toggleMenu()"
            >
              <i class="fas fa-user mr-2"></i>
              <span>Profil</span>
            </button>
            <button
              ngbDropdownItem
              class="btn w-100 d-flex justify-content-start align-items-center py-1"
              routerLink="/communicator"
              (click)="toggleMenu()"
              aria-label="Przycisk przejscia do komunikatora"
            >
              <i class="fas fa-envelope mr-2"></i>
              <span>Komunikator</span>
            </button>
            <button
              ngbDropdownItem
              class="btn w-100 d-flex justify-content-start align-items-center py-1"
              (click)="logout(); toggleMenu()"
              aria-label="Przycisk wyloguj"
            >
              <i class="fas fa-power-off mr-2"></i>
              <span>Wyloguj</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid mobile-header">
    <div class="row">
      <div class="col-6">
        <button class="btn" (click)="toggleMenu()" aria-label="Przycisk rozwiń menu" tabindex="2">
          <i class="fas fa-bars"></i><span class="sr-only">Rozwiń menu</span>
        </button>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <div ngbDropdown class="d-inline-block" placement="bottom-right">
          <button
            class="btn"
            id="accessibility"
            ngbDropdownToggle
            aria-label="Przycisk ułatwiania dostępu"
            tabindex="3"
          >
            Dostępność
          </button>
          <div ngbDropdownMenu aria-labelledby="accessibility">
            <app-wcag [isAdmin]="isAdmin" [isMobile]="isMobileLayout"></app-wcag>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<header>
  <div class="sm-hide-item">
    <app-wcag [isAdmin]="isAdmin" [isMobile]="isMobileLayout"></app-wcag>
  </div>
  <div [ngClass]="{ 'container-fluid': isAdmin, container: !isAdmin }">
    <div class="row mn-header align-items-center">
      <div class="col-lg-6 col-12 mn-logo-container">
        <a routerLink="/search" title="Małopolska nauka - strona główna" tabindex="{{ isMobileLayout ? 10 : 7 }}">
          <div class="mn-logo">
            <span class="sr-only">Małopolska nauka - strona główna</span>
          </div>
        </a>
      </div>
      <div class="d-lg-block d-md-none d-sm-none d-xs-none col-6 mn-logo-container">
        <div class="mn-logo-pattern" [ngClass]="{ 'mn-bg-size-auto': isAdmin, 'mn-bg-size-100': !isAdmin }"></div>
      </div>
    </div>
  </div>
  <div class="sm-hide-item" [ngClass]="{ 'container-fluid': isAdmin, container: !isAdmin }">
    <div class="row">
      <div class="col-12">
        <nav class="navbar navbar-expand-lg navbar-light px-0">
          <a
            href="https://www.spin.malopolska.pl/"
            target="_blank"
            class="spin-logo navbar-brand"
            [ngClass]="{ 'admin-spin-hide-sm': isAdmin }"
            tabindex="8"
          >
            <img
              src="assets/logo_spin.png"
              class="img-fluid max-spin-logo-width"
              alt="SPIN Małopolskie Centra Transferu Wiedzy"
            />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
            class="main-navbar collapse navbar-collapse"
            [ngClass]="{ 'admin-main-navbar': isAdmin }"
            id="navbarSupportedContent"
          >
            <ul class="link-navbar-nav navbar-nav mr-auto">
              <li class="nav-item">
                <a class="nav-link" routerLink="/about-base" routerLinkActive="active" tabindex="9">
                  <span>O bazie</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/knowledge-transfer-centers" routerLinkActive="active" tabindex="9">
                  <span>O centrach transferu wiedzy</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/problem-base" routerLinkActive="active" tabindex="9">
                  <span>Baza problemów</span>
                </a>
              </li>
              <li class="nav-item" *ngIf="isAdmin">
                <a class="nav-link" routerLink="/user-management" routerLinkActive="active" tabindex="9"
                  ><span>Użytkownicy</span></a
                >
              </li>
              <li class="nav-item" *ngIf="isAdmin">
                <a class="nav-link" routerLink="/configuration-app" routerLinkActive="active" tabindex="9"
                  ><span>Konfiguracja</span>
                </a>
              </li>
              <li class="nav-item" *ngIf="isAdmin">
                <a class="nav-link" routerLink="/event-log" routerLinkActive="active" tabindex="9"
                  ><span>Dziennik zdarzeń</span></a
                >
              </li>
              <li class="nav-item" *ngIf="isAdmin">
                <a class="nav-link" routerLink="/raports" routerLinkActive="active" tabindex="9"
                  ><span>Raporty</span></a
                >
              </li>
            </ul>
            <div class="nav-item dropdown hideOnNormalText" *ngIf="!isLogged">
              <button
                class="btn btn-outline-primary my-2 my-sm-0 nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Logowanie
              </button>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <form
                  class="w-auto d-flex align-items-end mx-2 my-2 my-lg-0 px-0"
                  #loginForm="ngForm"
                  (ngSubmit)="login(loginForm.form.value.username, loginForm.form.value.password)"
                >
                  <div class="form-group mr-2 input-box">
                    <label for="username">Login:</label>
                    <span class="input-icon" *ngIf="loginForm.form.value.username">
                      <i class="fas fa-check text-primary" aria-hidden="true"></i>
                    </span>
                    <input
                      type="text"
                      class="form-control login-field"
                      id="username"
                      name="username"
                      aria-labelledby="username"
                      required
                      ngModel
                    />
                  </div>
                  <div class="form-group mr-2 input-box">
                    <label for="password">Hasło:</label>
                    <span class="input-icon" *ngIf="loginForm.form.value.password">
                      <i class="fas fa-check text-primary" aria-hidden="true"></i>
                    </span>
                    <input
                      type="password"
                      class="form-control login-field"
                      id="password"
                      name="password"
                      aria-labelledby="password"
                      required
                      ngModel
                    />
                  </div>
                  <div class="form-group login-button">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      [disabled]="loginForm.form.invalid"
                      aria-label="Przycisk logowania"
                      (click)="toggleMenu()"
                    >
                      Zaloguj
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <form
              class="w-auto d-flex align-items-end my-2 my-lg-0 px-0 hideOnIncreasedText"
              #loginForm="ngForm"
              *ngIf="!isLogged"
              (ngSubmit)="login(loginForm.form.value.username, loginForm.form.value.password)"
            >
              <div class="form-group mr-2 input-box">
                <label for="username">Login:</label>
                <span class="input-icon" *ngIf="loginForm.form.value.username">
                  <i class="fas fa-check text-primary" aria-hidden="true"></i>
                </span>
                <input
                  type="text"
                  class="form-control login-field"
                  id="username"
                  name="username"
                  aria-labelledby="username"
                  required
                  ngModel
                />
              </div>
              <div class="form-group mr-2 input-box">
                <label for="password">Hasło:</label>
                <span class="input-icon" *ngIf="loginForm.form.value.password">
                  <i class="fas fa-check text-primary" aria-hidden="true"></i>
                </span>
                <input
                  type="password"
                  class="form-control login-field"
                  id="password"
                  name="password"
                  aria-labelledby="password"
                  required
                  ngModel
                />
              </div>
              <div class="form-group login-button">
                <button
                  type="submit"
                  class="btn btn-primary"
                  [disabled]="loginForm.form.invalid"
                  aria-label="Przycisk logowania"
                  (click)="toggleMenu()"
                >
                  Zaloguj
                </button>
              </div>
            </form>
            <div class="d-flex align-items-center justify-content-end" *ngIf="user && isLogged">
              <div ngbDropdown class="d-inline-block" placement="bottom-right">
                <button
                  class="btn d-flex align-items-center"
                  id="userLogged"
                  ngbDropdownToggle
                  aria-label="Przycisk ułatwiania dostępu"
                  tabindex="10"
                >
                  <div class="d-flex align-items-center">
                    <div class="mr-3">
                      <img
                        src="assets/images/scientist-icon.png"
                        class="img-fluid profile-icon"
                        alt="Profil naukowca"
                        *ngIf="user.scientistId !== null"
                      />
                      <img
                        src="assets/images/employer-icon.png"
                        class="img-fluid profile-icon"
                        alt="Profil przedsiębiorcy"
                        *ngIf="user.employerId !== null"
                      />
                      <i
                        class="fas fa-user-alt admin-profile"
                        *ngIf="user.scientistId === null && user.employerId === null"
                      ></i>
                    </div>
                    <div class="mr-2">
                      <span>{{ user.username }}</span>
                    </div>
                  </div>
                </button>
                <div ngbDropdownMenu aria-labelledby="userLogged">
                  <button
                    ngbDropdownItem
                    class="btn w-100 d-flex justify-content-start align-items-center py-1"
                    routerLink="/profile"
                    aria-label="Przycisk podglądu profilu"
                    (click)="toggleMenu()"
                    tabindex="11"
                  >
                    <i class="fas fa-user mr-2"></i>
                    <span>Profil</span>
                  </button>
                  <button
                    ngbDropdownItem
                    class="btn w-100 d-flex justify-content-start align-items-center py-1"
                    routerLink="/communicator"
                    (click)="toggleMenu()"
                    aria-label="Przycisk przejscia do komunikatora"
                    tabindex="11"
                  >
                    <i class="fas fa-envelope mr-2"></i>
                    <span>Komunikator</span>
                  </button>
                  <button
                    ngbDropdownItem
                    class="btn w-100 d-flex justify-content-start align-items-center py-1"
                    (click)="logout(); toggleMenu()"
                    aria-label="Przycisk wyloguj"
                    tabindex="11"
                  >
                    <i class="fas fa-power-off mr-2"></i>
                    <span>Wyloguj</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end">
        <p class="text-right text-small" *ngIf="!isLogged">
          Jeśli nie pamiętasz hasła,
          <a routerLink="/reset" (click)="toggleMenu()" title="Resetowanie hasła">kliknij tutaj</a>
        </p>
      </div>
    </div>
  </div>
  <div class="mn-info my-3" [ngClass]="{ 'container-fluid': isAdmin, container: !isAdmin }">
    <div class="row">
      <div class="col-12">
        <div class="py-3">
          <h1>Małopolska nauka</h1>
          <p class="text-muted">Baza danych kompetencji pracowników naukowych</p>
        </div>
      </div>
    </div>
  </div>
</header>
