import { WcagService } from './../../shared/wcag/wcag.service';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, CredentialsService, I18nService } from '@app/core';
import { AccountService } from '@app/services/account.service';
import { HelperService } from '@app/services/helper.service';
import { NotificationService } from '@app/shared/notifications/notification.service';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(router, authenticationService, _credentialsService, _notificationService, _accountService, i18nService, helperService, wcagService) {
        this.router = router;
        this.authenticationService = authenticationService;
        this._credentialsService = _credentialsService;
        this._notificationService = _notificationService;
        this._accountService = _accountService;
        this.i18nService = i18nService;
        this.helperService = helperService;
        this.wcagService = wcagService;
        this.menuHidden = true;
        this.startHideMenu = true;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLogged = this._credentialsService.isAuthenticated();
        this.getAccountInformation();
        this.wcagService.isContrastVesrion.subscribe(function (isConstrast) {
            if (isConstrast) {
                _this.mobileLogo = '/assets/logo_contrast.png';
            }
            else {
                _this.mobileLogo = '/assets/logo.png';
            }
        });
        this.isMobileLayout = document.body.offsetWidth < 1080;
        window.onresize = function () { return (_this.isMobileLayout = window.innerWidth <= 1080); };
    };
    HeaderComponent.prototype.getAccountInformation = function () {
        var _this = this;
        if (this.isLogged) {
            this._accountService.getAccount().subscribe(function (data) {
                _this.user = data;
                _this.checkUserIsAdmin(data);
                _this.authenticationService.setUserData(data);
                // Check is user Limited, if yes => redirect to fill profile
                _this.helperService.limitedUserRedirect();
                if (data.showPasswordExpiredNotification) {
                    _this._notificationService.sendMessage({
                        type: 'info',
                        title: 'Informacja',
                        message: 'Zbliża się termin ważności hasła. Przejdź do profilu, aby je zmienić.'
                    });
                }
            }, function () {
                _this.isLogged = _this._credentialsService.isAuthenticated();
            });
        }
        else {
            this.authenticationService.logout();
        }
    };
    HeaderComponent.prototype.checkUserIsAdmin = function (userData) {
        this.isAdmin = userData.roles.some(function (role) {
            return role === 'ADMIN';
        });
    };
    HeaderComponent.prototype.toggleMenu = function () {
        this.menuHidden = !this.menuHidden;
        this.startHideMenu = false;
    };
    HeaderComponent.prototype.login = function (username, password) {
        var _this = this;
        this.authenticationService.login(username, password).subscribe(function (credentials) {
            _this._credentialsService.setCredentials(credentials);
            _this.isLogged = true;
            _this._notificationService.sendMessage({
                type: 'success',
                title: 'Logowanie',
                message: 'Logowanie przebiegło pomyślnie'
            });
            _this.getAccountInformation();
            _this.router.navigate(['search'], { replaceUrl: true });
        });
    };
    HeaderComponent.prototype.logout = function () {
        var _this = this;
        this.authenticationService.logout().subscribe(function () {
            _this.isLogged = false;
            _this.isAdmin = false;
            _this.user = null;
            _this.router.navigate(['search'], { replaceUrl: true });
        });
    };
    Object.defineProperty(HeaderComponent.prototype, "currentLanguage", {
        get: function () {
            return this.i18nService.language;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "languages", {
        get: function () {
            return this.i18nService.supportedLanguages;
        },
        enumerable: true,
        configurable: true
    });
    return HeaderComponent;
}());
export { HeaderComponent };
