import { ScientistRegisterComponent } from './scientist-register.component';
import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
var ɵ0 = { title: extract('Rejestracja naukowca') };
var routes = [
    Shell.childRoutes([
        {
            path: 'scientist-register',
            component: ScientistRegisterComponent,
            data: ɵ0
        }
    ])
];
var ScientistRegisterRoutingModule = /** @class */ (function () {
    function ScientistRegisterRoutingModule() {
    }
    return ScientistRegisterRoutingModule;
}());
export { ScientistRegisterRoutingModule };
export { ɵ0 };
