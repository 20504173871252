import * as tslib_1 from "tslib";
import { DictionariesService } from './../../../../services/dictionaries.service';
import { OnInit } from '@angular/core';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
import { ConfigurationAppService } from '@app/services/configuration-app.service';
import { zip } from 'rxjs';
var DictionaryEditComponent = /** @class */ (function () {
    function DictionaryEditComponent(_notificationService, _dialogService, configurationAppService, dictionariesService) {
        this._notificationService = _notificationService;
        this._dialogService = _dialogService;
        this.configurationAppService = configurationAppService;
        this.dictionariesService = dictionariesService;
        this.msgs = [];
        this.showAddButton = true;
        this.listItemToView = [];
        this.baseNewItem = {};
        this.dependEditDict = [];
    }
    DictionaryEditComponent.prototype.ngOnInit = function () {
        this._getRequiredDictionaries();
        this.showInfoFillRequired();
    };
    DictionaryEditComponent.prototype.setEditMode = function (dictionaryItem) {
        this.editDictItemId = dictionaryItem.id;
        this.isEditMode = true;
    };
    DictionaryEditComponent.prototype.updateItemDict = function (dictionaryItem) {
        var _this = this;
        if (!dictionaryItem.id) {
            var prepareToSave = tslib_1.__assign({}, dictionaryItem, this.baseNewItem);
            delete prepareToSave.id;
            this.configurationAppService.createItemDictionary(this.rootDictionaryName, prepareToSave).subscribe(function (response) {
                _this.listItemToView[0] = response;
                _this._notificationService.sendMessage({
                    type: 'success',
                    title: 'Edycja słownika',
                    message: 'Pomyślnie dodano wpis'
                });
            });
        }
        else {
            this.configurationAppService.updateDictionary(this.rootDictionaryName, dictionaryItem).subscribe(function (response) {
                _this._notificationService.sendMessage({
                    type: 'success',
                    title: 'Edycja słownika',
                    message: 'Pomyślnie edytowano wpis'
                });
            });
        }
        // Reset
        if (dictionaryItem.name) {
            delete this.baseNewItem.id;
            this.editDictItemId = null;
            this.isEditMode = false;
        }
    };
    DictionaryEditComponent.prototype.addNewItemDict = function () {
        this.editDictItemId = 0;
        this.baseNewItem.id = 0;
        var preparedItemModel = tslib_1.__assign({}, this.baseNewItem);
        this.listItemToView.unshift(preparedItemModel);
        this.setEditMode(this.baseNewItem);
    };
    DictionaryEditComponent.prototype.loadDictionary = function (dictionaryId) {
        var _this = this;
        this.dependEditDict = [];
        var getEditDict = this.allEditableDict.find(function (editableDict) {
            return editableDict.id === dictionaryId;
        });
        this.rootDictionaryName = getEditDict.id;
        if (getEditDict.nestedDict.length) {
            getEditDict.nestedDict.forEach(function (nestedDict, index) {
                nestedDict.command.subscribe(function (dictionary) {
                    _this.dependEditDict[index] = {
                        dependDictionary: [],
                        dependFieldName: null,
                        dependFieldModel: null
                    };
                    _this.dependEditDict[index].dependDictionary = dictionary.map(function (dict) {
                        return {
                            label: dict.name,
                            value: dict.id
                        };
                    });
                    _this.dependEditDict[index].dependFieldName = nestedDict.field;
                });
            });
            this.showInfoWithoutData(true);
        }
        else {
            getEditDict.command.subscribe(function (dictionary) {
                _this.listItemToView = dictionary;
            });
            this.showInfoWithoutData();
        }
        this.listItemToView = [];
    };
    DictionaryEditComponent.prototype.filterRootDictionary = function (index) {
        var _this = this;
        if (this.dependEditDict.length - 1 === index) {
            var getEditDict = this.allEditableDict.find(function (editableDict) {
                return editableDict.id === _this.selectedDictForEdit;
            });
            getEditDict.command.subscribe(function (dictionary) {
                _this.listItemToView = dictionary;
                _this.dependEditDict.forEach(function (dependDict) {
                    _this.listItemToView = _this.listItemToView.filter(function (item) {
                        return item[dependDict.dependFieldName] === dependDict.dependFieldModel;
                    });
                    if (!_this.listItemToView.length) {
                        _this.showInfoWithoutData();
                    }
                    _this.baseNewItem[dependDict.dependFieldName] = dependDict.dependFieldModel;
                });
            });
        }
        else {
            this.listItemToView = [];
            if (this.dependEditDict.every(function (dependDict) { return !!dependDict.dependFieldModel; })) {
                this.showInfoWithoutData();
            }
            else {
                this.showInfoWithoutData(true);
            }
        }
    };
    DictionaryEditComponent.prototype.showInfoWithoutData = function (isNestedDictionary) {
        if (isNestedDictionary === void 0) { isNestedDictionary = false; }
        this.msgs = [];
        this.msgs.push({
            severity: 'info',
            summary: 'Informacja',
            detail: isNestedDictionary ? 'Wybierz słowniki powiązane' : 'Brak danych do wyświetlenia'
        });
        this.showAddButton = true;
    };
    DictionaryEditComponent.prototype.showInfoFillRequired = function () {
        this.msgs = [];
        this.msgs.push({ severity: 'info', summary: 'Informacja', detail: 'Wybierz dane do wyświetlenia' });
        this.showAddButton = false;
    };
    DictionaryEditComponent.prototype._getRequiredDictionaries = function () {
        var _this = this;
        zip(this.dictionariesService.getAllEditableDictionaries()).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 1), EditableDictionaries = _b[0];
            _this.allEditableDict = EditableDictionaries;
            _this.listEditableDictionaries = EditableDictionaries.map(function (editDict) {
                return {
                    label: editDict.name,
                    value: editDict.id
                };
            });
        });
    };
    return DictionaryEditComponent;
}());
export { DictionaryEditComponent };
