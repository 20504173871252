import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { ProblemBaseComponent } from './problem-base.component';
var ɵ0 = { title: extract('Baza problemów') };
var routes = [
    Shell.childRoutes([
        { path: 'problem-base', component: ProblemBaseComponent, data: ɵ0 }
    ])
];
var ProblemBaseRoutingModule = /** @class */ (function () {
    function ProblemBaseRoutingModule() {
    }
    return ProblemBaseRoutingModule;
}());
export { ProblemBaseRoutingModule };
export { ɵ0 };
