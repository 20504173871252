import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DictionariesService } from '@app/services/dictionaries.service';
import { zip } from 'rxjs';
import { DynamicDialogConfig } from 'primeng/api';
import { UserManagementService } from '@app/services/user-management.service';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
import { TranslateService } from '@ngx-translate/core';
import { flatten } from 'lodash';
var CreateAccountComponent = /** @class */ (function () {
    function CreateAccountComponent(_dictionariesService, userManagementService, _notificationService, _dialogService, translate, config) {
        this._dictionariesService = _dictionariesService;
        this.userManagementService = userManagementService;
        this._notificationService = _notificationService;
        this._dialogService = _dialogService;
        this.translate = translate;
        this.config = config;
        this.editMode = false;
        this.createUserAccount = {
            email: null,
            passwordChangeInFirstLoginRequired: null,
            passwordRuleId: null,
            rolesId: null,
            username: null
        };
        this.modifyAccountModel = {
            status: null,
            passwordRuleId: null,
            passwordChangeRequired: null,
            rolesIdentifiers: null
        };
    }
    CreateAccountComponent.prototype.ngOnInit = function () {
        if (this.config && this.config.data) {
            this.editMode = this.config.data.editMode;
            this.userAccountId = this.config.data.id;
            if (this.userAccountId) {
                this.getAccount();
            }
        }
        this._getAllDictionaries();
    };
    CreateAccountComponent.prototype.getAccount = function () {
        var _this = this;
        this.userManagementService.getUserAccount(this.userAccountId).subscribe(function (response) {
            _this.modifyAccountModel = response;
            _this.modifyAccountUserRoleSelectedId = _this.modifyAccountModel.rolesIdentifiers.filter(function (userRoleId) { return userRoleId !== 6; })[0];
        });
    };
    CreateAccountComponent.prototype.createAccount = function () {
        var _this = this;
        this.createUserAccount.rolesId = flatten([this.createUserAccount.rolesId]);
        this.userManagementService.createAccount(this.createUserAccount).subscribe(function (resp) {
            _this._dialogService.closeModal();
            _this._notificationService.sendMessage({
                type: 'success',
                title: 'Tworzenie użytkownika',
                message: 'Pomyślnie dodano nowe konto'
            });
        }, function (err) {
            _this.createUserAccount.rolesId = null;
        });
    };
    CreateAccountComponent.prototype.modifyAccount = function () {
        var _this = this;
        var userRoleIdentifiers = [];
        if (this.modifyAccountModel.rolesIdentifiers.indexOf(6) !== -1) {
            userRoleIdentifiers.push(this.modifyAccountUserRoleSelectedId);
            userRoleIdentifiers.push(6);
        }
        else {
            userRoleIdentifiers.push(this.modifyAccountUserRoleSelectedId);
        }
        this.modifyAccountModel.rolesIdentifiers = userRoleIdentifiers;
        this.userManagementService.modifyUserAccount(this.userAccountId, this.modifyAccountModel).subscribe(function (resp) {
            _this._notificationService.sendMessage({
                type: 'success',
                title: 'Zarządzanie użytkownikiem',
                message: 'Pomyślnie zmodyfikowano konto'
            });
        });
    };
    CreateAccountComponent.prototype._getAllDictionaries = function () {
        var _this = this;
        zip(this._dictionariesService.getAllLimitedRoles(), this._dictionariesService.getAllPasswordRules(), this._dictionariesService.getAllUserStatus()).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 3), userRoles = _b[0], passwordRules = _b[1], UserStatus = _b[2];
            _this.userRoles = userRoles.map(function (userRole) {
                return {
                    label: _this.translate.instant('userRoles.' + userRole.name),
                    value: userRole.id
                };
            });
            _this.passwordRules = passwordRules.map(function (passwordRule) {
                return {
                    label: _this.translate.instant(passwordRule.name),
                    value: passwordRule.id
                };
            });
            _this.userEnabledDict = UserStatus;
        });
    };
    return CreateAccountComponent;
}());
export { CreateAccountComponent };
