import * as tslib_1 from "tslib";
import { HelperService } from '@app/services/helper.service';
import { NotificationService } from '@app/shared/notifications/notification.service';
import { DictionariesService } from '@app/services/dictionaries.service';
import { OnInit } from '@angular/core';
import { UserProfileService } from '@app/services/user-profile.service';
import { CredentialsService, AuthenticationService } from '@app/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { filter, find, indexOf } from 'lodash';
import { zip, BehaviorSubject } from 'rxjs';
var UserProfileComponent = /** @class */ (function () {
    function UserProfileComponent(userProfileService, _credentialsService, _authenticationService, dictionaries, _router, _notificationService, messageService, helperService) {
        this.userProfileService = userProfileService;
        this._credentialsService = _credentialsService;
        this._authenticationService = _authenticationService;
        this.dictionaries = dictionaries;
        this._router = _router;
        this._notificationService = _notificationService;
        this.messageService = messageService;
        this.helperService = helperService;
        this.isProfileLoaded = new BehaviorSubject(false);
        this.maxTextareaLength = 2000;
        this.msgs = [];
        this.userScientist = {
            username: null,
            email: null,
            firstName: null,
            surname: null,
            department: null,
            universityId: null,
            projectsDescription: null,
            scientificWorksDescription: null,
            descriptionOfCooperationWithEmployers: null,
            phoneNumber: null,
            academicTitleId: null,
            keyWords: null,
            numberOfProjects: null,
            leadingSpecialisationId: null,
            intelligentSpecialisationId: null,
            availableForCooperation: true,
            specialisations: [],
            rodoAccepted: false,
            privacyPolicyAccepted: false,
            sendingInformationAccepted: false,
            dataProcessingAccepted: false
        };
        this.userEmployer = {
            id: null,
            username: null,
            email: null,
            companyName: null,
            communeId: null,
            city: null,
            phoneNumber: null,
            intelligentSpecialisationId: null,
            leadingSpecialisationId: null,
            scienceId: null,
            scienceDomainId: null,
            specialisations: [],
            rodoAccepted: false,
            privacyPolicyAccepted: false,
            sendingInformationAccepted: false,
            dataProcessingAccepted: false
        };
        this.userPassword = {
            oldPassword: null,
            password: null,
            repeatedPassword: null
        };
        this.userEmial = {
            email: null
        };
        this.showMoreRequirements = {
            rodoAccepted: false,
            privacyPolicyAccepted: false,
            sendingInformationAccepted: false,
            dataProcessingAccepted: false
        };
        this.isSelectedAllRequirements = false;
    }
    UserProfileComponent.prototype.ngOnInit = function () {
        this.userData = {};
        this.isLogged = this._credentialsService.isAuthenticated();
        this.getAccountInformation();
        this.msgs = [];
        if (this.userData.profileFillRequired) {
            this.msgs.push({
                severity: 'info',
                summary: 'Uwaga!',
                detail: 'Aby móc korzystać z aplikacji wymagane jest uzupełnienie profilu'
            });
        }
        if (this.userData.passwordChangeRequired) {
            this.msgs.push({
                severity: 'info',
                summary: 'Uwaga!',
                detail: 'Aby móc korzystać z aplikacji wymagana jest zmiana hasła'
            });
        }
        if (this.isUserScientist || this.isUserScientistFillRequired) {
            this.getScientistUserProfile();
            if (this.userData.profileFillRequired) {
                this.addSpecialisation();
            }
        }
        else if (this.isUserEmployer || this.isUserEmployerFillRequired) {
            this.getEmployerUserProfile();
            if (this.userData.profileFillRequired) {
                this.addSpecialisation();
            }
        }
        // Get common dictionaries
        this._getAllDictionaries();
    };
    UserProfileComponent.prototype.isSelectedAll = function () {
        if (this.isUserScientistFillRequired) {
            return (this.userScientist.privacyPolicyAccepted &&
                this.userScientist.rodoAccepted &&
                this.userScientist.sendingInformationAccepted &&
                this.userScientist.dataProcessingAccepted);
        }
        if (this.isUserEmployerFillRequired) {
            return (this.userEmployer.privacyPolicyAccepted &&
                this.userEmployer.rodoAccepted &&
                this.userEmployer.sendingInformationAccepted &&
                this.userEmployer.dataProcessingAccepted);
        }
    };
    UserProfileComponent.prototype.selectAllRequirements = function () {
        if (this.isUserScientistFillRequired) {
            this.userScientist.privacyPolicyAccepted = this.isSelectedAllRequirements;
            this.userScientist.rodoAccepted = this.isSelectedAllRequirements;
            this.userScientist.sendingInformationAccepted = this.isSelectedAllRequirements;
            this.userScientist.dataProcessingAccepted = this.isSelectedAllRequirements;
        }
        if (this.isUserEmployerFillRequired) {
            this.userEmployer.privacyPolicyAccepted = this.isSelectedAllRequirements;
            this.userEmployer.rodoAccepted = this.isSelectedAllRequirements;
            this.userEmployer.sendingInformationAccepted = this.isSelectedAllRequirements;
            this.userEmployer.dataProcessingAccepted = this.isSelectedAllRequirements;
        }
    };
    UserProfileComponent.prototype.changeShowMoreLess = function (type) {
        this.showMoreRequirements[type] = !this.showMoreRequirements[type];
    };
    UserProfileComponent.prototype.getAccountInformation = function () {
        if (this.isLogged) {
            this.userData = this._authenticationService.getUserData();
            this.isUserScientist = !!this.userData.scientistId;
            this.isUserEmployer = !!this.userData.employerId;
            if (this.userData.profileFillRequired) {
                if (indexOf(this.userData.roles, 'LIMITED_SCIENTIST') !== -1) {
                    this.isUserScientistFillRequired = true;
                    this.userScientist.username = this.userData.username;
                    this.userScientist.email = this.userData.email;
                }
                else if (indexOf(this.userData.roles, 'LIMITED_EMPLOYER') !== -1) {
                    this.isUserEmployerFillRequired = true;
                    this.userEmployer.username = this.userData.username;
                    this.userEmployer.email = this.userData.email;
                }
            }
        }
    };
    UserProfileComponent.prototype.getScientistUserProfile = function () {
        var _this = this;
        if (this.isUserScientistFillRequired !== true) {
            this.userProfileService.getScientistProfile(this.userData.scientistId).subscribe(function (scientistProfile) {
                _this.userScientist = scientistProfile;
                _this.userScientist.availableForCooperation = !!scientistProfile.availableForCooperation;
                if (scientistProfile.specialisations.length === 0) {
                    _this.addSpecialisation();
                }
                _this.isProfileLoaded.next(true);
            });
        }
        else {
            this.userScientist.id = this.userData.id;
        }
    };
    UserProfileComponent.prototype.getEmployerUserProfile = function () {
        var _this = this;
        if (this.isUserEmployerFillRequired !== true) {
            this.userProfileService.getEmployerProfile(this.userData.employerId).subscribe(function (employerProfile) {
                _this.userEmployer = employerProfile;
                if (employerProfile.specialisations.length === 0) {
                    _this.addSpecialisation();
                }
                _this.isProfileLoaded.next(true);
            });
        }
        else {
            this.userEmployer.id = this.userData.id;
        }
    };
    UserProfileComponent.prototype.getSciencesAndClasification = function () {
        var _this = this;
        if (this.isUserEmployer) {
            var filteredSpecialisation = filter(this.specialisations, function (specialisation) {
                return (specialisation.intelligentSpecialisationId === _this.userEmployer.intelligentSpecialisationId &&
                    specialisation.id === _this.userEmployer.leadingSpecialisationId);
            });
            this.userEmployer.scienceDomainId = filteredSpecialisation && filteredSpecialisation[0].scienceDomainId;
            this.userEmployer.scienceId = find(this.scienceDomainsDict, { id: this.userEmployer.scienceDomainId }).scienceId;
        }
        else if (this.isUserScientist) {
            var filteredSpecialisation = filter(this.specialisations, function (specialisation) {
                return (specialisation.intelligentSpecialisationId === _this.userScientist.intelligentSpecialisationId &&
                    specialisation.id === _this.userScientist.leadingSpecialisationId);
            });
            this.userScientist.scienceDomainId = filteredSpecialisation && filteredSpecialisation[0].scienceDomainId;
            this.userScientist.scienceId = find(this.scienceDomainsDict, {
                id: this.userScientist.scienceDomainId
            }).scienceId;
        }
    };
    UserProfileComponent.prototype.changeIntelligentSpecialisation = function () {
        if (this.isUserScientist || this.isUserScientistFillRequired) {
            var findInteligentSpecialisation = find(this.specialisations, [
                'id',
                this.userScientist.leadingSpecialisationId
            ]);
            this.userScientist.intelligentSpecialisationId = findInteligentSpecialisation.intelligentSpecialisationId;
        }
        else if (this.isUserEmployer || this.isUserEmployerFillRequired) {
            var findInteligentSpecialisation = find(this.specialisations, [
                'id',
                this.userEmployer.leadingSpecialisationId
            ]);
            this.userEmployer.intelligentSpecialisationId = findInteligentSpecialisation.intelligentSpecialisationId;
        }
    };
    UserProfileComponent.prototype.keyWordsOnKeyDown = function (event) {
        if (event.key === ',') {
            event.preventDefault();
            var element = event.target;
            element.blur();
            element.focus();
        }
    };
    UserProfileComponent.prototype.addSpecialisation = function () {
        var emptySpecialisation = {
            id: null,
            name: null
        };
        if (this.isUserScientist || this.isUserScientistFillRequired) {
            this.userScientist.specialisations.push(emptySpecialisation);
        }
        else if (this.isUserEmployer || this.isUserEmployerFillRequired) {
            this.userEmployer.specialisations.push(emptySpecialisation);
        }
    };
    UserProfileComponent.prototype.reloadUserData = function () {
        var _this = this;
        this._authenticationService.reloadUserInfo().subscribe(function (userData) {
            _this.userData = userData;
            _this.ngOnInit();
            _this.getAccountInformation();
        });
    };
    UserProfileComponent.prototype.removeEmptySpecialisation = function () {
        if (this.isUserScientist || this.isUserScientistFillRequired) {
            this.userScientist.specialisations = this.userScientist.specialisations.filter(function (specialisation) {
                return specialisation.id !== null;
            });
            if (!this.userScientist.specialisations ||
                (this.userScientist.specialisations && !this.userScientist.specialisations.length)) {
                this.userScientist.specialisations = [];
            }
        }
        else if (this.isUserEmployer || this.isUserEmployerFillRequired) {
            this.userEmployer.specialisations = this.userEmployer.specialisations.filter(function (specialisation) {
                return specialisation.id !== null;
            });
            if (!this.userEmployer.specialisations ||
                (this.userEmployer.specialisations && !this.userEmployer.specialisations.length)) {
                this.userEmployer.specialisations = [];
            }
        }
    };
    UserProfileComponent.prototype.showUpdateAlert = function (_accountType, resp) {
        this._notificationService.sendMessage({
            type: 'success',
            title: 'Aktualizacja konta',
            message: "Pomy\u015Blnie zaktualizowano profil " + (_accountType === 'scientist' ? 'naukowca' : 'przedsiębiorcy')
        });
        this.reloadUserData();
        if (resp.specialisations.length === 0) {
            this.addSpecialisation();
        }
    };
    UserProfileComponent.prototype.save = function () {
        var _this = this;
        this.removeEmptySpecialisation();
        if (this.isUserScientistFillRequired) {
            this.userProfileService.updateScientistLimitedProfile(this.userData.id, this.userScientist).subscribe(function (resp) {
                _this.showUpdateAlert('scientist', resp);
            });
        }
        else if (this.isUserScientist) {
            this.userProfileService.updateScientistProfile(this.userData.id, this.userScientist).subscribe(function (resp) {
                _this.showUpdateAlert('scientist', resp);
            });
        }
        else if (this.isUserEmployerFillRequired) {
            this.userEmployer.id = this.userData.id;
            this.userProfileService.updateEmployerLimitedProfile(this.userData.id, this.userEmployer).subscribe(function (resp) {
                _this.showUpdateAlert('employer', resp);
            });
        }
        else if (this.isUserEmployer) {
            this.userEmployer.id = this.userData.id;
            this.userProfileService.updateEmployerProfile(this.userData.id, this.userEmployer).subscribe(function (resp) {
                _this.showUpdateAlert('employer', resp);
            });
        }
    };
    UserProfileComponent.prototype.changePassword = function () {
        var _this = this;
        this.userProfileService.changePassword(this.userData.id, this.userPassword).subscribe(function (resp) {
            _this._notificationService.sendMessage({
                type: 'success',
                title: 'Aktualizacja konta',
                message: 'Hasło zmienione pomyślnie'
            });
            _this.reloadUserData();
        });
    };
    UserProfileComponent.prototype.changeEmail = function () {
        var _this = this;
        this.userProfileService.changeEmail(this.userData.id, this.userEmial).subscribe(function (resp) {
            _this._notificationService.sendMessage({
                type: 'success',
                title: 'Aktualizacja konta',
                message: 'Zmiana adresu e-mail przebiegła pomyślnie'
            });
            _this.reloadUserData();
        });
    };
    UserProfileComponent.prototype.filteredDepartmentByUniversity = function (universityId) {
        if (this.departments) {
            this.departmentDict = this.departments
                .filter(function (department) {
                return department.universityId === universityId;
            })
                .map(function (department) {
                return {
                    label: department.name,
                    value: department.name
                };
            });
        }
    };
    UserProfileComponent.prototype.isOtherCommune = function (communeId) {
        return this.helperService.checkIsOtherCommune(communeId);
    };
    UserProfileComponent.prototype.setInteligentSpecialisation = function (specialisationId) {
        if (specialisationId) {
            var specialisation = find(this.specialisations, { id: specialisationId });
            if (specialisation) {
                var intelligentSpecialisation = find(this.intelligentSpecialisationDict, {
                    value: specialisation.intelligentSpecialisationId
                });
                if (this.isUserEmployer || this.isUserEmployerFillRequired) {
                    this.userEmployer.intelligentSpecialisationId = intelligentSpecialisation.value;
                }
                else if (this.isUserScientist || this.isUserScientistFillRequired) {
                    this.userScientist.intelligentSpecialisationId = intelligentSpecialisation.value;
                }
            }
            this.aditionalSpecialisationDict = this.aditionalSpecialisationDict.filter(function (itemDict) {
                return itemDict.value !== specialisationId;
            });
        }
    };
    UserProfileComponent.prototype.changeModelScienceDomainDict = function (scienceId) {
        this.userScientist.scienceDomainId = null;
        this.userScientist.leadingSpecialisationId = null;
        this.userEmployer.scienceDomainId = null;
        this.userEmployer.leadingSpecialisationId = null;
        this.scienceDomainSelectDict = this.mapScienceDomains(scienceId);
    };
    UserProfileComponent.prototype.changeModelSpecialisationDict = function (scienceDomainId) {
        this.userScientist.leadingSpecialisationId = null;
        this.userEmployer.leadingSpecialisationId = null;
        this.specialisationsDict = this.mapSpecialisations(scienceDomainId);
        this.aditionalSpecialisationDict = this.mapSpecialisations(scienceDomainId);
    };
    UserProfileComponent.prototype.mapScienceDomains = function (scienceId) {
        if (this.scienceDomainsDict && scienceId) {
            return this.scienceDomainsDict
                .filter(function (scienceDomain) {
                return scienceDomain.scienceId === scienceId;
            })
                .map(function (scienceDomain) {
                return {
                    label: scienceDomain.name,
                    value: scienceDomain.id
                };
            });
        }
        else {
            return null;
        }
    };
    UserProfileComponent.prototype.mapSpecialisations = function (scienceDomainId) {
        if (this.specialisations && scienceDomainId) {
            return this.specialisations
                .filter(function (specialisation) {
                return specialisation.scienceDomainId === scienceDomainId;
            })
                .map(function (specialisation) {
                return {
                    label: specialisation.name,
                    value: specialisation.id
                };
            });
        }
        else {
            return null;
        }
    };
    // Dictionaries
    UserProfileComponent.prototype._getAllDictionaries = function () {
        var _this = this;
        zip(this.dictionaries.getAllAcademicTitles(), this.dictionaries.getAllUniversities(), this.dictionaries.getAllScienceDomains(), this.dictionaries.getAllSciences(), this.dictionaries.getAllSpecialisations(), this.dictionaries.getAllIntelligentSpecialisations(), this.dictionaries.getSimpleUserStatus(), this.dictionaries.getAllDepartments(), this.dictionaries.getAllCommunes()).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 9), AcademicTitles = _b[0], Universities = _b[1], ScienceDomains = _b[2], Sciences = _b[3], Specialisations = _b[4], IntelligentSpecialisations = _b[5], Statuses = _b[6], Departments = _b[7], Communes = _b[8];
            _this.academicTitlesDict = AcademicTitles.map(function (academicTitle) {
                return {
                    label: academicTitle.name,
                    value: academicTitle.id
                };
            });
            _this.universitiesDict = Universities.map(function (university) {
                return {
                    label: university.name,
                    value: university.id
                };
            });
            _this.sciencesDict = Sciences.map(function (sciences) {
                return {
                    label: sciences.name,
                    value: sciences.id
                };
            });
            _this.scienceDomainSelectDict = ScienceDomains.map(function (scienceDomain) {
                return {
                    label: scienceDomain.name,
                    value: scienceDomain.id
                };
            });
            _this.specialisationsDict = Specialisations.map(function (specialisation) {
                return {
                    label: specialisation.name,
                    value: specialisation.id
                };
            });
            _this.allSpecialisationDict = Specialisations.map(function (spec) {
                return {
                    label: spec.name,
                    value: spec.id
                };
            });
            _this.intelligentSpecialisationDict = IntelligentSpecialisations.map(function (intelligentSpecialisation) {
                return {
                    label: intelligentSpecialisation.name,
                    value: intelligentSpecialisation.id
                };
            });
            _this.statusesDict = Statuses;
            _this.communesDict = Communes.map(function (commune) {
                return {
                    label: commune.name,
                    value: commune.id
                };
            });
            // All dictionaries
            _this.specialisations = Specialisations;
            _this.scienceDomainsDict = ScienceDomains;
            _this.departments = Departments;
            _this.isProfileLoaded.subscribe(function (isLoaded) {
                if (isLoaded) {
                    _this.getSciencesAndClasification();
                    if (_this.isUserScientist) {
                        _this.scienceDomainSelectDict = _this.mapScienceDomains(_this.userScientist.scienceId);
                        _this.specialisationsDict = _this.mapSpecialisations(_this.userScientist.scienceDomainId);
                        _this.aditionalSpecialisationDict = _this.mapSpecialisations(_this.userScientist.scienceDomainId);
                        _this.setInteligentSpecialisation(_this.userScientist.leadingSpecialisationId);
                        if (_this.userScientist.universityId) {
                            _this.filteredDepartmentByUniversity(_this.userScientist.universityId);
                        }
                    }
                    else {
                        _this.scienceDomainSelectDict = _this.mapScienceDomains(_this.userEmployer.scienceId);
                        _this.specialisationsDict = _this.mapSpecialisations(_this.userEmployer.scienceDomainId);
                        _this.aditionalSpecialisationDict = _this.mapSpecialisations(_this.userEmployer.scienceDomainId);
                        _this.setInteligentSpecialisation(_this.userEmployer.leadingSpecialisationId);
                    }
                }
            });
        });
    };
    return UserProfileComponent;
}());
export { UserProfileComponent };
