import * as tslib_1 from "tslib";
import { HelperService } from '@app/services/helper.service';
import { AuthenticationService } from '@app/core';
import { SearchService } from './../../services/search.service';
import { DictionariesService } from './../../services/dictionaries.service';
import { OnInit, OnDestroy } from '@angular/core';
import { zip } from 'rxjs';
import { TableService } from '@app/services/table.service';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';
var SearchComponent = /** @class */ (function () {
    function SearchComponent(searchService, tableService, _dialogBaseService, _dictionariesService, authenticationService, helperService) {
        this.searchService = searchService;
        this.tableService = tableService;
        this._dialogBaseService = _dialogBaseService;
        this._dictionariesService = _dictionariesService;
        this.authenticationService = authenticationService;
        this.helperService = helperService;
        this.isLoading = true;
        this.isAnnonymousSearched = false;
        this.activeTabId = 0;
        this.maxDescriptionLength = 50;
        this.paginatorScientist = null;
        this.paginatorEmployer = null;
        this.msgs = [];
        this.searchScientistModel = {
            keyWords: null,
            scienceDomainId: null,
            scienceId: null,
            specialisationIdentifiers: null,
            description: null,
            isSearchConfidential: false
        };
        this.searchEmployerModel = {
            scienceDomainId: null,
            scienceId: null,
            specialisationIdentifiers: null
        };
        this.sendMessageListId = [];
    }
    SearchComponent.prototype.ngOnDestroy = function () {
        this.restorePaginator();
    };
    SearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._getAllDictionaries();
        this.authenticationService.isLogged.subscribe(function (isLogged) {
            if (_this.isLogged && !isLogged) {
                _this.activeTabId = 0;
                _this.msgs = [];
                _this.scientistsData = null;
                _this.employerData = null;
                _this.searchScientistModel = {
                    keyWords: null,
                    scienceDomainId: null,
                    scienceId: null,
                    specialisationIdentifiers: null,
                    description: null,
                    isSearchConfidential: false
                };
                _this.searchEmployerModel = {
                    scienceDomainId: null,
                    scienceId: null,
                    specialisationIdentifiers: null
                };
                _this.searchEngineEntryId = null;
                _this.sendMessageListId = [];
                _this.isAnnonymousSearched = null;
                _this.anonymousSearchModel = null;
                _this.isEmployer = false;
                _this.isScientist = false;
            }
            else {
                if (_this.isLogged !== undefined) {
                    _this.userData = _this.authenticationService.getUserData();
                    if (_this.userData) {
                        _this.isEmployer = _this.userData.roles.some(function (role) {
                            return role === 'EMPLOYER';
                        });
                        _this.isScientist = _this.userData.roles.some(function (role) {
                            return role === 'SCIENTIST';
                        });
                        _this.isAnnonymousSearched = null;
                    }
                    else {
                        _this.isEmployer = false;
                        _this.isScientist = false;
                    }
                }
            }
            _this.isLogged = isLogged;
            _this.authenticationService.userData$.subscribe(function (userData) {
                if (userData) {
                    _this.userData = userData;
                    _this.isEmployer = _this.userData.roles.some(function (role) {
                        return role === 'EMPLOYER';
                    });
                    _this.isScientist = _this.userData.roles.some(function (role) {
                        return role === 'SCIENTIST';
                    });
                    _this.isAnnonymousSearched = null;
                }
                else {
                    _this.isEmployer = false;
                    _this.isScientist = false;
                }
            });
        });
        var defaultPaginatorConfig = this.tableService.getPaginatorConfig();
        defaultPaginatorConfig.sort = true;
        this.tableService.setPaginatorConfig(defaultPaginatorConfig);
    };
    SearchComponent.prototype.restorePaginator = function () {
        this.tableService.restorePaginatorConfig();
    };
    SearchComponent.prototype.clearFilter = function () {
        this.searchScientistModel = {
            keyWords: null,
            scienceDomainId: null,
            scienceId: null,
            specialisationIdentifiers: null,
            description: null,
            isSearchConfidential: false
        };
        this.searchEmployerModel = {
            scienceDomainId: null,
            scienceId: null,
            specialisationIdentifiers: null
        };
    };
    SearchComponent.prototype.keyWordsOnKeyDown = function (event) {
        if (event.key === ',') {
            event.preventDefault();
            var element = event.target;
            element.blur();
            element.focus();
        }
    };
    SearchComponent.prototype._getAllDictionaries = function () {
        var _this = this;
        zip(this._dictionariesService.getAllSciences(), this._dictionariesService.getAllScienceDomains(), this._dictionariesService.getAllSpecialisations()).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 3), Sciences = _b[0], ScienceDomain = _b[1], Specialisation = _b[2];
            _this.sciencesDict = Sciences.map(function (sciences) {
                return {
                    label: sciences.name,
                    value: sciences.id
                };
            });
            _this.scienceDomainDict = ScienceDomain;
            _this.specialisationDict = Specialisation;
            _this.isLoading = false;
        });
    };
    SearchComponent.prototype.onChangeTab = function (event) {
        this.activeTabId = event.index;
        this.scientistsData = null;
        this.employerData = null;
        this.searchEngineEntryId = null;
        this.sendMessageListId = [];
        this.anonymousSearchModel = [];
        this.isAnnonymousSearched = false;
        this.restorePaginator();
    };
    SearchComponent.prototype.isSearchQueryFill = function () {
        if (this.activeTabId === 0) {
            return this.helperService.checkAnyObjectFieldIsFill(this.searchScientistModel, [
                'isSearchConfidential',
                'description',
                'direction',
                'page',
                'size',
                'sort'
            ]);
        }
        else if (this.activeTabId === 1) {
            return this.helperService.checkAnyObjectFieldIsFill(this.searchEmployerModel, [
                'direction',
                'page',
                'size',
                'sort'
            ]);
        }
    };
    SearchComponent.prototype.searchEngine = function (fromPaginate) {
        var _this = this;
        if (fromPaginate === void 0) { fromPaginate = false; }
        if (this.activeTabId === 0) {
            var prepareQuery = fromPaginate
                ? tslib_1.__assign({}, this.searchScientistModel, { searchEngineEntryIdentifier: this.searchEngineEntryId }) : this.searchScientistModel;
            if (prepareQuery.description === null) {
                prepareQuery.description = ' ';
            }
            if (this.isLogged) {
                this.searchService
                    .searchScientist(this.tableService.prepareParamsToServer(prepareQuery))
                    .subscribe(function (searchResult) {
                    _this.scientistsData = searchResult.searchedPage.content;
                    _this.searchEngineEntryId = searchResult.searchEngineEntryIdentifier;
                    _this.tableService.setPaginatorConfigFormServer(searchResult.searchedPage);
                    _this.paginatorScientist = _this.tableService.getPaginatorConfig();
                    if (!searchResult.searchedPage.content.length) {
                        _this.showEmptyDataAlert();
                    }
                });
            }
            else {
                this.searchService
                    .searchScientistAnnonumous(this.tableService.prepareParamsToServer(prepareQuery))
                    .subscribe(function (searchResult) {
                    _this.anonymousSearchModel = searchResult.searchResponse;
                    _this.isAnnonymousSearched = true;
                    if (!_this.anonymousSearchModel.length) {
                        _this.showEmptyDataAlert();
                    }
                });
            }
        }
        else if (this.activeTabId === 1) {
            var prepareQuery = fromPaginate
                ? tslib_1.__assign({}, this.searchEmployerModel, { searchEngineEntryIdentifier: this.searchEngineEntryId }) : this.searchEmployerModel;
            if (this.isLogged) {
                this.searchService
                    .searchEmployer(this.tableService.prepareParamsToServer(prepareQuery))
                    .subscribe(function (searchResult) {
                    _this.employerData = searchResult.searchedPage.content;
                    _this.searchEngineEntryId = searchResult.searchEngineEntryIdentifier;
                    _this.tableService.setPaginatorConfigFormServer(searchResult.searchedPage);
                    _this.paginatorEmployer = _this.tableService.getPaginatorConfig();
                    if (!searchResult.searchedPage.content.length) {
                        _this.showEmptyDataAlert();
                    }
                });
            }
            else {
                this.searchService
                    .searchEmployerAnnonumous(this.tableService.prepareParamsToServer(prepareQuery))
                    .subscribe(function (searchResult) {
                    _this.anonymousSearchModel = searchResult.searchResponse;
                    _this.isAnnonymousSearched = true;
                    if (!_this.anonymousSearchModel.length) {
                        _this.showEmptyDataAlert();
                    }
                });
            }
        }
    };
    SearchComponent.prototype.showUserProfile = function (userId, userType) {
        this._dialogBaseService.setSwitchTemplateStatus('ShowUserProfileComponent', {
            data: {
                userId: userId,
                userType: userType,
                searchEntryId: this.searchEngineEntryId,
                activeTabId: this.activeTabId
            },
            closable: true,
            header: 'Profil użytkownika',
            styleClass: 'medium-modal'
        });
        this.ref = this._dialogBaseService.getInstance();
        this.ref.onClose.subscribe(function () {
            // this.getListMessage();
        });
    };
    SearchComponent.prototype.showEmptyDataAlert = function () {
        this.msgs = [];
        this.msgs.push({ severity: 'info', summary: 'Informacja', detail: 'Brak wyników wyszukiwania' });
    };
    SearchComponent.prototype.paginateScientist = function (paginatorEvent) {
        var paginator = {
            direction: 'ASC',
            page: paginatorEvent.page,
            size: paginatorEvent.rows,
            sort: true
        };
        this.tableService.setPaginatorConfig(paginator);
        this.searchEngine(true);
    };
    SearchComponent.prototype.sendMessage = function (isContactWithScientist) {
        var _this = this;
        if (isContactWithScientist === void 0) { isContactWithScientist = false; }
        var multiselectScientistDict = this.prepareMsgScientistDictionary();
        this._dialogBaseService.setSwitchTemplateStatus('SendMessageComponent', {
            data: {
                isContactFromSearch: true,
                multiselectDict: multiselectScientistDict,
                searchEngineEntryId: this.searchEngineEntryId,
                activeTabId: this.activeTabId,
                isContactWithScientist: isContactWithScientist
            },
            closable: true,
            header: 'Skontaktuj się z użytkownikiem',
            contentStyle: { overflow: 'auto' },
            styleClass: 'big-modal'
        });
        this.ref = this._dialogBaseService.getInstance();
        this.ref.onClose.subscribe(function () {
            _this.searchEngine();
        });
    };
    SearchComponent.prototype.prepareMsgScientistDictionary = function () {
        var _this = this;
        if (this.activeTabId === 0) {
            return this.scientistsData
                .filter(function (scientist) { return _this.sendMessageListId.indexOf(scientist.userId.toString()) !== -1; })
                .map(function (scientist) {
                return {
                    label: scientist.academicTitle + " " + scientist.firstName + " " + scientist.surname,
                    value: scientist.userId
                };
            });
        }
        else {
            return this.employerData
                .filter(function (employer) { return _this.sendMessageListId.indexOf(employer.userId.toString()) !== -1; })
                .map(function (employer) {
                return {
                    label: "" + employer.companyName,
                    value: employer.userId
                };
            });
        }
    };
    // Mapers
    SearchComponent.prototype.getModelDictScience = function (scienceId) {
        this.scienceDropdownModel = this.mapScienceDomains(scienceId);
        this.searchScientistModel.scienceDomainId = null;
        this.searchScientistModel.specialisationIdentifiers = null;
        this.searchEmployerModel.scienceDomainId = null;
        this.searchEmployerModel.specialisationIdentifiers = null;
    };
    SearchComponent.prototype.getModelDictSpecialisation = function (scienceDomainId) {
        this.specialisationDropdownModel = this.mapSpecialisations(scienceDomainId);
        this.searchScientistModel.specialisationIdentifiers = null;
        this.searchEmployerModel.specialisationIdentifiers = null;
    };
    SearchComponent.prototype.mapScienceDomains = function (scienceId) {
        if (this.scienceDomainDict && scienceId) {
            return this.scienceDomainDict
                .filter(function (scienceDomain) {
                return scienceDomain.scienceId === scienceId;
            })
                .map(function (scienceDomain) {
                return {
                    label: scienceDomain.name,
                    value: scienceDomain.id
                };
            });
        }
        else {
            return null;
        }
    };
    SearchComponent.prototype.mapSpecialisations = function (scienceDomainId) {
        if (this.specialisationDict && scienceDomainId) {
            return this.specialisationDict
                .filter(function (specialisation) {
                return specialisation.scienceDomainId === scienceDomainId;
            })
                .map(function (specialisation) {
                return {
                    label: specialisation.name,
                    value: specialisation.id
                };
            });
        }
        else {
            return null;
        }
    };
    SearchComponent.prototype.keyUpAccessibility = function () { };
    return SearchComponent;
}());
export { SearchComponent };
