import { HelperService } from '@app/services/helper.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./helper.service";
var MailNotificationService = /** @class */ (function () {
    function MailNotificationService(_http, helperService) {
        this._http = _http;
        this.helperService = helperService;
        this._eventLogUrl = '/admin/event-logs';
    }
    MailNotificationService.prototype.getEventLogs = function (paginatorConfig) {
        return this._http.get(this._eventLogUrl, paginatorConfig);
    };
    MailNotificationService.prototype.confirmCooperation = function (token, confirm) {
        var queryParam = this.helperService.buildQueryParams({ confirm: confirm });
        return this._http.get("/notifications/cooperation/token/" + token + "/confirm", {
            params: queryParam
        });
    };
    MailNotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MailNotificationService_Factory() { return new MailNotificationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HelperService)); }, token: MailNotificationService, providedIn: "root" });
    return MailNotificationService;
}());
export { MailNotificationService };
