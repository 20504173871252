import { Subject, of, Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WcagService {
  public removeImages = new BehaviorSubject<boolean>(false);
  public isContrastVesrion = new BehaviorSubject<boolean>(false);

  setWcagConfig(websiteModeName: string, modeValue: string | number) {
    localStorage.removeItem(websiteModeName);

    const preparedMode = JSON.stringify(modeValue);
    localStorage.setItem(websiteModeName, preparedMode);
  }

  getWcagConfig(websiteModeName: string): Observable<string | number> {
    const getItemFromStorage = localStorage.getItem(websiteModeName);
    if (getItemFromStorage) {
      return of(JSON.parse(getItemFromStorage));
    } else {
      return of(null);
    }
  }

  setRemoveImages(value: boolean) {
    this.removeImages.next(value);
  }

  setContrastVersion(value: boolean) {
    this.isContrastVesrion.next(value);
  }
}
