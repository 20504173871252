import * as tslib_1 from "tslib";
import { AuthenticationService } from '@app/core';
import * as moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { isNil, isPlainObject } from 'lodash';
import { Router } from '@angular/router';
import * as DomToImage from 'dom-to-image';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../core/authentication/authentication.service";
import * as i2 from "@angular/router";
var HelperService = /** @class */ (function () {
    function HelperService(_authenticationService, router) {
        this._authenticationService = _authenticationService;
        this.router = router;
        this.documentToImage = DomToImage;
    }
    HelperService.prototype.removeNullPropertyFromObject = function (object) {
        Object.keys(object).forEach(function (key) { return (object[key] == null || object[key] === '') && delete object[key]; });
    };
    HelperService.prototype.removeNullNestedPropertyFromObject = function (object) {
        var _this = this;
        Object.keys(object)
            .filter(function (k) { return object[k] != null; })
            .reduce(function (newObj, k) {
            var _a, _b;
            return typeof object[k] === 'object'
                ? tslib_1.__assign({}, newObj, (_a = {}, _a[k] = _this.removeNullNestedPropertyFromObject(object[k]), _a)) : tslib_1.__assign({}, newObj, (_b = {}, _b[k] = object[k], _b));
        }, {});
    };
    HelperService.prototype.checkAnyObjectFieldIsFill = function (object, exceptionFields) {
        if (exceptionFields === void 0) { exceptionFields = []; }
        return !Object.keys(object)
            .filter(function (key) {
            return exceptionFields.indexOf(key) === -1;
        })
            .some(function (key) {
            return Array.isArray(object[key]) ? object[key].length : object[key];
        });
    };
    HelperService.prototype.convertLocalDateToServerWithoutTimezone = function (date) {
        var momentDate = moment(date);
        return momentDate.isValid() ? momentDate.format('YYYY-MM-DDTHH:mm:ss') + '.000Z' : null;
    };
    HelperService.prototype.convertLocalDateToServerOnlyDate = function (date) {
        var momentDate = moment(date);
        return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : null;
    };
    HelperService.prototype.comparePassword = function (password, passwordRepeat) {
        return password === passwordRepeat;
    };
    HelperService.prototype.buildQueryParams = function (source) {
        var queryParams = new HttpParams();
        Object.keys(source).forEach(function (key) {
            var value = source[key];
            if (isNil(value)) {
                return;
            }
            value = isPlainObject(value) ? JSON.stringify(value) : value.toString();
            queryParams = queryParams.append(key, value);
        });
        return queryParams;
    };
    HelperService.prototype.checkIsOtherCommune = function (communeId) {
        return communeId === 3;
    };
    HelperService.prototype.isLimitedUser = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this._authenticationService.userData$.subscribe(function (userData) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var isUserLimited$, isUserLimited;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!userData) return [3 /*break*/, 2];
                            return [4 /*yield*/, this._authenticationService.getUserRoles()];
                        case 1:
                            isUserLimited$ = _a.sent();
                            isUserLimited = isUserLimited$.some(function (role) {
                                return role.indexOf('LIMITED_EMPLOYER') !== -1 || role.indexOf('LIMITED_SCIENTIST') !== -1;
                            });
                            if (!isUserLimited && userData) {
                                isUserLimited = userData.profileFillRequired || userData.passwordChangeRequired;
                            }
                            observer.next(isUserLimited);
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        });
    };
    HelperService.prototype.limitedUserRedirect = function () {
        var _this = this;
        // Redirect user to fill profile data
        this.isLimitedUser().subscribe(function (isLimited) {
            if (isLimited) {
                _this.router.navigate(['/profile']);
            }
        });
    };
    HelperService.prototype.printFromImage = function (printEl) {
        this.documentToImage.toPng(printEl).then(function (dataUrl) {
            var popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
            popupWin.document.open();
            popupWin.document.write("\n      <html>\n        <body onload=\"window.print();window.close()\"><img src=\"" + dataUrl + "\"></body>\n      </html>");
            popupWin.document.close();
        });
    };
    HelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HelperService_Factory() { return new HelperService(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.Router)); }, token: HelperService, providedIn: "root" });
    return HelperService;
}());
export { HelperService };
