import * as i0 from "@angular/core";
var credentialsKey = 'credentials';
/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
var CredentialsService = /** @class */ (function () {
    function CredentialsService() {
        this._credentials = null;
        var savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
        if (savedCredentials) {
            this._credentials = JSON.parse(savedCredentials);
        }
    }
    /**
     * Checks is the user is authenticated.
     * @return True if the user is authenticated.
     */
    CredentialsService.prototype.isAuthenticated = function () {
        return !!this.credentials();
    };
    /**
     * Gets the user credentials.
     * @return The user credentials or null if the user is not authenticated.
     */
    CredentialsService.prototype.credentials = function () {
        return this._credentials;
    };
    /**
     * Sets the user credentials.
     * The credentials may be persisted across sessions by setting the `remember` parameter to true.
     * Otherwise, the credentials are only persisted for the current session.
     * @param credentials The user credentials.
     * @param remember True to remember credentials across sessions.
     */
    CredentialsService.prototype.setCredentials = function (credentials, remember, userLogout) {
        if (userLogout === void 0) { userLogout = false; }
        this._credentials = credentials || null;
        if (credentials && !userLogout) {
            var storage = remember ? localStorage : sessionStorage;
            storage.setItem(credentialsKey, JSON.stringify(credentials));
        }
        var isNotExistUserData = JSON.parse(sessionStorage.getItem('userData')) === null;
        var isNotExistCredentials = JSON.parse(sessionStorage.getItem(credentialsKey)) === null;
        if (!isNotExistCredentials && isNotExistUserData && userLogout) {
            sessionStorage.removeItem(credentialsKey);
            localStorage.removeItem(credentialsKey);
        }
    };
    CredentialsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CredentialsService_Factory() { return new CredentialsService(); }, token: CredentialsService, providedIn: "root" });
    return CredentialsService;
}());
export { CredentialsService };
