import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { RegulationsComponent } from './regulations.component';
var ɵ0 = { title: extract('Regulamin') };
var routes = [
    Shell.childRoutes([
        {
            path: 'regulations',
            component: RegulationsComponent,
            data: ɵ0
        }
    ])
];
var RegulationsRoutingModule = /** @class */ (function () {
    function RegulationsRoutingModule() {
    }
    return RegulationsRoutingModule;
}());
export { RegulationsRoutingModule };
export { ɵ0 };
