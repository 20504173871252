import { ManageAccountComponent } from './manage-account.component';
import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
var ɵ0 = { title: extract('Resetowanie hasła') }, ɵ1 = { title: extract('Resetowanie hasła') }, ɵ2 = { title: extract('Aktywacja konta') };
var routes = [
    Shell.childRoutes([
        {
            path: 'reset',
            component: ManageAccountComponent,
            data: ɵ0
        },
        {
            path: 'reset/:passwordResetToken',
            component: ManageAccountComponent,
            data: ɵ1
        },
        {
            path: 'activate/:token',
            component: ManageAccountComponent,
            data: ɵ2
        }
    ])
];
var ManageAccountRoutingModule = /** @class */ (function () {
    function ManageAccountRoutingModule() {
    }
    return ManageAccountRoutingModule;
}());
export { ManageAccountRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
