<div class="ui-dialog-content">
  <div class="spacer"></div>
  <form #createAccountForm="ngForm" *ngIf="!editMode">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="email" class="required">E-mail:</label>
          <input
            type="email"
            class="form-control"
            [(ngModel)]="createUserAccount.email"
            email
            [class.is-invalid]="
              createAccountForm.form.get('email')?.invalid && createAccountForm.form.get('email').touched
            "
            name="email"
            id="email"
            required
          />
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label for="username" class="required">Login:</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="createUserAccount.username"
            name="username"
            id="username"
            required
          />
          <span class="text-small text-muted">Hasło zostanie wygenerowane i wysłane na e-mail</span>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="mn-form-group">
          <label for="passwordRuleId" class="required">Zasada haseł:</label>
          <p-dropdown
            [options]="passwordRules"
            [(ngModel)]="createUserAccount.passwordRuleId"
            placeholder="Wybierz z listy"
            styleClass="form-control"
            name="passwordRuleId"
            id="passwordRuleId"
            filter="true"
            ariaLabelledBy="passwordRuleId"
            inputId="passwordRuleId"
            ariaFilterLabel="Filtrowanie wyników"
            emptyFilterMessage="Brak wyników wyszukiwania"
            baseZIndex="100009"
            required
          ></p-dropdown>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="mn-form-group">
          <label for="rolesId" class="required">Rola:</label>
          <p-dropdown
            [options]="userRoles"
            [(ngModel)]="createUserAccount.rolesId"
            defaultLabel="Wybierz z listy"
            styleClass="form-control"
            placeholder="Wybierz z listy"
            name="rolesId"
            id="rolesId"
            filter="true"
            ariaLabelledBy="rolesIDLabel"
            inputId="rolesIDLabel"
            ariaFilterLabel="Filtrowanie wyników"
            emptyFilterMessage="Brak wyników wyszukiwania"
            baseZIndex="100009"
            required
          ></p-dropdown>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="!createAccountForm.form.valid"
            aria-label="Przycisk tworzenia konta"
            (click)="createAccount()"
          >
            Utwórz konto
          </button>
        </div>
      </div>
    </div>
  </form>
  <form #modifyAccountForm="ngForm" *ngIf="editMode">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="mn-form-group">
          <label for="passwordRuleId" class="required">Zasada haseł:</label>
          <p-dropdown
            [options]="passwordRules"
            [(ngModel)]="modifyAccountModel.passwordRuleId"
            placeholder="Wybierz z listy"
            styleClass="form-control"
            filter="true"
            name="passwordRuleId"
            id="passwordRuleId"
            ariaLabelledBy="passwordRuleId"
            inputId="passwordRuleId"
            ariaFilterLabel="Filtrowanie wyników"
            emptyFilterMessage="Brak wyników wyszukiwania"
            baseZIndex="100009"
          ></p-dropdown>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="mn-form-group">
          <label for="rolesIdentifiers" class="required">Rola:</label>
          <p-dropdown
            [options]="userRoles"
            [(ngModel)]="modifyAccountUserRoleSelectedId"
            placeholder="Wybierz z listy"
            filter="true"
            selectedItemsLabel="{0} wybranych opcji"
            defaultLabel="Wybierz z listy"
            styleClass="form-control"
            name="rolesIdentifiers"
            id="rolesIdentifiers"
            ariaLabelledBy="rolesIDLabel"
            inputId="rolesIDLabel"
            ariaFilterLabel="Filtrowanie wyników"
            emptyFilterMessage="Brak wyników wyszukiwania"
            baseZIndex="100009"
            required
          ></p-dropdown>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="mn-form-group">
          <label for="userStatus" class="required">Status:</label>
          <p-dropdown
            [options]="userEnabledDict"
            [showClear]="true"
            [(ngModel)]="modifyAccountModel.status"
            placeholder="Wybierz z listy"
            styleClass="form-control"
            filter="true"
            id="userStatus"
            name="userStatus"
            ariaLabelledBy="userStatus"
            inputId="userStatus"
            ariaFilterLabel="Filtrowanie wyników"
            emptyFilterMessage="Brak wyników wyszukiwania"
            baseZIndex="100009"
            required
          ></p-dropdown>
        </div>
      </div>
      <div class="col-md-6 col-12 d-flex align-items-center mt-2">
        <div class="form-group">
          <p-checkbox
            binary="true"
            [(ngModel)]="modifyAccountModel.passwordChangeRequired"
            name="passwordChangeRequired"
            id="passwordChangeRequired"
            label="Wymagana zmiana hasła"
            class="ui-checkbox-container"
            required
          ></p-checkbox>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="!modifyAccountForm.form.valid"
            aria-label="Przycisk modyfikacji konta"
            (click)="modifyAccount()"
          >
            Modyfikuj konto
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
