<div class="container">
  <div class="row">
    <div class="w-100">
      <div class="card">
        <div class="card-header">
          <h3>
            Komunikator
            <span *ngIf="getShownUsername()"
              >(Przeglądasz wiadomości użytkownika: {{ getShownUsername() }}
              <i
                class="fas fa-info-circle"
                pTooltip="Aby zobaczyć swoje wiadomości kliknij na liście wyboru użytkowników przycisk 'X'"
                tooltipPosition="top"
                tooltipStyleClass="text-center"
              ></i
              >)</span
            >
          </h3>
        </div>
        <div class="card-body reset-padding-y">
          <div class="row border-bottom communicator-header p-3">
            <div [ngClass]="{ 'col-lg-5 col-md-5 col-sm-12 col-xs-12': isAnyAdmin, 'col-12': !isAnyAdmin }">
              <div class="py-1 d-flex align-items-center h-100">
                <button
                  class="btn btn-primary mr-2"
                  *ngIf="previewMessage && messagesThread?.length"
                  (click)="backToMainList()"
                  pTooltip="Powrót"
                  aria-label="Przycisk powrotu do listy wiadomości"
                  tooltipPosition="top"
                >
                  <i class="fas fa-angle-left"></i>
                </button>
                <button
                  class="btn btn-primary mr-2"
                  *ngIf="previewMessage && messagesThread?.length && activeMessageTab === 1 && !getShownUsername()"
                  aria-label="Przycisk odpowiedzi na wiadomość"
                  (click)="replyMessage()"
                >
                  Odpowiedz
                </button>
                <button
                  class="btn btn-primary mr-2"
                  (click)="contactWithAdmin()"
                  *ngIf="!isAdmin && !isContactAdmin"
                  aria-label="Przycisk kontaktu z administratorem"
                >
                  Kontakt z Administratorem
                </button>
                <button
                  class="btn btn-primary mr-2"
                  (click)="contactWithUsers()"
                  *ngIf="(isAdmin || isContactAdmin) && !previewMessage"
                  aria-label="Przycisk kontaktu z użytkownikami"
                >
                  Kontakt z Użytkownikami
                </button>
              </div>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12" *ngIf="isAnyAdmin && !previewMessage">
              <div class="py-1 d-flex align-items-center justify-content-end flex-wrap">
                <p-dropdown
                  [options]="basicUserData"
                  [(ngModel)]="adminSelectedUserId"
                  styleClass="form-control"
                  [showClear]="true"
                  (onChange)="detectChangeSelectedUser($event)"
                  filter="true"
                  placeholder="Wybierz użytkownika"
                  name="showUserMessages"
                  id="showUserMessages"
                  ariaLabelledBy="specialisation"
                  inputId="specialisation"
                  ariaFilterLabel="Filtrowanie wyników"
                  emptyFilterMessage="Brak wyników wyszukiwania"
                ></p-dropdown>
                <button
                  class="btn btn-primary ml-2 my-1"
                  (click)="showAdminSelectedUserMessage()"
                  *ngIf="(isAdmin || isContactAdmin) && !previewMessage"
                  aria-label="Przycisk kontaktu z użytkownikami"
                >
                  Pokaż wiadomości użytkownika
                </button>
              </div>
            </div>
          </div>
          <ng-container *ngIf="!previewMessage">
            <div class="row border-bottom">
              <div class="col-12">
                <p class="text-center py-2 m-0 font-weight-bold">Lista wiadomości</p>
              </div>
            </div>
            <div class="row min-height-message-list">
              <div class="col-md-3 col-12 px-0 border-right">
                <div class="col-12 px-0 border-bottom">
                  <button
                    class="btn btn-message"
                    (click)="restorePaginator(); getListMessage()"
                    aria-label="Odebrane wiadomości"
                    [ngClass]="{ active: activeMessageTab === 1 }"
                  >
                    <i class="fas fa-envelope mx-3"></i>
                    Odebrane
                  </button>
                </div>
                <div class="col-12 px-0 border-bottom">
                  <button
                    class="btn btn-message"
                    (click)="restorePaginator(); getListSentMessage()"
                    aria-label="Przycisk wysłane wiadomości"
                    [ngClass]="{ active: activeMessageTab === 2 }"
                  >
                    <i class="fas fa-paper-plane mx-3"></i>
                    Wysłane
                  </button>
                </div>
                <div class="col-12 px-0 border-bottom">
                  <button
                    class="btn btn-message"
                    (click)="restorePaginator(); getListArchiveMessage()"
                    aria-label="Przycisk wiadomości archiwalne"
                    [ngClass]="{ active: activeMessageTab === 3 }"
                  >
                    <i class="fas fa-folder mx-3"></i>
                    Archiwum
                  </button>
                </div>
              </div>
              <div class="col-md-9 col-12">
                <ng-container *ngIf="messagesList?.length">
                  <div
                    class="row message-border-bottom"
                    *ngFor="let messagesListItem of messagesList"
                    [ngClass]="{ isNotRead: !messagesListItem.isRead }"
                  >
                    <div
                      class="col-12"
                      (click)="openMessage(messagesListItem.id)"
                      (contextmenu)="onLinkRightClicked(messagesListItem.id, $event)"
                      role="button"
                      aria-pressed="false"
                    >
                      <div class="row main-email">
                        <div class="col-12">
                          <div class="mail-data">
                            <div class="mail-data-header py-2 mb-2 border-bottom">
                              <div class="user-profile">
                                <div class="user-profile-icon">
                                  <img
                                    src="assets/images/scientist-icon.png"
                                    class="img-fluid profile-icon"
                                    *ngIf="messagesListItem.senderRole === 'SCIENTIST'"
                                    alt="Naukowiec"
                                  />
                                  <img
                                    src="assets/images/employer-icon.png"
                                    class="img-fluid profile-icon"
                                    *ngIf="messagesListItem.senderRole === 'EMPLOYER'"
                                    alt="Przedsiębiorca"
                                  />
                                  <div
                                    class="admin-profile"
                                    *ngIf="
                                      messagesListItem.senderRole === 'ADMIN' ||
                                      messagesListItem.senderRole === 'CONTACT_ADMIN'
                                    "
                                  >
                                    <i class="fas fa-user"></i>
                                  </div>
                                </div>
                                <span class="ml-4 text-center">{{ messagesListItem.senderDisplayName }}</span>
                              </div>
                              <div class="mail-date">
                                <span>{{ messagesListItem.createTime | date: 'dd/MM/yyyy HH:mm:ss' }}</span>
                              </div>
                            </div>
                            <div class="mail-data-content pb-2">
                              <span class="font-weight-bold">Temat: {{ messagesListItem.title }}</span>
                              <div [innerHTML]="messagesListItem.shortContent"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 pb-2">
                      <button
                        class="btn btn-primary mr-2"
                        type="button"
                        (click)="replyMessageFormList(messagesListItem.id)"
                        *ngIf="activeMessageTab === 1 && !getShownUsername()"
                      >
                        Odpowiedz
                      </button>
                      <button class="btn btn-primary" (click)="openMessage(messagesListItem.id)">
                        Pokaż wątek
                      </button>
                    </div>
                  </div>
                  <p-contextMenu #messageContextMenu appendTo="body"></p-contextMenu>
                  <p-confirmDialog
                    header="Usuń wiadomość"
                    icon="pi pi-exclamation-triangle"
                    acceptLabel="Tak"
                    rejectLabel="Nie"
                  ></p-confirmDialog>
                </ng-container>
                <div class="row">
                  <div class="col-12" *ngIf="!messagesList?.length">
                    <p-messages [(value)]="msgs" [closable]="false"></p-messages>
                  </div>
                  <div class="col-12 p-0" *ngIf="messagesList?.length">
                    <p-paginator
                      [rows]="paginator.size"
                      [totalRecords]="paginator.totalElements"
                      (onPageChange)="paginate($event)"
                      [rowsPerPageOptions]="[10, 20, 50, 100]"
                    ></p-paginator>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="row" *ngIf="previewMessage && messagesThread?.length">
            <div class="col-12 border-top" *ngFor="let message of messagesThread">
              <div class="row mail-box my-2">
                <div class="col-md-2 col-12 border-right">
                  <div class="user-profile d-flex flex-column align-items-center w-100">
                    <div class="user-profile-icon">
                      <img
                        src="assets/images/scientist-icon.png"
                        class="img-fluid profile-icon"
                        alt="Naukowiec"
                        *ngIf="message.role === 'SCIENTIST'"
                      />
                      <img
                        src="assets/images/employer-icon.png"
                        class="img-fluid profile-icon"
                        alt="Przedsiębiorca"
                        *ngIf="message.role === 'EMPLOYER'"
                      />
                      <div class="admin-profile" *ngIf="message.role === 'ADMIN' || message.role === 'CONTACT_ADMIN'">
                        <i class="fas fa-user"></i>
                      </div>
                    </div>
                    <span class="text-center">{{ message.displayName }}</span>
                  </div>
                </div>
                <div class="col-md-10 col-12">
                  <div class="d-flex justify-content-between">
                    <p class="font-weight-bold">{{ message.title }}</p>
                    <span>{{ message.sendDate | date: 'dd/MM/yyyy HH:mm:ss' }}</span>
                  </div>
                  <p [innerHTML]="message.content"></p>
                </div>
              </div>
            </div>
            <div class="col-12 border-top" *ngIf="messagesThread[0].receiverDisplayName">
              <p class="my-2">
                <i>Rozpoczędzie komunikacji z: {{ getStartUserConversationName() }}</i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
