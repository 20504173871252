import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef, ComponentRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DialogBaseService } from '@app/shared/dialog/dialog-base.service';

// tslint:disable: max-line-length
import { UserTypeComponent } from '@app/entities/admin-app/user-management/reqister-dialogs/user-type/user-type.component';
import { AccountTypeSelectionComponent } from '@app/entities/registration-dialogs/account-type-selection/account-type-selection.component';
import { ScientistRegistrationComponent } from '@app/entities/registration-dialogs/scientist-registration/scientist-registration.component';
import { EmployerRegistrationComponent } from '@app/entities/registration-dialogs/employer-registration/employer-registration.component';
import { PasswordRuleDialogComponent } from '@app/entities/admin-app/configuration-app/password-rule-dialog/password-rule-dialog.component';
import { CreateAccountComponent } from './../../../entities/registration-dialogs/create-account/create-account.component';
import { RegeneratePasswordComponent } from './../../../entities/registration-dialogs/regenerate-password/regenerate-password.component';
import { SendMessageComponent } from '@app/entities/communicator/send-message/send-message.component';
import { ShowUserProfileComponent } from '@app/entities/search/show-user-profile/show-user-profile.component';
import { DictionaryEditComponent } from '@app/entities/admin-app/configuration-app/dictionary-edit/dictionary-edit.component';

@Component({
  templateUrl: './dialog-dynamic-template.component.html'
})
export class DialogDynamicTemplateComponent implements OnInit {
  @ViewChild('childContainer', { read: ViewContainerRef, static: false }) childContainer: ViewContainerRef;

  public componentsList = {
    UserTypeComponent,
    AccountTypeSelectionComponent,
    ScientistRegistrationComponent,
    CreateAccountComponent,
    RegeneratePasswordComponent,
    EmployerRegistrationComponent,
    SendMessageComponent,
    ShowUserProfileComponent,
    PasswordRuleDialogComponent,
    DictionaryEditComponent
  };
  private _dialogBaseSubscription$: Subject<any> = new Subject();

  constructor(private componentResolver: ComponentFactoryResolver, private _dialogBaseService: DialogBaseService) {}

  ngOnInit() {
    this.subscribeToDialogBaseStatus();
  }

  switchComponentsTemplate(componentName: string) {
    const componentFactory = this.componentResolver.resolveComponentFactory(this.componentsList[componentName]);
    this.childContainer.clear();
    const componentRef: ComponentRef<any> = this.childContainer.createComponent(componentFactory);
  }

  private subscribeToDialogBaseStatus() {
    this._dialogBaseService
      .getSwitchTemplateStatus()
      .pipe(takeUntil(this._dialogBaseSubscription$))
      .subscribe(componentName => {
        this.switchComponentsTemplate(componentName);
      });
  }
}
